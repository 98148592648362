import React, { useEffect, useState } from 'react';
import { Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { addFileStore, getFileStoreInfo, updateProfile } from '../volumeActions';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmModal from './ConfirmModal';
import AddSMBModal from './AddSMBModal';

const WindowsSmbTarget = ({ userProfile, createFlag, fileStoreInfo, fileStoreType }) => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [updateDetails, setUpdateDetails] = useState({});
  const [selectCheck, setSelectCheck] = useState(false);
  const [openSMBModal, setOpenSMBModal] = useState(false);
  const [uncPath, setUncPath] = useState("")

  const schema = yup.object().shape({
    cloudProfileId: yup.string().required('required'),
    fileStoreType: yup.string().required('required'),
  });
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (params.id) {
      if (!isEmpty(fileStoreInfo)) {
        reset(fileStoreInfo);
      }
    }
  }, [fileStoreInfo]);

  useEffect(() => {
    if (userProfile && userProfile.length) {
      userProfile.map((item, i) => {
        if (item.id == watch('cloudProfileId')) {
          setUncPath(item.uncpath)
        }
      })
    }
  }, [watch('cloudProfileId')])

  const onSubmit = (data) => {
    let companyId = localStorage.getItem('companyId');
    data.encryption = false;
    data.fileStoreTempPath = "";
    data.fileStorePath = uncPath;
    if (params.id) {
      setOpenModal(true);
      setUpdateDetails(data);
    } else {
      dispatch(
        updateProfile(
          {
            id: 0,
            companyId: +companyId,
            fileStoreType: data.fileStoreType,
            // fileStoreName: data.fileStoreName,
            fileStorePath: uncPath || "",
            writable: true,
            fileStoreTempPath: '',
            // fileStoreTempPath: `${data.fileStoreName}/${localStorage.getItem('companyTagLocal')}/filestore/content` || '',
            encryption: data.encryption || false,
            fileStoreBase: +fileStoreType,
            fileStoreRegionName: data.fileStoreRegionName || '',
            fileStoreRegionId: data.fileStoreRegionId || 0,
            markAsDefault: data.markAsDefault || false,
            awsAuthorizedPassword: '',
            awsDefaultBasket: 0,
            awsAccessKeyId: '',
            awsSecretAccessKey: '',
            awsAccessUrl: '',
            azureConnectionString: '',
            userProfile: data.userProfile || '',
            cloudProfileId: data.cloudProfileId || '',
            azueTieringByModifiedDate: data.azueTieringByModifiedDate || false,
            azureTieringYear: data.azureTieringYear || '',
            azureArhciveTierPolicy: data.azureArhciveTierPolicy || null,
          },
          'POST',
          'file-stores',
          navigate
        )
      );
    }
  };

  return (
    <>
      <AddSMBModal
        openAzureModal={openSMBModal}
        close={() => setOpenSMBModal(!openSMBModal)}
        fileStoreType={fileStoreType}
      />
      <ConfirmModal openModal={openModal} close={() => setOpenModal(false)} updateDetails={updateDetails} />
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid xs={12} md={11}>
          <Stack direction={{ md: 'row', sm: 'column' }} spacing={2}>
            <FormControl sx={{ flex: 5 }} fullWidth>
              <InputLabel id="user">Select SMB Profile</InputLabel>
              <Select
                name="cloudProfileId"
                labelId="user"
                variant="standard"
                label="Select SMB Profile"
                value={watch('cloudProfileId') || ''}
                helperText={errors.cloudProfileId && errors.cloudProfileId.message}
                error={errors.cloudProfileId && errors.cloudProfileId.message}
                {...register('cloudProfileId')}
              >
                {userProfile && userProfile.length
                  ? userProfile.map((user) => {
                    return (
                      <MenuItem key={user.id} value={user.id}>
                        {user.name}
                      </MenuItem>
                    );
                  })
                  : null}
              </Select>
            </FormControl>
            <Button variant="contained" sx={{ color: '#ffff', flex: 1 }} onClick={() => setOpenSMBModal(true)}>
              Create SMB Profile
            </Button>
          </Stack>
        </Grid>

        <Grid item xs={12} md={11}>
          <TextField
            name="fileStoreType"
            label="File Store Name"
            // value={fileStore.fileStoreType}
            variant="standard"
            helperText={errors.fileStoreType && errors.fileStoreType.message}
            error={errors.fileStoreType && errors.fileStoreType.message}
            {...register('fileStoreType')}
            sx={{ width: '100%', marginTop: '15px' }}
            InputLabelProps={{
              shrink: true,
            }}
            // onChange={(e) => setFileStore({ ...fileStore, fileStoreName: e.target.value, fileStoreType: e.target.value })}
            required
          />
        </Grid>

        <Grid item xs={12} md={11}>

          <Grid item xs={12} md={11} sx={{ mt: 2, pt: 1, pb: 1 }}>
            {/* <Button variant="contained" sx={{ color: '#ffff' }} onClick={() => setOpenSMBModal(true)}>
              Create SMB Profile
            </Button> */}
            <Button type="submit" variant="contained" sx={{ color: '#ffff', ml: '10px' }}>
              {createFlag ? 'Submit' : 'Update'}
            </Button>
            <Link to="/dashboard/volume-management" style={{ textDecoration: 'none' }}>
              <Button variant="contained" sx={{ color: '#ffff', ml: '10px' }}>
                Back
              </Button>
            </Link>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default WindowsSmbTarget;
