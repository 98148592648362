import React, { useEffect, useState } from 'react';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import AddS3CloudModal from './AddS3CloudModal';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfile } from '../volumeActions';
import ConfirmModal from './ConfirmModal';

const S3Cloud = ({ userProfile, fileStoreInfo, createFlag, fileStoreType }) => {
  const params = useParams();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [updateDetails, setUpdateDetails] = useState({});
  const dispatch = useDispatch();
  const region = useSelector((state) => state.volumeReducer.region);
  const [openCloudModal, setOpenCloudModal] = useState(false);

  const schema = yup.object().shape({
    cloudProfileId: yup.string().required('required'),
    fileStoreType: yup.string().required('required'),
  });
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (params.id) {
      if (!isEmpty(fileStoreInfo)) {
        reset(fileStoreInfo);
      }
    }
  }, [fileStoreInfo]);

  const onSubmit = (data) => {
    let companyId = localStorage.getItem('companyId');
    if (params.id) {
      setOpenModal(true);
      let editData = {
        awsAccessKeyId: '',
        awsAccessUrl: '',
        awsAuthorizedPassword: '',
        awsDefaultBasket: 0,
        awsSecretAccessKey: '',
        // azueTieringByModifiedDate: false,
        azureArhciveTierPolicy: data.azureArhciveTierPolicy || null,
        azureConnectionString: '',
        azureTieringYear: data.azureTieringYear || 0,
        cloudProfileId: +data.cloudProfileId || '',
        companyId: +companyId,
        companyName: data.companyName,
        encryption: data.encryption || false,
        fileStoreBase: +fileStoreType,
        fileStoreName: data.fileStoreName,
        fileStorePath: `${data.fileStoreName}/${localStorage.getItem('companyTagLocal')}/filestore/content` || '',
        fileStoreRegionName: data.fileStoreRegionName || '',
        fileStoreTempPath: data.fileStoreTempPath,
        fileStoreType: data.fileStoreType,
        id: data.id,
        markAsDefault: data.markAsDefault || false,
        writable: true,
      };
      setUpdateDetails(editData);
      // azueTieringByModifiedDate: data.azueTieringByModifiedDate || false,
      // userProfile: data.userProfile || '',
      // fileStoreRegionId: data.fileStoreRegionId || 0,
    } else {
      dispatch(
        updateProfile(
          {
            awsAccessKeyId: '',
            awsAccessUrl: '',
            awsAuthorizedPassword: '',
            awsDefaultBasket: 0,
            awsSecretAccessKey: '',
            azureArhciveTierPolicy: data.azureArhciveTierPolicy || null,
            azureConnectionString: '',
            azureTieringYear: data.azureTieringYear || '',
            cloudProfileId: +data.cloudProfileId || '',
            companyId: +companyId,

            encryption: data.encryption || false,
            fileStoreBase: +fileStoreType,
            fileStoreName: data.fileStoreName || '',
            fileStorePath:
              `${data.fileStoreName || ''}/${localStorage.getItem('companyTagLocal')}/filestore/content` || '',
            fileStoreRegionName: data.fileStoreRegionName || '',
            fileStoreTempPath: data.fileStoreTempPath || '',
            fileStoreType: data.fileStoreType,
            id: 0,
            markAsDefault: data.markAsDefault || false,
            writable: true,

            fileStoreRegionId: data.fileStoreRegionId || 0,
            userProfile: data.userProfile || '',
            azueTieringByModifiedDate: data.azueTieringByModifiedDate || false,
          },
          'POST',
          'file-stores',
          navigate
        )
      );
    }
  };

  return (
    <>
      <AddS3CloudModal openModal={[openCloudModal, setOpenCloudModal]} region={region} fileStoreType={fileStoreType} />
      <ConfirmModal openModal={openModal} close={() => setOpenModal(false)} updateDetails={updateDetails} />
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid item xs={12} md={11}>
          <Stack direction={{ md: 'row', sm: 'column' }} spacing={2}>
            <FormControl sx={{ flex: 5 }} fullWidth>
              <InputLabel id="user">S3 User Profile</InputLabel>
              <Select
                name="cloudProfileId"
                labelId="user"
                variant="standard"
                label="S3 User Profile"
                value={watch('cloudProfileId') || ''}
                helperText={errors.cloudProfileId && errors.cloudProfileId.message}
                error={errors.cloudProfileId && errors.cloudProfileId.message}
                {...register('cloudProfileId')}
              >
                {userProfile && userProfile.length
                  ? userProfile.map((user) => {
                      return (
                        <MenuItem key={user.id} value={user.id}>
                          {user.name}
                        </MenuItem>
                      );
                    })
                  : null}
              </Select>
            </FormControl>
            <Button variant="contained" sx={{ color: '#ffff', flex: 1 }} onClick={() => setOpenCloudModal(true)}>
              Create S3 Profile
            </Button>
          </Stack>
        </Grid>

        <Grid item xs={12} md={11}>
          <TextField
            name="fileStoreType"
            label="Bucket Name"
            // value={fileStore.fileStoreType}
            variant="standard"
            helperText={errors.fileStoreType && errors.fileStoreType.message}
            error={errors.fileStoreType && errors.fileStoreType.message}
            {...register('fileStoreType')}
            sx={{ width: '100%', marginTop: '15px' }}
            InputLabelProps={{
              shrink: true,
            }}
            // onChange={(e) => setFileStore({ ...fileStore, fileStoreName: e.target.value, fileStoreType: e.target.value })}
            required
          />
        </Grid>
        <Grid item xs={12} md={11} sx={{ mt: '20px' }}>
          {/* <Button variant="contained" sx={{ color: '#ffff' }} onClick={() => setOpenCloudModal(true)}>
            Create S3 Profile
          </Button> */}
          <Button type="submit" variant="contained" sx={{ color: '#ffff', ml: '10px' }}>
            {createFlag ? 'Submit' : 'Update'}
          </Button>
          <Link to="/dashboard/volume-management" style={{ textDecoration: 'none' }}>
            <Button variant="contained" sx={{ color: '#ffff', ml: '10px' }}>
              Back
            </Button>
          </Link>
        </Grid>
      </form>
    </>
  );
};

export default S3Cloud;
