import { CheckBox } from '@mui/icons-material';
import {
  Card,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const BasicInfo = ({
  types,
  agents,
  jobInfo,
  register,
  errors,
  watch,
  proxyPaths,
  setValue,
  setIsLocal,
  setFileType,
  getValues,
  proxy,
  proxyCheck
}) => {
  const policies = useSelector((state) => state.jobManagementReducer.policies);
  const [proxyIds, setProxyIds] = proxy;
  useEffect(() => {
    if (jobInfo.proxyStubServerIds) {
      setProxyIds(jobInfo.proxyStubServerIds);
    }
  }, [jobInfo]);

  useEffect(() => {
    if (agents && agents.length) {
      for (let i = 0; i < agents.length; i++) {
        if (agents[i].id == watch('agentId')) {
          if (agents[i].hostname == 'localhost') {
            setIsLocal(0);
            return;
          } else {
            setIsLocal(1);
          }
        } else {
          setIsLocal(1);
        }
      }
      if (watch('agentId') == 1) {
        // setFileType(0);
        setValue('stagingPath', '');
      }
    }
  }, [watch('agentId')]);

  useEffect(() => {
    setValue('proxyStubServerIds', proxyIds);
  }, [proxyIds]);

  const sharePathClick = (e, p) => {
    let val = e.target.checked;
    if (val) {
      setProxyIds([...proxyIds, p.id]);
    } else {
      let ids = proxyIds.filter((id) => id != p.id);
      setProxyIds([...ids]);
    }
  };
  const checkProxyPath = (p) => {
    let val = false;
    proxyIds.map((id) => {
      if (id == p.id) {
        val = true;
        return val;
      }
    });
    return val;
  };

  return (
    <Card sx={{ m: '20px 80px 40px 80px', p: '40px' }}>
      {policies.length ?
        <Grid container spacing={3} sx={{ mx: '10px' }}>
          {/* {watch('fileSystemType') == 'remote' && (
          
        )} */}
          <>
            <Grid item xs={12} md={6}>
              <TextField
                name="name"
                label="Name"
                helperText={errors.name && errors.name.message}
                error={errors.name && errors.name.message}
                {...register('name')}
                variant="standard"
                sx={{ width: '100%' }}
                InputLabelProps={{
                  shrink: true,
                }}
                // onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <TextField
                label="Description"
                name="description"
                // value={jobDetails.description || ''}
                helperText={errors.description && errors.description.message}
                error={errors.description && errors.description.message}
                {...register('description')}
                variant="standard"
                sx={{ width: '100%' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={10} md={6}>
              <FormControl fullWidth>
                <InputLabel id="type">Type</InputLabel>
                <Select
                  name="actionType"
                  labelId="type"
                  variant="standard"
                  value={watch('actionType') || ''}
                  label="Type"
                  {...register('actionType')}
                >
                  {types.map((type, i) => {
                    return (
                      <MenuItem key={i} value={type.value}>
                        {type.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            {watch('actionType') != 'DELETESTUB' && (
              <Grid item xs={10} md={5}>
                <FormControl fullWidth>
                  <InputLabel id="agent">Agent</InputLabel>
                  {/* {watch('selected') == 0 && ( */}
                  <Select
                    name="agentId"
                    labelId="agent"
                    variant="standard"
                    label="Agent"
                    value={watch('agentId') || ''}
                    {...register('agentId')}
                  >
                    {agents &&
                      agents.content.map((agent) => {
                        return (
                          <MenuItem key={agent.id} value={agent.id} sx={(agent.online) ? { color: "#45b55f" } : { color: "#f53b3b" }}>
                            {agent.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  {/* )} */}
                </FormControl>
              </Grid>
            )}
          </>
        </Grid> :
        <Typography sx={{ textAlign: 'center' }} variant='h6'>No policy is available, a policy must be created before a job is created!</Typography>
      }
    </Card>
  );
};

export default BasicInfo;
