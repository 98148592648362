import {
  Button,
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';

const UserSearchHistory = ({ rows, pages, pageRows, handleRerun }) => {
  const loading = useSelector((state) => state.ediscoveryReducer.loading);
  const [historyPage, setHistoryPage] = pages;
  const [historyPerPage, setHistoryPerPage] = pageRows;
  const handleChangeRowsPerPage = (event) => {
    setHistoryPerPage(parseInt(event.target.value));
    setHistoryPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setHistoryPage(newPage);
  };
  return (
    <>
      <Card sx={{ p: '20px' }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Source IP</TableCell>
                <TableCell>Keywords</TableCell>
                <TableCell>Files</TableCell>
                <TableCell align="center">Repository Results</TableCell>
                <TableCell align="center">File Server Results</TableCell>
                <TableCell>Date/Time</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.content && rows.content.length ? (
                rows.content.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell sx={{ py: 0 }}>{row.sourceIP}</TableCell>
                    <TableCell sx={{ py: 0 }}>
                      {row.request.keyWords == 'email-compliance-data'
                        ? 'email-compliance'
                        : row.request.keyWords.length
                        ? row.request.keyWords
                        : 'null'}
                    </TableCell>
                    <TableCell sx={{ py: 0 }}>
                      {row.request.fileNames[0].length ? row.request.fileNames.toString() : 'null'}
                    </TableCell>
                    <TableCell sx={{ py: 0 }} align="center">
                      {row.repoResultCount}
                    </TableCell>
                    <TableCell sx={{ py: 0 }} align="center">
                      {row.fsResultCount}
                    </TableCell>
                    <TableCell sx={{ py: 0 }}>{moment(row.callTime).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                    <TableCell sx={{ py: 0 }}>
                      <Button onClick={() => handleRerun(row)}>Rerun</Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : loading ? (
                <TableRow>
                  <TableCell align="center" colSpan={7}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50, 100]}
            component="div"
            count={rows.totalElements ? rows.totalElements : 0}
            rowsPerPage={historyPerPage}
            page={historyPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Card>
    </>
  );
};

export default UserSearchHistory;
