import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardHeader, Container, Grid, TextField } from '@mui/material';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import LoadingScreen from 'src/components/LoadingScreen';
import { getBuildInfo } from './buildInfoActions';

export default function BuildInfo() {
  const dispatch = useDispatch();
  const { themeStretch } = useSettings();
  const buildInfo = useSelector((state) => state.buildInfoReducer.buildInfo);
  const loading = useSelector((state) => state.buildInfoReducer.loading);

  useEffect(() => {
    dispatch(getBuildInfo());
  }, []);

  const buildDate = (param) => {
    var dd = param.getDate();
    var mm = param.getMonth() + 1;

    var yyyy = param.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    return dd + '/' + mm + '/' + yyyy;
  }

  return (
    <Page>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} style={{ paddingTop: '1px' }}>
            {!loading ? (
              buildInfo && (
                <Card>
                  <CardHeader title="Build Info" />
                  <Grid container spacing={2} sx={{ m: '40px', mt: 1 }}>
                    <Grid item xs={12} md={5}>
                      <TextField
                        id="standard-basic"
                        value={buildDate(new Date(buildInfo.build.time))}
                        label="Build Date"
                        variant="standard"
                        sx={{ width: '100%' }}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <TextField
                        id="standard-basic"
                        value={buildInfo.build.version}
                        label="Build Version"
                        variant="standard"
                        sx={{ width: '100%' }}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <TextField
                        id="standard-basic"
                        value={buildInfo.git.branch}
                        label="Branch"
                        variant="standard"
                        sx={{ width: '100%' }}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <TextField
                        id="standard-basic"
                        value={buildInfo.git.commit?.id?.abbrev || buildInfo.git.commit?.id}
                        label="Commit Id"
                        variant="standard"
                        sx={{ width: '100%' }}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <TextField
                        id="standard-basic"
                        value={buildInfo.env.java['runtime-version']}
                        label="Runtime Version"
                        variant="standard"
                        sx={{ width: '100%' }}
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Card>
              )
            ) : (
              <LoadingScreen />
            )}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
