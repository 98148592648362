import { Card, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LineChart from '../../charts/LineChart';
import { getFilesArchievd } from '../repositoryActions';

const days = [
  { label: 'Last 24 hours', value: '24hr' },
  { label: 'Yesterday', value: 'Yesterday' },
  { label: 'Week', value: 'Week' },
  { label: 'Month', value: 'Month' },
  { label: 'Year', value: 'Year' },
];

const FileArchieved = () => {
  const dispatch = useDispatch();
  const filesArchieved = useSelector((state) => state.repositoryReducer.filesArchieved);
  const [range, setRange] = useState('24hr');
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState(new Date());
  const [archievedFilesLabels, setArchievedFilesLabels] = useState();
  const [archievedFilesData, setArchievedFilesData] = useState();
  useEffect(() => {
    let start;
    if (range == 'Yesterday') {
      let startingDate = new Date();
      let date = startingDate.setDate(startingDate.getDate() - 1);
      start = new Date(date).setHours(0, 0, 0);
      setStartDate(start);
      setEndDate(new Date(date).setHours(23, 59, 59));
    } else if (range == 'Week') {
      let weekDate = new Date();
      let weekD = weekDate.getDate() - weekDate.getDay();
      start = new Date(weekDate.setDate(weekD)).setHours(0, 0, 0);
      setStartDate(start);
      setEndDate(new Date().setHours(23, 59, 59));
    } else if (range == 'Month') {
      let startingDate = new Date();
      let date = startingDate.setDate(1);
      start = new Date(date).setHours(0, 0, 0);
      setStartDate(start);
      setEndDate(new Date(endDate).setHours(23, 59, 59));
    } else if (range == 'Year') {
      let startingDate = new Date();
      let date = startingDate.setMonth(0) && startingDate.setDate(1);
      start = new Date(date).setHours(0, 0, 0);
      setStartDate(start);
      setEndDate(new Date().setHours(23, 59, 59));
    } else if (range == '24hr') {
      let startingDate = new Date();
      let hour = startingDate.getHours();
      let minutes = startingDate.getMinutes();
      let date = startingDate.setDate(startingDate.getDate() - 1);
      start = new Date(date).setHours(hour, minutes, 0);
      setStartDate(start);
      setEndDate(new Date().setHours(hour, minutes, 0));
    }
  }, [range]);

  const dateFormatCompare = (dateC) => {
    let date = new Date(dateC);
    return date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
  };

  useEffect(() => {
    const labels = [];
    const data = [];
    let dataBucket = [];

    if (filesArchieved['aggregations'] !== undefined) {
      for (let bucket of filesArchieved['aggregations'][2].buckets) {
        let year = bucket.key;
        if (year >= startDate && year <= endDate) {
          if (labels.indexOf(dateFormatCompare(bucket.key)) == -1) {
            labels.push(dateFormatCompare(bucket.key));
          }
        }
      }
      for (let bucket of filesArchieved['aggregations'][2].buckets) {
        let year = bucket.key;
        if (year >= startDate && year <= endDate) {
          dataBucket[labels.indexOf(dateFormatCompare(bucket.key))] = bucket.doc_count;
        }
      }
      data.push({
        data: dataBucket,
        fill: false,
        name: 'Number Of Files',
        showLine: true,
        label: 'No. of Files',
        xlabel: 'Dates',
      });
      dataBucket = [];
    }
    setArchievedFilesData(data);
    setArchievedFilesLabels(labels);
  }, [filesArchieved]);

  useEffect(() => {
    const params = {
      startTimeInMs: startDate,
      endTimeInMs: new Date(endDate).setHours(23, 59, 59),
    };
    if (startDate && endDate) {
      dispatch(getFilesArchievd(params));
    }
  }, [startDate]);
  return (
    <>
      <Card sx={{ mb: '20px' }}>
        <Grid container>
          <Grid item xs={12} md={11}>
            <FormControl variant="standard" sx={{ m: '20px' }} fullWidth>
              <InputLabel id="demo-simple-select-standard-label">Select Range</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={range}
                onChange={(e) => setRange(e.target.value)}
                label="Range"
                name="range"
              >
                {days.map((option, i) => (
                  <MenuItem key={i} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12}>
            <LineChart
              labels={archievedFilesLabels}
              data={archievedFilesData}
              chartHeading="Line graph showing file Archived per Hour"
            />
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default FileArchieved;
