import {
  Button,
  Card,
  CardHeader,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingScreen from 'src/components/LoadingScreen';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import AddAzureModal from './AddAzureModal';
import { addFileStore, getAwsRegion, getFileStoreInfo, getUserProfile } from '../volumeActions';
import AzureBlobStore from './AzureBlobStore';
import FileSystem from './FileSystem';
import S3Cloud from './S3Cloud';
import AddS3CloudModal from './AddS3CloudModal';
import { Link, useParams } from 'react-router-dom';
import WindowsSmbTarget from './WindowsSmbTarget';
const AddFileStore = () => {
  const params = useParams();
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.volumeReducer.userProfile);
  const fileStoreInfo = useSelector((state) => state.volumeReducer.fileStoreInfo);
  const loading = useSelector((state) => state.volumeReducer.loading);
  const [fileStoreType, setFileStoreType] = useState("1");
  const [createFlag, setCreateFlag] = useState(true);
  const [fileStore, setFileStore] = useState({
    id: 0,
    companyId: +localStorage.getItem('companyId'),
    fileStoreType: '',
    fileStoreName: '',
    fileStorePath: '',
    writable: true,
    fileStoreTempPath: '',
    encryption: false,
    fileStoreBase: 1,
    fileStoreRegionName: '',
    fileStoreRegionId: 0,
    markAsDefault: false,
    awsAuthorizedPassword: '',
    awsDefaultBasket: 0,
    awsAccessKeyId: '',
    awsSecretAccessKey: '',
    awsAccessUrl: '',
    azureConnectionString: '',
    userProfile: '',
    cloudProfileId: '',
    azueTieringByModifiedDate: false,
    azureTieringYear: '',
    azureArhciveTierPolicy: null,
  });

  useEffect(() => {
    let { id } = params;
    if (id) {
      // User Edit
      dispatch(getFileStoreInfo(params.id));
      setCreateFlag(false);
    } else {
      // Add
      setCreateFlag(true);
    }
  }, []);

  useEffect(() => {
    dispatch(getUserProfile(fileStoreType));
    dispatch(getAwsRegion());
  }, [fileStoreType]);

  useEffect(() => {
    if (createFlag) {
      setFileStoreType(1)
    } else {
      setFileStoreType(fileStoreInfo.fileStoreBase)
    }
  }, [fileStoreInfo])

  const RenderStoreType = () => {
    return (
      <>
        {fileStoreType == 3 ? (
          <AzureBlobStore userProfile={userProfile} fileStoreInfo={fileStoreInfo} fileStoreType={fileStoreType} createFlag={createFlag} />
        ) : fileStoreType == 2 ? (
          <S3Cloud userProfile={userProfile} fileStoreInfo={fileStoreInfo} fileStoreType={fileStoreType} createFlag={createFlag} />
        )
          : fileStoreType == 4 ? (
            <WindowsSmbTarget userProfile={userProfile} fileStoreInfo={fileStoreInfo} fileStoreType={fileStoreType} createFlag={createFlag} />
          )
            : (
              <FileSystem createFlag={createFlag} fileStoreInfo={fileStoreInfo} fileStoreType={fileStoreType} />
            )}
      </>
    );
  };

  return (
    <Page>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        {!loading ? (
          <Card sx={{ p: '20px' }}>
            <CardHeader title={createFlag ? 'Add FileStore' : 'Update FileStore'} />
            <Grid container spacing={3} sx={{ m: '20px' }}>
              <Grid item xs={10} md={3}>
                {/* <FormControl
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <FormLabel id="sourceId">File Store Type</FormLabel>
                  <RadioGroup
                    aria-labelledby="sourceId"
                    name="fileStoreBase"
                    value={fileStoreType}
                    sx={{ display: 'flex', flexDirection: 'row' }}
                    onChange={(e) => setFileStoreType(e.target.value)}
                  >
                    <FormControlLabel value={"1"} control={<Radio />} label="File System" />
                    <FormControlLabel value={"2"} control={<Radio />} label="S3 Cloud" />
                    <FormControlLabel value={'3'} control={<Radio />} label="Azure Blob Store" />
                    <FormControlLabel value={4} control={<Radio />} label="Windows SMB Target" />
                  </RadioGroup>
                </FormControl> */}
                <FormControl fullWidth>
                  <InputLabel id="agent" sx={{ marginLeft: '-15px' }}>File Store Type</InputLabel>
                  <Select
                    name="fileStoreBase"
                    labelId="agent"
                    variant="standard"
                    label="File Store"
                    value={fileStoreType || ''}
                    onChange={(e) => {
                      setFileStoreType(e.target.value);
                    }}
                  >
                    <MenuItem value={"1"}>File System</MenuItem>
                    <MenuItem value={"2"}>S3 Cloud</MenuItem>
                    <MenuItem value={"3"}>Azure Blob Store</MenuItem>
                    <MenuItem value={4}>SMB/CIFS Target</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <RenderStoreType />
              </Grid>
            </Grid>
          </Card>
        ) : (
          <LoadingScreen />
        )}
      </Container>
    </Page>
  );
};

export default AddFileStore;
