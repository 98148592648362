import { useTheme } from '@mui/material/styles';
import {
  Box,
  Card,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  Typography,
  TableContainer,
  TablePagination,
  Stack,
  TextField,
  Grid,
  Tooltip,
  tooltipClasses,
} from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Scrollbar from '../../../../components/Scrollbar';
import { Link } from 'react-router-dom';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import CopyModal from './CopyModal';
import { useEffect, useState } from 'react';
import DeleteAlert from 'src/pages/DeleteAlert';
import { deleteJob } from '../jobManagementActions';
import { useDispatch } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { styled } from '@mui/styles';
import moment from 'moment';

export default function JobManagementTable({ pages, size, jobs, columns, sortData }) {
  const [rowsPerPage, setRowsPerPage] = size;
  const [page, setPage] = pages;
  const [openCopyModal, setOpenCopyModal] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [jobId, setJobId] = useState('');
  const [deleteId, setDeleteId] = useState('');
  const [searchValue, setSearchValue] = useState();
  const [jobsData, setJobsData] = useState();
  const [sort, setSort] = sortData;
  const [arrow, setArrow] = useState(false);
  const [active, setActive] = useState();
  const [showButtons, setShowButtons] = useState(true);

  const dispatch = useDispatch();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  useEffect(() => {
    if (jobs) {
      setJobsData(jobs.content);
    }
  }, [jobs]);

  const removeInfo = () => {
    dispatch(deleteJob(deleteId));
    setDeleteDialog(false);
  };

  const handleDelete = (id) => {
    setDeleteId(id);
    setDeleteDialog(true);
  };
  const optionChange = (e) => {
    let val = e.target.value;
    setSearchValue(val);
    if (val == '') {
      setJobsData(jobs.content);
      setShowButtons(true);
    } else {
      let filterResult =
        jobsData.length &&
        jobsData.filter(
          (item) =>
            (item.name && item.name.toLowerCase().includes(val.toLowerCase())) ||
            (item.actionType && item.actionType.toLowerCase().includes(val.toLowerCase())) ||
            (item.agentName && item.agentName.toLowerCase().includes(val.toLowerCase())) ||
            (item.policyName && item.policyName.toLowerCase().includes(val.toLowerCase()))
        );
      if (filterResult.length == 0) {
        setJobsData([{ name: 'No Data Found' }]);
        setShowButtons(false);
      } else {
        setJobsData(filterResult);
      }
    }
  };

  const TableSort = (i) => {
    setActive(i);
    setArrow(!arrow);
    if (i == 0) {
      if (!arrow) {
        setSort('name,desc');
      } else {
        setSort('name,asc');
      }
    }
  };

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} enterDelay={600} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      fontSize: 13,
      fontWeight: 400,
      maxWidth: 'none',
    },
  }));
  return (
    <>
      <Card>
        <DeleteAlert openDialog={[deleteDialog, setDeleteDialog]} remove={removeInfo} />
        <CopyModal openModal={openCopyModal} jobId={jobId} close={() => setOpenCopyModal(false)} />
        <Grid container spacing={5} sx={{ alignItems: 'center' }}>
          <Grid item xs={3}>
            <CardHeader title="Job Management" sx={{ mb: 3 }} />
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row">
              <SearchIcon sx={{ marginTop: '23px', marginRight: '20px' }} />
              <TextField
                id="standard-basic"
                name="search"
                label="Search"
                variant="standard"
                fullWidth
                // sx={{width:"150px"}}
                value={searchValue}
                onInput={(e) => optionChange(e)}
              />
            </Stack>
          </Grid>
          <Grid item xs={3}>
            <Link to="/dashboard/create-job" style={{ textDecoration: 'none', marginLeft: '40px' }}>
              <Button variant="contained" sx={{ color: '#ffff', height: '40px', m: '20px' }}>
                Add New job
              </Button>
            </Link>
          </Grid>
        </Grid>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 720 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  {columns.map((column, i) => (
                    <TableCell key={i}>
                      <Stack direction="row" onClick={() => TableSort(i)}>
                        <Typography style={{ marginRight: '8px' }}>{column}</Typography>
                        {i < 1 && active == i ? (
                          arrow == true ? (
                            <ArrowDownwardIcon />
                          ) : (
                            <ArrowUpwardIcon />
                          )
                        ) : i < 1 ? (
                          <ArrowUpwardIcon size="small" sx={{ opacity: '0', '&:hover': { opacity: '0.5' } }} />
                        ) : null}
                      </Stack>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {jobsData &&
                  jobsData.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell sx={{ color: '#f79816' }}>
                        {row.scheduled && (
                          <LightTooltip
                            title={`Next Execution @ ${moment(row.nextTimeOfRun).format('MMMM Do YYYY, h:mm:ss a')}`}
                          >
                            <QueryBuilderIcon />
                          </LightTooltip>
                        )}
                      </TableCell>
                      <TableCell>
                        <Typography sx={{ color: '#699ad4' }}>{row.name}</Typography>
                      </TableCell>
                      <TableCell sx={{ textTransform: 'lowercase' }}>{row.actionType==='WITHOUTSTUB' ? 'archive & delete' : row.actionType }</TableCell>
                      <TableCell>{row.agentName}</TableCell>
                      <TableCell>{row.policyName}</TableCell>
                      {showButtons ? (
                        <TableCell sx={{ maxWidth: '150px' }}>
                          {row.actionType == 'RESTORE' ? null : (
                            <Link to={`/dashboard/job-management/edit/${row.id}`} style={{ textDecoration: 'none' }}>
                              <Button sx={{ minWidth: 'fit-content', p: 0 }}>
                                <ModeEditIcon />
                              </Button>
                            </Link>
                          )}
                          <Button sx={{ minWidth: 'fit-content', p: 0 }} onClick={() => handleDelete(row.id)}>
                            <DeleteForeverIcon />
                          </Button>
                          <Button
                            sx={{ minWidth: 'fit-content', p: 0 }}
                            onClick={() => {
                              setJobId(row.id);
                              setOpenCopyModal(true);
                            }}
                          >
                            <ContentCopyIcon />
                          </Button>
                        </TableCell>
                      ) : null}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20, 100]}
              component="div"
              count={jobs.totalElements}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Scrollbar>
      </Card>
    </>
  );
}
