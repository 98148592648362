import {
  Box,
  Button,
  Card,
  CardHeader,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import EmailConfiguration from './EmailConfiguration';
import { useDispatch } from 'react-redux';
import { testEmail } from '../globalActions';
import CredentialsModal from './CredentialsModal';

const GlobalConfig = ({ watch, register, errors, fileAuths, setValue, onSubmit }) => {
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const [fileAuth, setFileAuth] = fileAuths;
  const [openModal, setOpenModal] = useState(false)

  return (
    <Page>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <CredentialsModal
          openModal={openModal}
          close={() => setOpenModal(false)}
          watch={watch}
          register={register}
          errors={errors}
        />
        <Card sx={{ p: 0 }}>
          <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            {/* <CardHeader width='30%' title="Enable Object Locking (S3/Azure)" /> */}
            <CardHeader width='30%' title="" />
            <Button
              width='30%'
              type="submit"
              variant="contained"
              disabled={watch('restorePassword')?.includes('@') ? true : false}
              sx={{ color: '#ffff', m: 2 }}
            >
              Submit
            </Button>

          </Stack>
          <Grid container spacing={2} sx={{ m: '10px' }}>
            <Grid item xs={12} md={5.5}>
              <TextField
                name="restoreDomainName"
                label="Domain"
                // value={global.restoreDomainName || ''}
                helperText={errors.restoreDomainName && errors.restoreDomainName.message}
                error={errors.restoreDomainName && errors.restoreDomainName.message}
                {...register('restoreDomainName')}
                variant="standard"
                sx={{ width: '100%' }}
                InputLabelProps={{
                  shrink: true,
                }}
                // onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={5.5}>
              <TextField
                name="restoreUser"
                // value={global.restoreUser || ''}
                label="User Name"
                helperText={errors.restoreUser && errors.restoreUser.message}
                error={errors.restoreUser && errors.restoreUser.message}
                {...register('restoreUser')}
                variant="standard"
                sx={{ width: '100%' }}
                InputLabelProps={{
                  shrink: true,
                }}
                // onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={5.5}>
              <TextField
                name="restorePassword"
                type="password"
                label="Password"
                // value={global.restorePassword || ''}
                helperText={errors.restorePassword && errors.restorePassword.message}
                error={errors.restorePassword && errors.restorePassword.message}
                {...register('restorePassword')}
                variant="standard"
                sx={{ width: '100%' }}
                InputLabelProps={{
                  shrink: true,
                }}
                // onChange={handleChange}
                required
              />
              {watch('restorePassword')?.includes('@') ?
                <Typography variant="caption" color="red" sx={{ width: 'fit-content' }}>
                  @ symbol in password is not accepted, please update the password and try again
                </Typography> : null
              }
            </Grid>
            <Grid item xs={12} md={2} sx={{ mt: 1 }}>
              <Button variant="contained" disabled={watch('restorePassword')?.includes('@') ? true : false} onClick={() => setOpenModal(true)}>
                Test Credentials
              </Button>
            </Grid>
            <Grid item xs={12} md={5.5}>
              <TextField
                name="maxNoOfVersions"
                label="Max no. of Versions"
                // value={global.maxNoOfVersions || ''}
                helperText={errors.maxNoOfVersions && errors.maxNoOfVersions.message}
                error={errors.maxNoOfVersions && errors.maxNoOfVersions.message}
                {...register('maxNoOfVersions')}
                variant="standard"
                sx={{ width: '100%' }}
                InputLabelProps={{
                  shrink: true,
                }}
                // onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={5.5}>
              <TextField
                name="serverWarningLimit"
                label="Disk Warning Limit(%)"
                // value={global.serverWarningLimit || ''}
                helperText={errors.serverWarningLimit && errors.serverWarningLimit.message}
                error={errors.serverWarningLimit && errors.serverWarningLimit.message}
                {...register('serverWarningLimit')}
                variant="standard"
                sx={{ width: '100%' }}
                InputLabelProps={{
                  shrink: true,
                }}
                // onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={5.5}>
              <TextField
                name="serverCriticalLimit"
                label="Disk Critical Limit(%)"
                // value={global.serverCriticalLimit || ''}
                helperText={errors.serverCriticalLimit && errors.serverCriticalLimit.message}
                error={errors.serverCriticalLimit && errors.serverCriticalLimit.message}
                {...register('serverCriticalLimit')}
                variant="standard"
                sx={{ width: '100%' }}
                InputLabelProps={{
                  shrink: true,
                }}
                // onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={5.5}>
              <TextField
                name="encryptionFileSize"
                label="Encryption File Size(MBs)"
                // value={global.encryptionFileSize || ''}
                helperText={errors.encryptionFileSize && errors.encryptionFileSize.message}
                error={errors.encryptionFileSize && errors.encryptionFileSize.message}
                {...register('encryptionFileSize')}
                variant="standard"
                sx={{ width: '100%' }}
                InputLabelProps={{
                  shrink: true,
                }}
                // onChange={handleChange}
                required
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={5.5} sx={{ ml: 4 }}>
            <FormGroup sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <FormControlLabel
                name="enableS3ObjectLock"
                {...register('enableS3ObjectLock')}
                control={<Checkbox />}
                checked={watch('enableS3ObjectLock')}
                label="Enable Object Locking"
              />
            </FormGroup>
          </Grid>

          <Grid container spacing={2} sx={{ m: '10px' }}>
            <Grid item xs={12} md={11}>
              <Typography sx={{ fontSize: '1.125 rem !important', fontWeight: '700', mt: 1 }} >File Authorization Mode</Typography>
              <FormControl
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <RadioGroup
                  aria-labelledby="sourceId"
                  name="fileAuthorizationMode"
                  {...register('fileAuthorizationMode')}
                  sx={{ display: 'flex', flexDirection: 'row' }}
                  value={watch('fileAuthorizationMode') || 2}
                  onChange={(e) => { setFileAuth(e.target.value); setValue('fileAuthorizationMode', e.target.value) }}
                >
                  <FormControlLabel value={1} control={<Radio />} label="Use Share & NTFS Permissions" />
                  <FormControlLabel value={2} control={<Radio />} label="NTFS Permissions Only" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={11}>
              <Typography sx={{ fontSize: '1.125 rem !important', fontWeight: '700', mb: 1, mt: 1 }} >Stub and Security Settings</Typography>
              <TextField
                name="stubIconFilePath"
                label="Stub Icons Path"
                // value={global.stubIconFilePath || ''}
                helperText={errors.stubIconFilePath && errors.stubIconFilePath.message}
                error={errors.stubIconFilePath && errors.stubIconFilePath.message}
                {...register('stubIconFilePath')}
                variant="standard"
                sx={{ width: '100%' }}
                InputLabelProps={{
                  shrink: true,
                }}
                // onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={11}>
              <Typography sx={{ fontSize: '1.125 rem !important', fontWeight: '700', mb: 1, mt: 2 }} >E-Discovery</Typography>
              <Stack direction='row' gap={2}>

                <Grid item xs={12} md={6}>
                  <TextField
                    name="maxSearchResults"
                    label="Max no. of Search Results"
                    // value={global.maxSearchResults || ''}
                    helperText={errors.maxSearchResults && errors.maxSearchResults.message}
                    error={errors.maxSearchResults && errors.maxSearchResults.message}
                    {...register('maxSearchResults')}
                    variant="standard"
                    sx={{ width: '100%' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    // onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="maxResultsToExport"
                    label="Max no. of Expor Results"
                    // value={global.maxResultsToExport || ''}
                    helperText={errors.maxResultsToExport && errors.maxResultsToExport.message}
                    error={errors.maxResultsToExport && errors.maxResultsToExport.message}
                    {...register('maxResultsToExport')}
                    variant="standard"
                    sx={{ width: '100%' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    // onChange={handleChange}
                    required
                  />
                </Grid>

              </Stack>
            </Grid>
            <Box sx={{ width: '100%', pl: 2, mt: 3 }}>
              <Typography sx={{ fontSize: '1.125 rem !important', fontWeight: '700', mt: 1 }} >Replication</Typography>


              <Grid container spacing={2} sx={{ m: '0px' }}>
                <Grid item xs={12} md={4} sx={{ padding: "10px !important" }}>
                  <FormControlLabel
                    name='enableReplication'
                    control={<Checkbox />}
                    checked={!!watch('enableReplication')}
                    label="Enable Replication"
                    onChange={(e) => setValue('enableReplication', e.target.checked)}
                  />
                </Grid>
                <Grid item xs={12} md={5} sx={{ padding: "10px !important" }}  >
                  <FormControl
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography>Application Mode</Typography>
                    <RadioGroup
                      aria-labelledby="mode"
                      name="applicationMode"
                      sx={{ display: 'flex', flexDirection: 'row' }}
                      value={watch('applicationMode') || '1'}
                      onChange={(e) => setValue('applicationMode', e.target.value)}
                    >
                      <FormControlLabel value='1' control={<Radio />} label="Master" />
                      <FormControlLabel value='2' control={<Radio />} label="Slave" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={11} sx={{ padding: "10px !important" }}>
                  <TextField
                    name="replicationServer"
                    label="Slave Server"
                    // value={global.replicationServer || ''}
                    helperText={errors.replicationServer && errors.replicationServer.message}
                    error={errors.replicationServer && errors.replicationServer.message}
                    {...register('replicationServer')}
                    variant="standard"
                    sx={{ width: '100%' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    // onChange={handleChange}
                    required
                  />
                </Grid>
              </Grid>
            </Box>
            <Grid item xs={12} md={12} sx={{ mt: '10px' }}>
              <EmailConfiguration
                register={register}
                errors={errors}
                watch={watch}
                setValue={setValue}
              />
            </Grid>
            <Grid item xs={11} md={11.4} sx={{ mt: '10px', display: 'flex', justifyContent: 'right' }}>
              <Button
                width='30%'
                type="submit"
                variant="contained"
                disabled={watch('restorePassword')?.includes('@') ? true : false}
                sx={{ color: '#ffff', m: 2 }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Container>
    </Page>
  );
};

export default GlobalConfig;
