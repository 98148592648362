import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Grid, Skeleton } from '@mui/material';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import JobManagementTable from './components/JobManagementTable';
import { deleteJob, getJobs } from './jobManagementActions';
import LoadingScreen from 'src/components/LoadingScreen';

export default function JobManagement() {
  const dispatch = useDispatch();
  const { themeStretch } = useSettings();
  const jobs = useSelector((state) => state.jobManagementReducer.jobs);
  const loading = useSelector((state) => state.jobManagementReducer.loading);
  const refresh = useSelector((state) => state.jobManagementReducer.refresh);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [sort,setSort]= useState('lastProcessedTime,desc')

  useEffect(() => {
    dispatch(getJobs({ page, size: rowsPerPage,sort:sort }));
  }, [page, rowsPerPage, refresh,sort]);

  const columns = ['Name', 'Type', 'Agent', 'Policy','Action'];

  return (
    <Page>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} style={{ paddingTop: '1px' }}>
            {loading && <LoadingScreen/> }
              {jobs.content &&
              <JobManagementTable
                pages={[page, setPage]}
                size={[rowsPerPage, setRowsPerPage]}
                jobs={jobs}
                columns={columns}
                sortData = {[sort,setSort]}
              />}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
