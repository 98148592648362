import {
  Button,
  Card,
  CardHeader,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { getAgentConfiguration, updateAgent } from '../AgentActions';
import LoadingScreen from 'src/components/LoadingScreen';
import { isEmpty } from 'lodash';
import { getFTPUsers } from 'src/pages/user-groups/user-management/usersActions';


const EditAgent = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const agentConfig = useSelector((state) => state.agentReducer.agentConfig);
  const loading = useSelector((state) => state.agentReducer.loading);
  const ftpUsers = useSelector((state) => state.usersReducer.ftpUsers);

  const [agentAnalysis, setAgentAnalysis] = useState({});
  const [logLevel, setLogLevel] = useState();

  useEffect(() => {
    setAgentAnalysis(agentConfig);
  }, [agentConfig]);

  useEffect(() => {
    dispatch(getAgentConfiguration(params.id));
  }, []);

  useEffect(()=> {
    dispatch(getFTPUsers())
  },[])

  const schema = yup.object().shape({
    archiverThread: yup.string().required('required'),
    evalutorThread: yup.string().required('required'),
    stubberThread: yup.string().required('required'),
    jobStatisticThread: yup.string().required('required'),
    exporterEvalutorThread: yup.string().required('required'),
    exporterThread: yup.string().required('required'),
    exportJobStatisticThread: yup.string().required('required'),
    bulkIndexCapacity: yup.string().required('required'),
    profileId: yup.string().required('required'),
    logLevel: yup.string().required('required'),
  });
  const {
    register,
    handleSubmit,
    watch,
    clearErrors,
    formState: { errors },
    reset,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    data.id = params.id;
    data.logLevel = logLevel;
    dispatch(updateAgent(data, navigate));
  };

  useEffect(() => {
    if (!isEmpty(agentConfig)) {
      reset({
        archiverThread: agentConfig.archiverThread,
        evalutorThread: agentConfig.evalutorThread,
        stubberThread: agentConfig.stubberThread,
        jobStatisticThread: agentConfig.jobStatisticThread,
        exporterEvalutorThread: agentConfig.exporterEvalutorThread,
        exporterThread: agentConfig.exporterThread,
        exportJobStatisticThread: agentConfig.exportJobStatisticThread,
        bulkIndexCapacity: agentConfig.bulkIndexCapacity,
        profileId: agentConfig.profileId,
        logLevel: agentConfig.logLevel,
      });
      setLogLevel(getValues('logLevel'))
    }
  }, [agentConfig]);
  return (
    <Page>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        {!loading ? (
          <Card sx={{ p: '20px' }}>
            <CardHeader title="Archive/Analysis Threads" />
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2} sx={{ mx: '20px', my: '10px' }}>
                <Grid item xs={12} md={5.5}>
                  <TextField
                    name="archiverThread"
                    // value={agentAnalysis.archiverThread}
                    label="Archiver Thread"
                    variant="standard"
                    helperText={errors.archiverThread && errors.archiverThread.message}
                    error={errors.archiverThread && errors.archiverThread.message}
                    {...register('archiverThread')}
                    sx={{ width: '100%' }}
                    // onChange={(e) => handleChange(e)}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <TextField
                    name="evalutorThread"
                    // value={agentAnalysis.evalutorThread}
                    label="Evalutor Thread"
                    helperText={errors.evalutorThread && errors.evalutorThread.message}
                    error={errors.evalutorThread && errors.evalutorThread.message}
                    {...register('evalutorThread')}
                    variant="standard"
                    sx={{ width: '100%' }}
                    // onChange={(e) => handleChange(e)}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <TextField
                    name="stubberThread"
                    // value={agentAnalysis.stubberThread}
                    label="Stubber Thread"
                    helperText={errors.stubberThread && errors.stubberThread.message}
                    error={errors.stubberThread && errors.stubberThread.message}
                    {...register('stubberThread')}
                    variant="standard"
                    sx={{ width: '100%' }}
                    // onChange={(e) => handleChange(e)}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <TextField
                    name="jobStatisticThread"
                    // value={agentAnalysis.jobStatisticThread}
                    label="Statistics Thread"
                    helperText={errors.jobStatisticThread && errors.jobStatisticThread.message}
                    error={errors.jobStatisticThread && errors.jobStatisticThread.message}
                    {...register('jobStatisticThread')}
                    variant="standard"
                    sx={{ width: '100%' }}
                    // onChange={(e) => handleChange(e)}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <TextField
                    name="exporterEvalutorThread"
                    // value={agentAnalysis.exporterEvalutorThread}
                    label="Exporter Evalutor Thread"
                    helperText={errors.exporterEvalutorThread && errors.exporterEvalutorThread.message}
                    error={errors.exporterEvalutorThread && errors.exporterEvalutorThread.message}
                    {...register('exporterEvalutorThread')}
                    variant="standard"
                    sx={{ width: '100%' }}
                    // onChange={(e) => handleChange(e)}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <TextField
                    name="exporterThread"
                    // value={agentAnalysis.exporterThread}
                    label="Exporter Thread"
                    helperText={errors.exporterThread && errors.exporterThread.message}
                    error={errors.exporterThread && errors.exporterThread.message}
                    {...register('exporterThread')}
                    variant="standard"
                    sx={{ width: '100%' }}
                    // onChange={(e) => handleChange(e)}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <TextField
                    name="exportJobStatisticThread"
                    // value={agentAnalysis.exportJobStatisticThread}
                    label="Export Job Statistic Thread"
                    helperText={errors.exportJobStatisticThread && errors.exportJobStatisticThread.message}
                    error={errors.exportJobStatisticThread && errors.exportJobStatisticThread.message}
                    {...register('exportJobStatisticThread')}
                    variant="standard"
                    sx={{ width: '100%' }}
                    // onChange={(e) => handleChange(e)}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <TextField
                    name="bulkIndexCapacity"
                    // value={agentAnalysis.bulkIndexCapacity}
                    label="Bulk Analysis Count"
                    helperText={errors.bulkIndexCapacity && errors.bulkIndexCapacity.message}
                    error={errors.bulkIndexCapacity && errors.bulkIndexCapacity.message}
                    {...register('bulkIndexCapacity')}
                    variant="standard"
                    sx={{ width: '100%' }}
                    // onChange={(e) => handleChange(e)}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <FormControl fullWidth>
                    <InputLabel id="reg" sx={{marginLeft:"-15px"}}>Profile</InputLabel>
                    <Select
                      name="profileId"
                      labelId="user"
                      variant="standard"
                      value={watch ('profileId')|| ''}
                      {...register('profileId')}
                      // onChange={(e) => handleChange(e)}
                    >
                      {ftpUsers && ftpUsers.map((user,i) => <MenuItem value={i+1}>{user.profileName}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={9}>
                  <FormControl>
                    <FormLabel id="sourceId">Logger Settings</FormLabel>
                    <RadioGroup
                      aria-labelledby="sourceId"
                      name="logLevel"
                      sx={{ display: 'flex', flexDirection: 'row' }}
                      value={logLevel || ''}
                      onChange={(e) => {
                        setLogLevel(e.target.value);
                      }}
                    >
                      <FormControlLabel value="DEBUG" control={<Radio />} label="Debug" />
                      <FormControlLabel value="INFO" control={<Radio />} label="Info" />
                      <FormControlLabel value="WARN" control={<Radio />} label="Warn" />
                      <FormControlLabel value="ERROR" control={<Radio />} label="Error" />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Button type="submit" variant="contained" sx={{ color: '#ffff' }}>
                    Update
                  </Button>
                  <Link to="/dashboard/agent-registration" style={{ textDecoration: 'none' }}>
                    <Button variant="contained" sx={{ color: '#ffff', ml: '10px' }}>
                      Cancel
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </form>
          </Card>
        ) : (
          <LoadingScreen />
        )}
      </Container>
    </Page>
  );
};

export default EditAgent;
