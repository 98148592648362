import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Input, Slide, Button, InputAdornment, ClickAwayListener, TextField, Stack } from '@mui/material';
import cssStyles from '../../../utils/cssStyles';
import Iconify from '../../../components/Iconify';
import { IconButtonAnimate } from '../../../components/animate';
import { Box } from '@mui/system';
import moment from 'moment/moment';

import { useDispatch } from 'react-redux';

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const SearchbarStyle = styled('div')(({ theme }) => ({
  ...cssStyles(theme).bgBlur(),
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  justifyContent: 'space-around',
  position: 'absolute',
  alignItems: 'center',
  height: APPBAR_MOBILE,
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  [theme.breakpoints.up('md')]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));
export const DATES = 'DATES';
export default function Searchbar() {
  const [isOpen, setOpen] = useState(false);
  const [startDate, setStartDate] = useState('1995-01-01');
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const dispatch = useDispatch();

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSearch = () => {
    dispatch({
      type: DATES,
      payload: { startDate, endDate },
    });
    setOpen(false);
  };

  // useEffect(() => {
  //   const date = new Date();
  //   date.setFullYear(date.getFullYear() - 25);
  //   setStartDate(moment(date).format('YYYY-MM-DD'));
  // }, [])

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!isOpen && (
          <Stack direction='row' alignItems='center' gap={2}>
            <Button onClick={handleOpen}>
              <Iconify icon={'eva:search-fill'} width={20} height={20} />
            </Button>
            <Button variant="contained" onClick={() => window.location.reload(false)}>
              Refresh
            </Button>
          </Stack>
        )}
        <Slide direction="down" in={isOpen} mountOnEnter unmountOnExit>
          <SearchbarStyle>
            <Box>
              <TextField
                type="date"
                autoFocus
                fullWidth
                label="Start Date"
                value={startDate}
                disableUnderline
                sx={{ mr: 1, width: '20rem' }}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <TextField
                type="date"
                autoFocus
                fullWidth
                label="End Date"
                value={endDate}
                disableUnderline
                sx={{ mr: 1, width: '20rem' }}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </Box>
            <Button variant="contained" onClick={handleSearch}>
              Search
            </Button>
          </SearchbarStyle>
        </Slide>
      </div>
    </ClickAwayListener>
  );
}
