import { Alert, Button, Card, CardHeader, Checkbox, FormControlLabel, Grid, InputLabel, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getRoleLimitisConfiguration, postRoleLimitisConfiguration } from '../global-configuration/globalActions';
import { LoadingButton } from '@mui/lab';

const RoleLimitsSection = () => {
  const form = useForm({
    defaultValues: {
      configuration: [
        {
          adminLimit: 0,
          ldapLimit: 0,
          name: '',
          privLimit: 0,
          timePeriod: 'daily',
          sendEmail: false,
          email: '',
        },
        {
          adminLimit: 0,
          ldapLimit: 0,
          name: '',
          privLimit: 0,
          timePeriod: 'daily',
          sendEmail: true,
          email: '',
        },
        {
          adminLimit: 0,
          ldapLimit: 0,
          name: '',
          privLimit: 0,
          timePeriod: 'daily',
          sendEmail: false,
          email: '',
        },
      ],
    },
  });
  const { register, handleSubmit, formState, reset, setValue, control, watch } = form;
  const { errors } = formState;
  const { fields } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormProvider)
    name: 'configuration', // unique name for your Field Array
  });
  const { roleLimitisConfiguration, isRoleLimitLoading, isRoleLimitAddLoading } = useSelector((state) => state.globalReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getRoleLimitisConfiguration());
  }, [dispatch]);
  useEffect(() => {
    if (roleLimitisConfiguration && roleLimitisConfiguration?.length > 0) {
      setValue('configuration', roleLimitisConfiguration);
    } else {
      setValue('configuration', []);
    }
  }, [roleLimitisConfiguration, setValue]);
  const onSubmit = (values) => {
    dispatch(postRoleLimitisConfiguration(values?.configuration))
  }
  if (isRoleLimitLoading || !roleLimitisConfiguration || roleLimitisConfiguration?.length <= 0) {
    return null
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card my={2} sx={{ pb: 2 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mr: '20px' }}>
          <CardHeader title="Role Based Data Access Limits and Notifications" sx={{ mb: 3 }} />
          <LoadingButton loading={isRoleLimitAddLoading} type='sumbit' variant="contained">
            Save
          </LoadingButton>
        </Stack>
        <Stack py={2} px={4} direction="column" gap={4}>
          <Alert sx={{ width: 'fit-content' }} severity="info">
          Please note, the limits will only trigger notifications and will not prevent the users from accessing data.
          </Alert>
          {fields.map((field, index) => {
            let title = '';
            if (index === 0) {
              title = 'Daily File Download Limits';
            } else if (index === 1) {
              title = 'Daily File Stub Limits';
            } else {
              title = 'Daily File Restore Limits';
            }
            return (
              <Card sx={{ p: 2 }}>
                <Stack my={3} key={field?.id} direction="column" gap={1}>
                  <Typography variant="subtitle2">{title}</Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                      <TextField
                        type="number"
                        label="Admin Limit"
                        variant="standard"
                        sx={{ width: '100%' }}
                        {...register(`configuration.${index}.adminLimit`, { required: true, min: 1 })}
                        error={
                          errors?.configuration &&
                          errors?.configuration?.length > 0 &&
                          errors?.configuration[index]?.adminLimit
                        }
                        helperText={
                          errors?.configuration &&
                          errors?.configuration?.length > 0 &&
                          errors?.configuration[index]?.adminLimit &&
                          'This field is required and must be a positive number'
                        }
                        disabled = {index === 1}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        type="number"
                        label=" LDAP User Limit"
                        variant="standard"
                        sx={{ width: '100%' }}
                        {...register(`configuration.${index}.ldapLimit`, { required: true, min: 1 })}
                        // required
                        error={
                          errors?.configuration &&
                          errors?.configuration?.length > 0 &&
                          errors?.configuration[index]?.ldapLimit
                        }
                        helperText={
                          errors?.configuration &&
                          errors?.configuration?.length > 0 &&
                          errors?.configuration[index]?.ldapLimit &&
                          'This field is required and must be a positive number'
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        type="number"
                        label="Priv Limit"
                        disabled = {index === 1}
                        variant="standard"
                        sx={{ width: '100%' }}
                        {...register(`configuration.${index}.privLimit`, { required: true, min: 1 })}
                        // required
                        error={
                          errors?.configuration &&
                          errors?.configuration?.length > 0 &&
                          errors?.configuration[index]?.privLimit
                        }
                        helperText={
                          errors?.configuration &&
                          errors?.configuration?.length > 0 &&
                          errors?.configuration[index]?.privLimit &&
                          'This field is required and must be a positive number'
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Stack direction="row" alignItems="center">
                        {/* <Checkbox  {...register(`configuration.${index}.sendEmail`)} />
                      <Typography>Enable email Alert</Typography> */}
                        <FormControlLabel
                          control={
                            <Controller
                              name={`configuration.${index}.sendEmail`}
                              control={control}
                              render={({ field }) => (
                                <Checkbox {...field} checked={watch(`configuration.${index}.sendEmail`)} />
                              )}
                            />
                          }
                          label="Enable email Alert"

                        />
                      </Stack>
                      {/* <TextField
                      label="Enable email Alert"
                      name="awsSecretAccessKey"
                      variant="standard"
                      {...register(`configuration.${index}.sendEmail`)}
                      sx={{ width: '100%' }}
                      required
                    /> */}
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="Email Recipient"
                        name="awsSecretAccessKey"
                        variant="standard"
                        sx={{ width: '100%' }}
                        {...register(`configuration.${index}.email`, { required: true })}
                        // required
                        error={
                          errors?.configuration &&
                          errors?.configuration?.length > 0 &&
                          errors?.configuration[index]?.email
                        }
                        helperText={
                          errors?.configuration &&
                          errors?.configuration?.length > 0 &&
                          errors?.configuration[index]?.email &&
                          'Email is required'
                        }
                      />
                    </Grid>
                  </Grid>
                </Stack>
              </Card>
            );
          })}
        </Stack>
      </Card>

    </form>
  );
};

export default RoleLimitsSection;
