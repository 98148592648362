import { Card, CardHeader } from '@mui/material';
import chartColors from 'src/utils/chartColors';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import axiosInstance from 'src/utils/axios';
import { BASE_URL } from 'src/config';
import { ERROR_ALERT, PROGRESS_START,PROGRESS_DONE, SUCCESS_ALERT } from 'src/redux/reducers/alertReducer';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { getHeaders } from 'src/utils/fetchServices';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement);

export default function PieChart({ labels, data }) {
  const dataPercentage = (value) => {
    let totalValue = data.reduce((sum, val) => sum+val, 0) 
    return (100 * value) / totalValue; 
  }
  const [label, setLabel] = useState("");
  const dispatch = useDispatch();
  const data2 = {
    labels: labels,
    datasets: [
      {
        data: data,
        backgroundColor: chartColors,
      },
    ],
  };

  const options = {
    onClick: function(evt, element) {
      if(element.length > 0){
        var ind = element[0].index;
        let date = new Date()
        let endDate = new Date (date).setHours(12)
        let sDate = date.setFullYear(2009,0,1)
        let startDate = new Date (sDate).setHours(12)

        let params = {
          fileExtension: label,
          startDate: startDate,
         endDate: endDate,
        }
        chartDownload(params)
      }
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        displayColors: false,
        titleFont: "'Open Sans', Helvetica, Arial, sans-serif",
        titleFontStyle: 'normal',
        titleColor: '#66afc9',
        bodyFont: "'Open Sans', Helvetica, Arial, sans-serif",
        bodyFontStyle: 'normal',
        bodyColor: '#66afc9',
        footerFont: "'Open Sans', Helvetica, Arial, sans-serif",
        footerFontStyle: 'normal',
        footerColor: '#66afc9',
        afterFooterFont: "'Open Sans', Helvetica, Arial, sans-serif",
        afterFooterFontStyle: 'normal',
        afterFooterColor: '#66afc9',
        footerSpacing: 7,
        callbacks: { 
          label: function (tooltipItem) {
            setLabel(tooltipItem.label)
            return 'File Type : ' + tooltipItem.label;
          },
          footer: function (tooltipItem) {
            return 'Sum of File Sizes : ' + tooltipItem[0].formattedValue + ' GB (' + dataPercentage(tooltipItem[0].parsed).toFixed(2) + '%)';
          },
          afterFooter: function () {
            return 'Click to download list';
          },
        },
      },
      title: {
        display: true,
        text: 'Chart.js Doughnut Chart',
      },
    },
  };

  const chartDownload =async (params) => {
    let companyId = localStorage.getItem("companyId")
    dispatch({
      type: PROGRESS_START,
    });
    try {
      const response = await axiosInstance.get(`${BASE_URL}charts/company/${companyId}/data.csv`,{params, headers:getHeaders()});
      const data = response.data;
      var blob = new Blob([data]);
      let url = window.URL.createObjectURL(blob);
      let tempLink = document.createElement('a');
      tempLink.href = url;
      tempLink.setAttribute('download', 'data.csv');
      tempLink.click();
      if(response.status == 200){
        dispatch({
          type: SUCCESS_ALERT,
          payload: 'Files exported successfully',
        });
      }
    } catch (error) {
      dispatch({
        type: ERROR_ALERT,
        payload: 'File Not Exported',
      });
    }
    dispatch({
      type: PROGRESS_DONE,
    });
  };

  return (
    <Card sx={{pb:"40px"}}>
      <CardHeader title="Total volume grouped files in repository" />
      {data && data.length ? <Doughnut data={data2} options={options} /> : null}
    </Card>
  );
}
