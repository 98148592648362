import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Grid, Typography } from '@mui/material';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import moment from 'moment';
import DateFilter from './components/DateFilter';
import LineChart from '../charts/LineChart';
import { getNumberOfFiles, getNumberOfFilesPerPath } from './repositoryActions';
import LoadingScreen from 'src/components/LoadingScreen';
import FileArchieved from './components/FileArchieved';

export default function SystemStatus() {
  const dispatch = useDispatch();
  const { themeStretch } = useSettings();

  const dates = useSelector((state) => state.systemReducer.dates);
  const numberOfFiles = useSelector((state) => state.repositoryReducer.numberOfFiles);
  const numberOfFilesPerPath = useSelector((state) => state.repositoryReducer.numberOfFilesPerPath);
  const loading = useSelector((state) => state.repositoryReducer.loading);
  const [startDate, setStartDate] = useState(new Date('1995/01/01'));
  const [endDate, setEndDate] = useState(new Date());

  const [numberOfFilesLabels, setNumberOfFilesLabels] = useState([]);
  const [numberOfFilesData, setNumberOfFilesData] = useState([]);

  const [numberOfFilesPerPathLabels, setNumberOfFilesPerPathLabels] = useState([]);
  const [numberOfFilesPerPathData, setNumberOfFilesPerPathData] = useState([]);
  const [dateType, setDateType] = useState('archived');

  useEffect(() => {
    dispatch(
      getNumberOfFiles({
        startDate: moment(dates.startDate).format('x'),
        endDate: new Date(dates.endDate).setHours(23, 59, 59),
        dateType,
      })
    );
    dispatch(
      getNumberOfFilesPerPath({
        startDate: moment(dates.startDate).format('x'),
        endDate: new Date(dates.endDate).setHours(23, 59, 59),
      })
    );
  }, [dates, dateType]);

  const dateFormatCompare = (dateC) => {
    let date = new Date(dateC);
    return date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
  };

  useEffect(() => {
    const labels = [];
    const data = [];
    let dataBucket = [];

    if (numberOfFiles['aggregations'] !== undefined) {
      for (let bucket of numberOfFiles['aggregations'][2].buckets) {
        let year = bucket.key;
        if (year >= startDate && year <= endDate) {
          if (labels.indexOf(dateFormatCompare(bucket.key)) == -1) {
            labels.push(dateFormatCompare(bucket.key));
          }
        }
      }
      for (let bucket of numberOfFiles['aggregations'][2].buckets) {
        let year = bucket.key;
        if (year >= startDate && year <= endDate) {
          dataBucket[labels.indexOf(dateFormatCompare(bucket.key))] = bucket.doc_count;
        }
      }
      data.push({
        data: dataBucket,
        fill: false,
        name: 'Number Of Files',
        showLine: true,
        label: 'No. of Files',
        xlabel: 'Dates',
      });
      dataBucket = [];
    }
    setNumberOfFilesData(data);
    setNumberOfFilesLabels(labels);
  }, [numberOfFiles]);

  useEffect(() => {
    const labels = [];
    const data = [];
    let dataBucket = [];
    let pathKeyLine = [];

    if (numberOfFilesPerPath['aggregations'] !== undefined) {
      for (let bucket of numberOfFilesPerPath['aggregations'][2].buckets) {
        let year = bucket.key;
        if (year >= startDate && year <= endDate) {
          for (let item of bucket[3].buckets) {
            for (let place of item[4].buckets) {
              if (pathKeyLine.indexOf(place.key) == -1) {
                pathKeyLine.push(place.key);
              }
            }
          }
        }
      }
      for (let bucket of numberOfFilesPerPath['aggregations'][2].buckets) {
        let year = bucket.key;
        if (year >= startDate && year <= endDate) {
          for (let item of bucket[3].buckets) {
            for (let place of item[4].buckets) {
              if (place)
                if (labels.indexOf(dateFormatCompare(bucket.key)) == -1) {
                  labels.push(dateFormatCompare(bucket.key));
                }
            }
          }
        }
      }
      for (let path of pathKeyLine) {
        for (let bucket of numberOfFilesPerPath['aggregations'][2].buckets) {
          let year = bucket.key;
          if (year >= startDate && year <= endDate) {
            for (let item of bucket[3].buckets) {
              for (let place of item[4].buckets) {
                if (place.key == path) {
                  if (dataBucket[labels.indexOf(dateFormatCompare(bucket.key))])
                    dataBucket[labels.indexOf(dateFormatCompare(bucket.key))] += place.doc_count;
                  else dataBucket[labels.indexOf(dateFormatCompare(bucket.key))] = place.doc_count;
                }
              }
            }
          }
        }
        for (let i = 0; i < labels.length; i++) {
          if (!dataBucket[i]) dataBucket[i] = 0;
        }
        data.push({
          data: dataBucket,
          fill: false,
          name: path,
          showLine: true,
          label: 'No. of Files',
          xlabel: 'Dates',
        });
        dataBucket = [];
      }
    }
    setNumberOfFilesPerPathData(data);
    setNumberOfFilesPerPathLabels(labels);
  }, [numberOfFilesPerPath]);

  const handleDateType = (filterType) => setDateType(filterType);

  return (
    <Page>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        {!loading ? (
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} style={{ paddingTop: '1px' }}>
              <FileArchieved />
            </Grid>
            <Grid item xs={12} md={12} style={{ paddingTop: '1px' }}>
              <DateFilter handleDateType={handleDateType} type={[dateType, setDateType]} />
            </Grid>
            <Grid item xs={12} md={12}>
              <LineChart
                labels={numberOfFilesLabels}
                data={numberOfFilesData}
                chartHeading="Line graph showing file Archived"
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <LineChart
                labels={numberOfFilesPerPathLabels}
                data={numberOfFilesPerPathData}
                chartHeading="Line graph showing files accessed by path"
              />
            </Grid>
          </Grid>
        ) : (
          <LoadingScreen />
        )}
      </Container>
    </Page>
  );
}
