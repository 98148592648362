import { ACTIVE_AGENTS_FETCH_SUCCESS, AGENT_CONFIG_FETCH_SUCCESS, AGENT_DELETED, DATA_LOADER, FETCH_FAIL, SUCCESS } from './AgentActions';

const initialState = {
  loading: false,
  activeAgents: {},
  agentConfig:{},
  error: null,
};

const agentReducer = (state = initialState, action) => {
  switch (action.type) {
    case DATA_LOADER: {
      return {
        ...state,
        loading: true,
      };
    }
    case ACTIVE_AGENTS_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        activeAgents: action.payload,
      };
    }
    case AGENT_CONFIG_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        agentConfig: action.payload,
      };
    }
    case AGENT_DELETED: {
      return {
        ...state,
        loading: false,
      };
    }
    case SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case FETCH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default: {
      return state;
    }
  }
};

export default agentReducer;
