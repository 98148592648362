import {useEffect} from 'react'
import {
  Box,
  Card,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  TableContainer,
  TablePagination,
  Typography,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Scrollbar from '../../../../components/Scrollbar';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { deleteProfile } from '../volumeActions';
import DeleteAlert from 'src/pages/DeleteAlert';
import { useState } from 'react';

export default function ProfileTable({ pages,size, cloudProfile, columns, cloudType }) {
  const dispatch = useDispatch();
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState();
  
  const [clouldProvider, setCloudProvider] = cloudType;
  const [rowsPerPage, setRowsPerPage] = size;
  const [page, setPage] = pages;

  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleDelete = (id) => {
    setDeleteDialog(true);
    setDeleteId(id);
  };

  const removeProfile = () => {
    dispatch(deleteProfile(deleteId));
  };

  return (
    <>
      <Card>
        <DeleteAlert remove={removeProfile} openDialog={[deleteDialog, setDeleteDialog]} />
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <CardHeader title="Manage Profile List" sx={{ mb: 3 }} />
          <Stack direction="row" alignItems="center">
            <FormControl sx={{ width: '200px' }}>
              <InputLabel id="user">Select Cloud Provider</InputLabel>
              <Select
                labelId="user"
                variant="standard"
                value={clouldProvider}
                label="Select Cloud Provider"
                onChange={(e) => setCloudProvider(e.target.value)}
              >
                <MenuItem value={2}>S3</MenuItem>
                <MenuItem value={3}>AZURE</MenuItem>
                <MenuItem value={4}>WINDOWS SMB</MenuItem>
              </Select>
            </FormControl>
            <Link to={`add/${clouldProvider === 3 ? 'azure' : clouldProvider === 4 ? 'WindowsSMB' : 's3' }`} style={{ textDecoration: 'none' }}>
              <Button variant="contained" sx={{ color: '#ffff', height: '40px', m: '10px' }}>
                Add
              </Button>
            </Link>
            <Link to="/dashboard/volume-management" style={{ textDecoration: 'none' }}>
              <Button variant="contained" sx={{ color: '#ffff', height: '40px', m: '10px' }}>
                Back
              </Button>
            </Link>
          </Stack>
        </Box>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 720 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column, i) => (
                    <TableCell key={i}>{column}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {cloudProfile &&
                  cloudProfile.map((row, i) => (
                    <TableRow key={row.id}>
                      <TableCell>{i + 1}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.fileStoreBase === 2 ? 'S3' :row.fileStoreBase === 4 ? "WINDOWS SMB"  :'AZURE'}</TableCell>
                      <TableCell sx={{ maxWidth: '100px' }}>
                        <Link to={`edit/${row.id}`} style={{ textDecoration: 'none' }}>
                          <Button sx={{minWidth:'fit-content', p:0}}>
                            <ModeEditIcon />
                          </Button>
                        </Link>
                        <Button sx={{minWidth:'fit-content', p:0}} onClick={() => handleDelete(row.id)}>
                          <DeleteForeverIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {/* <TablePagination
              rowsPerPageOptions={[5, 10, 20, 100]}
              component="div"
              count={cloudProfile && cloudProfile.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
          </TableContainer>
        </Scrollbar>
      </Card>
    </>
  );
}
