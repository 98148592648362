import { GetDetails} from 'src/utils/fetchServices';

export const JOBS_STATUS_FETCH_SUCCESS = 'JOB_STATUS_FETCH_SUCCESS';
export const FETCH_FAIL = 'FETCH_FAIL';


export const jobsStatusAction = () => async  (dispatch)=>{
    try {
        const response = await GetDetails({ url: `jobs/all-status`});
        const data = response.data;
        dispatch({
          type: JOBS_STATUS_FETCH_SUCCESS,
          payload: data,
        });
      } catch (error) {
        dispatch({
          type: FETCH_FAIL,
          payload: error.message,
        });
    }

}