import { Button, Card, CardHeader, Container, Grid, Modal, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import { updateProfile } from '../volumeActions';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router';
import { testAccess } from '../../job-management/jobManagementActions';
import { ERROR_ALERT } from 'src/redux/reducers/alertReducer';
import TestAccessModal from '../../job-management/components/TestAccessModal';

const AddSMBModal = ({ openAzureModal, close, fileStoreType }) => {
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const testData = useSelector((state) => state.jobManagementReducer.testData);
  const [fileStorePath, setFileStorePath] = useState('');
  const [openAccessModal, setOpenAccessModal] = useState(false);
  const [testAccessLoading, setTestAccessLoading] = useState(false);

  let companyId = localStorage.getItem('companyId');
  const schema = yup.object().shape({
    fileStoreType: yup.string().required('required'),
    fileStorePath: yup.string().required('required'),
    remoteDomainName: yup.string().required('required'),
    remoteUserName: yup.string().required('required'),
    remoteUserPassword: yup.mixed().required('required'),
  });
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const createSMB = () => {
    dispatch(updateProfile(
      {
        name: watch("fileStoreType"),
        companyId: +companyId,
        domain: watch("remoteDomainName"),
        fileStoreBase: 4,
        password: watch("remoteUserPassword"),
        uncpath: fileStorePath || '',
        username: watch("remoteUserName")
      },
      'POST',
      'cloud-profile',
      navigate
    ))
    close();
  };

  const getAccessData = async () => {
    setTestAccessLoading(true);
    if (fileStorePath.slice(0, 2) == '\\\\' && fileStorePath.split('\\').length > 3) {
      await dispatch(
        testAccess({
          domain: watch('remoteDomainName'),
          userName: watch('remoteUserName'),
          password: watch('remoteUserPassword'),
          agentId: watch('agentId') || 0,
          uncPath: fileStorePath || '',
          libType: watch('remoteLibType') || 'JNQ',
        })
      );
      setOpenAccessModal(true);
    } else {
      dispatch({
        type: ERROR_ALERT,
        payload: 'Please enter a valid path',
      });
    }
    setTestAccessLoading(false);
  };

  return (
    <>
      <TestAccessModal
        testData={testData}
        openModal={openAccessModal}
        close={() => { setOpenAccessModal(false) }}
        watch={watch}
        loader={testAccessLoading}
      />
      <Modal
        open={openAzureModal}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Page sx={{ width: '100%' }}>
          <Container maxWidth={themeStretch ? false : 'xl'}>
            <Card sx={{ backgroundColor: '#ffff', pb: '40px' }}>
              <CardHeader title="Windows SMB Profile" />

              <Grid container spacing={3} sx={{ mx: '30px', mt: 1 }}>
                <Grid item xs={12} md={5.5}>
                  <TextField
                    name="fileStoreType"
                    label="Name"
                    helperText={errors.fileStoreType && errors.fileStoreType.message}
                    error={errors.fileStoreType && errors.fileStoreType.message}
                    {...register('fileStoreType')}
                    variant="standard"
                    sx={{ width: '100%', marginTop: '10px' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                  />
                </Grid>

                <Grid item xs={12} md={5.5}>
                  <TextField
                    name="fileStorePath"
                    label="File Store Path"
                    value={fileStorePath || ''}
                    onChange={(e) => setFileStorePath(e.target.value)}
                    variant="standard"
                    sx={{ width: '100%', marginTop: '10px' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                  />
                </Grid>

                <Grid item xs={12} md={5.5}>
                  <TextField
                    label="Domain"
                    name="remoteDomainName"
                    helperText={errors.remoteDomainName && errors.remoteDomainName.message}
                    error={errors.remoteDomainName && errors.remoteDomainName.message}
                    {...register('remoteDomainName')}
                    variant="standard"
                    sx={{ width: '100%', marginTop: '10px' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <TextField
                    label="Username"
                    name="remoteUserName"
                    variant="standard"
                    helperText={errors.remoteUserName && errors.remoteUserName.message}
                    error={errors.remoteUserName && errors.remoteUserName.message}
                    {...register('remoteUserName')}
                    sx={{ width: '100%', marginTop: '10px' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <TextField
                    name="remoteUserPassword"
                    type="password"
                    label="Password"
                    variant="standard"
                    helperText={errors.remoteUserPassword && errors.remoteUserPassword.message}
                    error={errors.remoteUserPassword && errors.remoteUserPassword.message}
                    {...register('remoteUserPassword')}
                    sx={{ width: '100%', marginTop: '10px' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Button
                    variant="contained"
                    sx={{ color: '#ffff' }}
                    onClick={createSMB}
                    disabled={!(watch("fileStoreType") && fileStorePath && watch("remoteDomainName") && watch("remoteUserName") && watch("remoteUserPassword"))}
                  >
                    Create
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ color: '#ffff', ml: '10px' }}
                    onClick={getAccessData}
                    disabled={fileStorePath == "" ? true : false}
                  >
                    Test SMB
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Container>
        </Page>
      </Modal>
    </>
  );
};

export default AddSMBModal;
