import {
  Button,
  Card,
  CardHeader,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import { updateProfile } from '../volumeActions';

const AddS3CloudModal = ({ openModal, region, fileStoreType }) => {
  const { themeStretch } = useSettings();
  const [openCloudModal, setOpenCloudModal] = openModal;
  const dispatch = useDispatch();
  let companyId = localStorage.getItem('companyId');
  const [customAccess, setCustomAccess] = useState(false);
  const [customAccessURL, setCustomAccessURL] = useState('');
  const [createData, setCreateData] = useState({
    awsAccessKeyId: '',
    awsSecretAccessKey: '',
    filestoreRegionName: '',
    companyId: +companyId,
    name: '',
    awsAccessUrl: '',
    fileStoreBase: fileStoreType,
    azureConnectionString: '',
  });

  const inputHandle = (e) => {
    setCreateData((prevData) => ({ ...prevData, [e.target.name]: e.target.value }));
  };
  const createCloud = () => {
    dispatch(updateProfile(createData));
    handleClose();
  };

  const handleClose = () => {
    setOpenCloudModal(false);
  };

  const accessSelection = (e) => {
    if (e.target.value == 'Add Custom') {
      setCustomAccess(false);
      // setCreateData({ ...createData,awsAccessUrl:e.target.value })
      if (customAccessURL) {
        setCreateData((prevData) => ({ ...prevData, awsAccessUrl: customAccessURL }));
      } else {
        setCreateData({ ...createData, awsAccessUrl: e.target.value });
      }
    } else {
      setCustomAccess(true);
      setCreateData({ ...createData, awsAccessUrl: e.target.value });
    }
  };

  useEffect(() => {
    if (customAccessURL) {
      setCreateData((prevData) => ({ ...prevData, awsAccessUrl: customAccessURL }));
    }
  }, [customAccessURL, setCreateData]);

  return (
    <>
      <Modal
        open={openCloudModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Page sx={{ width: '70%' }}>
          <Container maxWidth={themeStretch ? false : 'xl'}>
            <Card sx={{ backgroundColor: '#ffff', pb: '40px' }}>
              <CardHeader title="S3 Cloud" />

              <Grid container spacing={3} sx={{ mx: '40px' }}>
                <Grid item xs={12} md={5.5}>
                  <TextField
                    label="Name"
                    name="name"
                    variant="standard"
                    sx={{ width: '100%' }}
                    value={createData.name}
                    onChange={(e) => inputHandle(e)}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <InputLabel sx={{ fontSize: '0.7rem' }} id="awsAccessUrl">
                    Access Url
                  </InputLabel>
                  <Stack direction={'row'} gap={3}>
                    <Select
                      name="awsAccessUrl"
                      labelId="awsAccessUrl"
                      variant="standard"
                      value={!customAccess ? 'Add Custom' : createData.awsAccessUrl || ''}
                      onChange={(e) => accessSelection(e)}
                      sx={{ width: '100%', marginTop: '0px !important' }}
                    >
                      <MenuItem value="Add Custom">Add Custom</MenuItem>
                      <MenuItem value="https://s3.wasabisys.com">s3.wasabisys.com </MenuItem>
                      <MenuItem value="s3.amazonaws.com">s3.amazonaws.com</MenuItem>
                    </Select>
                    <TextField
                      name="awsAccessUrl"
                      placeholder="Add Custom"
                      value={customAccess ? createData.awsAccessUrl : customAccessURL || ''}
                      disabled={customAccess}
                      variant="standard"
                      sx={{ width: '100%' }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => setCustomAccessURL(e.target.value)}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <TextField
                    label="Access Key Id"
                    name="awsAccessKeyId"
                    variant="standard"
                    type="password"
                    value={createData.awsAccessKeyId}
                    sx={{ width: '100%' }}
                    onChange={(e) => inputHandle(e)}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <TextField
                    label="Secret Access Key"
                    name="awsSecretAccessKey"
                    variant="standard"
                    type="password"
                    value={createData.awsSecretAccessKey}
                    sx={{ width: '100%' }}
                    onChange={(e) => inputHandle(e)}
                    required
                  />
                </Grid>
                {region.length && (
                  <Grid item xs={12} md={5.5}>
                    <FormControl fullWidth>
                      <InputLabel id="reg" sx={{ marginLeft: '-15px' }}>
                        Region*
                      </InputLabel>
                      <Select
                        labelId="user"
                        variant="standard"
                        label="Policy"
                        name="filestoreRegionName"
                        value={createData.filestoreRegionName}
                        onChange={(e) => inputHandle(e)}
                      >
                        {region.length &&
                          region.map((reg, i) => {
                            return (
                              <MenuItem key={i} value={reg.name}>
                                {reg.name}-{reg.description}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={12} md={12}>
                  <Button
                    variant="contained"
                    sx={{ color: '#ffff', ml: '10px' }}
                    onClick={createCloud}
                    disabled={
                      !(
                        createData.name &&
                        // createData.awsAccessUrl &&
                        createData.awsAccessKeyId &&
                        createData.awsSecretAccessKey &&
                        createData.filestoreRegionName &&
                        (customAccess ? createData.awsAccessUrl : customAccessURL)
                      )
                    }
                  >
                    Create
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Container>
        </Page>
      </Modal>
    </>
  );
};

export default AddS3CloudModal;
