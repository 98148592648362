import { ERROR_ALERT, SUCCESS_ALERT, PROGRESS_DONE, PROGRESS_START } from 'src/redux/reducers/alertReducer';
import { deleteInfo, GetDetails, postInfo } from 'src/utils/fetchServices';

export const GLOBAL_FETCH_SUCCESS = 'GLOBAL_FETCH_SUCCESS';
export const PII_FETCH_SUCCESS = 'PII_FETCH_SUCCESS';
export const INDEX_FETCH_SUCCESS = 'INDEX_FETCH_SUCCESS';
export const AZURE_FETCH_SUCCESS = 'AZURE_FETCH_SUCCESS';
export const LANGUAGES_FETCH_SUCCESS = 'LANGUAGES_FETCH_SUCCESS';
export const TOKENIZERS_FETCH_SUCCESS = 'TOKENIZERS_FETCH_SUCCESS';
export const PROXY_PATH_FETCH_SUCCESS = 'PROXY_PATH_FETCH_SUCCESS';
export const UNC_PATH_FETCH_SUCCESS = 'UNC_PATH_FETCH_SUCCESS';
export const POST_SUCCESS = 'POST_SUCCESS';
export const DATA_LOADER = 'DATA_LOADER';
export const DELETED_SUCCESSFULLY = 'DELETED_SUCCESSFULLY';
export const FETCH_FAIL = 'FETCH_FAIL';
export const ROLE_LIMITS_FETCH_SUCCESS = 'ROLE_LIMITS_FETCH_SUCCESS';
export const ROLE_LIMITS_FETCH_FAIL = 'ROLE_LIMITS_FETCH_FAIL';
export const ROLE_LIMITS_FETCH_LOADER = 'ROLE_LIMITS_FETCH_LOADER';
export const ROLE_LIMITS_ADD_SUCCESS = 'ROLE_LIMITS_ADD_SUCCESS';
export const ROLE_LIMITS_ADD_LOADER = 'ROLE_LIMITS_ADD_LOADER';

export const getGlobalConfiguration = () => async (dispatch) => {
  dispatch({
    type: DATA_LOADER,
  });
  const companyId = localStorage.getItem('companyId');
  try {
    const response = await GetDetails({ url: `system-codes/global-configuration/company/${companyId}` });
    const data = response.data;
    dispatch({
      type: GLOBAL_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};
export const getRoleLimitisConfiguration = () => async (dispatch) => {
  dispatch({
    type: ROLE_LIMITS_FETCH_LOADER,
  });
  const companyId = localStorage.getItem('companyId');
  try {
    const response = await GetDetails({ url: `company/${companyId}/role-feature-access/email-alert` });
    const data = await response.data;
    dispatch({
      type: ROLE_LIMITS_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ROLE_LIMITS_FETCH_FAIL,
      payload: error.message,
    });
  }
};
export const postRoleLimitisConfiguration = (body) => async (dispatch) => {
  dispatch({
    type: ROLE_LIMITS_ADD_LOADER,
  });
  const companyId = localStorage.getItem('companyId');
  try {
    const response = await postInfo({ url: `company/${companyId}/role-feature-access/email-alert`, method:'PUT', body });
    const data = await response.data;
    dispatch({
      type: ROLE_LIMITS_ADD_SUCCESS,
      payload: data,
    });
    dispatch({
      type: SUCCESS_ALERT,
      payload: "Configuration updated succesfully",
    });
  } catch (error) {
    dispatch({
      type: ROLE_LIMITS_FETCH_FAIL,
      payload: error.message,
    });
    dispatch({
      type: ERROR_ALERT,
      payload: error?.message || 'Something went wrong',
    });
  }
};
export const getPiiConfiguration = () => async (dispatch) => {
  // dispatch({
  //   type: DATA_LOADER,
  // });
  const companyId = localStorage.getItem('companyId');
  try {
    const response = await GetDetails({ url: `system-codes/pii-entity-configuration/company/${companyId}` });
    const data = response.data;
    dispatch({
      type: PII_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};

export const getAzureConfiguration = () => async (dispatch) => {
  dispatch({
    type: DATA_LOADER,
  });
  const companyId = localStorage.getItem('companyId');
  try {
    const response = await GetDetails({ url: `azure-ad-config/company/${companyId}` });
    const data = response.data;
    dispatch({
      type: AZURE_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};

export const getIndexConfiguration = () => async (dispatch) => {
  // dispatch({
  //   type: DATA_LOADER,
  // });
  const companyId = localStorage.getItem('companyId');
  try {
    const response = await GetDetails({ url: `system-codes/elastic-search-configuration/company/${companyId}` });
    const data = response.data;
    dispatch({
      type: INDEX_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};
export const getLanguages = () => async (dispatch) => {
  dispatch({
    type: DATA_LOADER,
  });
  const companyId = localStorage.getItem('companyId');
  try {
    const response = await GetDetails({ url: `global/analyzer/languages` });
    const data = response.data;
    dispatch({
      type: LANGUAGES_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};
export const getTokenizers = () => async (dispatch) => {
  dispatch({
    type: DATA_LOADER,
  });
  try {
    const response = await GetDetails({ url: `global/analyzer/tokenizers` });
    const data = response.data;
    dispatch({
      type: TOKENIZERS_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};
export const getProxyPath = () => async (dispatch) => {
  dispatch({
    type: DATA_LOADER,
  });
  const companyId = localStorage.getItem('companyId');
  try {
    const response = await GetDetails({ url: `company/${companyId}/proxy-servers` });
    const data = response.data;
    dispatch({
      type: PROXY_PATH_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};
export const deletePath = (id) => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  try {
    const response = await deleteInfo({ url: `company/${companyId}/proxy-servers/${id}` });
    const data = response.data;
    dispatch({
      type: DELETED_SUCCESSFULLY,
      payload: data,
    });
    dispatch({
      type: SUCCESS_ALERT,
      payload: 'Path Deleted Successfully',
    });
    if (response.status == 422) {
      dispatch({
        type: ERROR_ALERT,
        payload: response.data.message,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};

export const postLdap = (body) => async (dispatch) => {
  dispatch({
    type: DATA_LOADER,
  });
  const companyId = localStorage.getItem('companyId');
  try {
    const response = await postInfo({ url: `system-codes/ldap-configuration/company/${companyId}`, body });
    const data = response.data;
    dispatch({
      type: POST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};
export const testEmail = (body) => async (dispatch) => {
  // dispatch({
  //   type: DATA_LOADER,
  // });
  dispatch({
    type: PROGRESS_START,
  });
  try {
    const response = await postInfo({ url: `system-codes/email-configuration/test`, body });
    if (response.status === 200) {
      const data = response.data;
      dispatch({
        type: POST_SUCCESS,
        payload: data,
      });
      dispatch({
        type: SUCCESS_ALERT,
        payload: 'Email Tested',
      });
    } else {
      dispatch({ type: FETCH_FAIL });
      dispatch({
        type: ERROR_ALERT,
        payload: 'Testing Failed Something Went Wrong!',
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
    });
  }
  dispatch({
    type: PROGRESS_DONE,
  });
};

export const addProxyPath = (body, id, method) => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  const config = {
    url: `company/${companyId}/proxy-servers${id ? `/${id}` : ''}`,
    body,
    method: method || 'POST',
  };
  try {
    const response = await postInfo(config);
    const data = response.data;
    dispatch({
      type: POST_SUCCESS,
      payload: data,
    });
    dispatch({
      type: SUCCESS_ALERT,
      payload: `Path ${id ? 'Updated' : 'Added'} Succesfully`,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};
export const saveConfig = (body) => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  const config = {
    url: `system-codes/elastic-search-configuration/company/${companyId}`,
    body,
  };
  try {
    const response = await postInfo(config);
    const data = response.data;
    dispatch({
      type: POST_SUCCESS,
      payload: data,
    });
    dispatch({
      type: SUCCESS_ALERT,
      payload: `Index Configuration Added Succesfully`,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};
export const savePiiConfig = (body) => async (dispatch) => {
  dispatch({
    type: PROGRESS_START,
  });
  const companyId = localStorage.getItem('companyId');
  const config = {
    url: `system-codes/pii-entity-configuration/company/${companyId}`,
    body,
  };
  try {
    const response = await postInfo(config);
    const data = response.data;
    if (response.status == 200) {
      dispatch({
        type: POST_SUCCESS,
      });
      dispatch({
        type: SUCCESS_ALERT,
        payload: `PII Configuration Saved Succesfully`,
      });
    } else {
      dispatch({
        type: ERROR_ALERT,
        payload: data.message || `Something went wrong`,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
  dispatch({
    type: PROGRESS_DONE,
  });
};

export const updateConfig = (body) => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  const config = {
    url: `system-codes/global-configuration/company/${companyId}`,
    body,
  };
  dispatch({
    type: PROGRESS_START,
  });
  try {
    const response = await postInfo(config);
    const data = response.data;
    dispatch({
      type: POST_SUCCESS,
      payload: data,
    });
    dispatch({
      type: SUCCESS_ALERT,
      payload: `Global Configuration Updated Succesfully`,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
  dispatch({
    type: PROGRESS_DONE,
  });
};

export const getUncPath = (companyTag) => async (dispatch) => {
  dispatch({
    type: DATA_LOADER,
  });
  try {
    const response = await GetDetails({
      url: `file-stubber/stub-servers/companyTag/${companyTag || 'DataRepository'}`,
    });
    const data = response.data;
    dispatch({
      type: UNC_PATH_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};
