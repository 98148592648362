import { Alert, Box, Card } from '@mui/material';
import React from 'react';
import BarChart from 'src/pages/system/charts/BarChart';

const LicsenceExpired = () => {
  const dummyData = [
    {
      data: [0.0017108842730522156],
      label: 'tif',
      backgroundColor: '#FF5B24',
    },
    {
      data: [
        null,
        0.0005960622802376747,
        0.0008862586691975594,
        null,
        0.0032211095094680786,
        0.005766438320279121,
        0.003280426375567913,
        0.6281879777088761,
        24.053040022030473,
        28.23833167180419,
        0.2071422729641199,
        0.12868976965546608,
        0.41024197544902563,
        null,
        92.4987305868417,
        92.47446310054511,
        92.69543787185103,
        0.2036412199959159,
        null,
        0.02064275834709406,
      ],
      label: 'pdf',
      backgroundColor: '#00C2FF',
    },
    {
      data: [
        null,
        null,
        0.0016851425170898438,
        0.019840717315673828,
        0.00023746490478515625,
        0.0031447410583496094,
        0.005996227264404297,
        0.008960247039794922,
        0.6920810397714376,
      ],
      label: 'doc',
      backgroundColor: '#0E5E6F',
    },
    {
      data: [
        null,
        null,
        0.0015797615051269531,
        0.08673381805419922,
        0.00781393051147461,
        0.011073112487792969,
        null,
        null,
        0.6634870544075966,
      ],
      label: 'ppt',
      backgroundColor: '#c80064',
    },
    {
      data: [
        null,
        null,
        0.00048783794045448303,
        null,
        null,
        null,
        null,
        null,
        1.7388973673805594,
        0.1140350317582488,
        null,
        null,
        null,
        null,
        null,
        70.05606079101562,
        null,
        70.05606079101562,
      ],
      label: 'ai',
      backgroundColor: '#09D1EC',
    },
    {
      data: [null, null, null, 2.738189697265625],
      label: 'avi',
      backgroundColor: '#32A4D0',
    },
    {
      data: [
        null,
        null,
        null,
        0.5199283957481384,
        null,
        null,
        null,
        null,
        null,
        null,
        16.7532901763916,
        null,
        null,
        2.114482879638672,
      ],
      label: 'pst',
      backgroundColor: '#F09D1E',
    },
    {
      data: [null, null, null, 0.3228607177734375],
      label: 'pstx',
      backgroundColor: '#29CFA7',
    },
    {
      data: [
        null,
        null,
        null,
        null,
        0.008221733383834362,
        null,
        null,
        null,
        null,
        0.1950603974983096,
        0.08247954491525888,
        null,
        null,
        0.27237743511796,
        null,
        null,
        42.52747272886336,
        null,
        0.5406498610973358,
      ],
      label: 'zip',
      backgroundColor: '#CFC929',
    },
    {
      data: [
        null,
        null,
        null,
        null,
        null,
        14.176708221435547,
        null,
        null,
        null,
        null,
        null,
        0.05924331769347191,
        50.79469378199428,
        null,
        91.7062371764332,
        null,
        13.459270647726953,
        8.592024398036301,
        92.57485016435385,
        0.00937065202742815,
      ],
      label: 'docx',
      backgroundColor: '#7FC835',
    },
    {
      data: [null, null, null, null, null, 0.0007619494572281837],
      label: 'psd',
      backgroundColor: '#35C89C',
    },
    {
      data: [
        null,
        null,
        null,
        null,
        null,
        null,
        14.176708221435547,
        17.90199851989746,
        null,
        null,
        null,
        18.83332061767578,
        null,
        167.3273525238037,
        null,
        4.863482475280762,
        51.42961120605469,
        null,
        18.83332061767578,
      ],
      label: 'jpeg',
      backgroundColor: '#35BFC8',
    },
    {
      data: [null, null, null, null, null, null, 0.0007490534335374832, 0.0003943219780921936],
      label: 'pages',
      backgroundColor: '#AEF78E',
    },
    {
      data: [null, null, null, null, null, null, 0.0003292560577392578],
      label: 'otf',
      backgroundColor: '#F7717D',
    },
    {
      data: [null, null, null, null, null, null, null, 0.0002306513488292694],
      label: 'png',
      backgroundColor: '#F5EE9E',
    },
    {
      data: [null, null, null, null, null, null, null, null, 2.1958457911387086, 0.14253217726945877],
      label: 'jpg',
      backgroundColor: '#758BFD',
    },
    {
      data: [
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        0.5723209222778678,
        null,
        null,
        0.09781754016876221,
        0.48709230683743954,
        0.05797513574361801,
      ],
      label: 'mp4',
      backgroundColor: '#57CC99',
    },
    {
      data: [
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        32.80316162109375,
        56.08622360229492,
        null,
        null,
        4.863482475280762,
        4.863482475280762,
        null,
        140.11212539672852,
        84.0259017944336,
      ],
      label: 'cad',
      backgroundColor: '#ffc425',
    },
    {
      data: [null, null, null, null, null, null, null, null, null, null, 0.0762869343161583, 0.036343188025057316],
      label: 'pptx',
      backgroundColor: '#208AAE',
    },
    {
      data: [null, null, null, null, null, null, null, null, null, null, null, null, 84.10598747711629],
      label: 'xlsx',
      backgroundColor: '#3BCECC',
    },
    {
      data: [
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        0.14652939978986979,
        null,
        null,
        null,
        null,
        null,
        null,
        0.01206119917333126,
      ],
      label: 'log',
      backgroundColor: '#FFC933',
    },
    {
      data: [null, null, null, null, null, null, null, null, null, null, null, null, null, 0.4225018145516515],
      label: 'mp3',
      backgroundColor: '#10BFF9',
    },
    {
      data: [null, null, null, null, null, null, null, null, null, null, null, null, null, null, 0.03938418347388506],
      label: 'epub',
      backgroundColor: '#CA3F3F',
    },
    {
      data: [
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        8.588772773742676,
      ],
      label: 'xls',
      backgroundColor: '#ea5545',
    },
    {
      data: [
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        0.2620216868817806,
      ],
      label: 'pcapng',
      backgroundColor: '#f46a9b',
    },
    {
      data: [
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        0.06630396097898483,
        0.927853174507618,
      ],
      label: 'dll',
      backgroundColor: '#ef9b20',
    },
    {
      data: [
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        0.0285671167075634,
      ],
      label: 'pml',
      backgroundColor: '#edbf33',
    },
    {
      data: [
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        0.0061141010373830795,
      ],
      label: 'bmp',
      backgroundColor: '#ede15b',
    },
  ];
  const dymmyDataLabels = [
    '2003',
    '2004',
    '2005',
    '2007',
    '2008',
    '2009',
    '2010',
    '2011',
    '2012',
    '2013',
    '2014',
    '2015',
    '2016',
    '2017',
    '2018',
    '2019',
    '2020',
    '2021',
    '2022',
    '2023',
  ];
  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backdropFilter: 'blur(10px)',
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
        }}
      />
      <BarChart
        labels={dymmyDataLabels}
        data={dummyData}
        showOptions={false}
        chartHeading="Stacked Chart showing Volume of files types per"
        title="year"
      />
      <Box
        sx={{
          position: 'absolute',
          zIndex: 3, // Ensure the card appears above the blurred background and the chart
          top: '50%',
          left: '55%',
          transform: 'translate(-40%, -40%)',
          textAlign: 'center',
          width: '80%',
          background: 'transparent',
          // maxWidth: 400,
          padding: 20,
        }}
      >
        <Alert severity="warning">License expired: Unable to show Analytics</Alert>
      </Box>
    </>
  );
};

export default LicsenceExpired;
