import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, AccordionDetails, AccordionSummary, Card, Typography } from '@mui/material';
import Page from 'src/components/Page';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BarChart from '../../charts/BarChart';

export default function FolderSizeChart({ data, labels }) {
    
  return (
    <Page>
      <Card>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography variant="h6">Bar chart showing the top 10 largest folders by data volume</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <BarChart
              labels={labels}
              data={data}
              showOptions={false}
            />
          </AccordionDetails>
        </Accordion>
      </Card>
    </Page>
  );
}
