import { useState } from 'react';
import {  useTheme } from '@mui/material/styles';
import { Box, Card, Typography, Stack, FormControl, RadioGroup, FormControlLabel, Radio, Button } from '@mui/material';

export default function DateFilter( {handleDateType, type}) {
  const [dateType, setDateType] = type;
  const [date, setDate] = useState('archived');
  return (
    <Card sx={{ display: 'flex', alignItems: 'center', p: 3, minHeight: '90px' }}>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="subtitle1" sx={{ mb: '10px' }}>
          Date Archived / Accessed
        </Typography>
        <Stack direction='row'>
          <FormControl>
            <RadioGroup row defaultValue={dateType} name="radio-buttons-group" sx={{ gap: 10 }} onChange={(e)=> setDate(e.target.value)}>
              <FormControlLabel value="archived" control={<Radio />} label="Archived" />
              <FormControlLabel value="accessed" control={<Radio />} label="Accessed" />
            </RadioGroup>
          </FormControl>
          <Button variant="contained" sx={{minWidth:'60px', ml:'40px',color:'#fff' }} onClick={() => handleDateType(date)}>
              Filter
          </Button>
        </Stack>
      </Box>
    </Card>
  );
}
