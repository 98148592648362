import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import jwt from 'jwt-decode';
import { Container, Grid } from '@mui/material';
import LoadingScreen from 'src/components/LoadingScreen';
import GeneratLicence from './components/GenerateLicense';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import { getLicenseDetails, getLicenseDetails2 } from './licenseActions';
import ApplyLicense from './components/ApplyLicense';
import LicenseDetails from './components/LicenseDetails';

export default function License() {
  const dispatch = useDispatch();
  const { themeStretch } = useSettings();
  const license = useSelector((state) => state.licenseReducer.license);
  const license2 = useSelector((state) => state.licenseReducer.license2);
  const requestId = useSelector((state) => state.licenseReducer.requestId.key);
  const loading = useSelector((state) => state.licenseReducer.loading);

  const access_token = localStorage.getItem('accessToken');
  let admin = '';

  useEffect(() => {
    if (access_token) {
      const decodedToken = jwt(access_token);
      admin = decodedToken.authorities[0];
    }
  }, [access_token])

  useEffect(() => {
    dispatch(getLicenseDetails());
    if (admin == 'SUPERADMIN') {
      dispatch(getLicenseDetails2())
    }
  }, [admin]);

  return (
    <Page>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} style={{ paddingTop: '1px' }}>
            {!loading ? (
              // license  && (
              <GeneratLicence license={license} requestId={requestId} />
              // )
            ) : (
              <LoadingScreen />
            )}
          </Grid>
          <Grid item xs={12} md={12}>
            {!loading ? (
              // license && (
              <ApplyLicense license={license} />
              // )
            ) : (
              <LoadingScreen />
            )}
          </Grid>
          <Grid item xs={12} md={12}>
            {!loading ? (
              // license && (
              <LicenseDetails license={license} license2={license2} />
              // )
            ) : (
              <LoadingScreen />
            )}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
