import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Grid } from '@mui/material';
import useSettings from 'src/hooks/useSettings';
import Widgets from '../system-status/components/Widgets';
import { jobsStatusAction } from './jobsStatusAction';

export default function JobsStatus(){
    const dispatch = useDispatch();
    const { themeStretch } = useSettings();
    const jobs = useSelector((state) => state.jobsStatusReducer.jobsStatus);

    useEffect(()=>{
      dispatch(jobsStatusAction())
    },[])

    return(
        <>
        <Container maxWidth={themeStretch ? false : 'xl'}>
          <Grid container spacing={3}>
              <Grid item xs={12} md={4} style={{ paddingTop: '1px' }}>
                <Widgets title="Pending Jobs"  total={jobs.pendingJobCount || 0}   color="#03a9f4" />
              
              </Grid>

              <Grid item xs={12} md={4} style={{ paddingTop: '1px' }}>
                <Widgets title="Running Jobs"  total={jobs.runningJobCount || 0}  color="#f44336" />
              </Grid>

              <Grid item xs={12} md={4} style={{ paddingTop: '1px' }}>
                <Widgets title="Scheduled Jobs"  total={jobs.scheduledJobCount || 0}   color="#ff9800" />
              </Grid>
          </Grid>
        </Container>
        </>
    )
}