import { ACCOUNT_POLICY_FETCH_SUCCESS,DATA_LOADER, FETCH_FAIL, POST_SUCCESS  } from './accountPolicyActions';

const initialState = {
  loading: false,
  accountPolicy: {},
  error: null,
};

const accountPolicyReducer = (state = initialState, action) => {
  switch (action.type) {
    case DATA_LOADER: {
      return {
        ...state,
        loading: true,
      };
    }
    case ACCOUNT_POLICY_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        accountPolicy: action.payload,
      };
    }
    case POST_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case FETCH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default: {
      return state;
    }
  }
};

export default accountPolicyReducer;
