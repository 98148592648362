import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import { Box, Link, Typography } from '@mui/material';
import jwt from 'jwt-decode';
// hooks
import useAuth from '../../../hooks/useAuth';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import MyAvatar from '../../../components/MyAvatar';
import { useEffect, useState } from 'react';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

NavbarAccount.propTypes = {
  isCollapse: PropTypes.bool,
};

export default function NavbarAccount({ isCollapse }) {
  const [userName, setuserName] = useState();
  const companyTag = localStorage.getItem('companyTag');
  const companyTagLocal = localStorage.getItem('companyTagLocal');
  const access_token = localStorage.getItem('accessToken');
  const [adminRole, setAdminRole] = useState();


  useEffect(() => {
    if (access_token) {
      const decodedToken = jwt(access_token);
      setAdminRole(decodedToken.authorities[0])
      setuserName(decodedToken.user_name)
    }
  }, [access_token])

  return (
    // <Link underline="none" color="inherit" component={RouterLink} to={PATH_DASHBOARD.user.account}>
    <RootStyle
      sx={{
        ...(isCollapse && {
          bgcolor: 'transparent',
        }),
      }}
    >
      <MyAvatar />

      <Box
        sx={{
          ml: 2,
          transition: (theme) =>
            theme.transitions.create('width', {
              duration: theme.transitions.duration.shorter,
            }),
          ...(isCollapse && {
            ml: 0,
            width: 0,
          }),
        }}
      >
        <Typography variant="subtitle2" noWrap sx={{ textTransform: 'capitalize' }}>
          {userName}
        </Typography>
        <Typography variant="body2" noWrap sx={{ color: 'text.secondary', textAlign: "center" }}>
          {companyTag ? `(${companyTag})` : adminRole == "SUPERADMIN" ? null : `(${companyTagLocal})`}
        </Typography>
      </Box>
    </RootStyle>
    // </Link>
  );
}
