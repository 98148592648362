import { Typography, Stack, LinearProgress } from '@mui/material';
import { useEffect, useState } from 'react';


export default function DiscStatus({totalSpace, usedSpace, label}) {
  const [space, setSpace] = useState({
    totalSpace: {
      value: '',
      ext: ''
    },
    usedSpace: {
      value: '',
      ext: ''
    }
  });
  useEffect(() => {
    if(totalSpace && usedSpace){
      let totalDiscSpace = totalSpace / (1024 * 1024 * 1024)
      let usedDiscSpace = usedSpace / (1024 * 1024 * 1024)
      if(totalDiscSpace >= 1024 && usedDiscSpace >= 1024){
        setSpace({
          totalSpace: {value: Math.round(totalDiscSpace / 1024), ext: 'TB'},
          usedSpace: {value: Math.round(usedDiscSpace / 1024), ext: 'TB'},
        })
      }else if(totalDiscSpace >= 1024){
        setSpace({
          totalSpace: {value: Math.round(totalDiscSpace / 1024), ext: 'TB'},
          usedSpace: {value: Math.round(usedDiscSpace), ext: 'GB'},
        })
      }else{
        setSpace({
          totalSpace: {value: Math.round(totalDiscSpace), ext: 'GB'},
          usedSpace: {value: Math.round(usedDiscSpace), ext: 'GB'},
        })
      }
    }
  }, [label]);

  return (

    <ProgressItem  totalSpace = {space.totalSpace} usedSpace={space.usedSpace} label={label} />
  );
}

function ProgressItem({totalSpace, usedSpace, label}) {
  const [discName, setDiscName] = useState('');

  useEffect(() => {
    if (label) {
      if (Array.isArray(label)) {
        const names = label.map(item => item.name);
        const joinedNames = names.join(', ');
        const result = joinedNames.replace(/, $/, '');
        setDiscName(result);
      } else {
        setDiscName(label);
      }
    }
  }, [label]);

  return (
    <Stack spacing={1}>
      <Typography variant='subtitle1' mb={1}>
        {discName}
      </Typography>
      <Stack direction="row" gap={1} alignItems="center">
        <Typography variant="subtitle2" >Used Space: </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary',flexGrow: 1 }}>
         {usedSpace && `${usedSpace.value} ${usedSpace.ext}`}
        </Typography>
        <Typography variant="subtitle2">Total Space : </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        {totalSpace && `${totalSpace.value} ${totalSpace.ext}`}
        </Typography>
      </Stack>

      <LinearProgress
        variant="determinate"
        value={totalSpace.value && usedSpace.value && (usedSpace.value / totalSpace.value) * 100}
        sx={{height:'20px'}}
      />
    </Stack>
  );
}
