import { USERS_FETCH_SUCCESS, DATA_LOADER, FETCH_FAIL, FTP_USERS_FETCH_SUCCESS, ROLES_FETCH_SUCCESS, USER_INFO_FETCH_SUCCESS, USER_ADDED_SUCCESSFULLY, FTP_USER_INFO_FETCH_SUCCESS } from './usersActions';

const initialState = {
  loading: false,
  users: {},
  userInfo: {},
  ftpUserInfo: [],
  ftpUsers: [],
  roles: [],
  error: null,
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case DATA_LOADER: {
      return {
        ...state,
        loading: true,
      };
    }
    case USERS_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        users: action.payload,
      };
    }
    case FTP_USERS_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        ftpUsers: action.payload,
      };
    }
    case ROLES_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        roles: action.payload,
      };
    }
    case FTP_USER_INFO_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        ftpUserInfo: action.payload,
      };
    }
    case USER_ADDED_SUCCESSFULLY: {
      return {
        ...state,
        loading: false,
      };
    }
    case USER_INFO_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        userInfo: action.payload,
      };
    }
    case FETCH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default: {
      return state;
    }
  }
};

export default usersReducer;
