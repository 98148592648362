import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Grid } from '@mui/material';
import useSettings from 'src/hooks/useSettings';
import Page from 'src/components/Page';
import LoadingScreen from 'src/components/LoadingScreen';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import GlobalConfig from './components/GlobalConfig';
import {
  getGlobalConfiguration,
  getIndexConfiguration,
  getLanguages,
  getPiiConfiguration,
  getProxyPath,
  getTokenizers,
  getUncPath,
  updateConfig,
} from './globalActions';
import StubSecurity from './components/StubSecurity';
import EDiscovery from './EDiscovery';
import FileAuthorization from './components/FileAuthorization';
import EmailConfiguration from './components/EmailConfiguration';
import Replication from './components/Replication';
import IndexConfiguration from './components/IndexConfiguration';
import ProxyServer from './components/ProxyServer';
import { isEmpty } from 'lodash';
import PiiConfiguration from './components/PiiConfiguration';

export default function GlobalConfiguration() {
  const dispatch = useDispatch();
  const { themeStretch } = useSettings();
  const globalConfiguration = useSelector((state) => state.globalReducer.globalConfiguration);
  const indexConfiguration = useSelector((state) => state.globalReducer.indexConfiguration);
  const piiConfiguration = useSelector((state) => state.globalReducer.piiConfiguration);
  const languages = useSelector((state) => state.globalReducer.languages);
  const tokenizers = useSelector((state) => state.globalReducer.tokenizers);
  const proxyPaths = useSelector((state) => state.globalReducer.proxyPaths);
  const uncPaths = useSelector((state) => state.globalReducer.uncPaths);
  const loading = useSelector((state) => state.globalReducer.loading);
  const [fileAuth, setFileAuth] = useState();
  const companyTag = localStorage.getItem('companyTag');

  useEffect(() => {
    dispatch(getGlobalConfiguration());
    dispatch(getIndexConfiguration());
    dispatch(getPiiConfiguration());
    dispatch(getLanguages());
    dispatch(getTokenizers());
    dispatch(getProxyPath());
    dispatch(getUncPath(companyTag));
  }, []);

  const schema = yup.object().shape({
    // restoreDomainName: yup.string().required('required'),
    // restoreUser: yup.string().required('required'),
    // restorePassword: yup.string().required('required'),
    maxNoOfVersions: yup.string().required('required'),
    serverWarningLimit: yup.string().required('required'),
    serverCriticalLimit: yup.string().required('required'),
    encryptionFileSize: yup.string().required('required'),
    serverWarningLimit: yup.string().required('required'),
    serverWarningLimit: yup.string().required('required'),
  });
  const {
    register,
    handleSubmit,
    clearErrors,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (!isEmpty(globalConfiguration)) {
      reset(globalConfiguration);
    }
  }, [globalConfiguration]);

  const onSubmit = (data) => {
    data.fileAuthorizationMode = +fileAuth || 1;
    dispatch(updateConfig(data));
  };

  return (
    <Page>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        {!loading ? (
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={12} style={{ paddingTop: '1px' }}>
                <GlobalConfig
                  register={register}
                  watch={watch}
                  errors={errors}
                  setValue={setValue}
                  fileAuths={[fileAuth, setFileAuth]}
                  onSubmit={onSubmit}
                />
              </Grid>
              {/* <Grid item xs={12} md={12} sx={{ mt: '10px' }}>
                <FileAuthorization
                  register={register}
                  watch={watch}
                  setValue={setValue}
                  fileAuths={[fileAuth, setFileAuth]}
                />
              </Grid> */}
              {/* <Grid item xs={12} md={12} sx={{ mt: '10px' }}>
                <StubSecurity
                  register={register}
                  errors={errors}
                />
              </Grid> */}
              {/* <Grid item xs={12} md={12} sx={{ mt: '10px' }}>
                <EDiscovery
                  register={register}
                  errors={errors}
                />
              </Grid> */}
              {/* <Grid item xs={12} md={12} sx={{ mt: '10px' }}>
                <EmailConfiguration
                  register={register}
                  errors={errors}
                  watch={watch}
                  setValue={setValue}
                />
              </Grid> */}
              {/* <Grid item xs={12} md={12} sx={{ mt: '10px' }}>
                <Replication
                  register={register}
                  errors={errors}
                  watch={watch}
                  setValue={setValue}
                />
              </Grid> */}
              <Grid item xs={12} md={12} sx={{ mt: '10px' }}>
                <IndexConfiguration
                  indexConfiguration={indexConfiguration}
                  languages={languages}
                  tokenizers={tokenizers}
                  register={register}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} sx={{ mt: '10px' }}>
                <PiiConfiguration piiConfiguration={piiConfiguration} uncPaths={uncPaths} />
              </Grid>
              <Grid item xs={12} md={12} sx={{ mt: '10px' }}>
                <ProxyServer
                  register={register}
                  errors={errors}
                  proxyPaths={proxyPaths}
                  // onSubmit = {onSubmit}
                />
              </Grid>
            </Grid>
          </form>
        ) : (
          <LoadingScreen />
        )}
      </Container>
    </Page>
  );
}
