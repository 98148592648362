import {
  Button,
  Card,
  CardHeader,
  Checkbox,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Stack,
  TextField,
  Typography,
  Select,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import { restoreCAll, restoreFile } from '../fileActions';
import { useDispatch, useSelector } from 'react-redux';
import { ERROR_ALERT } from 'src/redux/reducers/alertReducer';
import moment from 'moment';
import { testAccess } from 'src/pages/configuration/job-management/jobManagementActions';
import TestAccessModal from 'src/pages/configuration/job-management/components/TestAccessModal';

const RestoreFolder = ({ openModal, close, node, agent, stubData, selectRestore }) => {
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const [stubPath, setStubPath] = useState('');
  const [agentId, setAgentId] = useState(null);

  const [destinationPath, setDestinationPath] = useState('');
  const [domainName, setDomainName] = useState('');
  const [userName, setUserName] = useState('');
  const [userPassword, setuserPassword] = useState('');

  const [jobName, setJobName] = useState('');
  const [checkFiles, setCheckFiles] = useState(true);
  const [checkStubs, setCheckStubs] = useState(true);
  const [checkStubFolder, setCheckStubFolder] = useState(true);
  const [selectPathArray, setSelectPathArray] = selectRestore;
  const [restoreFiles, setRestoreFiles] = useState([]);
  const [sendrestoreFiles, setSendRestoreFiles] = useState([]);
  const [editfile, setEditfile] = useState(0);
  const [openAccessModal, setOpenAccessModal] = useState(false);
  const [validTest, setValidTest] = useState(false);
  const testData = useSelector((state) => state.jobManagementReducer.testData);
  const [testAccessLoading, setTestAccessLoading] = useState(false);

  useEffect(() => {
    let restoreData = [];
    setEditfile(0);
    selectPathArray?.map((item) => {
      restoreData.push({
        destinationPath: item.path,
        lastModifiedDate: moment(node['lastModified']).format('x'),
        overwriteFiles: checkFiles,
        sourcePath: item.path,
        uuid: item.uuid,
      });
      setRestoreFiles(restoreData);
    });
  }, [selectRestore, checkFiles]);

  useEffect(() => {
    if (agent && agent.content && agent.content.length == 1) {
      setAgentId(agent.content[0].id);
    }
  }, [agent]);

  useEffect(() => {
    setSendRestoreFiles(restoreFiles);
  }, [restoreFiles]);

  useEffect(() => {
    if (node.path) {
      let path = node.path.split('/').slice(3);
      if (path.length >= 1) {
        path.join('');
        setStubPath(`\\\\${path}`);
      } else {
        path.join('');
        setStubPath(`\\${path}`);
      }
    }
  }, [node.path]);

  useEffect(() => {
    setUserName(stubData.restoreUser);
    setuserPassword(stubData.restorePassword);
    setDomainName(stubData.restoreDomainName);
  }, []);

  const restoreSubmit = () => {
    const companyId = localStorage.getItem('companyId');

    if (agentId == null) {
      dispatch({
        type: ERROR_ALERT,
        payload: 'Please select Agent',
      });
    } else if (node['type'] == 'nt:folder' && jobName == '') {
      dispatch({
        type: ERROR_ALERT,
        payload: 'Please enter job name',
      });
    } else if (node['type'] != 'nt:folder') {
      if (editfile > 0) {
        sendrestoreFiles.pop();
      }
      dispatch(
        restoreFile({
          agentId: agentId,
          companyId: +companyId,
          domain: domainName,
          password: userPassword,
          userName: userName,
          restoreFile: sendrestoreFiles,
        })
      );
      close();
      // setSendRestoreFiles([])
    } else {
      dispatch(
        restoreCAll({
          agentId: agentId,
          companyId: +companyId,
          destinationPath: destinationPath || stubPath.replace(/,/g, '\\'),
          domain: domainName,
          jobName: jobName,
          overwriteFiles: checkFiles,
          overwriteStubs: checkStubs,
          overwriteSubfolder: checkStubFolder,
          password: userPassword,
          policyId: 0,
          userName: userName,
          uuid: node.uuid,
        })
      );
      close();
    }
  };

  const fileDataMap = (e, i) => {
    setEditfile(editfile + 1);
    let val = e.target.value;
    setSendRestoreFiles([...restoreFiles, (restoreFiles[i].destinationPath = val)]);
  };

  const testAccessData = async () => {
    setTestAccessLoading(true);
    if (agentId == null) {
      dispatch({
        type: ERROR_ALERT,
        payload: 'Please select Agent',
      });
    } else {
      let path = '';
      if (destinationPath) {
        path = destinationPath;
      } else {
        path = node.uncpath;
      }
      if (path.slice(0, 2) == '\\\\' && path.split('\\').length > 3) {
        const pathParts = path.split('\\');
        const basePath = pathParts.slice(0, 4).join('\\');
        await dispatch(
          testAccess({
            domain: domainName,
            userName: userName,
            password: userPassword,
            agentId: agentId || 0,
            uncPath: `${basePath}`,
            libType: 'JNQ',
          })
        );
        setValidTest(true)
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload: 'Please enter a valid path',
        });
      }
    }
    setTestAccessLoading(false);
  };

  useEffect(() => {
    if (validTest && testData) {
      setOpenAccessModal(true);
    }

  }, [testData])
  return (
    <>
      <Modal
        open={openModal}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Page sx={{ width: '60%', maxHeight: '500px' }}>
          <Container maxWidth={themeStretch ? false : 'xl'}>
            <TestAccessModal
              testData={testData}
              openModal={openAccessModal}
              close={() => { setOpenAccessModal(false) }}
              loader={testAccessLoading}
            />
            <Card sx={{ backgroundColor: '#ffff', pb: '40px', px: '40px', overflowY: 'auto', maxHeight: '600px' }}>
              <CardHeader
                title={node['type'] == 'nt:folder' ? 'Restore Folder' : 'Restore File'}
                sx={{ mb: '10px', ml: '-25px' }}
              />

              <Grid container spacing={3}>
                {node['type'] == 'nt:folder' ? (
                  <>
                    <Grid item xs={12} md={12}>
                      <Stack flexDirection="column">
                        <Typography>Path</Typography>
                        <TextField variant="standard" disabled defaultValue={stubPath.replace(/,/g, '\\')} />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Stack flexDirection="column">
                        <Typography>Destination Path</Typography>
                        <TextField
                          variant="standard"
                          defaultValue={stubPath.replace(/,/g, '\\')}
                          onChange={(e) => setDestinationPath(e.target.value)}
                        />
                      </Stack>
                    </Grid>
                  </>
                ) : (
                  <>
                    {selectPathArray &&
                      selectPathArray.map((item, i) => {
                        return (
                          <>
                            <Grid item xs={12} md={12}>
                              <Stack flexDirection="column">
                                <Typography>Path</Typography>
                                <TextField variant="standard" disabled defaultValue={item.path} />
                              </Stack>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <Stack flexDirection="column">
                                <Typography>Destination Path</Typography>
                                <TextField
                                  variant="standard"
                                  defaultValue={item.path}
                                  // onChange={(e)=>setDestinationPath(e.target.value)}
                                  onChange={(e) => fileDataMap(e, i)}
                                />
                              </Stack>
                            </Grid>
                          </>
                        );
                      })}
                  </>
                )}

                <Grid item xs={12} md={12}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel id="agent">Agent</InputLabel>
                    <Select value={agentId} onChange={(e) => setAgentId(e.target.value)}>
                      {agent.content &&
                        agent.content.length &&
                        agent.content.map((agent) => {
                          return (
                            <MenuItem
                              key={agent.id}
                              value={agent.id}
                              sx={agent.online ? { color: '#45b55f' } : { color: '#f53b3b' }}
                            >
                              {agent.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
                {node['type'] == 'nt:folder' ? (
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Job Name"
                      variant="standard"
                      sx={{ width: '100%' }}
                      value={jobName}
                      onChange={(e) => setJobName(e.target.value)}
                    />
                  </Grid>
                ) : null}
                <Grid item xs={12} md={6}>
                  <TextField
                    defaultValue={domainName || ''}
                    label="Domain"
                    variant="standard"
                    sx={{ width: '100%' }}
                    onChange={(e) => setDomainName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    defaultValue={userName || ''}
                    label="Username*"
                    variant="standard"
                    sx={{ width: '100%' }}
                    onChange={(e) => setUserName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    defaultValue={userPassword || ''}
                    type="password"
                    label="Password*"
                    variant="standard"
                    sx={{ width: '100%' }}
                    onChange={(e) => setuserPassword(e.target.value)}
                  />
                  {userPassword?.includes('@') ?
                    <Typography variant="caption" color="red" sx={{ width: 'fit-content' }}>
                      @ symbol in password is not accepted, please update the password and try again
                    </Typography> : null
                  }
                </Grid>
                <Grid item xs={12} md={12}>
                  <Button variant="contained"
                    onClick={testAccessData}
                    disabled={userPassword?.includes('@') ? true : false}
                  > Test CIFS Access </Button>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Stack flexDirection="row">
                    <Stack flexDirection="row" alignItems="center">
                      <Checkbox checked={checkFiles} onChange={(e) => setCheckFiles(e.target.checked)} />{' '}
                      <Typography>Overwrite Files</Typography>
                    </Stack>
                    {node['type'] == 'nt:folder' ? (
                      <>
                        <Stack flexDirection="row" alignItems="center">
                          <Checkbox defaultChecked={checkStubs} onChange={(e) => setCheckStubs(e.target.checked)} />{' '}
                          <Typography>Overwrite Stubs</Typography>
                        </Stack>
                        <Stack flexDirection="row" alignItems="center">
                          <Checkbox
                            defaultChecked={checkStubFolder}
                            onChange={(e) => setCheckStubFolder(e.target.checked)}
                          />{' '}
                          <Typography>Restore Sub-folders</Typography>
                        </Stack>
                      </>
                    ) : null}
                  </Stack>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Stack flexDirection="row">
                    <Button variant="contained" onClick={() => close()} sx={{ color: '#fff', ml: '10px' }}>
                      Cancel
                    </Button>
                    <Button variant="contained"
                      disabled={userPassword?.includes('@') ? true : false}
                      onClick={() => restoreSubmit()}
                      sx={{ color: '#fff', ml: '10px' }}
                    >
                      Restore
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Card>
          </Container>
        </Page>
      </Modal>
    </>
  );
};

export default RestoreFolder;
