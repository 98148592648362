import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, AccordionDetails, AccordionSummary, Card, CardHeader, Container, Typography } from '@mui/material';
import Page from 'src/components/Page';
import moment from 'moment';
import useSettings from 'src/hooks/useSettings';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BarChart from '../../charts/BarChart';
import chartColors from 'src/utils/chartColors';
import { getTotalFileVolume } from '../analyticsActions';

export default function TotalFileVolume() {
  const dispatch = useDispatch();
  const [pieChartForAllData, setPieChartForAllData] = useState([]);
  const [pieChartForAllLabels, setPieChartForAllLabels] = useState([]);

  const dates = useSelector((state) => state.systemReducer.dates);
  const fileVolume = useSelector((state) => state.analyticsReducers.fileVolume);

  const [startDate, setStartDate] = useState(new Date('1995/01/01'));
  const [endDate, setEndDate] = useState(new Date());
  const [year, setYear] = useState();

  useEffect(() => {
    let unixStartDate = moment(dates.startDate).format('x');
    let unixEndDate = new Date(dates.endDate).setHours(23, 59, 59);

    // dispatch(getAnalyticsWidgetCount(unixStartDate, unixEndDate));
    // getVolumeFilesChartData();
    // dispatch(getVolumeData({ startDate: unixStartDate, endDate: unixEndDate }));
    dispatch(getTotalFileVolume({ startDate: unixStartDate, endDate: unixEndDate }));
  }, [dates]);

  useEffect(() => {
    if (fileVolume['aggregations'] !== undefined) {
      let data = [];
      let chartData = [];
      let labelBucket = [];

      let lab = [];
      let i = 0;
      for (let bucket of fileVolume['aggregations'][2].buckets) {
        let year = new Date(bucket.key).getFullYear().toString();

        //pieChartForAllLabels.sort();
        if (parseInt(year) >= startDate.getFullYear() && parseInt(year) <= endDate.getFullYear()) {
          for (let item of bucket[3].buckets) {
            const bucketValue = item[1].value / (1024 * 1024 * 1024);

            const index = chartData.findIndex((x) => x.label === item.key);
            //const index = labelBucket.indexOf(item.key);
            if (index === -1) {
              chartData.push({ label: item.key, data: bucketValue });
              // labelBucket.push(item.key);
              // data.push(bucketValue);
            } else {
              // data[index]['data'][0] =  data[index]['data'][0] + bucketValue;
              chartData[index].data += bucketValue;
            }
          }
        }
      }

      chartData.sort((a, b) => (a.data < b.data ? 1 : -1));

      labelBucket = chartData.map((d) => d.label);
      data = chartData.map((d) => d.data);

      setPieChartForAllLabels(labelBucket);
      setPieChartForAllData([{ data: data, label: 'Chart', backgroundColor: chartColors[i++] }]);
    }
  }, [fileVolume]);

  return (
    <Page>
      <Card sx={{ m: 0, p: 0 }}>
        {/* <Accordion> */}
        {/* <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header"> */}
        {/* <Container> */}
        {/* <Typography variant="h5" sx={{textAlign:"center",p:1}}>Aggregate size by file type</Typography> */}
        {/* </Container> */}
        {/* </AccordionSummary>
          <AccordionDetails> */}
        <BarChart
          labels={pieChartForAllLabels}
          data={pieChartForAllData}
          title='size by file type'
          chartHeading='Aggregate'
          showOptions={false}
          analyticsApi={true}
        />
        {/* </AccordionDetails> */}
        {/* </Accordion> */}
      </Card>
    </Page>
  );
}
