import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const PiiFilePathsTable = ({ columns, rows, handleDelete, editPath }) => {
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column, i) => (
                <TableCell key={i} align={column === 'Actions' ? 'right' : 'left'}>
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {rows.length ? 
          <TableBody>
            {rows.length &&
              rows.map((row, i) => (
                <TableRow key={i}>
                  <TableCell>{row}</TableCell>
                  <TableCell align="right">
                      <Button sx={{minWidth:'fit-content', p:0}} onClick={()=> editPath(row, i)}>
                        <ModeEditIcon />
                      </Button>
                    <Button sx={{minWidth:'fit-content', p:0}} onClick={() => handleDelete(row, i)}>
                      <DeleteForeverIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          :
          <TableBody>
            <TableCell></TableCell>
          </TableBody>  
        }
        </Table>
      </TableContainer>
    </>
  );
};

export default PiiFilePathsTable;
