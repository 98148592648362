import { Box, Card, Skeleton, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

export default function Widgets({ title, total, color }) {
  return (
    <Card sx={{ display: 'flex', alignItems: 'center', p: 3, minHeight: '90px' }}>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="subtitle2">{title}</Typography>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography sx={{ margin: '10px 30px' }} color={color} variant="h3">
              {total}
            </Typography>
        </div>
      </Box>
    </Card>
  );
}
