import { Button, Card, CardHeader, Container, Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { updateSharePointConfiguration, deleteSharePointConfiguration } from '../ldapActions';
import Iconify from 'src/components/Iconify';

const schema = yup.object().shape({
  sharePointSecret: yup.string().required('required'),
  sharePointTenantId: yup.string().required('required'),
  sharePointApplicationId: yup.string().required('required'),
});

const SharePointConfiguration = ({ sharePointConfiguration }) => {
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
const sharePointApplicationId = watch('sharePointApplicationId')
  useEffect(() => {
    if (!isEmpty(sharePointConfiguration)) {
      reset(sharePointConfiguration);
    } else {
      reset({
        sharePointApplicationId: '',
        sharePointSecret: '',
        sharePointTenantId: '',
      });
    }
  }, [sharePointConfiguration]);

  const onDelete = () => {
    if(sharePointApplicationId){
      dispatch(deleteSharePointConfiguration({sharePointApplicationId}));
    }
  };

  const onSubmit = (data) => {
    const companyId = localStorage.getItem('companyId');
    data.companyId = +companyId || 0;
    dispatch(updateSharePointConfiguration(data));
  };

  return (
    <Page>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Card sx={{ p: '20px' }}>
          <CardHeader title="SharePoint Configuration" />
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} sx={{ m: '10px' }}>
              <Grid item xs={12} md={9}>
                <TextField
                  name="sharePointApplicationId"
                  // value={sharePointConfiguration.sharePointApplicationId || ''}
                  label="SharePoint Application Id"
                  helperText={errors.sharePointApplicationId && errors.sharePointApplicationId.message}
                  error={errors.sharePointApplicationId && errors.sharePointApplicationId.message}
                  {...register('sharePointApplicationId')}
                  variant="standard"
                  sx={{ width: '100%' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  // onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  name="sharePointSecret"
                  label="SharePoint Secret"
                  type={showPassword ? 'text' : 'password'}
                  // value={sharePointConfiguration.sharePointSecret || ''}
                  helperText={errors.sharePointSecret && errors.sharePointSecret.message}
                  error={errors.sharePointSecret && errors.sharePointSecret.message}
                  {...register('sharePointSecret')}
                  variant="standard"
                  sx={{ width: '100%' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                          <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  // onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  name="sharePointTenantId"
                  label="Tenant Id"
                  // value={sharePointConfiguration.sharePointTenantId || ''}
                  helperText={errors.sharePointTenantId && errors.sharePointTenantId.message}
                  error={errors.sharePointTenantId && errors.sharePointTenantId.message}
                  {...register('sharePointTenantId')}
                  variant="standard"
                  sx={{ width: '100%' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  // onChange={handleChange}
                  required
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Button type="submit" variant="contained" sx={{ color: '#ffff' }}>
                  Update
                </Button>
                <Button variant="contained" sx={{ color: '#ffff', ml: '10px' }} onClick={onDelete}>
                  Delete
                </Button>
              </Grid>
            </Grid>
          </form>
        </Card>
      </Container>
    </Page>
  );
};

export default SharePointConfiguration;
