import { Button, Card, Container, Grid, Modal, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import jwt from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import Page from 'src/components/Page';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import useSettings from 'src/hooks/useSettings';
import { getAccountPolicy } from 'src/pages/user-groups/account-policy/accountPolicyActions';
import { postInfo } from 'src/utils/fetchServices';
import { SUCCESS_ALERT } from 'src/redux/reducers/alertReducer';

const ChangePassword = ({ openModal, close }) => {
  const dispatch = useDispatch();
  const { themeStretch } = useSettings();
  const accountPolicy = useSelector((state) => state.accountPolicyReducer.accountPolicy);
  const [userData, setUserData] = useState({ oldPassword: '', newPassword: '', confirmPassword: '' });
  const [passLength, setPassLength] = useState(6);
  const companyId = +localStorage.getItem('companyId');
  const access_token = localStorage.getItem('accessToken');
  const [userName, setuserName] = useState();

  useEffect(() => {
    if (access_token) {
      const decodedToken = jwt(access_token);
      setuserName(decodedToken.user_name)
    }
  }, [access_token])

  useEffect(() => {
    if (companyId) {
      dispatch(getAccountPolicy());
    }
  }, []);

  useEffect(() => {
    setPassLength(accountPolicy.passwordMinimumLength || 6);
  }, [accountPolicy]);

  const schema = yup.object().shape({
    oldPassword: yup.string().required('required'),
    newPassword: yup
      .string()
      .required('required')
      .min(passLength, `Password must be at least ${passLength} characters`)
      .matches(
        '(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])',
        'Must Contain One Uppercase, One Lowercase, One Number and one special case Character'
      ),
    confirmPassword: yup
      .string()
      .required('required')
      .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const updatePassword = (body) => async (dispatch) => {
    try {
      const response = await postInfo({ url: `users/change-password`, body, method: 'PUT' }, dispatch);
      if (response && response.status === 200) {
        dispatch({
          type: SUCCESS_ALERT,
          payload: 'Password updated successfully.',
        });
      }
      close()
    } catch (error) {
      console.log('errors', error);
    }
  };

  const onSubmit = (data) => {
    let companyId = localStorage.getItem('companyId');
    data.companyId = parseInt(companyId);
    data.userName = userName;
    dispatch(updatePassword(data));
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Page sx={{ width: '100%' }}>
          <Container maxWidth={themeStretch ? false : 'xl'}>
            <Card sx={{ backgroundColor: '#ffff', p: '40px' }}>
              <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Stack gap={2}>
                      <TextField
                        fullWidth
                        name="oldPassword"
                        variant="standard"
                        type="password"
                        helperText={errors.oldPassword && errors.oldPassword.message}
                        error={errors.oldPassword && errors.oldPassword.message}
                        {...register('oldPassword')}
                        label="Old Password"
                        required
                      />
                      <TextField
                        fullWidth
                        name="newPassword"
                        variant="standard"
                        type="password"
                        helperText={errors.newPassword && errors.newPassword.message}
                        error={errors.newPassword && errors.newPassword.message}
                        {...register('newPassword')}
                        label="New Password"
                        required
                      />
                      <TextField
                        fullWidth
                        name="confirmPassword"
                        variant="standard"
                        type="password"
                        helperText={errors.confirmPassword && errors.confirmPassword.message}
                        error={errors.confirmPassword && errors.confirmPassword.message}
                        {...register('confirmPassword')}
                        label="Confirm Password"
                        required
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Button type="submit" variant="contained" sx={{ color: '#ffff' }}>
                      Change Password
                    </Button>
                    <Button variant="contained" sx={{ color: '#ffff', ml: '10px' }} onClick={close}>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Stack gap={2}>
                      <Typography variant="h6">Password Policy:</Typography>
                      <Typography>
                        Required min length : {accountPolicy && accountPolicy.passwordMinimumLength}
                      </Typography>
                      <Typography>
                        Capital Letter Required :
                        {accountPolicy && accountPolicy.enforceCapsInPasswords ? ' Yes' : ' No'}
                      </Typography>
                      <Typography>
                        Numbers Required :{accountPolicy && accountPolicy.enforceNumbersInPasswords ? ' Yes' : ' No'}
                      </Typography>
                      <Typography>
                        Special Characters Required:{' '}
                        {accountPolicy && accountPolicy.enforceSpecialCharactersInPasswords ? ' Yes' : ' No'}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </form>
            </Card>
          </Container>
        </Page>
      </Modal>
    </>
  );
};

export default ChangePassword;
