import { useDispatch } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Page from 'src/components/Page';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import copiedFunction from 'src/utils/copyMethod';

export default function PathListTable({ pathList }) {
  const dispatch = useDispatch();

  const columns = ['Path ID', 'Path', ''];

  return (
    <Page>
      <Card sx={{p:2}}>
        {/* <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header"> */}
            <Typography variant="h6" sx={{p:2}}>Folder ID to Path mappings</Typography>
          {/* </AccordionSummary>
          <AccordionDetails> */}
            <TableContainer sx={{ minWidth: 720 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map((column, i) => (
                      <TableCell key={i}>{column}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pathList?.length &&
                    pathList.map((row, i) => (
                      <TableRow key={i}>
                        <TableCell sx={{py:0}} >
                          <Typography sx={{ color: '#699ad4' }}>
                            {row.md5Path.substr(0,3) + (row.md5Path).substr(row.md5Path.length-3,3)}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{py:0}} >{row.plainPath}</TableCell>
                        <TableCell sx={{py:0}} >
                          <Button onClick={() => copiedFunction(row.plainPath, dispatch)}>
                            <ContentCopyIcon />
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          {/* </AccordionDetails>
        </Accordion> */}
      </Card>
    </Page>
  );
}
