import {
  Box,
  Card,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  TableContainer,
  TablePagination,
  Typography,
  Stack,
} from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Scrollbar from '../../../../components/Scrollbar';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { deleteUser } from '../usersActions';
import { useState } from 'react';
import DeleteAlert from 'src/pages/DeleteAlert';
import PasswordDialog from './PasswordDilog';

export default function UsersTable({ pages, size, users, ftpUsers, userColumns, ftpUserColumns }) {
  const dispatch = useDispatch();
  const [rowsPerPage, setRowsPerPage] = size;
  const [page, setPage] = pages;
  const [deleteId, setDeleteId] = useState();
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [passwordDialog, setPasswordDialog] = useState(false);
  const [id,setId]= useState("")

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleDelete = (id) => {
    setDeleteId(id);
    setDeleteDialog(true);
  };
  const handlePassword = (row) => {
    setId(row)
    setPasswordDialog(true)
  };
  const removeInfo = () => {
    dispatch( deleteUser(deleteId));
    setDeleteDialog(false);
  };

  return (
    <>
      <Card>
      <DeleteAlert openDialog={[deleteDialog, setDeleteDialog]} remove={removeInfo} />
      <PasswordDialog openDialog={[passwordDialog, setPasswordDialog]} id={id} />
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <CardHeader title="User List" sx={{ mb: 3 }} />
          <Stack direction="row" alignItems="center">
            <Link to="add" style={{ textDecoration: 'none' }}>
              <Button variant="contained" sx={{ color: '#ffff', height: '40px', m: '20px' }}>
                Add
              </Button>
            </Link>
          </Stack>
        </Box>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 720 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {userColumns.map((column, i) => (
                    <TableCell key={i}>{column}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {users &&
                  users.content.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>
                        <Typography sx={{ color: '#699ad4' }}>{row.userName}</Typography>
                      </TableCell>
                      <TableCell>{row.roleName}</TableCell>
                      <TableCell>{row.firstName}</TableCell>
                      <TableCell>{row.lastName}</TableCell>
                      <TableCell>{row.lockStatus}</TableCell>
                      <TableCell sx={{ maxWidth: '150px' }}>
                        <Link to={`edit/${row.id}`} style={{ textDecoration: 'none' }}>
                          <Button sx={{minWidth:'fit-content', p:0}}>
                            <ModeEditIcon />
                          </Button>
                        </Link>
                        <Button onClick={() => handleDelete(row.id)} >
                          <DeleteForeverIcon />
                        </Button>
                        <Button sx={{minWidth:'fit-content', p:0}} onClick={()=>handlePassword(row.id)} >
                          <LockOpenIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20, 100]}
              component="div"
              count={users.totalElements}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Scrollbar>
      </Card>

      <Card sx={{mt:'20px'}}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <CardHeader title="FTP Users" sx={{ mb: 3 }} />
        </Box>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 720 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {ftpUserColumns.map((column, i) => (
                    <TableCell key={i}>{column}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {ftpUsers &&
                  ftpUsers.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>
                        <Typography sx={{ color: '#699ad4' }}>{row.profileName}</Typography>
                      </TableCell>
                      <TableCell>DataRepository</TableCell>
                      <TableCell sx={{ maxWidth: '150px' }}>
                      <Link to={`edit/ftp/${row.id}`} style={{ textDecoration: 'none' }}>
                        <Button>
                          <ModeEditIcon />
                        </Button>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Card>
    </>
  );
}
