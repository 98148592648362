import { Container, Grid, Modal, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import { downloadFile } from '../jobStatusActions';
import JobLogTable from './JobLogTable';

const JobLogModal = ({ modalPopUp }) => {
  const jobLogs = useSelector((state) => state.jobStatusReducer.jobLogs);
  const dispatch = useDispatch();

  const [openJobLogModal, setOpenJobLogModal] = modalPopUp;
  const { themeStretch } = useSettings();

  const jobLogColumns = ['Sequence Id', 'File Name', 'Last Modified Date', 'Action'];

  const handleClose = () => {
    setOpenJobLogModal(false);
  };
  const handleDownload =(jobId, name)=>{
    dispatch(downloadFile(jobId, name))
  }
  return (
    <>
      <Modal
        open={openJobLogModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Page sx={{ width: '100%' }}>
          <Container maxWidth={themeStretch ? false : 'xl'}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <JobLogTable jobLogColumns={jobLogColumns} jobLogs={jobLogs} handleDownload={handleDownload} close={handleClose} />
              </Grid>
            </Grid>
          </Container>
        </Page>
      </Modal>
    </>
  );
};

export default JobLogModal;
