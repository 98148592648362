import { ERROR_ALERT, SUCCESS_ALERT } from "src/redux/reducers/alertReducer";

const copiedFunction = (value, dispatch) => {
    if(value){
      const copy = function (e) {
        e.clipboardData.setData("text/plain", value);
        e.preventDefault();

        document.removeEventListener("copy", copy,true);
    }
      document.addEventListener("copy", copy,true);
      document.execCommand("copy");
      dispatch({
        type: SUCCESS_ALERT,
        payload: 'Copied to Clipboard',
      });
    }else {
      dispatch({
        type: ERROR_ALERT,
        payload:'Unable to copy',
      });
    }
  }
export default copiedFunction;