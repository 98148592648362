import React, { useState, useEffect } from 'react';
import { Button, Card, CardHeader, Container, Grid, TextField } from '@mui/material';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import useSettings from 'src/hooks/useSettings';
import LoadingScreen from 'src/components/LoadingScreen';
import Page from 'src/components/Page';
import AddDocumentCategoryTable from './AddDocumentCategoryTable';
import { getDocumentInfo, updateDocument, addDocument } from '../documentActions';
import { ERROR_ALERT, SUCCESS_ALERT } from 'src/redux/reducers/alertReducer';

const columns = ['Document Type', 'Extension', 'Action'];

const AddDocumentCategory = () => {
  const { themeStretch } = useSettings();
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const documentInfo = useSelector((state) => state.documentReducer.documentInfo);
  const [documentClassifier, setDocumentClassifier] = useState({
    name: "",
    description: "",
    children: null,
    companyId: null,
    id: 0,
    documentTypeString: "",
    documentTypes: [],
    removeDocumentTypes: null,
  });
  const [documentTypesArray, setDocumentTypesArray] = useState([]);
  const [documentType, setDocumentType] = useState({ name: '', value: '', checked: false, id: 0 });
  const [createFlag, setCreateFlag] = useState(true);

  useEffect(() => {
    let id = params.id;
    let companyId = localStorage.getItem('companyId');

    if (id) {
      dispatch(getDocumentInfo(params.id));
      setDocumentClassifier({ ...documentClassifier, id })
      setCreateFlag(false);
    } else {
      setDocumentClassifier({ ...documentClassifier, companyId: +companyId })
    }
  }, []);

  useEffect(() => {
    if (!createFlag) {
      setDocumentClassifier({ name: documentInfo.name, description: documentInfo.description });
      setDocumentTypesArray(documentInfo.documentTypes);
    }
  }, [documentInfo]);


  const schema = yup.object().shape({
    categoryName: yup.string().required('Category name is required')
      .matches(/^[a-zA-Z0-9\s\-#@$%&*!]+$/, 'Invalid characters in Category name'),
  });

  const {
    register,
    handleSubmit,
    clearErrors,
    reset, setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setValue("categoryName", documentClassifier.name)
  }, [documentClassifier.name])

  const addDocumentType = (documetType) => {
    if (documetType.value.charAt(0) != '.' || documetType.value.length > 5) {
      dispatch({
        type: ERROR_ALERT,
        payload: 'Please add a valid extension'
      })
      return;
    }
    setDocumentTypesArray([...documentTypesArray, documentType]);
    setDocumentType({ name: '', value: '' });
    // setDocumentClassifier({...documentClassifier, documentTypes: [...documentClassifier.documentTypes, documetType]}) 
  };

  const removeDocumentType = (index) => {
    documentTypesArray.splice(index, 1);
    setDocumentTypesArray([...documentTypesArray]);
  };

  const removeAllDocumentType = () => {
    documentTypesArray.splice(0, documentTypesArray.length);
    setDocumentTypesArray([...documentTypesArray]);
  };

  const onSubmit = () => {
    if (!createFlag) {
      dispatch(
        updateDocument({
          companyId: documentInfo.companyId,
          description: documentClassifier.description,
          documentTypes: documentTypesArray,
          id: documentInfo.id,
          indexContentEnabled: documentInfo.indexContentEnabled,
          name: documentClassifier.name,
        }, navigate)
      );
    } else {
      documentClassifier.documentTypes = documentTypesArray
      dispatch(
        addDocument(documentClassifier, navigate)
      )
    }
  };

  return (
    <Page>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Card sx={{ p: '20px' }}>
          <CardHeader title={`${createFlag ? 'Add' : 'Update'} Document Category`} />
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} sx={{ m: '20px' }}>
              <Grid item xs={12} md={5.5}>
                <TextField
                  name="categoryName"
                  value={documentClassifier.name || ''}
                  label="Category Name"
                  helperText={errors.categoryName && errors.categoryName.message}
                  error={errors.categoryName && errors.categoryName.message}
                  {...register('categoryName')}
                  variant="standard"
                  sx={{ width: '100%' }}
                  onChange={(e) => setDocumentClassifier({ ...documentClassifier, name: e.target.value })}
                  required
                />
              </Grid>
              <Grid item xs={12} md={5.5}>
                <TextField
                  name="description"
                  value={documentClassifier.description}
                  label="Description"
                  variant="standard"
                  sx={{ width: '100%' }}
                  onChange={(e) => setDocumentClassifier({ ...documentClassifier, description: e.target.value })}
                />
              </Grid>
              <Grid item xs={12} md={5.5}>
                <TextField
                  name="documentType"
                  value={documentType.name}
                  label="Document Type"
                  variant="standard"
                  sx={{ width: '100%' }}
                  onChange={(e) => setDocumentType({ ...documentType, name: e.target.value })}
                />
              </Grid>
              <Grid item xs={12} md={5.5}>
                <TextField
                  name="extension"
                  value={documentType.value}
                  label="Extension"
                  variant="standard"
                  sx={{ width: '100%' }}
                  onChange={(e) => setDocumentType({ ...documentType, value: e.target.value })}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Button
                  variant="contained"
                  sx={{ color: '#ffff' }}
                  onClick={() => addDocumentType(documentType)}
                  disabled={documentType.name === '' || documentType.value === '' ? true : false}
                >
                  Add
                </Button>
                <Button variant="contained" sx={{ color: '#ffff', ml: '10px' }} onClick={() => removeAllDocumentType()}>
                  Remove All
                </Button>
              </Grid>
              <Grid item xs={12} md={11}>
                <AddDocumentCategoryTable
                  columns={columns}
                  rows={documentTypesArray}
                  handleDelete={removeDocumentType}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ color: '#ffff' }}
                  disabled={documentClassifier.name === '' ? true : false}
                >
                  {createFlag ? 'Submit' : 'Update'}
                </Button>
                <Link to="/dashboard/document-classifier" style={{ textDecoration: 'none' }}>
                  <Button variant="contained" sx={{ color: '#ffff', ml: '10px' }}>
                    Back
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </form>
        </Card>
      </Container>
    </Page>
  );
};

export default AddDocumentCategory;