import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Grid } from '@mui/material';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import JobTable from './components/JobTable';
import { getJobStatus, postJobRerun, terminateJob } from './jobStatusActions';
import LoadingScreen from 'src/components/LoadingScreen';

export default function JobStatus() {
  const dispatch = useDispatch();
  const { themeStretch } = useSettings();
  const jobs = useSelector((state) => state.jobStatusReducer.jobStatus);
  const loading = useSelector((state) => state.jobStatusReducer.loading);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [sort, setSort] = useState('lastProcessedTime,desc')

  useEffect(() => {
    dispatch(getJobStatus({ page, size: rowsPerPage, sort: sort }));
  }, [page, rowsPerPage, refresh, sort]);

  const jobRerun = (row) => {
    if (row.jobStatus == 'PROCESSING') {
      dispatch(terminateJob(row.id));
    } else {
      dispatch(postJobRerun({ id: row.id, execTimeStartDate: row.execTimeStartDate, isScheduled: row.scheduled }));
    }
  };

  const columns = ['Name', 'Type', 'Agent', 'Policy', 'Target', 'Status', 'Terminate / Re-run'];

  return (
    <Page>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} style={{ paddingTop: '1px' }}>
            {!loading ? (
              jobs.content &&
              <JobTable
                pages={[page, setPage]}
                size={[rowsPerPage, setRowsPerPage]}
                jobs={jobs}
                jobRerun={jobRerun}
                columns={columns}
                reload={[refresh, setRefresh]}
                sortData={[sort, setSort]}
              />
            ) : <LoadingScreen />}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
