import { GetFetch } from 'src/utils/fetchServices';

export const STATUS_FETCH_SUCCESS = 'STATUS_FETCH_SUCCESS';
export const DISC_STATUS_FETCH_SUCCESS = 'DISC_STATUS_FETCH_SUCCESS';
export const PIE_CHART_FETCH_SUCCESS = 'PIE_CHART_FETCH_SUCCESS';
export const BAR_SYSTEM_CHART_YEAR_FETCH_SUCCESS = 'BAR_SYSTEM_CHART_YEAR_FETCH_SUCCESS';
export const BAR_CHART_MONTH_FETCH_SUCCESS = 'BAR_CHART_MONTH_FETCH_SUCCESS';
export const LINE_CHART_FETCH_SUCCESS = 'LINE_CHART_FETCH_SUCCESS';
export const DATA_LOADER = 'DATA_LOADER';
export const FETCH_FAIL = 'FETCH_FAIL';

export const statusWidgetCount = (startDate, endDate) => async (dispatch) => {
  dispatch({
    type: DATA_LOADER,
  });
  const body = {
    startDate: startDate,
    endDate: endDate,
  };
  const companyId = localStorage.getItem('companyId') 

  try {
    const response = await GetFetch({ url: `charts/company/${companyId}/widget`, params: body });
    const data = response.data;
    dispatch({
      type: STATUS_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};

export const getBarChartDataByYear = (startDate, endDate) => async (dispatch) => {
  dispatch({
    type: DATA_LOADER,
  });
  const body = {
    chartType: 'barByYear',
    startDate: startDate,
    endDate: endDate,
  };
  const companyId = localStorage.getItem('companyId') 

  try {
    const response = await GetFetch({ url: `charts/company/${companyId}/query`, params: body });
    const data = response.data;
    dispatch({
      type: BAR_SYSTEM_CHART_YEAR_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
    dispatch({
      type: BAR_SYSTEM_CHART_YEAR_FETCH_SUCCESS,
      payload: {},
    });
  }
};

export const getBarChartDataByMonth = (startDate, endDate) => async (dispatch) => {
  dispatch({
    type: DATA_LOADER,
  });
  const params = {
    chartType: 'barByMonth',
    startDate: startDate,
    endDate: endDate,
  };
  const companyId = localStorage.getItem('companyId') 
  try {
    const response = await GetFetch({ url: `charts/company/${companyId}/query`, params });
    const data = response.data;
    dispatch({
      type: BAR_CHART_MONTH_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};

export const getDiscStatus = () => async (dispatch) => {
  dispatch({
    type: DATA_LOADER,
  });
  const token = localStorage.getItem('accessToken');
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    Authorization: `Bearer ${token}`,
  };
  const companyId = localStorage.getItem('companyId') 
  try {
    const response = await GetFetch({ url: `system-status/disc-status/${companyId}`, headers });
    const data = response.data;
    dispatch({
      type: DISC_STATUS_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};

export const getLineChartData = (startDate, endDate) => async (dispatch) => {
  dispatch({
    type: DATA_LOADER,
  });
  const body = {
    chartType: 'pie',
    extrapolation: true,
    startDate: startDate,
    endDate: endDate,
  };
  const companyId = localStorage.getItem('companyId') 
  try {
    const response = await GetFetch({ url: `charts/company/${companyId}/query`, params: body });
    const data = response.data;
    dispatch({
      type: LINE_CHART_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};

export const getPieChartData = (startDate, endDate) => async (dispatch) => {
  dispatch({
    type: DATA_LOADER,
  });
  const body = {
    chartType: 'pieAll',
    startDate: startDate,
    endDate: endDate,
  };
  const companyId = localStorage.getItem('companyId') 
  try {
    const response = await GetFetch({ url: `charts/company/${companyId}/query`, params: body });
    const data = response.data;
    dispatch({
      type: PIE_CHART_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};
