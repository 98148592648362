import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Grid } from '@mui/material';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import DocumentTable from './components/DocumentTable';
import { getDocumentList } from './documentActions';
import LoadingScreen from 'src/components/LoadingScreen';

export default function DocumentClassifier() {
  const dispatch = useDispatch();
  const { themeStretch } = useSettings();
  const documents = useSelector((state) => state.documentReducer.documents);
  const loading = useSelector((state) => state.documentReducer.loading);
  const refresh = useSelector((state) => state.documentReducer.refresh);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [sort,setSort]= useState('name,desc')
 

  useEffect(() => {
    dispatch(getDocumentList({ page, size: rowsPerPage ,sort:sort}));
  }, [page, rowsPerPage,sort]);
  
  useEffect(()=> {
    if(refresh) {
      dispatch(getDocumentList({ page, size: rowsPerPage }));
    }
  },[refresh])

  const columns = ['Name', 'Content Index', 'Description', 'Action'];

  return (
    <Page>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} style={{ paddingTop: '1px' }}>
            {loading && <LoadingScreen />}
            {documents.content && 
              <DocumentTable
                pages={[page, setPage]}
                size={[rowsPerPage, setRowsPerPage]}
                documents={documents}
                columns={columns}
                sortData = {[sort,setSort]}
              />
            }
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
