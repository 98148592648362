import {
  Button,
  Card,
  Container,
  Modal,
  Stack,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Tooltip,
  tooltipClasses,
  TablePagination,
} from '@mui/material';
import { styled } from '@mui/styles';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import moment from 'moment';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import { formatSize } from 'src/utils/formatSize';
import copiedFunction from 'src/utils/copyMethod';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';

const columns = ['File Path', 'Last modified', 'Last Accessed', 'Size'];

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} enterDelay={600} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: 13,
    fontWeight: 400,
    maxWidth: 'none',
  },
}));

const DuplicateFileModal = ({
  openModal,
  close,
  data,
  totalCount,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
}) => {
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const [csvData, setCsvData] = useState([]);

  useEffect(() => {
    if (data?.documents) {
      setCsvData(
        data?.documents.map((item) => ({
          createdDate: item.createdDate,
          documentName: item.documentName,
          documentPath: item.documentPath,
          modifiedBy: item.modifiedBy,
          lastModificationDate: item.lastModificationDate,
          documentSize: item.documentSizeToDisplay,
        }))
      );
    }
  }, [data]);
  return (
    <>
      <Modal
        open={openModal}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Page sx={{ width: '1200px' }}>
          <Container maxWidth={themeStretch ? false : 'xl'}>
            <Card sx={{ p: '20px' }}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: '10px' }}>
                <Typography variant="h6"> File HashId : {data?.fileHashId}</Typography>
                <Stack direction="row" gap={2}>
                  <CSVLink data={csvData} filename={`${data?.fileHashId}.csv`} style={{ textDecoration: 'none' }}>
                    <Button variant="contained">Export</Button>
                  </CSVLink>
                  <Button variant="contained" onClick={close}>
                    Close
                  </Button>
                </Stack>
              </Stack>
              <TableContainer sx={{ maxHeight: 400 }}>
                <Table stickyHeader>
                  <colgroup>
                    <col style={{ width: '48%' }} />
                    <col style={{ width: '16%' }} />
                    <col style={{ width: '16%' }} />
                    <col style={{ width: '10%' }} />
                  </colgroup>
                  <TableHead>
                    <TableRow>
                      {columns.map((column, i) => (
                        <TableCell key={i}>{column}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.documents?.length &&
                      data?.documents.map((row, i) => (
                        <TableRow key={i}>
                          <TableCell sx={{ py: 0 }}>
                            <Stack direction="row" alignItems="center">
                              <Button
                                onClick={() => copiedFunction(row.documentPath, dispatch)}
                                sx={{ minWidth: 'fit-content' }}
                              >
                                <ContentCopyIcon />
                              </Button>
                              <LightTooltip title={row.documentPath}>
                                <Typography
                                  sx={{
                                    cursor: 'pointer',
                                    color: '#039be5',
                                    fontSize: '14px',
                                  }}
                                >
                                  {row.documentPath.slice(0, 70)}
                                  {row.documentPath.length > 70 ? '...' : ''}
                                </Typography>
                              </LightTooltip>
                            </Stack>
                          </TableCell>
                          <TableCell sx={{ py: 0 }}>
                            {moment(row.lastModificationDate).format('YYYY-MM-DD HH:mm:ss')}
                          </TableCell>
                          <TableCell sx={{ py: 0 }}>{moment(row.accessedOn).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                          <TableCell sx={{ py: 0 }}>{formatSize(row.fileSize)}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 50, 100]}
                  component="div"
                  count={totalCount ? totalCount : 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            </Card>
          </Container>
        </Page>
      </Modal>
    </>
  );
};

export default DuplicateFileModal;
