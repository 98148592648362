import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import login from './reducers/login';
import systemReducer from './reducers/systemReducer';
import systemStatusReducers from 'src/pages/system/system-status/systemStatusReducers';
import analyticsReducers from 'src/pages/system/analytics/analyticsReducer';
import repositoryReducer from 'src/pages/system/repository-report/repositoryReducer';
import jobStatusReducer from 'src/pages/system/job-status/jobStatusReducer';
import jobManagementReducer from 'src/pages/configuration/job-management/jobManagementReducer';
import policyManagementReducer from 'src/pages/configuration/policy-management/policyManagementReducer';
import documentReducer from 'src/pages/configuration/document-classifier/documentReducer';
import agentReducer from 'src/pages/configuration/archive-agents/AgentReducer';
import volumeReducer from 'src/pages/configuration/volume-management/volumeReducer';
import stubReducer from 'src/pages/configuration/stub-agent/stubReducer';
import usersReducer from 'src/pages/user-groups/user-management/usersReducer';
import buildInfoReducer from 'src/pages/system-configuration/build-info/buildInfoReducer';
import licenseReducer from 'src/pages/license/licenseReducer';
import ldapReducer from 'src/pages/system-configuration/ldap-configuration/ldapReducer';
import accountPolicyReducer from 'src/pages/user-groups/account-policy/accountPolicyReducer';
import globalReducer from 'src/pages/system-configuration/global-configuration/globalReducer';
import fileReducer from 'src/pages/system/file-explorer/fileReducer';
import alertReducer from './reducers/alertReducer';
import userSettingReducer from 'src/layouts/dashboard/header/userSettingReducer';
import ediscoveryReducer from 'src/pages/system/e-discovery/ediscoveryReducer';
import companyReducer from 'src/pages/configuration/company-list/companyReducer';
import featureReducer from 'src/pages/system-configuration/user-feature/featureReducer';
import auditReducer from 'src/pages/system/audit login log/auditReducer';
import dataRetensionReducer from 'src/pages/system/data retension/dataRetensionReducer';
import auditAccessReducer from 'src/pages/system/audit access log/auditAccessReducer';
import applicationEventReducer from 'src/pages/system/application-event-log/applicationEventReducers';
import jobsStatusReducer from 'src/pages/system/jobs-status/jobsReducer';
import analyticPathReducer from 'src/pages/system/analytics-paths/AnalyticsPathReducer';
import searchHistoryReducer from 'src/pages/system/user-search-history/searchHistoryReducer';

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  login,
  systemReducer,
  systemStatusReducers,
  analyticsReducers,
  repositoryReducer,
  jobStatusReducer,
  jobManagementReducer,
  policyManagementReducer,
  documentReducer,
  agentReducer,
  volumeReducer,
  stubReducer,
  usersReducer,
  buildInfoReducer,
  licenseReducer,
  ldapReducer,
  accountPolicyReducer,
  globalReducer,
  fileReducer,
  alertReducer,
  userSettingReducer,
  ediscoveryReducer,
  companyReducer,
  featureReducer,
  auditReducer,
  dataRetensionReducer,
  auditAccessReducer,
  applicationEventReducer,
  jobsStatusReducer,
  analyticPathReducer,
  searchHistoryReducer,
});

export { rootPersistConfig, rootReducer };
