import { capitalCase } from 'change-case';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Card, Stack, Link, Alert, Tooltip, Container, Typography } from '@mui/material';
// routes
import { PATH_AUTH } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';
import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';
import Logo from '../../components/Logo';
import Image from '../../components/Image';
// sections
import { LoginForm } from '../../sections/auth/login';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ColorInfo, appInfo, appTitleName, companyName, getCompanyMode } from 'src/redux/actions/loginActions';
import { dispatch } from 'src/redux/store';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { method } = useAuth();
  const params = useParams();
  const navigate = useNavigate()
  const smUp = useResponsive('up', 'sm');
  const [companyTagLocal, setCompanyTagLocal] = useState()
  const [companyMode, setCompanyMode] = useState()
  const companyTag = useSelector((state) => state.login.companyTag);
  const singleCompanyMode = useSelector((state) => state.login.singleCompanyMode);

  
  useEffect(() => {
    dispatch(getCompanyMode());
    dispatch(appTitleName())
    dispatch(appInfo())
    dispatch(ColorInfo())
    if(params['tag']){
      dispatch(companyName(params['tag']))
    }
  }, [companyTag]);

  useEffect(()=> {
    setCompanyMode(singleCompanyMode)
  },[singleCompanyMode])

  useEffect(()=> {
    if(companyMode == 'true' || companyMode == true ){
      localStorage.setItem('companyTagLocal','DataRepository')
      // navigate('/')
    }else{
      if (params["tag"] && !localStorage.getItem("access_token") ) {
        localStorage.setItem("companyTagLocal", params["tag"]);
      }
    }
      if (
        localStorage.getItem("companyTagLocal") != "null" &&
        localStorage.getItem("companyTagLocal") != "undefined" &&
        localStorage.getItem("companyTagLocal") != null
        ) {
        setCompanyTagLocal(localStorage.getItem("companyTagLocal"));
      }
  },[companyTag, companyMode])

  const mdUp = useResponsive('up', 'md');

  return (
    <Page title="Login">
      <RootStyle>
        <HeaderStyle>
          <Logo />
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  Login
                </Typography>
               {companyTagLocal && <Typography sx={{ color: 'text.secondary' }}>({companyTagLocal})</Typography>}
              </Box>

              {/* <Tooltip title={capitalCase(method)} placement="right">
                <>
                  <Image
                    disabledEffect
                    src={`https://minimal-assets-api.vercel.app/assets/icons/auth/ic_${method}.png`}
                    sx={{ width: 32, height: 32 }}
                  />
                </>
              </Tooltip> */}
            </Stack>
            {/* 
            <Alert severity="info" sx={{ mb: 3 }}>
              Use email : <strong>demo@minimals.cc</strong> / password :<strong> demo1234</strong>
            </Alert> */}

            <LoginForm companyTagLocal={companyTagLocal} companyMode={companyMode} />
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
