import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Grid } from '@mui/material';
import useSettings from 'src/hooks/useSettings';
import Page from 'src/components/Page';
import ProfileTable from './ProfileTable';
import LoadingScreen from 'src/components/LoadingScreen';
import { getCloudProfile } from '../volumeActions';

export default function ManageProfile() {
  const dispatch = useDispatch();
  const { themeStretch } = useSettings();
  const cloudProfile = useSelector((state) => state.volumeReducer.cloudProfile);
  const loading = useSelector((state) => state.volumeReducer.loading);
  const [clouldProvider, setCloudProvider] = useState(2);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  useEffect(() => {
    dispatch(getCloudProfile(clouldProvider, { page, size: rowsPerPage }));
  }, [clouldProvider, rowsPerPage, page]);

  const columns = ['No.', 'Name', 'fileStoreBase', 'Action'];

  return (
    <Page>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} style={{ paddingTop: '1px' }}>
            {!loading ? (
              cloudProfile && (
                <ProfileTable
                  pages={[page, setPage]}
                  size={[rowsPerPage, setRowsPerPage]}
                  cloudProfile={cloudProfile}
                  columns={columns}
                  cloudType={[clouldProvider, setCloudProvider]}
                />
              )
            ) : (
              <LoadingScreen />
            )}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
