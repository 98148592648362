import { Button, Card, Container, Modal, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import LoadingScreen from 'src/components/LoadingScreen';
import { truncateString } from 'src/utils/helper';

const TestAccessModal = ({ openModal, close, testData, loader }) => {
  const { themeStretch } = useSettings();
  const [expandState, setExpandState] = useState({
    mountStatus: false,
    readStatus:false,
    writeStatus: false
  })

  const expand = (nm) => {
    setExpandState((prev) =>(
      {
        ...prev,
        [nm]: !prev[nm]
      })
    )
  }

  return (
    <>
      <Modal
        open={openModal}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Page sx={{ width: '680px' }}>
          <Container maxWidth={themeStretch ? false : 'xl'}>
            {loader ?
              <LoadingScreen />
              :
              <Card sx={{ backgroundColor: '#ffff' }}>
                <Card
                  sx={{
                    p: '20px',
                    m: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {testData?.mountStatus?.message && <p className={`status-error ${expandState.mountStatus ? 'expand' : ''}`}>{expandState.mountStatus ? testData?.mountStatus?.message : truncateString(testData?.mountStatus?.message, 120)}{testData?.mountStatus?.message.length > 120 ? <button onClick={()=> expand('mountStatus')}>{expandState.mountStatus ? 'Show Less' : 'Expand'}</button> : ''}</p>}
                  <Stack direction="row">
                    <Typography variant="h6">Mount Status : </Typography>
                    {testData?.mountStatus?.status === 'failed' ? <CloseIcon sx={{ color: 'red' }} /> : <DoneIcon sx={{ color: "green" }} />}
                  </Stack>
                  {testData?.readStatus?.message && <p className={`status-error ${expandState.readStatus ? 'expand' : ''}`}>{expandState.readStatus ? testData?.readStatus?.message : truncateString(testData?.readStatus?.message, 120)}{testData?.readStatus?.message.length > 120 ? <button onClick={()=> expand('readStatus')}>{expandState.readStatus ? 'Show Less' : 'Expand'}</button> : ''}</p>}
                  <Stack direction="row">
                    <Typography variant="h6">Read Status : </Typography>
                    {testData?.readStatus?.status === 'failed' ? <CloseIcon sx={{ color: 'red' }} /> : <DoneIcon sx={{ color: "green" }} />}
                  </Stack>
                  {testData?.writeStatus?.message && <p className={`status-error ${expandState.writeStatus ? 'expand' : ''}`}>{expandState.writeStatus ? testData?.writeStatus?.message : truncateString(testData?.writeStatus?.message, 120)}{testData?.writeStatus?.message.length > 120 ? <button onClick={()=> expand('writeStatus')}>{expandState.writeStatus ? 'Show Less' : 'Expand'}</button> : ''}</p>}
                  <Stack direction="row">
                    <Typography variant="h6">Write Status : </Typography>
                    {testData?.writeStatus?.status === 'failed' ? <CloseIcon sx={{ color: 'red' }} /> : <DoneIcon sx={{ color: "green" }} />}
                  </Stack>
                  <Button variant="contained" sx={{ color: '#ffff', m: '10px' }} onClick={close}>
                    Close
                  </Button>
                </Card>
              </Card>
            }
          </Container>
        </Page>
      </Modal>
    </>
  );
};

export default TestAccessModal;
