import { ERROR_ALERT, SUCCESS_ALERT } from "src/redux/reducers/alertReducer";
import { deleteInfo, GetDetails } from "src/utils/fetchServices";


export const DATA_RETENSION_SUCCESS = 'DATA_RETENSION_SUCCESS';
export const SUCCESS = 'SUCCESS';
export const LOADER = 'LOADER';
export const FETCH_FAIL = 'FETCH_FAIL';

export const getRetensionData = () => async (dispatch) => {
    const companyId = localStorage.getItem('companyId')
    dispatch({
        type: LOADER
    })
    try {
      const response = await GetDetails({ url: `delete-document/${companyId}` });
      const data = response.data;
      dispatch({
        type: DATA_RETENSION_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_FAIL,
        payload: error.message,
      });
    }
  };

export const deleteDocument = (uuid) => async (dispatch) => {
    const companyId = localStorage.getItem('companyId')
    dispatch({
        type: LOADER
    })
    try {
      const response = await deleteInfo({ url: `delete-document/${companyId}/${uuid}` });
      dispatch({
        type:SUCCESS,
      })
      dispatch({
        type: SUCCESS_ALERT,
        payload: 'Approved',
      });
    } catch (error) {
      dispatch({
        type: FETCH_FAIL
      });
      dispatch({
        type: ERROR_ALERT,
        payload: 'Something Went Wrong',
      });
    }
  };