import {
  DATA_LOADER,
  FETCH_FAIL,
  FILES_ARCHIEVED,
  NUMBER_OF_FILES_FETCH_SUCCESS,
  NUMBER_OF_FILES_PER_PATH_FETCH_SUCCESS,
} from './repositoryActions';

const initialState = {
  loading: false,
  numberOfFiles: [],
  numberOfFilesPerPath: [],
  filesArchieved: {},
  error: null,
};

const repositoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case DATA_LOADER: {
      return {
        ...state,
        loading: true,
      };
    }
    case NUMBER_OF_FILES_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        numberOfFiles: action.payload,
      };
    }
    case NUMBER_OF_FILES_PER_PATH_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        numberOfFilesPerPath: action.payload,
      };
    }
    case FETCH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FILES_ARCHIEVED:
      return {
        ...state,
        loading: false,
        filesArchieved: action.payload,
      };
    default: {
      return state;
    }
  }
};

export default repositoryReducer;
