import React, { useEffect } from 'react';
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
import { Card, CardHeader, Box, TextField, Button } from '@mui/material';
import { BaseOptionChart } from '../../../components/chart';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import axiosInstance from 'src/utils/axios';
import { BASE_URL } from 'src/config';
import { ERROR_ALERT, PROGRESS_DONE, PROGRESS_START, SUCCESS_ALERT } from 'src/redux/reducers/alertReducer';
import moment from 'moment/moment';
import { getHeaders } from 'src/utils/fetchServices';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function formatBytes(bytes, decimals = 3) {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export default function BarChart({
  labels,
  data,
  showOptions,
  selectDataYearly,
  selectDataMonthly,
  steps,
  chartHeading,
  title,
  allData,
  year,
  month,
  pathKey,
  analyticsApi,
  stackedChart,
  onClose,
}) {
  const dates = useSelector((state) => state.systemReducer.dates);
  const [seriesData, setSeriesData] = useState('Year');
  const [stepCount, setStepCount] = steps || [];
  const [label, setLabel] = useState('');
  const dispatch = useDispatch();
  const [selectMonths, setSelectMonths] = useState('');
  const [selectedState, setSelectedState] = useState({
    type: 'yearly',
    selectedMonth: '',
    selectedMonthVal: '',
    selectedYear: '',
    selectedYearVal: '',
  });
  const [data2,setData2] = useState({
    labels: [],
    datasets: [],
  })

  let months;

  const getMonthsData = (month) => {
    switch (month) {
      case 'Jan':
        months = '0';
        break;
      case 'Feb':
        months = '1';
        break;
      case 'Mar':
        months = '2';
        break;
      case 'Apr':
        months = '3';
        break;
      case 'May':
        months = '4';
        break;
      case 'Jun':
        months = '5';
        break;
      case 'Jul':
        months = '6';
        break;
      case 'Aug':
        months = '7';
        break;
      case 'Sep':
        months = '8';
        break;
      case 'Oct':
        months = '9';
        break;
      case 'Nov':
        months = '10';
        break;
      case 'Dec':
        months = '11';
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    getMonthsData(month);
    setSelectMonths(months);
  }, [month]);

  const options = {
    onClick: function (evt, element) {
      if (element.length > 0) {
        var ind = element[0].index;
        let yearData, startDate, endDate;
        let MonthData = selectMonths || 11;
        if (year != null) {
          yearData = +year;
          startDate = moment(new Date(yearData, ind, 1)).format('x');
          endDate = moment(new Date(yearData, ind, 31)).format('x');
          if (selectMonths) {
            let weekData = labels[ind];
            let startWeekDate = new Date(weekData);
            let endWeekDate = new Date(startWeekDate).setDate(new Date(weekData).getDate() + 7);

            startDate = moment(new Date(startWeekDate)).format('x');
            endDate = moment(new Date(endWeekDate)).format('x');
          }
        } else if (analyticsApi) {
          startDate = moment.utc(dates.startDate).format('x');
          endDate = new Date().setHours(23, 59, 59);
        } else {
          yearData = +labels[ind];
          startDate = moment(new Date(yearData - 1, MonthData, 32)).format('x');
          endDate = moment(new Date(yearData, MonthData, 32)).format('x');
        }

        let params = {
          fileExtension: label,
          startDate: startDate,
          endDate: endDate,
        };
        if (pathKey) {
          let path = pathKey.replace('\\\\', '').replace(/\\/g, '/');
          params.rootPath = path;
        }
        if (params.fileExtension != 'nt:linkedFile' && params.fileExtension != 'nt:file' && params.fileExtension != '')
          chartDownload(params);
      }
    },
    plugins: {
      title: {
        display: true,
      },
      tooltip: {
        displayColors: false,
        titleFont: "'Open Sans', Helvetica, Arial, sans-serif",
        titleFontStyle: 'normal',
        titleColor: '#66afc9',
        bodyFont: "'Open Sans', Helvetica, Arial, sans-serif",
        bodyFontStyle: 'normal',
        bodyColor: '#66afc9',
        footerFont: "'Open Sans', Helvetica, Arial, sans-serif",
        footerFontStyle: 'normal',
        footerColor: '#66afc9',
        afterFooterFont: "'Open Sans', Helvetica, Arial, sans-serif",
        afterFooterFontStyle: 'normal',
        afterFooterColor: '#66afc9',
        footerSpacing: 7,
        callbacks: {
          title: function (tooltipItem) {
            return 'Size Volume:  ' + formatBytes(tooltipItem[0].parsed.y * 1024 * 1024 * 1024);
          },
          label: function (tooltipItem) {
            if (tooltipItem && tooltipItem.dataset.label == '') {
              return 'UUID: ' + tooltipItem.label;
            } else {
              return 'Date Modified:  ' + tooltipItem.label;
            }
          },
          footer: function (tooltipItem) {
            setLabel(tooltipItem[0].dataset.label);
            if (tooltipItem && tooltipItem[0].dataset.label != '') {
              return 'File Extensions:  ' + tooltipItem[0].dataset.label;
            }
          },
          afterFooter: function (tooltipItem) {
            if (
              tooltipItem &&
              tooltipItem[0].dataset.label != '' &&
              tooltipItem[0].dataset.label != 'nt:file' &&
              tooltipItem[0].dataset.label != 'nt:linkedFile'
            ) {
              return 'Click to download list';
            }
          },
        },
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        ticks: {
          autoSkip: title === 'month' ? true : false,
        },
        title: {
          display: true,
          text: chartHeading == 'Aggregate' ? 'Data Type':'Date Modified',
        },
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: 'Size Volume (GB)',
        },
      },
    },
  };

  useEffect(()=>{
    setData2({ labels: labels,datasets: data,})
  },[data,labels])

  const onBackAction = () => {
    if (selectedState.type === 'monthly') {
      setSelectedState({
        ...selectedState,
        type: 'yearly',
        selectedMonthVal: '',
        selectedMonth: '',
      });
      setStepCount(1);
      allData();
    } else if (selectedState.type === 'single-month') {
      selectDataYearly(null, selectedState.selectedYearVal);
      setSelectedState({
        ...selectedState,
        type: 'monthly',
      });
    } else {
      setSelectedState({
        ...selectedState,
        type: 'yearly',
        selectedMonth: '',
        selectedMonthVal: '',
        selectedYear: '',
        selectedYearVal: '',
      });
    }
  };

  const onNext = (e) => {
    if (selectedState.type === 'yearly') {
      selectDataYearly(e);
      setSelectedState({
        ...selectedState,
        type: 'monthly',
        selectedYearVal: e.target.value,
        selectedYear: e,
      });
    } else if (selectedState.type === 'monthly') {
      selectDataMonthly(e);
      setSelectedState({
        ...selectedState,
        type: 'single-month',
        selectedMonthVal: e.target.value,
        selectedMonth: e,
      });
    }
  };

  const chartDownload = async (params) => {
    let companyId = localStorage.getItem('companyId');
    dispatch({
      type: PROGRESS_START,
    });
    try {
      let barChartAPI = '';
      if (showOptions || analyticsApi || pathKey) {
        barChartAPI = 'analysis';
      } else {
        barChartAPI = 'charts';
      }
      const response = await axiosInstance.get(`${BASE_URL}${barChartAPI}/company/${companyId}/data.csv`, {
        params,
        headers: getHeaders(),
      });
      const data = response.data;
      var blob = new Blob([data]);
      let url = window.URL.createObjectURL(blob);
      let tempLink = document.createElement('a');
      tempLink.href = url;
      tempLink.setAttribute('download', 'data.csv');
      tempLink.click();
      if (response.status == 200) {
        dispatch({
          type: SUCCESS_ALERT,
          payload: 'Files exported successfully',
        });
      }
    } catch (error) {
      dispatch({
        type: ERROR_ALERT,
        payload: 'File Not Exported',
      });
    }
    dispatch({
      type: PROGRESS_DONE,
    });
  };

  return (
    <Card sx={{ p: '20px' }}>
      <CardHeader
        title={title && `${chartHeading ? chartHeading : ''} ${title}`}
        sx={{ mb: '20px', p:0}}
        action={
          showOptions ? (
            <>
              <div style={{ display: 'flex' }}>
                {selectedState.type !== 'yearly' ? (
                  <Button variant="contained" sx={{ maxHeight: '28px', mr: '10px' }} onClick={onBackAction}>
                    Back
                  </Button>
                ) : null}

                {selectedState.type === 'single-month' ? null : (
                  <TextField
                    select
                    fullWidth
                    value={
                      selectedState.type === 'yearly' ? selectedState.selectedYearVal : selectedState.selectedMonthVal
                    }
                    SelectProps={{ native: true }}
                    onChange={onNext}
                    sx={{
                      '& fieldset': { border: '0 !important' },
                      '& select': { pl: 1, py: 0.5, pr: '24px !important', typography: 'subtitle2' },
                      '& .MuiOutlinedInput-root': { borderRadius: 0.75, bgcolor: 'background.neutral' },
                      '& .MuiNativeSelect-icon': { top: 4, right: 0, width: 20, height: 20 },
                    }}
                  >
                    <option>{selectedState.type === 'yearly' ? 'Select Year' : 'Select Month'}</option>
                    {labels.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </TextField>
                )}
              </div>
            </>
          ) : stackedChart ? (
                  <Button variant="contained" sx={{ maxHeight: '48px', mr: '10px' }} onClick={onClose}>
                    Close
                  </Button>
          ): null
        }
      />
      {data && data.length ? (
        <>
          <Bar options={options} data={data2} />
        </>
      ) : null}
    </Card>
  );
}
