import { SET_SEARCH_PATH, PROXY_PATH, STUB_FETCH_SUCCESS, AGENTS_FETCH_SUCCESS, CHILDREN_NODES_FAIL, SET_VISIBLITY, CHILDREN_NODES_LOADING, CHILDREN_NODES_SUCCESS, DATA_LOADER, FETCH_FAIL, ROOT_NODES_FETCH_SUCCESS, CHILDREN_NODES_FOLDER_SUCCESS, NODES_FOLDER_BY_PATH_SUCCESS, AUDIT_LOG_DATA_FAIL, AUDIT_LOG_DATA_SUCCESS, USERS_FETCH_SUCCESS } from './fileActions';
const initialState = {
  loading: false,
  rootNodes: [],
  error: null,
  childrenNodes: [],
  folderViewNodes: { nodes: [], maxResultCount: 0 },
  folderByPath: [],
  auditLog: { list: [], totalCount: 0 },
  agents: [],
  stubDetails: [],
  proxy: [],
  searchPath: [],
  users: {}
};

const fileReducer = (state = initialState, action) => {
  switch (action.type) {
    case DATA_LOADER: {
      return {
        ...state,
        loading: true,
      };
    }
    case AGENTS_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        agents: action.payload
      };
    }
    case STUB_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        stubDetails: action.payload
      };
    }
    case USERS_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        users: action.payload
      };
    }
    case SET_SEARCH_PATH: {
      return {
        ...state,
        loading: false,
        searchPath: action.payload,
        folderViewNodes: { nodes: action.payload, maxResultCount: action.payload.length }
      };
    }

    case ROOT_NODES_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        rootNodes: action.payload,
      };
    }
    case PROXY_PATH:
      return {
        ...state,
        loading: false,
        proxy: action.payload,
      };
    case FETCH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CHILDREN_NODES_LOADING: {
      return {
        ...state,
        childrenLoading: true,
      };
    }

    case CHILDREN_NODES_SUCCESS: {
      return {
        ...state,
        loading: false,
        childrenNodes: action.payload,
      };
    }

    case CHILDREN_NODES_FOLDER_SUCCESS: {
      return {
        ...state,
        loading: false,
        folderViewNodes: action.payload,
      };
    }
    case SET_VISIBLITY: {
      return {
        ...state,
        loading: false,
      };
    }
    case NODES_FOLDER_BY_PATH_SUCCESS: {
      return {
        ...state,
        loading: false,
        folderByPath: action.payload,
      };
    }

    case AUDIT_LOG_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        auditLog: action.payload,
      };
    }

    case AUDIT_LOG_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default: {
      return state;
    }
  }
};

export default fileReducer;
