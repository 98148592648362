import React, { useEffect, useState } from 'react';
import { Button, Card, CircularProgress, Container, Modal, Stack } from '@mui/material';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { BASE_URL } from 'src/config';

const DocumentViewModal = ({ openModal, close, ext }) => {
  const { themeStretch } = useSettings();
  const fileView = useSelector((state) => state.ediscoveryReducer.fileView);
  const [doc, setDoc] = useState([]);
  useEffect(() => {
    if (!isEmpty(fileView)) {
            let link = fileView && fileView.fileLink
            let linkLength = link && link.length
            let link2 = link.slice(5,linkLength)
            let fileExt = fileView && (fileView.fileName).slice(-3)
      setDoc([
        {
          fileType:  fileExt == 'zip' ? 'ext':'pdf',
          fileName: fileView.fileName,
          uri: `${BASE_URL}${link2}`,
        },
      ]);
    }
  }, [fileView]);
  return (
    <>
      <Modal
        open={openModal}
        onClose={() => {
          setDoc([]);
          close();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Page sx={{ width: '100%' }}>
          <Container maxWidth={themeStretch ? false : 'xl'}>
            <Card sx={{ p: '20px', minWidth: '80%' }}>
              {doc.length ? (
                <DocViewer
                  documents={doc}
                  initialActiveDocument={doc[0]}
                  pluginRenderers={DocViewerRenderers}
                  style={{ width: '100%', height: 600 }}
                  scale={5.0}
                  zoomto="fullpage"
                />
              ) : (
                <Stack alignItems="center" justifyContent="center">
                  <CircularProgress />
                </Stack>
              )}
              <Stack direction="row" gap={4} sx={{ mt: '20px' }}>
                <Button
                  variant="contained"
                  onClick={() => {
                    setDoc([]);
                    close();
                  }}
                >
                  Cancel
                </Button>
              </Stack>
            </Card>
          </Container>
        </Page>
      </Modal>
    </>
  );
};

export default DocumentViewModal;
