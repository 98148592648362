import { ERROR_ALERT, SUCCESS_ALERT } from 'src/redux/reducers/alertReducer';
import { GetDetails, GetFetch, postInfo } from 'src/utils/fetchServices';

export const FEATURE_FETCH_SUCCESS = 'FEATURE_FETCH_SUCCESS';
export const SUCCESS = 'SUCCESS';
export const DATA_LOADER = 'DATA_LOADER';
export const FETCH_FAIL = 'FETCH_FAIL';

export const getUserFeature = () => async (dispatch) => {
  dispatch({
    type: DATA_LOADER,
  });
  try {
    const response = await GetDetails({ url: 'role-features' });
    const data = response.data;
    dispatch({
      type: FEATURE_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};

export const updateUserFeature = (body) => async (dispatch) => {
  dispatch({ type: DATA_LOADER });
  try {
    const response = await postInfo({url: `role-features`, body},dispatch);
    if (response && response.status === 200) {
      const data = response.data;
      dispatch({
        type: SUCCESS,
      });
      dispatch({
        type: SUCCESS_ALERT,
        payload: 'User Feature Updated Successfully',
      });
    } else {
      dispatch({ type: FETCH_FAIL });
      dispatch({
        type: ERROR_ALERT,
        payload: 'An error occured',
      });
    }
  } catch (error) {
    dispatch({ type: FETCH_FAIL });
    dispatch({
      type: ERROR_ALERT,
      payload: 'An error occured',
    });
  }
};
