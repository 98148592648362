import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  Box,
  Card,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  TableContainer,
  TablePagination,
  Typography,
  Stack,
  TextField,
  Grid,
  Tooltip,
  tooltipClasses,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import SearchIcon from '@mui/icons-material/Search';
import { getApplicationEventUsers, downloadFile } from './applicationEventActions';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import copiedFunction from 'src/utils/copyMethod';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { styled } from '@mui/styles';

const DAYS = ['Custom', 'Today', 'Yesterday', 'This Week', 'This Month', 'This Year'];

export default function ApplicationEventLog() {
  const users = useSelector((state) => state.applicationEventReducer.users);
  const dispatch = useDispatch();
  const theme = useTheme();

  let date = new Date();
  let EndDate = date.getTime();
  let TodayStartDate = new Date(EndDate).setHours(0, 0, 0);

  let showEndDate = date.toISOString().slice(0, 10);
  let setDate = date.setDate(date.getDate() - 1);

  const [range, setRange] = useState('Today');
  const [page, setPage] = useState(0);
  const [arrow, setArrow] = useState(true);
  const [active, setActive] = useState(5);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const [filter, setFilter] = useState({
    sort: 'updatedTime,desc',
    startDate: TodayStartDate,
    endDate: EndDate,
    page: page,
    size: rowsPerPage,
  });

  const [responseTime, setresponseTime] = useState('');
  const [showDate, setShowdate] = useState({
    StartDate: '',
    EndDate: '',
  });

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} enterDelay={600} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      fontSize: 13,
      fontWeight: 400,
      maxWidth: '800px',
    },
  }));

  const handleRange = (event) => {
    setRange(event.target.value);
  };

  useEffect(() => {
    if (range == 'Today') {
      setFilter({ ...filter, startDate: TodayStartDate, endDate: EndDate });
      setShowdate({ StartDate: showEndDate, EndDate: showEndDate });
    } else if (range == 'Yesterday') {
      let Yesterday1 = new Date(setDate).setHours(0, 0, 0);
      let Yesterday2 = new Date(setDate).setHours(23, 59, 59);
      let showYesterday = new Date(setDate).toISOString().slice(0, 10);
      setFilter({ ...filter, startDate: Yesterday1, endDate: Yesterday2 });
      setShowdate({ StartDate: showYesterday, EndDate: showYesterday });
    } else if (range == 'This Year') {
      let showfyear = date.setMonth(0) && date.setDate(1);
      let setYear = new Date(showfyear).setHours(0, 0, 0);
      setFilter({ ...filter, startDate: setYear, endDate: EndDate });
      let showfullyear1 = moment(new Date(showfyear)).format('YYYY-MM-DD HH:mm:ss');
      let showYear = showfullyear1.slice(0, 10);
      setShowdate({ StartDate: showYear, EndDate: showEndDate });
    } else if (range == 'This Month') {
      let showMonth1 = moment(new Date(date.setDate(1))).format('YYYY-MM-DD HH:mm:ss');
      let showMonth = showMonth1.slice(0, 10);
      let Getmonth = new Date(date.setDate(1)).setHours(0, 0, 0);
      setFilter({ ...filter, startDate: Getmonth, endDate: EndDate });
      setShowdate({ StartDate: showMonth, EndDate: showEndDate });
    } else if (range == 'This Week') {
      let weekDate = new Date();
      let weekD = weekDate.getDate() - weekDate.getDay();
      let Week1 = new Date(weekDate.setDate(weekD)).setHours(0, 0, 0);
      setFilter({ ...filter, startDate: Week1, endDate: EndDate });
      let showWeek1 = moment(new Date(weekDate.setDate(weekD))).format('YYYY-MM-DD HH:mm:ss');
      let showWeek = showWeek1.slice(0, 10);
      setShowdate({ StartDate: showWeek, EndDate: showEndDate });
    }
  }, [range]);

  const handleStartDateChange = (event) => {
    setRange('Custom')
    let customStart = new Date(event.target.value).setHours(0, 0, 0);
    setFilter({ ...filter, startDate: customStart });
  };
  const handleEndDateChange = (event) => {
    setRange('Custom')
    let customEnd = new Date(event.target.value).setHours(0, 0, 0);
    setFilter({ ...filter, endDate: customEnd });
  };

  const TableRow1 = () => {
    setActive(1);
    setArrow(!arrow);
    if (arrow == false) {
      setFilter({ ...filter, sort: 'action,desc' });
    } else if (arrow == true) {
      setFilter({ ...filter, sort: 'action,asc' });
    }
  };
  const TableRow2 = () => {
    setActive(2);
    setArrow(!arrow);
    if (arrow == false) {
      setFilter({ ...filter, sort: 'subject,desc' });
    } else {
      setFilter({ ...filter, sort: 'subject,asc' });
    }
  };
  const TableRow3 = () => {
    setActive(3);
    setArrow(!arrow);
    if (arrow == false) {
      setFilter({ ...filter, sort: 'modifiedBy,desc' });
    } else {
      setFilter({ ...filter, sort: 'modifiedBy,asc' });
    }
  };
  const TableRow4 = () => {
    setActive(4);
    setArrow(!arrow);
    if (arrow == false) {
      setFilter({ ...filter, sort: 'description,asc' });
    } else {
      setFilter({ ...filter, sort: 'description,desc' });
    }
  };
  const TableRow5 = () => {
    setActive(5);
    setArrow(!arrow);
    if (arrow == true) {
      setFilter({ ...filter, sort: 'updatedTime,asc' });
    } else {
      setFilter({ ...filter, sort: 'updatedTime,desc' });
    }
  };

  const Search = () => {
    dispatch(getApplicationEventUsers(filter, handleSearchTime));
  };

  const handleSearchTime = (startTime, endTime) => {
    let ResponseTime = (endTime - startTime) / 1000;
    setresponseTime(ResponseTime);
  }; 

  useEffect(() => {
    Search();
  }, [page, rowsPerPage, active, arrow]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setFilter({ ...filter, page: newPage });
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setFilter({ ...filter, size: parseInt(event.target.value) });
  };

  const downloadCSV = () => {
    dispatch(
      downloadFile({
        startDate: filter.startDate,
        endDate: filter.endDate,
      })
    );
  };

  return (
    <>
      <Card>
        <Box sx={{ display: 'flex' }}>
          <CardHeader title="Application Event Log" sx={{ mb: 3 }} />
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          marginBottom="10px"
          backgroundColor="#F4F6F8"
          padding="10px"
          paddingLeft="30px"
        >
          <Grid container spacing={3}>
            <Grid item sx={12} md={4}>
            <FormControl variant="standard" sx={{ minWidth: 170 }}>
            <InputLabel id="demo-simple-select-standard-label">Select Range</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={range}
              onChange={handleRange}
              label="Range"
              name="range"
            >
              {DAYS.map((options, i) => (
                <MenuItem key={i} value={options}>
                  {options}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          </Grid>
            {range == 'Custom' ? (
            <Grid item sx={12} md={4}>
            <Box>
            <Stack direction='row'>
              <TextField
                id="startDate"
                type="date"
                name="startDate"
                label=" Start Date"
                variant="standard"
                InputProps={{ inputProps: { max: showEndDate } }}
                onChange={handleStartDateChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                id="endDate"
                type="date"
                name="endDate"
                label="End Date"
                variant="standard"
                sx={{ marginLeft: '20px' }}
                onChange={handleEndDateChange}
                InputProps={{ inputProps: { max: showEndDate } }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Stack>
      
            </Box>
            </Grid>
          ) : (
            <Grid item sx={12} md={4}>
            <Box>
            <Stack direction='row'>
              <TextField
                id="startDate"
                type="date"
                name="startDate"
                label=" Start Date"
                variant="standard"
                value={showDate.StartDate}
                onChange={handleStartDateChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                id="endDate"
                type="date"
                name="endDate"
                label="End Date"
                variant="standard"
                sx={{ marginLeft: '20px' }}
                value={showDate.EndDate}
                onChange={handleEndDateChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Stack>
            </Box>
            </Grid>
          )}

            </Grid>
          <Grid item sx={12} md={4}>
            <Button variant='contained' onClick={Search} style={{ marginLeft: '20px' }}>
              <SearchIcon /> Search
            </Button>

          </Grid>
         
         
        </Stack>
        {users.totalElements ? (
          <Stack
            fullWidth
            direction="row"
            justifyContent="space-between"
            sx={{
              p: '20px',
              m: '10px',
              backgroundColor: '#F4F6F8',
              borderRadius: '12px',
            }}
          >
            <Typography>
              Found {users.totalElements} Record(s) <br />
              Search Time {responseTime} Second(s)
            </Typography>
            <Button variant="contained" sx={{ padding: '10px' }} onClick={downloadCSV}>
              Export to CSV
            </Button>
          </Stack>
        ) : null}
        <TableContainer sx={{ minWidth: 720 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '220px' }}>
                  <Stack direction="row" onClick={TableRow1} style={{ width: '160px' }}>
                    <Typography style={{ marginRight: '8px' }}> Action </Typography>
                    {active == 1 ? (
                      arrow == true ? (
                        <ArrowDownwardIcon />
                      ) : (
                        <ArrowUpwardIcon />
                      )
                    ) : (
                      <ArrowUpwardIcon size="small" sx={{ opacity: '0', '&:hover': { opacity: '0.5' } }} />
                    )}
                  </Stack>
                </TableCell>
                <TableCell onClick={TableRow2} style={{ width: '220px' }}>
                  <Stack direction="row">
                    <Typography style={{ marginRight: '8px' }}> Scope</Typography>

                    {active == 2 ? (
                      arrow == true ? (
                        <ArrowDownwardIcon />
                      ) : (
                        <ArrowUpwardIcon />
                      )
                    ) : (
                      <ArrowUpwardIcon size="small" sx={{ opacity: '0', '&:hover': { opacity: '0.5' } }} />
                    )}
                  </Stack>
                </TableCell>
                <TableCell onClick={TableRow3} style={{ width: '220px' }}>
                  <Stack direction="row">
                    <Typography style={{ marginRight: '8px' }}> Modified By</Typography>
                    {active == 3 ? (
                      arrow == true ? (
                        <ArrowDownwardIcon />
                      ) : (
                        <ArrowUpwardIcon />
                      )
                    ) : (
                      <ArrowUpwardIcon size="small" sx={{ opacity: '0', '&:hover': { opacity: '0.5' } }} />
                    )}
                  </Stack>
                </TableCell>

                <TableCell onClick={TableRow4} style={{ width: '220px' }}>
                  <Stack direction="row">
                    <Typography style={{ marginRight: '8px' }}> Description</Typography>
                    {active == 4 ? (
                      arrow == true ? (
                        <ArrowDownwardIcon />
                      ) : (
                        <ArrowUpwardIcon />
                      )
                    ) : (
                      <ArrowUpwardIcon size="small" sx={{ opacity: '0', '&:hover': { opacity: '0.5' } }} />
                    )}
                  </Stack>
                </TableCell>

                <TableCell onClick={TableRow5} style={{ width: '220px' }}>
                  <Stack direction="row">
                    <Typography style={{ marginRight: '8px' }}> Last Modified Date</Typography>
                    {active == 5 ? (
                      arrow == true ? (
                        <ArrowDownwardIcon />
                      ) : (
                        <ArrowUpwardIcon />
                      )
                    ) : (
                      <ArrowUpwardIcon size="small" sx={{ opacity: '0', '&:hover': { opacity: '0.5' } }} />
                    )}
                  </Stack>
                </TableCell>
              </TableRow>
            </TableHead>
            {users.totalElements == 0 ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    <Typography>
                      Found {users.totalElements} Record(s) <br />
                      Search Time {responseTime} Second(s)
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {users.content &&
                  users.content.length &&
                  users.content.map((row) => (
                    
                    <TableRow>
                      <TableCell>
                      <LightTooltip title={row.payload ?row.payload : "no payload found" }>
                        <Button
                          sx={{ minWidth: 'fit-content', p: 0 }}
                          onClick={() => copiedFunction(row.payload, dispatch)}
                        >
                          <ContentCopyIcon />
                        </Button>
                      </LightTooltip>
                        {row.action}
                      </TableCell>
                      <TableCell>{row.subject}</TableCell>
                      <TableCell>{row.modifiedBy}</TableCell>
                      <TableCell>{row.description.substr(0, 30) + ' ...'}</TableCell>
                      <TableCell>{moment(row.updatedTime).format(' lll')}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            )}
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20, 100]}
            component="div"
            count={users.totalElements && users.totalElements}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Card>
    </>
  );
}
