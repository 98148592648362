import jwt from 'jwt-decode';
import { EXPIRE_DETAILS } from 'src/layouts/dashboard/header/LicenseExpire';
import {
  COMPANY_MODE_FETCH_SUCCESS,
  FETCH_FAIL,
  LOGIN,
  LOGIN_FAIL,
  LOGIN_LOADER,
  LOGOUT,
  SUCCESS_CODE,
  APP_TITLE,
  COMPANY_TAG,
  COMPANY_NUM,
  APP_INFO,
  ADMIN_ROLE,
  ROLE_ACCESS_DATA,
} from '../actions/loginActions';
import { useEffect } from 'react';
// import { SUCCESS_CODE } from 'src/layouts/dashboard/header/UserSetting';

const initialState = {
  token: '',
  role: '',
  isAuthenticated: false,
  companyId: '',
  singleCompanyMode: false,
  userId: '',
  error: null,
  twoFactorAuth: false,
  appTitle: '',
  companyTag: '',
  adminRole: '',
  appInfo: {},
  roleAccess: [],
  isExpire: false
};

const access_token = localStorage.getItem('accessToken');
if (localStorage.getItem('accessToken')) {
  initialState.token = localStorage.getItem('accessToken');
  initialState.isAuthenticated = true;
}
if (localStorage.getItem('companyId')) {
  initialState.companyId = localStorage.getItem('companyId');
}
if (localStorage.getItem('userId')) {
  initialState.userId = localStorage.getItem('userId');
}
if (access_token) {
  const decodedToken = jwt(access_token);
  initialState.adminRole = decodedToken.authorities[0];
}

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_LOADER: {
      return {
        ...state,
        loading: true,
      };
    }
    case LOGIN: {
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        error: action.payload.error,
      };
    }
    case COMPANY_TAG: {
      return {
        ...state,
        companyTag: action.payload,
      };
    }
    case COMPANY_NUM: {
      return {
        ...state,
        companyId: action.payload,
      };
    }
    case ADMIN_ROLE: {
      return {
        ...state,
        adminRole: action.payload,
      };
    }
    case COMPANY_MODE_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        singleCompanyMode: action.payload.enableSingleCompanyMode,
      };
    }
    case ROLE_ACCESS_DATA: {
      return {
        ...state,
        loading: false,
        roleAccess: action.payload,
      };
    }
    case LOGOUT: {
      localStorage.clear();
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        adminRole: ''
      };
    }
    case LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case FETCH_FAIL:
      return {
        ...state,
        loading: false,
      };
    case SUCCESS_CODE:
      return {
        ...state,
        twoFactorAuth: action.payload,
      };
    case APP_TITLE:
      return {
        ...state,
        appTitle: action.payload,
      };
    case APP_INFO:
      return {
        ...state,
        appInfo: action.payload,
      };
    case EXPIRE_DETAILS:
      return {
        ...state,
        isExpire: action.payload,
      };
    case 'SET_ALL_INITIAL':
      return (state = initialState);
    default: {
      return state;
    }
  }
};

export default login;
