import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Card,
  CardHeader,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import FolderRoundedIcon from '@mui/icons-material/FolderRounded';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useDispatch, useSelector } from 'react-redux';
// import PathTable from './PathTable';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Page from '../../../components/Page';
import useSettings from 'src/hooks/useSettings';
import { Box } from '@mui/system';
import {
  addPolicyAction,
  getDocuments,
  getPolicyDetails,
  getAnalytics,
  GET_POLICY_SUCCESS,
} from './policyManagementActions';
import { isEmpty } from 'lodash';
import { sub } from 'date-fns';

const styles = {
  treeView: {
    listStyleType: 'none',
    paddingLeft: 10,
    marginBottom: 5,
  },
  card: {
    maxHeight: '600px',
    overflow: 'auto',
  },
};

const AddPolicy = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { themeStretch } = useSettings();

  const { documents, policy, analytics } = useSelector((state) => state.policyManagementReducer);

  const [policyDetails, setPolicyDetails] = useState({
    type: 1,
    name: '',
    description: '',
    fileNames: '',
    folderNames: '',
    docAgeYears: 0,
    docAgeMonths: 0,
    lastModifiedYears: 0,
    lastModifiedMonths: 0,
    lastAccessedYears: 0,
    lastAccessedMonths: 0,
    sizeGreaterThan: 0,
    sizeUnits: 'KB',
  });
  const [ftpUser, setFtpUser] = useState({
    profileName: '',
    password: '',
    companyId: +localStorage.getItem('companyId'),
  });

  const [documentsData, setDocumentsData] = useState([]);
  const [startDate, setStartDate] = useState(new Date(1230748200000));
  const [endDate, setEndDate] = useState(new Date());
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [documentFilter, setDocumentFilter] = useState('analytic');
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [createFlag, setCreateFlag] = useState();
  const [fileError1, setFileError1] = useState(false);
  const [fileError2, setFileError2] = useState(false);
  const [pieChartForAllData, setPieChartForAllData] = useState([]);
  const [isPieData, setIsPieData] = useState(false);
  const [ext, setExt] = useState([]);
  const [ext2, setExt2] = useState([]);
  const [labelAll, setLabelAll] = useState(false);
  const [policyType, setPolicyType] = useState(1);

  const params = useParams();

  let TodayDate = new Date().getTime();

  const [analyticData, setAnalyticData] = useState({
    chartType: 'pie',
    startDate: 1230748200000,
    endDate: TodayDate,
  });

  useEffect(() => {
    dispatch(getAnalytics(analyticData));
  }, []);

  useEffect(() => {
    if (params.type == 'edit') {
      setCreateFlag(false);
    } else {
      setCreateFlag(true);
      setExt2([]);
    }
  }, []);

  useEffect(() => {
    if (analytics['aggregations'] != undefined) {
      let chartData = [];
      for (let bucket of analytics['aggregations'][2].buckets) {
        let year = new Date(bucket.key).getFullYear().toString();
        if (parseInt(year) >= startDate.getFullYear() && parseInt(year) <= endDate.getFullYear()) {
          for (let item of bucket[3].buckets) {
            const bucketValue = item[1].value / (1024 * 1024 * 1024);
            const index = chartData.findIndex((x) => x.label == item.key);
            if (index == -1) {
              chartData.push({ label: item.key, data: bucketValue, checked: false });
            } else {
              chartData[index].data += bucketValue;
            }
          }
        }
      }
      chartData.sort((a, b) => (a.data < b.data ? 1 : -1));

      setPieChartForAllData(chartData);
      setIsPieData(true);
    }
  }, [analytics]);

  useEffect(() => {
    //edit
    if (params.id) {
      setCreateFlag(false);
      dispatch(getPolicyDetails(params.id));
    }
    dispatch(getDocuments());
  }, []);

  useEffect(() => {
    if (policy) {
      let data = { ...policy };
      delete data.documentCategories;
      setPolicyDetails({ ...data });

      policy.documentCategories &&
        policy.documentCategories.forEach((child) => {
          if (child.documentTypes && child.documentTypes.length) {
            child.documentTypes.forEach((subChild) => {
              if (subChild.checked) {
                setExt((curr) => [...curr, subChild]);
                if (documentsData.length) {
                  documentsData.map((node) => {
                    if (node.documentTypes.length) {
                      node.documentTypes.map((rootChild) => {
                        if (rootChild.value == subChild.value) {
                          rootChild.checked = subChild.checked;
                        }
                      });
                    } else {
                      if (node.value == subChild.value) {
                        node.checked = subChild.checked;
                      }
                    }
                  });
                }
              }
            });
          } else {
            if (child.checked) setSelectedDocuments((curr) => [...curr, child]);
          }
        });
      if (policy.policyDocumentTypes) {
        setExt2(policy.policyDocumentTypes);
        setDocumentFilter('analytic');
      } else {
        setDocumentFilter('document');
      }
    }
  }, [policy, documentsData]);

  useEffect(() => {
    if (documents.content) setDocumentsData(documents.content);
  }, [documents]);

  const AddPolicySchema = yup.object().shape({
    // type: yup.number().required('required'),
    name: yup
      .string().trim()
      .max(50, 'Name is too long')
      .required('Name is required')
      .matches(/^[a-zA-Z0-9\s\-#@$%&*!,.]+$/, 'Invalid characters in Policy name'),
    description: yup.string().trim().when({
      is: (exists) => !!exists,
      then: (rule) =>
        rule.test('validate_description', 'Invalid characters in description', (description) => description.match(/^[a-zA-Z0-9\s.,;:'"!?()-]+$/)),
    }),
    fileNames: yup
      .string()
      // .required('required')
      .matches(/^[^.]*$/, 'File Name should not contain any extension'),
    folderNames: yup.string().matches(/^[^.]*$/, 'Folder Name should not contain any extension'),
    docAgeYears: yup.number().min(0).max(50).label('Years'),
    docAgeMonths: yup.number().min(0).max(11).label('Months'),
    lastModifiedYears: yup.number().min(0).max(50).label('Years'),
    lastModifiedMonths: yup.number().min(0).max(11).label('Months'),
    lastAccessedYears: yup.number().min(0).max(50).label('Years'),
    lastAccessedMonths: yup.number().min(0).max(11).label('Months'),
    sizeGreaterThan: yup.number(),
    sizeUnits: yup.string(),
  });

  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(AddPolicySchema),
  });
  useEffect(() => {
    if (params.id) {
      if (!isEmpty(policy)) {
        reset(policy);
      }
    }
  }, [policy]);

  const handleChange = (e, type = 'string') => {
    clearErrors(e.target.name);
    setPolicyDetails({
      ...policyDetails,
      [e.target.name]: type === 'number' ? parseInt(e.target.value) : e.target.value,
    });
  };
  const onSubmit = (data) => {
    data.policyDocumentCategoryIds = selectedDocuments.map((n) => n.id);
    data.documentCategories = documentsData;
    if (documentFilter == 'document') {
      data.policyDocumentTypeIds = ext.length ? ext.map((n) => n.id) : null;
    } else {
      data.policyDocumentTypes = ext2.length ? ext2 : [];
    }
    data.type = policyDetails.type;
    const payload = {
      id: 0,
      active: 0,
      documentAge: '0,0',
      lastAccessedDate: '0,0',
      lastModifiedDate: '0,0',
      sizeLargerThan: '',
      policyDocumentCategoryIds: selectedDocuments.map((n) => n.id),
      policyDocumentTypeIds: ext.map((n) => n.id), //ext
      documentTypesStr: '',
      companyId: localStorage.getItem('companyId'),
      lastRepoAccessedDate: '0,0',
      lastRepoAccessedMonths: 0,
      lastRepoAccessedYears: 0,
      documentCategories: [],
      ...policyDetails,
      policyDocumentTypes: ext2.length ? ext2 : [],
    };

    if (params.type == 'edit') {
      dispatch(addPolicyAction(data, 'PUT', navigate));
      dispatch({
        type: GET_POLICY_SUCCESS,
        payload: null,
      });
    } else {
      dispatch(addPolicyAction(payload, '', navigate));
    }
  };

  const toggleExpand = (tree) => {
    tree.expand = !tree.expand;
    setDocumentsData([...documentsData]);
  };

  const selectNode = (node) => {
    if (node.documentTypes?.length) {
      node.documentTypes.map((n) => selectNode(n));
    } else {
      node.checked = true;
      node.value && setExt((curr) => [...curr, node]);
    }
  };

  const onNodeCheck = (ev, node) => {
    node.checked = ev.target.checked;
    if (node.documentTypes?.length) {
      if (ev.target.checked) {
        setSelectedNodes([...selectedNodes, node]);
      } else {
        nodeUncheck(node);
        setSelectedNodes(selectedNodes.filter((n) => n.id !== node.id));
      }
    } else {
      if (node.checked) {
        if (node.value) {
          setExt((curr) => [...curr, node]);
        } else {
          setSelectedDocuments((curr) => [...curr, node]);
        }
      } else {
        let index = ext.findIndex((n) => node.id === n.id);
        if (index !== -1) {
          let data = ext.filter((n) => n.id != node.id);
          setExt([...data]);
        } else {
          setExt([...ext]);
        }
      }
    }
  };

  const nodeUncheck = (node) => {
    if (node.documentTypes?.length) {
      node.documentTypes.map((n) => nodeUncheck(n));
    } else {
      node.checked = false;
    }
  };

  useEffect(() => {
    setExt([]);
    if (selectedNodes?.length) {
      selectedNodes.map((n) => selectNode(n));
    }
  }, [selectedNodes]);

  const getUniqueExtensions = () => {
    return [...new Set(ext.map((n) => n.value))];
  };

  const renderDocumentsTree = (tree) => {
    return (
      <ul style={styles.treeView}>
        {tree &&
          tree.map((node, i) => {
            let allChildrenAreSelected = node.documentTypes && node.documentTypes.every((n) => n.checked);

            return (
              <li key={i} style={{ minHeight: 31 }}>
                <Stack direction="row" alignItems="center">
                  <Typography sx={{ cursor: 'pointer' }} onClick={() => toggleExpand(node)}>
                    {node.documentTypes?.length > 0 && (node.expand ? <ArrowDropDownIcon /> : <ArrowRightIcon />)}
                  </Typography>
                  {node.documentTypes?.length ? (
                    <Checkbox
                      sx={{ padding: 0 }}
                      onChange={(e) => onNodeCheck(e, node)}
                      checked={allChildrenAreSelected}
                    />
                  ) : (
                    <Checkbox sx={{ padding: 0 }} onChange={(e) => onNodeCheck(e, node)} checked={node.checked} />
                  )}
                  <div>
                    <span style={{ display: 'flex', gap: 3 }}>
                      {node.documentTypes?.length ? (
                        <FolderRoundedIcon sx={{ color: '#fda92d' }} />
                      ) : (
                        <InsertDriveFileIcon sx={{ color: '#fda92d' }} />
                      )}
                      <Typography sx={{ cursor: 'pointer' }}>
                        {node.name} {node.value && <span>({node.value})</span>}
                      </Typography>
                    </span>
                  </div>
                </Stack>
                {node.expand && node.documentTypes && renderDocumentsTree(node.documentTypes)}
              </li>
            );
          })}
      </ul>
    );
  };

  const showErr1 = () => {
    setFileError1(true);
  };
  const hideErr1 = () => {
    setFileError1(false);
  };
  const showErr2 = () => {
    setFileError2(true);
  };
  const hideErr2 = () => {
    setFileError2(false);
  };

  // ========================

  useEffect(() => {
    if (isPieData) {
      if (ext2.length) {
        let pieData = pieChartForAllData;
        pieData.forEach((val) => {
          ext2.map((item) => {
            if (val.label == item.name) {
              val.checked = true;
            }
          });
        });
        setPieChartForAllData([...pieData]);
      }
      setIsPieData(false);
    }
  }, [pieChartForAllData]);

  const AllLabelSelect = (e) => {
    let checked = e.target.checked;
    if (checked) {
      // setExt2([pieChartForAllData.map((item)=>{item.id= null, item.name= item.label, item.value= item.label, item.checked= true})])
      let data = pieChartForAllData;
      let dataValue = [];
      pieChartForAllData.map((item) => {
        dataValue.push({ id: null, name: item.label, value: item.label, checked: true });
      });
      data.forEach((checkValue) => (checkValue.checked = e.target.checked));
      setPieChartForAllData([...data]);
      setLabelAll(true);
      setExt2(dataValue);
    } else {
      setExt2([]);
      let data = pieChartForAllData;
      data.forEach((checkValue) => (checkValue.checked = e.target.checked));
      setPieChartForAllData([...data]);
      setLabelAll(false);
    }
  };

  const labelSelect = (e, item, i) => {
    let checked = e.target.checked;
    if (checked) {
      // setExt2([pieChartForAllData.map((item)=>{item.id= null, item.name= item.label, item.value= item.label, item.checked= true})])
      let data = pieChartForAllData;
      let dataValue = [];
      data.forEach((val) => {
        if (val.label === item.label) {
          val.checked = e.target.checked;
        }
      });
      data.map((val) => {
        if (val.label === item.label) {
          dataValue.push({ id: null, name: item.label, value: item.label, checked: item.checked });
        }
      });
      setPieChartForAllData([...data]);
      setExt2([...ext2, ...dataValue]);
    } else {
      setExt2(ext2.filter((val) => val.name !== item.label));
      setLabelAll(false);
      let data = pieChartForAllData;
      data.forEach((val) => {
        if (val.label === item.label) {
          val.checked = e.target.checked;
        }
      });
      setPieChartForAllData([...data]);
    }
  };

  const getUniqueExtensions2 = () => {
    return [...new Set(ext2.map((n) => n.name))];
  };

  const analyticsTable = (pieChartForAllData) => {
    return (
      <>
        <Stack direction="row" alignItems="center">
          <Checkbox sx={{ p: 0 }} checked={labelAll} onChange={AllLabelSelect} />
          <Typography>Select All</Typography>
        </Stack>
        {pieChartForAllData.length
          ? pieChartForAllData.map((item, i) => (
              <Stack direction="row" alignItems="center" key={i}>
                <Checkbox sx={{ p: 0 }} checked={item.checked} onChange={(e) => labelSelect(e, item)} />
                <Typography>
                  {item.label} ({item.data && item.data.toFixed(2)} GB)
                </Typography>
              </Stack>
            ))
          : null}
      </>
    );
  };

  return (
    <Page>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8} style={{ paddingTop: '1px' }}>
            <Card>
              <CardHeader title={createFlag ? 'Add Policy' : 'Update Policy'} />
              <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2} sx={{ m: '40px', mt: 1 }}>
                  <Grid item xs={12} md={11}>
                    <RadioGroup
                      row
                      name="type"
                      {...register('type')}
                      // value={policyType}
                      value={policyDetails.type}
                      onChange={(e) => handleChange(e, 'number')}
                    >
                      <FormControlLabel value={1} control={<Radio />} label="Inclusion" />
                      <FormControlLabel value={2} control={<Radio />} label="Exclusion" />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={12} md={11}>
                    <TextField
                      name="name"
                      label="Policy Name"
                      variant="standard"
                      helperText={errors.name && errors.name.message}
                      error={errors.name && errors.name.message}
                      {...register('name')}
                      sx={{ width: '100%' }}
                      onChange={(e) => handleChange(e)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={11}>
                    <TextField
                      name="description"
                      label="Description"
                      variant="standard"
                      {...register('description')}
                      sx={{ width: '100%' }}
                      onChange={(e) => handleChange(e)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      helperText={errors.description && errors.description.message}
                      error={errors.description && errors.description.message}
                    />
                  </Grid>
                  <Grid item xs={12} md={5.5}>
                    <Stack direction="column">
                      <Typography>Document Age (Created date)</Typography>

                      <Stack direction="row" gap={4}>
                        <TextField
                          name="docAgeYears"
                          defaultValue={0}
                          label="Years"
                          variant="standard"
                          type="number"
                          helperText={errors.docAgeYears && errors.docAgeYears.message}
                          error={errors.docAgeYears && errors.docAgeYears.message}
                          {...register('docAgeYears')}
                          InputProps={{
                            inputProps: { min: 0 },
                          }}
                          sx={{ width: '100%' }}
                          onChange={(e) => handleChange(e, 'number')}
                        />
                        <TextField
                          name="docAgeMonths"
                          defaultValue={0}
                          label="Months"
                          variant="standard"
                          type="number"
                          InputProps={{
                            inputProps: { min: 0 },
                          }}
                          helperText={errors.docAgeMonths && errors.docAgeMonths.message}
                          error={errors.docAgeMonths && errors.docAgeMonths.message}
                          {...register('docAgeMonths')}
                          sx={{ width: '100%' }}
                          onChange={(e) => handleChange(e, 'number')}
                        />
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={5.5}>
                    <Stack direction="column">
                      <Typography>Last Modified Date</Typography>

                      <Stack direction="row" gap={4}>
                        <TextField
                          name="lastModifiedYears"
                          defaultValue={0}
                          label="Years"
                          variant="standard"
                          type="number"
                          InputProps={{
                            inputProps: { min: 0 },
                          }}
                          helperText={errors.lastModifiedYears && errors.lastModifiedYears.message}
                          error={errors.lastModifiedYears && errors.lastModifiedYears.message}
                          {...register('lastModifiedYears')}
                          sx={{ width: '100%' }}
                          onChange={(e) => handleChange(e, 'number')}
                        />
                        <TextField
                          name="lastModifiedMonths"
                          defaultValue={0}
                          label="Months"
                          variant="standard"
                          type="number"
                          InputProps={{
                            inputProps: { min: 0 },
                          }}
                          helperText={errors.lastModifiedMonths && errors.lastModifiedMonths.message}
                          error={errors.lastModifiedMonths && errors.lastModifiedMonths.message}
                          {...register('lastModifiedMonths')}
                          sx={{ width: '100%' }}
                          onChange={(e) => handleChange(e, 'number')}
                        />
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={5.5}>
                    <Stack direction="column">
                      <Typography>Last Accessed Date</Typography>

                      <Stack direction="row" gap={4}>
                        <TextField
                          name="lastAccessedYears"
                          defaultValue={0}
                          label="Years"
                          variant="standard"
                          type="number"
                          InputProps={{
                            inputProps: { min: 0 },
                          }}
                          helperText={errors.lastAccessedYears && errors.lastAccessedYears.message}
                          error={errors.lastAccessedYears && errors.lastAccessedYears.message}
                          {...register('lastAccessedYears')}
                          sx={{ width: '100%' }}
                          onChange={(e) => handleChange(e, 'number')}
                        />
                        <TextField
                          name="lastAccessedMonths"
                          defaultValue={0}
                          label="Months"
                          variant="standard"
                          type="number"
                          InputProps={{
                            inputProps: { min: 0 },
                          }}
                          helperText={errors.lastAccessedMonths && errors.lastAccessedMonths.message}
                          error={errors.lastAccessedMonths && errors.lastAccessedMonths.message}
                          {...register('lastAccessedMonths')}
                          sx={{ width: '100%' }}
                          onChange={(e) => handleChange(e, 'number')}
                        />
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={5.5}>
                    <Stack direction="column">
                      <Typography>Selected Extension</Typography>
                      {documentFilter === 'document' ? (
                        <Typography mt={2.5}>[{getUniqueExtensions().join(', ')}]</Typography>
                      ) : (
                        <Typography mt={2.5}>[{getUniqueExtensions2().join(', ')}]</Typography>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={5.5}>
                    <Stack direction="row" gap={2} alignItems="baseline">
                      <Typography>Size Larger Than</Typography>

                      <Stack direction="row" gap={2}>
                        <TextField
                          name="sizeGreaterThan"
                          defaultValue={0}
                          // label="Years"
                          variant="standard"
                          type="number"
                          InputProps={{
                            inputProps: { min: 0 },
                          }}
                          {...register('sizeGreaterThan')}
                          sx={{ width: '100%' }}
                          onChange={(e) => handleChange(e, 'number')}
                        />
                        <FormControl fullWidth>
                          {/* <InputLabel id="sizeUnits" style={{ marginLeft: '-15px' }}>
                            KB/MB
                          </InputLabel> */}
                          <Select
                            name="sizeUnits"
                            labelId="sizeUnits"
                            variant="standard"
                            defaultValue={'KB'}
                            {...register('sizeUnits')}
                          >
                            <MenuItem value="KB">KB</MenuItem>
                            <MenuItem value="MB">MB</MenuItem>
                          </Select>
                        </FormControl>
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={11}>
                    <Typography>Advanced Config</Typography>
                  </Grid>
                  <Grid item xs={12} md={10}>
                    <TextField
                      name="fileNames"
                      label="File Name"
                      variant="standard"
                      onMouseEnter={showErr1}
                      onMouseLeave={hideErr1}
                      helperText={errors.fileNames && errors.fileNames.message}
                      error={errors.fileNames && errors.fileNames.message}
                      {...register('fileNames')}
                      sx={{ width: '100%' }}
                      onChange={(e) => handleChange(e)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    {fileError1 ? (
                      <p style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                        File and Folder Pattern filters, these filters are case sensitive and will accept wild cards
                        e.g. filename filename and *filename*
                      </p>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} md={11}>
                    <TextField
                      name="folderNames"
                      label="Folder Name"
                      variant="standard"
                      onMouseEnter={showErr2}
                      onMouseLeave={hideErr2}
                      helperText={errors.folderNames && errors.folderNames.message}
                      error={errors.folderNames && errors.folderNames.message}
                      {...register('folderNames')}
                      sx={{ width: '100%' }}
                      onChange={(e) => handleChange(e)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    {fileError2 ? (
                      <p style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                        File and Folder Pattern filters, these filters are case sensitive and will accept wild cards
                        e.g. filename filename and *filename*
                      </p>
                    ) : null}
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Button variant="contained" sx={{ mr: 2, color: '#fff' }} type="submit">
                      {createFlag ? 'Save' : 'Update'}
                    </Button>
                    <Link to="/dashboard/policy-management" style={{ textDecoration: 'none' }}>
                      <Button
                        variant="contained"
                        sx={{ color: '#fff' }}
                        onClick={() =>
                          dispatch({
                            type: GET_POLICY_SUCCESS,
                            payload: null,
                          })
                        }
                      >
                        Cancel
                      </Button>
                    </Link>
                  </Grid>
                </Grid>
              </form>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} style={{ paddingTop: '1px' }}>
            <Card sx={{ p: 3 }}>
              <RadioGroup
                row
                name="themeColorPresets"
                value={documentFilter}
                onChange={(_, value) => setDocumentFilter(value)}
              >
                <FormControlLabel value="analytic" control={<Radio />} label="Analytic" />
                <FormControlLabel value="document" control={<Radio />} label="Document" />
              </RadioGroup>
              {documentFilter === 'document' ? (
                <>
                  <Typography>Document Filter</Typography>

                  <Box overflow="auto" maxHeight={616}>
                    {renderDocumentsTree(documentsData)}
                  </Box>
                </>
              ) : (
                <>
                  <Typography>Analytic Filter</Typography>

                  <Box overflow="auto" maxHeight={616}>
                    {analyticsTable(pieChartForAllData)}
                  </Box>
                </>
              )}
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default AddPolicy;
