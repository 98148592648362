import { GetDetails, GetFetch, postInfo } from 'src/utils/fetchServices';
import { PROGRESS_DONE, PROGRESS_START, SUCCESS_ALERT,ERROR_ALERT } from 'src/redux/reducers/alertReducer';

export const JOB_STATUS_FETCH_SUCCESS = 'JOB_STATUS_FETCH_SUCCESS';
export const STATISTIC_FETCH_SUCCESS = 'STATISTIC_FETCH_SUCCESS';
export const JOB_LOG_FETCH_SUCCESS = 'JOB_LOG_FETCH_SUCCESS';
export const DOWNLOAD_SUCCESS = 'DOWNLOAD_SUCCESS';
export const EXECUTION_DETAILS_FETCH_SUCCESS = 'EXECUTION_DETAILS_FETCH_SUCCESS';
export const DATA_LOADER = 'DATA_LOADER';
export const JOB_RERUN_SUCCESS = 'JOB_RERUN_SUCCESS';
export const FETCH_FAIL = 'FETCH_FAIL';

export const getJobStatus = (params) => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  // dispatch({
  //   type: DATA_LOADER,
  // });
  dispatch({
    type: PROGRESS_START,
  });
  // params.sort = 'id,desc';
  try {
    const response = await GetFetch({ url: `jobs/company/${companyId}/status`, params });
    const data = response.data;
    dispatch({
      type: JOB_STATUS_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
  dispatch({
    type: PROGRESS_DONE,
  });
};
export const getStatisticDetails = (params, id) => async (dispatch) => {
  try {
    const response = await GetFetch({ url: `job-statistics/${id}`, params });
    const data = response.data;
    dispatch({
      type: STATISTIC_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};
export const getJobLogs = (params) => async (dispatch) => {
  try {
    const response = await GetFetch({ url: 'jobLog', params });
    const data = response.data;
    dispatch({
      type: JOB_LOG_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};

export const getExecutionDetails = (id) => async (dispatch) => {
  try {
    const response = await GetDetails({ url: `job-status/job/${id}` });
    const data = response.data;
    dispatch({
      type: EXECUTION_DETAILS_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};

export const terminateJob = (id) => async (dispatch) => {
  dispatch({
    type: PROGRESS_START,
  });
  try {
    const response = await GetDetails({ url: `job-status/job/${id}` });
    const data = response.data;
    if(data[0].executionId){
      const terminateRes = await postInfo({url: `job-status/terminate/${id}/${data[0].executionId}`, method:'PUT'});
      if(terminateRes.status == 200){
        dispatch({
          type:SUCCESS_ALERT,
          payload:"Job Terminated Successfully"
        })
      }else{
        dispatch({
          type:ERROR_ALERT,
          payload:'Termination Failed'
        })
      }
    }
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
  dispatch({
    type: PROGRESS_DONE,
  });
};

export const downloadFile = (id, name) => async (dispatch) => {
  try {
    const response = await GetDetails({ url: `jobLog/${id}/download` });
    const data = response.data;
    var blob = new Blob([data]);
    let url = window.URL.createObjectURL(blob);
    let tempLink = document.createElement('a');
    tempLink.href = url;
    tempLink.setAttribute('download', name);
    tempLink.click();
    dispatch({
      type: DOWNLOAD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};
export const downloadFolder = (id, name) => async (dispatch) => {
  try {
    const response = await GetDetails({ url: `jobLog/jobStatisticsId/${id}/download`, responseType:'blob' });
    const data = response.data;
    var blob = new Blob([data]);
    let url = window.URL.createObjectURL(blob);
    let tempLink = document.createElement('a');
    tempLink.href = url;
    tempLink.setAttribute('download', `${name}.zip`);
    tempLink.click();
    dispatch({
      type: DOWNLOAD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};

export const postJobRerun = (body) => async (dispatch) => {
  dispatch({
    type: PROGRESS_START,
  });
  const companyId = localStorage.getItem('companyId');
  body.companyId = +companyId;
  try {
    const response = await postInfo({ url: 'jobs/run-job', body });
    const data = response.data;
    dispatch({
      type: JOB_RERUN_SUCCESS,  
      payload: data,
    });
    dispatch({
      type: SUCCESS_ALERT,
      payload: 'Rerun Job Successfully',
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
  dispatch({
    type: PROGRESS_DONE,
  });
};

export const allJobStatus = (body) => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  body.companyId = companyId;
  try {
    const response = await postInfo({ url: 'jobs/run-job', body });
    const data = response.data;
    dispatch({
      type: JOB_RERUN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};


