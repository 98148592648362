import {
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  tooltipClasses,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DescriptionIcon from '@mui/icons-material/Description';
import jwt from 'jwt-decode';
import copiedFunction from 'src/utils/copyMethod';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { DocumentViewFile, downloadFile } from '../ediscoveryActions';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { styled } from '@mui/styles';
import DocumentViewModal from './DocumentViewModal';

const PathTable = ({
  columns,
  rows,
  showProperties,
  handleSelectedFiles,
  selectAllFiles,
  selectedFiles,
  handleSearchData,
  handleSearchAPI,
  pages,
  pageRows,
  searchLoading,
}) => {
  const dispatch = useDispatch();
  const { roleAccess } = useSelector((state) => state.login);
  const [rowsPerPage, setRowsPerPage] = pageRows;
  const [page, setPage] = pages;
  const [searchDetails, setSearchDetails] = handleSearchData;
  const [arrow, setArrow] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [active, setActive] = useState();
  const [ext, setExt] = useState('');

  const access_token = localStorage.getItem('accessToken');
  const [adminRole, setAdminRole] = useState();
  const [downloadOption, setDownloadOption] = useState({})
  useEffect(() => {
    if (access_token) {
      const decodedToken = jwt(access_token);
      setAdminRole(decodedToken.authorities[0])
    }
  }, [access_token])

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} enterDelay={600} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      fontSize: 13,
      fontWeight: 400,
      maxWidth: 'none',
    },
  }));

  useEffect(() => {
    if (roleAccess && roleAccess.length) {
      roleAccess.map((item, i) => {
        // if (item.name == 75) {
        if (item.name == 'eDiscovery.download') {
          setDownloadOption(item)
        }
      })
    }
  }, [roleAccess])

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const downloadData = (plainUuid, documentName) => {
    dispatch(
      downloadFile(
        {
          version: 0,
          plainUuid: plainUuid,
        },
        documentName
      )
    );
  };
  const handleDocumentViewer = (plainUuid, documentName) => {
    setOpenModal(true);
    let extention = documentName.split('.').pop();
    setExt(extention)
    dispatch(DocumentViewFile(plainUuid));
  };

  const handleIsSelected = (row) => {
    let val = false;
    if (selectedFiles.length) {
      selectedFiles.map((file) => {
        if (file.plainUuid == row.plainUuid) {
          val = true;
          return val;
        }
      });
    }
    return val;
  };

  const TableSort = (i) => {
    let data = searchDetails;
    setActive(i);
    setArrow(!arrow);
    if (i == 2) {
      data.sort = 'lastModifiedDate';
      if (!arrow) {
        data.order = 'desc';
      } else {
        data.order = 'asc';
      }
    }
    if (i == 3) {
      data.sort = 'lastAccessDate';
      if (!arrow) {
        data.order = 'desc';
      } else {
        data.order = 'asc';
      }
    }
    if (i == 4) {
      data.sort = 'size';
      if (!arrow) {
        data.order = 'desc';
      } else {
        data.order = 'asc';
      }
    }
    if (rows.results) {
      handleSearchAPI();
    }
  };

  return (
    <>
      <DocumentViewModal openModal={openModal} close={() => setOpenModal(false)} ext={ext} />
      <Card sx={{ p: '20px' }}>
        <TableContainer>
          <Table>
            <colgroup>
              <col style={{ width: '5%' }} />
              <col style={{ width: '10%' }} />
              <col style={{ width: '40%' }} />
              <col style={{ width: '15%' }} />
              <col style={{ width: '15%' }} />
              <col style={{ width: '10%' }} />
              <col style={{ width: '5%' }} />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox onChange={(e) => selectAllFiles(e)} />
                </TableCell>
                {columns.map((column, i) => (
                  <TableCell key={i} sx={{ pl: 0 }}>
                    {/* {column} */}
                    <Stack direction="row" onClick={column == 'Name' ? () => { } : () => TableSort(i)}>
                      <Typography> {column}</Typography>
                      {i > 1 && i < 5 && active == i ? (
                        arrow == true ? (
                          <ArrowDownwardIcon />
                        ) : (
                          <ArrowUpwardIcon />
                        )
                      ) : i > 1 && i < 5 ? (
                        <ArrowUpwardIcon size="small" sx={{ opacity: '0', '&:hover': { opacity: '0.5' } }} />
                      ) : null}
                    </Stack>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.results && rows.results.length ? (
                rows.results.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell sx={{ p: 0 }}>
                      <Checkbox checked={handleIsSelected(row)} onChange={(e) => handleSelectedFiles(e, row)} />
                    </TableCell>
                    <TableCell sx={{ p: 0 }}>
                      <Button
                        onClick={() => copiedFunction(row.documentPath, dispatch)}
                        sx={{ minWidth: 'fit-content' }}
                      >
                        <ContentCopyIcon />
                      </Button>
                    </TableCell>
                    <TableCell sx={{ p: 0, pr: '5px', width: '250px' }}>
                      <LightTooltip title={row.documentPath}>
                        <Typography
                          sx={{ cursor: 'pointer', color: '#039be5', fontSize: '14px' }}
                          onClick={() => showProperties(row, 1)}
                        >
                          {row.documentName}
                        </Typography>
                      </LightTooltip>
                    </TableCell>
                    <TableCell sx={{ p: 0 }}>
                      {moment(row.lastModificationDate).format('YYYY-MM-DD HH:mm:ss')}
                    </TableCell>
                    <TableCell sx={{ p: 0 }}>{moment(row.accessedOn).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                    <TableCell sx={{ p: 0 }}>{row.documentSizeToDisplay}</TableCell>
                    <TableCell sx={{ p: 0 }}>
                      <Stack direction="row">
                        {/* <Button 
                        disabled={ row.documentName.split('.')[1] != 'msg' && adminRole == 'LDAP' && downloadOption.ldapAllowed  || adminRole == 'PRIVILEGED' && downloadOption.privAllowed ?false:true} onClick={() => handleDocumentViewer(row.plainUuid, row.documentName)} sx={{ minWidth: 'fit-content'}}>
                          <DescriptionIcon />
                        </Button> */}
                        {adminRole == 'LDAP' && downloadOption.ldapAllowed || adminRole == 'PRIVILEGED' && downloadOption.privAllowed ?
                          <Button
                            // disabled={adminRole == 'LDAP' && downloadOption.ldapAllowed || adminRole == 'PRIVILEGED'&& downloadOption.privAllowed ? false : true} 
                            onClick={() => downloadData(row.plainUuid, row.documentName)} sx={{ minWidth: 'fit-content' }}>
                            <FileDownloadIcon />
                          </Button>
                          :
                          <Tooltip title={'Download permission disabled'} placement="top">
                            <Button
                              sx={{ minWidth: 'fit-content', color: '#ccc' }}>
                              <FileDownloadIcon />
                            </Button>
                          </Tooltip>
                        }
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))
              ) : searchLoading ? (
                <TableRow>
                  <TableCell align="center" colSpan={7}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50, 100]}
            component="div"
            count={rows.totalRepoResultCount ? rows.totalRepoResultCount : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Card>
    </>
  );
};

export default PathTable;
