import {
  Box,
  Card,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  TableContainer,
  Checkbox,
} from '@mui/material';
import Scrollbar from '../../../../components/Scrollbar';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { useEffect, useState } from 'react';
import ApproveModal from './ApproveModal';
import { useDispatch } from 'react-redux';
import { deleteDocument } from '../dataRetensionActions';
import { ERROR_ALERT } from 'src/redux/reducers/alertReducer';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import copiedFunction from 'src/utils/copyMethod';

export default function DataRetensionTable({ documents }) {
  const dispatch = useDispatch();
  const [selectedDocs, setSelectedDocs] = useState([]);
  const [documentList, setDocumentList] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setDocumentList(documents);
  }, [documents]);
  const handleSelect = (e, row) => {
    let checked = e.target.checked;
    if (checked) {
      setSelectedDocs([...selectedDocs, row.uuid]);
      setDocumentList(documentList.map((docs)=> {
        if(docs.uuid === row.uuid){
            return {...docs, checked:checked}
        }else{
            return docs
        }
      }))
    } else {
      let list = selectedDocs.filter((id) => id !== row.uuid);
      setSelectedDocs(list);
      setDocumentList(documentList.map((docs)=> {
        if(docs.uuid === row.uuid){
            return {...docs, checked:checked}
        }else{
            return docs
        }
      }))
    }
  };
  const handleSelectAll = (e) => {
    let checked = e.target.checked;
    if (checked) {
      setSelectedDocs(documents.map((docs) => docs.uuid));
      setDocumentList(documentList.map((docs)=> ({...docs, checked:true})))
    } else {
      setSelectedDocs([]);
      setDocumentList(documentList.map((docs)=> ({...docs, checked:false})))
    }
  };

  const handleOpenModal = () => {
    if (selectedDocs.length) {
      setOpenModal(true);
    } else {
      dispatch({
        type: ERROR_ALERT,
        payload: 'Please Select Checkbox',
      });
    }
  };
  const handleApprove = () => {
    setOpenModal(false);
    selectedDocs.map((docs) => dispatch(deleteDocument(docs)));
  };
  return (
    <>
      <Card>
        <ApproveModal openModal={openModal} close={() => setOpenModal(false)} handleApprove={handleApprove} />
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <CardHeader title="Data Retension" sx={{ mb: 3 }} />
          <Button variant="contained" sx={{ color: '#ffff', height: '40px', m: '20px' }} onClick={handleOpenModal}>
            Approve
          </Button>
        </Box>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 720 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Checkbox onChange={handleSelectAll} />
                    Approve All
                  </TableCell>
                  <TableCell>Requestee User</TableCell>
                  <TableCell>Path</TableCell>
                  <TableCell>Delete Subfolder</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {documentList.length
                  ? documentList.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell>
                          {<Checkbox checked={row.checked? true: false} onChange={(e) => handleSelect(e, row)} />}
                        </TableCell>
                        <TableCell>{row.userID}</TableCell>
                        <TableCell>
                          <Button
                            sx={{ minWidth: 'fit-content', p: 0 }}
                            onClick={() => copiedFunction(row.path, dispatch)}
                          >
                            <ContentCopyIcon />
                          </Button>
                          {row.path}
                        </TableCell>
                        <TableCell>{row.deleteSubfolder ? <DoneIcon /> : <CloseIcon />}</TableCell>
                      </TableRow>
                    ))
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Card>
    </>
  );
}
