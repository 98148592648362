import { Button, Card, CardHeader, Grid, Modal, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import TestAccessModal from 'src/pages/configuration/job-management/components/TestAccessModal';
import { testAccess } from 'src/pages/configuration/job-management/jobManagementActions';
import { ERROR_ALERT } from 'src/redux/reducers/alertReducer';
import { dispatch } from 'src/redux/store';

const CredentialsModal = ({ openModal, close, errors, register, watch, getValues, }) => {
  const { themeStretch } = useSettings();
  const [testPath, setTestPath] = useState('')
  const testData = useSelector((state) => state.jobManagementReducer.testData);
  const [openAccessModal, setOpenAccessModal] = useState(false);
  const [validTest, setValidTest] = useState(false);
  const [testAccessLoading, setTestAccessLoading] = useState(false);


  const getAccessData = async () => {
    setTestAccessLoading(true);
    if (testPath.slice(0, 2) == '\\\\' && testPath.split('\\').length > 3) {
      await dispatch(
        testAccess({
          domain: watch('restoreDomainName'),
          userName: watch('restoreUser'),
          password: watch('restorePassword'),
          agentId: watch('agentId') || 0,
          uncPath: testPath,
          libType: watch('remoteLibType') || 'JNQ',
        })
      );
      setValidTest(true)
    } else {
      dispatch({
        type: ERROR_ALERT,
        payload: 'Please enter a valid path',
      });
    }
    setTestAccessLoading(false)
  };

  useEffect(() => {
    if (validTest && testData) {
      setOpenAccessModal(true);
    }
  }, [testData])

  return (
    <>
      <Modal
        open={openModal}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Page sx={{ width: '50%' }}>
          <TestAccessModal
            testData={testData}
            openModal={openAccessModal}
            close={() => { setOpenAccessModal(false) }}
            loader={testAccessLoading}
          />
          <Card
            sx={{
              backgroundColor: '#ffff',
              p: '15px',
              maxHeight: '700px',
              overflowY: 'auto',
            }}
          >

            <CardHeader width='30%' title="Test Credentials" sx={{ pt: '0px' }} />
            <Grid container spacing={2} sx={{ m: '10px' }}>
              <Grid item xs={12} md={5.5}>
                <TextField
                  name="restoreDomainName"
                  label="Domain"
                  // value={global.restoreDomainName || ''}
                  helperText={errors.restoreDomainName && errors.restoreDomainName.message}
                  error={errors.restoreDomainName && errors.restoreDomainName.message}
                  {...register('restoreDomainName')}
                  variant="standard"
                  sx={{ width: '100%' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                  // onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12} md={5.5}>
                <TextField
                  name="restoreUser"
                  // value={global.restoreUser || ''}
                  label="User Name"
                  helperText={errors.restoreUser && errors.restoreUser.message}
                  error={errors.restoreUser && errors.restoreUser.message}
                  {...register('restoreUser')}
                  variant="standard"
                  sx={{ width: '100%' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                  // onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12} md={5.5}>
                <TextField
                  name="restorePassword"
                  type="password"
                  label="Password"
                  // value={global.restorePassword || ''}
                  helperText={errors.restorePassword && errors.restorePassword.message}
                  error={errors.restorePassword && errors.restorePassword.message}
                  {...register('restorePassword')}
                  variant="standard"
                  sx={{ width: '100%' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                  // onChange={handleChange}
                  required
                />

              </Grid>
              <Grid item xs={12} md={5.5}>
                <TextField
                  name="testPath"
                  type="text"
                  label="UNC (e.g \\server\folder)"
                  value={testPath || ''}
                  variant="standard"
                  sx={{ width: '100%' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => setTestPath(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ mt: 1 }}>
                <Stack direction='row' gap={2}>
                  <Button variant="contained" onClick={close}> Close </Button>
                  <Button variant="contained" disabled={testPath == "" ? true : false} onClick={getAccessData}> Test CIFS Access </Button>
                </Stack>
              </Grid>
            </Grid>


          </Card>
        </Page>
      </Modal>
    </>
  );
};

export default CredentialsModal;
