import {
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import React from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import copiedFunction from 'src/utils/copyMethod';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { downloadFile } from '../ediscoveryActions';

const SimilarDocTable = ({ columns, rows, pages, pageRows, showProperties }) => {
  const dispatch = useDispatch();
  const [similarDocPage, setSimilarDocPage] = pages;
  const [similarDocPerPage, setSimilarDocPerPage] = pageRows;

  const handleChangeRowsPerPage = (event) => {
    setSimilarDocPerPage(parseInt(event.target.value));
    setSimilarDocPage(0);
  };

  const downloadData = (plainUuid, documentName) => {
    dispatch(
      downloadFile(
        {
          version: 0,
          plainUuid: plainUuid,
        },
        documentName
      )
    );
  };

  const handleChangePage = (event, newPage) => {
    setSimilarDocPage(newPage);
  };
  return (
    <>
      <Card sx={{ p: '20px' }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column, i) => (
                  <TableCell key={i}>{column}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.elasticSearchDocuments && rows.elasticSearchDocuments.length
                ? rows.elasticSearchDocuments.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell sx={{ p: 0 }}>
                      <Button onClick={() => copiedFunction(row.documentPath, dispatch)}>
                        <ContentCopyIcon />
                      </Button>
                    </TableCell>
                    <TableCell
                      sx={{ p: 0, cursor: 'pointer', color: '#039be5' }}
                      onClick={() => showProperties(row, 1)}
                    >
                      {row.documentName}
                    </TableCell>
                    <TableCell sx={{ p: 0 }}>
                      {moment(row.lastModificationDate).format('YYYY-MM-DD HH:mm:ss')}
                    </TableCell>
                    <TableCell sx={{ p: 0 }}>{moment(row.accessedOn).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                    <TableCell sx={{ p: 0 }}>{row.documentSizeToDisplay}</TableCell>
                    <TableCell sx={{ p: 0 }}>
                      <Button onClick={() => downloadData(row.plainU, row.documentName)}>
                        <FileDownloadIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
                : null}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50, 100]}
            component="div"
            count={rows.totalDocuments ? rows.totalDocuments : 0}
            rowsPerPage={similarDocPerPage}
            page={similarDocPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Card>
    </>
  );
};

export default SimilarDocTable;
