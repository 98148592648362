import {
  Button,
  Card,
  CardHeader,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {isEmpty} from 'lodash'
import { useDispatch } from 'react-redux';
import { postLdap } from '../ldapActions';
import { ERROR_ALERT } from 'src/redux/reducers/alertReducer';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';

const AuthOrder = ['DB Only', 'DB,AD', 'AD,DB'];

const schema = yup.object().shape({
  dnsServer: yup.string().required('required'),
  domain: yup.string().required('required'),
  acctUsername: yup.string().required('required'),
  acctPassword: yup.string().required('required'),
  uidAttrbuteName: yup.string().required('required'),
  userBaseDN: yup.string().required('required'),
  searchBaseDN: yup.string().required('required'),
  dnsSite: yup.string().required('required'),
  authenticationMode: yup.string().required('required'),
});

const LdapConfig = ({ ldapConfiguration }) => {
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const [syncLDAPUser, setSyncLDAPUser] = useState(false);

  const {
    register,
    handleSubmit,
    clearErrors,
    reset,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if(!isEmpty(ldapConfiguration)){
      reset({
        dnsServer: ldapConfiguration.dnsServer,
        domain: ldapConfiguration.domain,
        acctUsername: ldapConfiguration.acctUsername,
        acctPassword: ldapConfiguration.acctPassword,
        uidAttrbuteName: ldapConfiguration.uidAttrbuteName,
        userBaseDN: ldapConfiguration.userBaseDN,
        searchBaseDN: ldapConfiguration.searchBaseDN,
        dnsSite: ldapConfiguration.dnsSite,
        authenticationMode: ldapConfiguration.authenticationMode,
      });
      setSyncLDAPUser(ldapConfiguration.enableSso)
    }
  }, [ldapConfiguration]);

  const onSubmit = (data) => {
      data.enableSso = syncLDAPUser;
      dispatch(postLdap(data));
  };

  const testLDAP = () => {
    let data = getValues();
    data.enableSso = syncLDAPUser;
    if(isEmpty(errors)){
      dispatch(postLdap(data, "system-codes/ldap-configuration/test"))
    }else {
      dispatch({
        type: ERROR_ALERT,
        payload:'Please fill all the fields',
      });
    }
  };

  useEffect(() => {
    console.log("errors", errors);
  }, [errors])

  return (
    <Page>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Card sx={{ p: '20px' }}>
          <CardHeader title="LDAP Configuration" />
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} sx={{ m: '10px' }}>
              <Grid item xs={12} md={5.5}>
                <TextField
                  name="dnsServer"
                  label="LDAP Server"
                  // value={ldap.dnsServer || ''}
                  helperText={errors.dnsServer && errors.dnsServer.message}
                  error={errors.dnsServer && errors.dnsServer.message}
                  {...register('dnsServer')}
                  variant="standard"
                  sx={{ width: '100%' }}
                  InputLabelProps={{
                    shrink: true,
                }}
                  // onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12} md={5.5}>
                <TextField
                  name="domain"
                  // value={ldap.domain || ''}
                  label="Base DN"
                  helperText={errors.domain && errors.domain.message}
                  error={errors.domain && errors.domain.message}
                  {...register('domain')}
                  variant="standard"
                  sx={{ width: '100%' }}
                  InputLabelProps={{
                    shrink: true,
                }}
                  // onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12} md={5.5}>
                <TextField
                  name="acctUsername"
                  label="User Name"
                  // value={ldap.acctUsername || ''}
                  helperText={errors.acctUsername && errors.acctUsername.message}
                  error={errors.acctUsername && errors.acctUsername.message}
                  {...register('acctUsername')}
                  variant="standard"
                  sx={{ width: '100%' }}
                  InputLabelProps={{
                    shrink: true,
                }}
                  // onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12} md={5.5}>
                <TextField
                  name="acctPassword"
                  type="password"
                  label="User Password"
                  // value={ldap.acctPassword || ''}
                  helperText={errors.acctPassword && errors.acctPassword.message}
                  error={errors.acctPassword && errors.acctPassword.message}
                  {...register('acctPassword')}
                  variant="standard"
                  sx={{ width: '100%' }}
                  InputLabelProps={{
                    shrink: true,
                }}
                  // onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12} md={5.5}>
                <TextField
                  name="uidAttrbuteName"
                  label="User Name Attribute"
                  // value={ldap.uidAttrbuteName || ''}
                  helperText={errors.uidAttrbuteName && errors.uidAttrbuteName.message}
                  error={errors.uidAttrbuteName && errors.uidAttrbuteName.message}
                  {...register('uidAttrbuteName')}
                  variant="standard"
                  sx={{ width: '100%' }}
                  InputLabelProps={{
                    shrink: true,
                }}
                  // onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12} md={5.5}>
                <TextField
                  name="userBaseDN"
                  label="User DN"
                  // value={ldap.userBaseDN || ''}
                  helperText={errors.userBaseDN && errors.userBaseDN.message}
                  error={errors.userBaseDN && errors.userBaseDN.message}
                  {...register('userBaseDN')}
                  variant="standard"
                  sx={{ width: '100%' }}
                  InputLabelProps={{
                    shrink: true,
                }}
                  // onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12} md={5.5}>
                <TextField
                  name="searchBaseDN"
                  label="Search DN"
                  // value={ldap.searchBaseDN || ''}
                  helperText={errors.searchBaseDN && errors.searchBaseDN.message}
                  error={errors.searchBaseDN && errors.searchBaseDN.message}
                  {...register('searchBaseDN')}
                  variant="standard"
                  sx={{ width: '100%' }}
                  InputLabelProps={{
                    shrink: true,
                }}
                  // onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12} md={5.5}>
                <TextField
                  name="dnsSite"
                  label="DNS Site"
                  // value={ldap.dnsSite || ''}
                  helperText={errors.dnsSite && errors.dnsSite.message}
                  error={errors.dnsSite && errors.dnsSite.message}
                  {...register('dnsSite')}
                  variant="standard"
                  sx={{ width: '100%' }}
                  InputLabelProps={{
                    shrink: true,
                }}
                  // onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12} md={5.5}>
                <FormControl fullWidth>
                  <InputLabel id="order" sx={{marginLeft:"-15px"}}>Authentication Order</InputLabel>
                  <Select
                    labelId="order"
                    name="authenticationMode"
                    // value={parseInt(ldap.authenticationMode) || ''}
                    value={watch("authenticationMode") || ""}
                    variant="standard"
                    label="Authentication Order"
                    error={errors.authenticationMode && errors.authenticationMode.message}
                    {...register('authenticationMode')}
                    // onChange={handleChange}
                    required
                  >
                    {AuthOrder.map((order, i) => {
                      return (
                        <MenuItem key={i} value={i + 1}>
                          {order}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={5.5}>
                <FormControlLabel
                  name="enableSso"
                  control={<Checkbox />}
                  label="Sync LDAP Users"
                  checked={syncLDAPUser}
                  onChange={(e) => setSyncLDAPUser(e.target.checked)}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ color: '#ffff' }}
                >
                  Submit
                </Button>
                  <Button variant="contained" sx={{ color: '#ffff', ml: '10px' }} onClick={testLDAP}>
                    Test LDAP
                  </Button>
              </Grid>
            </Grid>
          </form>
        </Card>
      </Container>
    </Page>
  );
};

export default LdapConfig;
