import { LICENSE_FETCH_SUCCESS,LICENSE2_FETCH_SUCCESS,DATA_LOADER, FETCH_FAIL, GENERATE_LICENSE_SUCCESS, LICENSE_ID_FETCH_SUCCESS, REQUEST_LICENSE_SUCCESS } from './licenseActions';

const initialState = {
  loading: false,
  license: null,
  license2: null,
  requestId: '',
  error: null,
};

const licenseReducer = (state = initialState, action) => {
  switch (action.type) {
    case DATA_LOADER: {
      return {
        ...state,
        loading: true,
      };
    }
    case LICENSE_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        license: action.payload,
      };
    }
    case LICENSE2_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        license2: action.payload,
      };
    }
    case REQUEST_LICENSE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case LICENSE_ID_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case GENERATE_LICENSE_SUCCESS: {
      return {
        ...state,
        loading: false,
        requestId: action.payload,
      };
    }
    case FETCH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default: {
      return state;
    }
  }
};

export default licenseReducer;
