import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Card,
  Typography,
  Stack,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function PathOrientedAnalysis({ handlePathFilter, analysisPaths }) {
  const [type, setType] = useState('MODIFIED');
  const [path, setPath] = useState('');
  return (
    <Card>
      <Box sx={{ flexGrow: 1, p: 2 }}>
        {/* <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header"> */}
        <Typography variant="h6" sx={{ p: 1, mb: 3 }}>
          Path Oriented Analysis
        </Typography>
        {/* </AccordionSummary>
          <AccordionDetails> */}
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} style={{ paddingTop: '1px' }}>
            <Stack direction="row">
              <FormControl fullWidth>
                <InputLabel id="path">Select Analysed Path</InputLabel>
                <Select
                  labelId="path"
                  variant="standard"
                  label="Select Analysed Path"
                  onChange={(e) => setPath(e.target.value)}
                >
                  <MenuItem value={null}>Select Path</MenuItem>
                  {analysisPaths.map((path, i) => {
                    return (
                      <MenuItem key={i} value={path}>
                        {path}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <Button
                variant="contained"
                sx={{ height: '40px', ml: '40px', color: '#fff' }}
                onClick={() => handlePathFilter(path, type)}
                disabled={path === '' && true}
              >
                Filter
              </Button>
            </Stack>
          </Grid>
          <Grid xs={12} md={5} sx={{ ml: '20px' }}>
            {/* <FormControl>
                    <RadioGroup
                      row
                      defaultValue={type}
                      name="radio-buttons-group"
                      sx={{ gap: 10 }}
                      onChange={(e) => setType(e.target.value)}
                    >
                      <FormControlLabel value="ACCESSED" control={<Radio />} label="Accessed" />
                      <FormControlLabel value="MODIFIED" control={<Radio />} label="Modified" />
                    </RadioGroup>
                  </FormControl> */}
          </Grid>
        </Grid>
        {/* </AccordionDetails>
        </Accordion> */}
      </Box>
    </Card>
  );
}
