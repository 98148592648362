import jwt from 'jwt-decode';
import { GetFetch } from 'src/utils/fetchServices';
export const USERROLE_HISTORY = 'USERROLE_HISTORY';
export const FETCH_FAIL = 'FETCH_FAIL';

export const getPrivUserHistory = (params) => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  try {
    const response = await GetFetch({ url: `search-query-history/company/${companyId}/user-role/PRIVILEGED`, params });
    const data = response.data;
    dispatch({
      type: USERROLE_HISTORY,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};
