// hooks
import { useEffect, useState } from 'react';
import jwt from 'jwt-decode';
import useAuth from '../hooks/useAuth';
// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar from './Avatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const access_token = localStorage.getItem('accessToken');
  const [userName, setuserName] = useState();

  useEffect(() => {
    if (access_token) {
      const decodedToken = jwt(access_token);
      setuserName(decodedToken.user_name)
    }
  }, [access_token])

  return (
    <Avatar
      // src={user?.photoURL}
      alt={userName}
      color='primary'
      {...other}
    >
      {createAvatar(userName).name}
    </Avatar>
  );
}
