import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardHeader,
  Container,
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getAnalysisPaths, getFolderSize, getPathList, getVolumeData } from '../analyticsActions';
import moment from 'moment';
import PathOrientedAnalysis from './PathOrientedAnalysis';
import FolderSizeChart from './FolderSizeChart';
import PathListTable from './PathListTable';
import chartColors from 'src/utils/chartColors';

export const INITIAL_DATA = 'INITIAL_DATA';
let months;
let monthNo;
let selectWeek = false;

export default function LargestFolderByVolume() {
  const dispatch = useDispatch();

  const pathList = useSelector((state) => state.analyticsReducers.pathList);
  const dates = useSelector((state) => state.systemReducer.dates);
  const folderSize = useSelector((state) => state.analyticsReducers.folderSize);
  const analysisPaths = useSelector((state) => state.analyticsReducers.analysisPaths);

  const [dateAnalysisPath, setDateAnalysisPath] = useState('MODIFIED');
  const [analysedPath, setAnalysedPath] = useState('');
  const [folderSizeBarChartLabels, setFolderSizeBarChartLabels] = useState([]);
  const [folderSizeBarChartData, setFolderSizeBarChartData] = useState([]);
  const [analysisPath, setAnalysisPath] = useState([]);
  const [pathData, setPathData] = useState([]);

  useEffect(() => {
    dispatch(getAnalysisPaths());
  }, []);

  useEffect(() => {
    let data = [];
    if (analysisPaths.length) {
      analysisPaths.forEach((path) => {
        data.push(path.replace('\\', '\\\\'));
      });
    }
    setAnalysisPath([...data]);
  }, [analysisPaths]);

  useEffect(() => {
    let unixStartDate = moment(dates.startDate).format('x');
    let unixEndDate = new Date(dates.endDate).setHours(23, 59, 59);
    if (analysedPath === null) {
      dispatch({ type: INITIAL_DATA });
    } else {
      dispatch(
        getFolderSize({
          rootPath: analysedPath,
          startDate: unixStartDate,
          endDate: unixEndDate,
          dateAnalysis: dateAnalysisPath,
        })
      );
      dispatch(
        getPathList({
          rootPath: analysedPath,
          startDate: unixStartDate,
          endDate: unixEndDate,
          dateAnalysis: dateAnalysisPath,
        })
      );
    }
  }, [analysedPath, dateAnalysisPath]);
  useEffect(() => {
    let barChartPerMonthLabels = [];
    let barChartPerMonthData = [];
    let i = 0;
    if (folderSize != '') {
      var MonthData = [];
      for (let bucket of Object.keys(folderSize)) {
        MonthData.push(parseInt(folderSize[bucket].size) / (1024 * 1024 * 1024));
        barChartPerMonthLabels.push(
          folderSize[bucket].md5Path.substr(0, 3) +
            folderSize[bucket].md5Path.substr(folderSize[bucket].md5Path.length - 3, 3)
        );
      }
      barChartPerMonthData.push({
        data: MonthData,
        label: '',
        backgroundColor: chartColors[i++],
      });
      setFolderSizeBarChartLabels(barChartPerMonthLabels);
      setFolderSizeBarChartData(barChartPerMonthData);
    } else {
      setFolderSizeBarChartLabels([]);
      setFolderSizeBarChartData([]);
    }
  }, [folderSize]);

  useEffect(() => {
    if (folderSizeBarChartLabels.length && pathList?.length) {
      let pathsArray = pathList;
      pathsArray.sort(function (a, b) {
        return (
          folderSizeBarChartLabels.indexOf(a.md5Path.substr(0, 3) + a.md5Path.substr(a.md5Path.length - 3, 3)) -
          folderSizeBarChartLabels.indexOf(b.md5Path.substr(0, 3) + b.md5Path.substr(b.md5Path.length - 3, 3))
        );
      });
      setPathData([...pathsArray]);
    }
  }, [folderSizeBarChartLabels, pathList]);

  const handlePathFilter = (path, type) => {
    setAnalysedPath(path);
    setDateAnalysisPath(type);
  };

  return (
    <Page>
      <Grid container spacing={3}>
        <CardHeader title="Largest Folders by Size" />
        <Grid item xs={12} md={12}>
          <PathOrientedAnalysis analysisPaths={analysisPath} handlePathFilter={handlePathFilter} />
        </Grid>
        <Grid item xs={12} md={12}>
          <FolderSizeChart data={folderSizeBarChartData} labels={folderSizeBarChartLabels} />
        </Grid>
        <Grid item xs={12} md={12}>
          <PathListTable pathList={pathData?.length && pathData} />
        </Grid>
      </Grid>
    </Page>
  );
}
