const { DATA_RETENSION_SUCCESS, DATA_LOADER, FETCH_FAIL, SUCCESS } = require('./dataRetensionActions');

const initialState = {
  loading: false,
  refresh: false,
  documents: [],
};

const dataRetensionReducer = (state = initialState, action) => {
  switch (action.type) {
    case DATA_LOADER: {
      return {
        ...state,
        loading: true,
        refresh: false,
      };
    }
    case DATA_RETENSION_SUCCESS: {
      return {
        ...state,
        loading: false,
        documents: action.payload
      };
    }
    case SUCCESS:
      return {
        ...state,
        loading: false,
        refresh: true,
      };
    case FETCH_FAIL:
      return {
        ...state,
        loading: false,
      };
    default: {
      return state;
    }
  }
};

export default dataRetensionReducer;
