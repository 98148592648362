import { Card } from '@mui/material';
import Page from 'src/components/Page';
import BarChart from 'src/pages/system/charts/BarChart';

export default function StubCharts({data, labels, pathKey }) {
  return (
    <Page>
      <Card>
            <BarChart labels={labels} data={data} showOptions={false} chartHeading="Stacked chart showing volume of the types per year" title={pathKey} />
      </Card>
    </Page>
  );
}
