import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Grid } from '@mui/material';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import { getPolicies } from './policyManagementActions';
import PolicyManagementTable from './components/PolicyManagementTable';
import LoadingScreen from 'src/components/LoadingScreen';

export default function PolicyManagement() {
  const dispatch = useDispatch();
  const { themeStretch } = useSettings();
  const policies = useSelector((state) => state.policyManagementReducer.policies);
  const refresh = useSelector((state) => state.policyManagementReducer.refresh);
  const loading = useSelector((state) => state.policyManagementReducer.loading);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [sort,setSort]= useState('id,desc')

  useEffect(() => {
    dispatch(getPolicies({ page, size: rowsPerPage,sort:sort }));
  }, [page, rowsPerPage,sort]);
  
  useEffect(() => {
    if(refresh){
      dispatch(getPolicies({ page, size: rowsPerPage }));
    }
  }, [refresh]);



  const columns = ['Name', 'Type', 'Description','Action'];

  return (
    <Page>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} style={{ paddingTop: '1px' }}>
            { !loading ? (
              policies.content &&
              <PolicyManagementTable
                pages={[page, setPage]}
                size={[rowsPerPage, setRowsPerPage]}
                policies={policies}
                columns={columns}
                sortData = {[sort,setSort]}
              />
            ): <LoadingScreen /> }
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
