import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
import { Card, CardHeader, Box } from '@mui/material';
import { BaseOptionChart } from '../../../components/chart';
export default function LineChart({labels, data, chartHeading}) {
  const chartOptions = merge(BaseOptionChart(), {
    chart:{
        // stacked:true
      },
    xaxis: {
      categories: labels,
      title: {
        text: data?.length ? data[0].xlabel : ""
      },
    },
    yaxis: {
        title: {
          text: data?.length ? data[0].label : ""
        },
        labels: {
          formatter: function(val) {
            return val && val.toFixed(0)
          }
        }
      },
  });

  return (
    <Card>
      <CardHeader
        title={chartHeading}
        sx={{ mb: '20px' }}
      />
      { (data && data.length) && (labels && labels.length) ?
        <Box  sx={{ mt: 3, mx: 3 }} dir="ltr">
           <ReactApexChart type="line" series={data} options={chartOptions} height={390} />
        </Box>:null
      }
    </Card>
  );
}
