import { SUCCESS_ALERT } from 'src/redux/reducers/alertReducer';
import { deleteInfo, GetDetails, GetFetch, postInfo } from 'src/utils/fetchServices';

export const ACTIVE_AGENTS_FETCH_SUCCESS = 'ACTIVE_AGENTS_FETCH_SUCCESS';
export const AGENT_CONFIG_FETCH_SUCCESS = 'AGENT_CONFIG_FETCH_SUCCESS';
export const AGENT_DELETED = 'AGENT_DELETED';
export const SUCCESS = 'SUCCESS';
export const DATA_LOADER = 'DATA_LOADER';
export const FETCH_FAIL = 'FETCH_FAIL';

export const getActiveAgents = (params) => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  // dispatch({
  //   type: DATA_LOADER,
  // });
  // params.sort = 'name,asc';
  try {
    const response = await GetFetch({ url: `agents/company/${companyId}`, params });
    const data = response.data;
    data.content && data.content.map((agent) => {
      agent.profileId = null
      agent.userProfile = null
    })
    dispatch({
      type: ACTIVE_AGENTS_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};

export const getAgentConfiguration = (id) => async (dispatch) => {
  dispatch({
    type: DATA_LOADER,
  });
  try {
    const response = await GetDetails({ url: `agents/configuration/${id}` });
    const data = response.data;
    dispatch({
      type: AGENT_CONFIG_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};

export const updateAgent = (body, navigate) => async (dispatch) => {
  dispatch({ type: DATA_LOADER });
  try {
    const response = await postInfo(
      {
        url:`agents/configuration`,
        body,
        method:'PUT',
      },
      dispatch
    );
    if (response && response.status === 200) {
      const data = response.data;
      dispatch({ type: SUCCESS });
        dispatch({
          type: SUCCESS_ALERT,
          payload:'Agent Updated Successfully',
        });
        navigate('/dashboard/agent-registration');
    } else {
      dispatch({ type: FETCH_FAIL });
    }
  } catch (error) {
    dispatch({ type: FETCH_FAIL });
  }
};

export const deleteAgentInfo = (id) => async (dispatch) => {
  dispatch({
    type: DATA_LOADER,
  });
  try {
    const response = await deleteInfo({ url: `agents/${id}` });
    const data = response.data;
    dispatch({
      type: AGENT_DELETED,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};

export const approveAgent = (body) => async (dispatch) => {
  // dispatch({ type: DATA_LOADER });
  try {
    const response = await postInfo(
      {
        url:`agents/approve`,
        body,
        method:'PUT',
      },
      dispatch
    );
    if (response && response.status === 200) {
      const data = response.data;
      dispatch({ type: SUCCESS });
        dispatch({
          type: SUCCESS_ALERT,
          payload:'Agent Approved Successfully',
        });
        // navigate('/dashboard/agent-registration');
    } else {
      dispatch({ type: FETCH_FAIL });
    }
  } catch (error) {
    dispatch({ type: FETCH_FAIL });
  }
};
