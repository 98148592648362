import { Button, Card, CardHeader, Container, Grid, Modal, Stack, TextField, Typography,Checkbox, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import { useDispatch, useSelector, } from 'react-redux';
import { deletionRequest } from '../fileActions';

const DeleteModal = ({ openModal, close,node, userInfo }) => {
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const [allFilesCheck,setAllFilesCheck] = useState(false);
  const [allDuplicateCheck,setallDuplicateCheck] = useState(false);
  const [filesCheck,setFilesCheck] = useState(false);
  const [duplicateCheck,setDuplicateCheck] = useState(false);
  const [pathData,setPathData] = useState("")
  const [dataGuardian,setDataGuardian] = useState("")

  const formatPathString = (filePath) => {
    if (filePath[filePath.length - 1] == "\\")
        return filePath.substr(0, filePath.length - 1);
    else return filePath;
  }
  useEffect(()=>{
    if(node && node['path']){
      let pathName = formatPathString(node['path']);
      setPathData(pathName)
    }
  },[node])

  const selectFilesCheck=(e)=>{
    let val = e.target.checked;
    if(val){
      setAllFilesCheck(true)
      setFilesCheck(true)
    }
    else{
      setAllFilesCheck(false)
      setFilesCheck(false)
    }
  }

  const selectDuplicateCheck=(e)=>{
    let val = e.target.checked;
    if(val){
      setallDuplicateCheck(true)
      setDuplicateCheck(true)
    }
    else{
      setallDuplicateCheck(false)
      setDuplicateCheck(false)
    }
  }

  const deletion =()=>{
    let userId = localStorage.getItem(('userId'))
    dispatch(deletionRequest({
      dataGuardianID: dataGuardian,
      deleteNodeInfoList:[{
        deleteDuplicateFiles: duplicateCheck,
        deleteSubfolder: filesCheck,
        folder: true,
        path: pathData,
        userID: userId,
        uuid: node.uuid,
      }]
    }))
    close()
  }

  return (
    <>
      <Modal
        open={openModal}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Page sx={{ width: '40%' }}>
          <Container maxWidth={themeStretch ? false : 'xl'}>
            <Card sx={{ backgroundColor: '#ffff', pb: '40px' }}>
              <CardHeader title="Delete Folder Request" />
              <Stack direction='row' sx={{disply:"flex",justifyContent:"space-between",alignItems:'center',paddingLeft:"25px",paddingRight:"25px"}}>
                <Stack>
                  <Typography>Folder Name</Typography>
                </Stack>
                <Stack  direction='row' alignItems='center'>
                  <Checkbox checked={allFilesCheck} onClick={(e)=>selectFilesCheck(e)} />
                  <Typography>All Files And Folders</Typography>
                  <Checkbox checked={allDuplicateCheck} onClick={(e)=>selectDuplicateCheck(e)} />
                  <Typography>All Duplicate Files</Typography>
                </Stack>
              </Stack>

              <Stack direction='row'  sx={{disply:"flex",justifyContent:"space-between",alignItems:'center',paddingLeft:"25px",paddingRight:"25px"}}>
                <Stack  direction='row' alignItems='center'>
                  <TextField
                    variant="standard"
                    disabled
                    value={node && node['name']}
                    fullWidth
                  />
                </Stack>
                <Stack direction='row' >
                  <Checkbox checked={filesCheck} onClick={()=>setFilesCheck(!filesCheck)}/>
                  <Checkbox checked={duplicateCheck}  onClick={()=>setDuplicateCheck(!duplicateCheck)}/>
                </Stack>
              </Stack>

              <FormControl fullWidth variant='standard' sx={{paddingLeft:3,paddingRight:3,paddingTop:"5px"}}>
                        <InputLabel sx={{marginLeft:"25px"}}>Select DataGuardian User Name</InputLabel>
                        <Select  onChange={(e)=>setDataGuardian(e.target.value)}>
                        {userInfo && userInfo.length &&
                          userInfo.map((item) => {
                            return (
                              <MenuItem key={item.id} value={item.userName}>
                                {item.userName}
                              </MenuItem>
                            );
                          })}
                        </Select>
              </FormControl>

              <Stack flexDirection="row" sx={{paddingTop:2,paddingLeft:"15px"}}>
                    <Button variant="contained" onClick={()=>close()} sx={{color:'#fff', ml:'10px'}}>Cancel</Button>
                    <Button variant="contained"  sx={{color:'#fff', ml:'10px'}} onClick={()=>deletion()}>Send Deletion Request</Button>
                </Stack>
            </Card>
          </Container>
        </Page>
      </Modal>
    </>
  );
};

export default DeleteModal;
