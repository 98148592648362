import { JOBS_STATUS_FETCH_SUCCESS,FETCH_FAIL } from "./jobsStatusAction";

const initialState ={
    jobsStatus :{}
}

const jobsStatusReducer=(state = initialState,action)=>{
    switch (action.type) {
        case JOBS_STATUS_FETCH_SUCCESS: {
            return {
              ...state,
              jobsStatus: action.payload,
            };
        }
        case FETCH_FAIL:
            return {
              ...state,
              error: action.payload,
            };
        default: {
            return state;
        }

    }

}

export default jobsStatusReducer;