import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, Alert, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { PATH_AUTH } from '../../../routes/paths';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';

import { getCompanyMode, appTitleName, login } from '../../../redux/actions/loginActions';
import { useDispatch, useSelector } from 'react-redux';
import { ERROR_ALERT } from 'src/redux/reducers/alertReducer';
import useSettings from 'src/hooks/useSettings';
import { rgbToHex } from 'src/pages/system-configuration/custom-branding/CustomBranding';
import AlertDialog from 'src/components/AlertDialog';
// ----------------------------------------------------------------------

export default function LoginForm({ companyTagLocal, companyMode}) {
  // const { login } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { themeLayout, onChangeCustomColor } = useSettings();
  const error = useSelector((state) => state.login.error);
  const codeVerifyData = useSelector((state) => state.login.twoFactorAuth);
  
  const isMountedRef = useIsMountedRef();
  const alert = useSelector((state) => state.alertReducer);
  const {type, message, progress} = alert;
  const [showPassword, setShowPassword] = useState(false);
  const [verifyLogin, setVerifyLogin] = useState(codeVerifyData);
  const [verifycode, setVerifycode] = useState('');
  const [manualTag, setManualTag] = useState('')
  const [showAlert, setShowAlert] = useState(false);
  const [loginDetails, setLoginDetails] = useState({ username: '', password: '' });
  const color = localStorage.getItem('colorHex')

  // useEffect(() => {
  //   setCompanyTagLocal('DataRepository');
  // }, []);

  useEffect(() => {
    setVerifyLogin(codeVerifyData);
  }, [codeVerifyData]);
  useEffect(() => {
    if(type) setShowAlert(true);
    setTimeout(() => {
      dispatch({
        type :'SET_INITIALSTATE'
      })
    }, 6000);
  }, [alert]);
  // useEffect(() => {
  //   dispatch(getCompanyMode());
  //   dispatch(appTitleName())
  // }, []);

    useEffect(()=>{
    if(color){
      onChangeCustomColor(rgbToHex(color));
    }
  },[color])

  const LoginSchema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required'),
  });
  const LoginSchema1 = Yup.object().shape({
    code: Yup.string().required('Code is required'),
  });
  const methods = useForm({
    resolver: yupResolver(verifyLogin ? LoginSchema1 : LoginSchema),
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    if(companyTagLocal){
      data.companyTag = companyTagLocal
    }    
    let companyTagStorage = data.companyTag || companyTagLocal
    if (!verifyLogin) {
      setLoginDetails({ username: data.username, password: data.password });
      await login(`${data.username}|${data.companyTag || companyTagLocal}`, data.password, '', 'password', navigate, companyTagStorage);
    } else {
      try {
        setVerifycode(data.code);
        await login(
          `${loginDetails.username}|${data.companyTag || companyTagLocal}`,
          loginDetails.password,
          data.code,
          'password',
          navigate
        );
      } catch (error) {
        console.error(error);
        reset();
        if (isMountedRef.current) {
          setError('afterSubmit', error);
        }
      }
    }
  };
  
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      {type && <AlertDialog type={type} message={message} openAlert={[showAlert, setShowAlert]} />}

      {verifyLogin ? (
        <RHFTextField name="code" label="Enter 2FA Code" style={{ marginBottom: '20px' }} />
      ) : (
        <>
          <Stack spacing={3}>
            {error && <Alert severity="error">{error}</Alert>}

            {(!companyTagLocal && (companyMode == 'false' || companyMode == false) ) && <RHFTextField name="companyTag" label="Company Tag" />}

            <RHFTextField name="username" label="Username" />

            <RHFTextField
              name="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
            <RHFCheckbox name="remember" label="Remember me" />
            {/* <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
            Forgot password?
          </Link> */}
          </Stack>
        </>
      )}
      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
        {verifyLogin ? 'Verify' : 'Login'}
      </LoadingButton>
    </FormProvider>
  );
}
