import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  TableContainer,
  Typography,
  Checkbox,
  Stack,
} from '@mui/material';
import { useEffect, useState } from 'react';
import Scrollbar from 'src/components/Scrollbar';

export default function RolePermissionTable({ roleAccess, title, handleLdapSelect, handlePrivSelect, handleAdminSelect, isFileExplorer }) {
  const [roleList, setRoleList] = useState([]);
  useEffect(() => {
    setRoleList(roleAccess);
  }, [roleAccess]);

  return (
    <>
      <Card sx={{ py: '20px' }}>
        {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mr: '20px' }}>
          {title && <CardHeader title={title} sx={{ mb: 3 }} />}
        </Stack> */}
        <Scrollbar>
          <TableContainer sx={{ minWidth: 720 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell> Admin </TableCell>
                  <TableCell>LDAP</TableCell>
                  <TableCell>Priv</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {roleList.length
                  ? roleList.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell sx={{py:0}}>
                          <Typography sx={row.name == 'fileExplorer' || row.name == 'eDiscovery'  ? {  textTransform: 'capitalize', fontWeight:900 }: { textTransform: 'capitalize', ml:'40px'}}>
                            {row.name.split('.')[1] == 'download' ?  'Download/DocViewer' :row.name.split('.')[1]|| row.name}
                          </Typography>
                        </TableCell>
                        {/* {isFileExplorer ? ( */}
                          <TableCell sx={{py:0}}>
                            {row.name.includes('fileExplorer') &&  <Checkbox checked={row?.adminAllowed} onChange={(e) => handleAdminSelect(e, row)} />}
                          </TableCell>
                        {/* ) : null} */}
                        <TableCell sx={{py:0}}>
                          <Checkbox checked={row?.ldapAllowed} onChange={(e) => handleLdapSelect(e, row)} />
                        </TableCell>
                        <TableCell sx={{py:0}}>
                          <Checkbox checked={row?.privAllowed} onChange={(e) => handlePrivSelect(e, row)} />
                        </TableCell>
                      </TableRow>
                    ))
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Card>
    </>
  );
}
