import { DATA_LOADER, FEATURE_FETCH_SUCCESS, FETCH_FAIL, SUCCESS } from './featureAction';

const initialState = {
  loading: false,
  userFeature: [],
  error: null,
};

const featureReducer = (state = initialState, action) => {
  switch (action.type) {
    case DATA_LOADER: {
      return {
        ...state,
        loading: true,
      };
    }
    case FEATURE_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        userFeature: action.payload,
      };
    }
    case SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case FETCH_FAIL:
      return {
        ...state,
        loading: false,
      };
    default: {
      return state;
    }
  }
};

export default featureReducer;
