import { GetFetch } from 'src/utils/fetchServices';

export const NUMBER_OF_FILES_FETCH_SUCCESS = 'NUMBER_OF_FILES_FETCH_SUCCESS';
export const NUMBER_OF_FILES_PER_PATH_FETCH_SUCCESS = 'NUMBER_OF_FILES_PER_PATH_FETCH_SUCCESS';
export const FILES_ARCHIEVED = 'FILES_ARCHIEVED'; 
export const DATA_LOADER = 'DATA_LOADER'; 
export const FETCH_FAIL = 'FETCH_FAIL';

export const getNumberOfFiles = (params) => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  dispatch({
    type: DATA_LOADER,
  });
  try {
    const response = await GetFetch({ url: `charts/company/${companyId}/line-graph-data`, params });
    const data = response.data;
    dispatch({
      type: NUMBER_OF_FILES_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};

export const getFilesArchievd = (params) => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  // dispatch({
  //   type: DATA_LOADER,
  // });
  try {
    const response = await GetFetch({ url: `charts/company/${companyId}/files-archived-per-hour`, params });
    const data = response.data;
    dispatch({
      type: FILES_ARCHIEVED,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};

export const getNumberOfFilesPerPath = (params) => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
    dispatch({
      type: DATA_LOADER,
    });
    params.chartType = 'bar';
    try {
      const response = await GetFetch({ url: `charts/company/${companyId}/files-accessed`, params });
      const data = response.data;
      dispatch({
        type: NUMBER_OF_FILES_PER_PATH_FETCH_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_FAIL,
        payload: error.message,
      });
    }
  };