import { LoadingButton } from '@mui/lab';
import { Button, Card, Container, Modal, Stack, TextField } from '@mui/material';
import { useState } from 'react';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';

const QueueModal = ({ close, handleSubmit, openModal, isLoading, queue, setQueue }) => {
  const { themeStretch } = useSettings();
  
  const handleClose = () => {
    setQueue('');
    close();
  };
  return (
    <>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Page sx={{ width: '40%' }}>
          <Container maxWidth={themeStretch ? false : 'xl'}>
            <Card sx={{ p: '40px' }}>
              {/* <Typography variant="h4">Export to Queue</Typography> */}
              <TextField
                fullWidth
                variant="standard"
                value={queue}
                label="Queue Name"
                placeholder="Enter a Queue Name"
                onChange={(e) => setQueue(e.target.value)}
              />
              <Stack direction="row" gap={2} sx={{ my: '20px' }}>
                <Button variant="contained" onClick={close}>
                  Cancel
                </Button>
                <LoadingButton
                  loading={isLoading}
                  variant="contained"
                  disabled={queue === ''}
                  onClick={() => handleSubmit(queue)}
                >
                  Submit
                </LoadingButton>
              </Stack>
            </Card>
          </Container>
        </Page>
      </Modal>
    </>
  );
};

export default QueueModal;
