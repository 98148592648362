import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, Link, ListItemText } from '@mui/material';
//
import Iconify from '../../Iconify';
import { ListItemStyle, ListItemTextStyle, ListItemIconStyle } from './style';
import { isExternalLink } from '..';
import { useSelector } from 'react-redux';

// ----------------------------------------------------------------------

NavItemRoot.propTypes = {
  active: PropTypes.bool,
  open: PropTypes.bool,
  isCollapse: PropTypes.bool,
  onOpen: PropTypes.func,
  item: PropTypes.shape({
    children: PropTypes.array,
    icon: PropTypes.any,
    info: PropTypes.any,
    path: PropTypes.string,
    title: PropTypes.string,
  }),
};

export function NavItemRoot({ item, isCollapse, open = false, active, onOpen, handleCheck }) {
  const { title, path, icon, info, children } = item;
  const { isExpire } = useSelector(state => state.login)

  const renderContent = (
    <>
      {icon && <ListItemIconStyle>{icon}</ListItemIconStyle>}
      <ListItemTextStyle disableTypography primary={title}  isCollapse={isCollapse} />
      {!isCollapse && (
        <>
          {info && info}
          {children && <ArrowIcon open={handleCheck(item)} />}
        </>
      )}
    </>
  );

  if (children) {
    return (
      <ListItemStyle disabled={(title== "Analytics" && isExpire) ? true : false}  onClick={ (title== "Analytics" && isExpire) ? ()=> {} : () => onOpen(item)} activeRoot={active}>
        {renderContent}
      </ListItemStyle>
    );
  }

  return isExternalLink(path) ? (
    <ListItemStyle component={Link} href={path} target="_blank" rel="noopener">
      {renderContent}
    </ListItemStyle>
  ) : (
    <ListItemStyle component={RouterLink} to={path} activeRoot={active}>
      {renderContent}
    </ListItemStyle>
  );
}

// ----------------------------------------------------------------------

NavItemSub.propTypes = {
  active: PropTypes.bool,
  open: PropTypes.bool,
  onOpen: PropTypes.func,
  item: PropTypes.shape({
    children: PropTypes.array,
    info: PropTypes.any,
    path: PropTypes.string,
    title: PropTypes.string,
  }),
};
export function NavItemSub({ item, open = false, active = false, onOpen }) {
  const { title, path, info, children } = item;

  const renderContent = (
    <>
      <DotIcon active={active} />
      <ListItemText disableTypography primary={title} sx={{fontSize:'13px'}} />
      {info && info}
      {children && <ArrowIcon   open={open} />}
    </>
  );

  if (children) {
    return (
      <ListItemStyle onClick={() => onOpen(item)} activeSub={active} subItem>
        {renderContent}
      </ListItemStyle>
    ) ;
  }

  return isExternalLink(path) ? (
    
    
    <ListItemStyle component={Link} href={path} target="_blank" rel="noopener" subItem>
      {renderContent}
    </ListItemStyle>
  ) : (
    // ((item.id == 'fileexplorer' || item.id == 'custombranding') && userRole == 'superadmin') ||
    //   (item.id == 'searchfile' && adminRole != 'PRIVILEGED' && adminRole != 'LDAP') ||
    //   (item.id != 'searchfile' && item.id != 'fileexplorer' && adminRole == 'PRIVILEGED') ||
    //   (item.id != 'searchfile' && item.id != 'fileexplorer' && adminRole == 'LDAP') ||
    //   (item.id != 'deletecodes' &&
    //     item.id != 'auditaccesslog' &&
    //     item.id != 'auditloginlog' &&
    //     item.id != 'user-activity' &&
    //     adminRole == 'DATAGUARDIAN') ||
    //   ((item.id == 'deletecodes' ||
    //     item.id == 'auditaccesslog' ||
    //     item.id == 'auditloginlog' ||
    //     item.id == 'user-activity') &&
    //     adminRole != 'DATAGUARDIAN') ? (
    //       <ListItemStyle component={RouterLink} to={path} activeSub={active} subItem>
    //       {renderContent}
    //     </ListItemStyle>
    // ) : null
    <ListItemStyle component={RouterLink} to={path} activeSub={active} subItem>
      {renderContent}
    </ListItemStyle>
  );
}

// ----------------------------------------------------------------------

DotIcon.propTypes = {
  active: PropTypes.bool,
};

export function DotIcon({ active }) {
  return (
    <ListItemIconStyle>
      <Box
        component="span"
        sx={{
          width: 4,
          height: 4,
          borderRadius: '50%',
          bgcolor: 'text.disabled',
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shorter,
            }),
          ...(active && {
            transform: 'scale(2)',
            bgcolor: 'primary.main',
          }),
        }}
      />
    </ListItemIconStyle>
  );
}

// ----------------------------------------------------------------------

ArrowIcon.propTypes = {
  open: PropTypes.bool,
};

export function ArrowIcon({ open }) {
  return (
    <Iconify
      icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
      sx={{ width: 16, height: 16, ml: 1 }}
    />
  );
}
