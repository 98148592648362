import {
  Box,
  Card,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  TableContainer,
  TablePagination,
  TextField,
  Stack,
  Grid,
} from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import Scrollbar from '../../../../components/Scrollbar';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { dispatch } from 'src/redux/store';
import copiedFunction from 'src/utils/copyMethod';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { COMPANY_NUM } from 'src/redux/actions/loginActions';

export default function CompanyTable({ pages, size, companyList, columns }) {
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = size;
  const [page, setPage] = pages;
  const [rows, setRows] = useState([]);
  const [searchValue, setSearchValue] = useState(null);

  useEffect(() => {
    if (companyList.content) {
      setRows(companyList.content);
    }
  }, [companyList]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  const loginAsCompany = (row) => {
    localStorage.setItem('globalKey', row.globalKey);
    localStorage.setItem('companyId', row.id);
    localStorage.setItem('companyName', row.companyUsername);
    localStorage.setItem('companyTag', row.companyTag);
    localStorage.setItem('userRole', 'superadmin');
    navigate(`/dashboard/system-status`);
    dispatch({
      type: COMPANY_NUM,
      payload: row.id,
    });
  };

  const convertToCSV = (objArray) => {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';
    for (var i = 0; i < array.length; i++) {
      var line = '';
      for (var index in array[i]) {
        if (line != '') line += ',';

        line += array[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  };

  const downloadData = () => {
    let headers = {
      companyName: 'Company Name',
      archivedVolume: 'Data Volume',
      companyPreferredName: 'Preffered Name',
      companyTag: 'CompanyTag',
      globalKey: 'Global Key',
      companyUsername: 'Admin Name',
    };
    var finalData = [];
    let data = companyList.content;
    data.forEach((obj) => {
      let newobj = {
        companyName: obj.companyName,
        archivedVolume: formatBytes(obj.archivedVolume),
        companyPreferredName: obj.companyPreferredName,
        companyTag: obj.companyTag,
        globalKey: obj.globalKey,
        companyUsername: obj.companyUsername,
      };
      finalData.push(newobj);
    });
    finalData.unshift(headers);

    var jsonObject = JSON.stringify(finalData);
    var csv = convertToCSV(jsonObject);

    var blob = new Blob([csv]);
    let url = window.URL.createObjectURL(blob);
    let tempLink = document.createElement('a');
    tempLink.href = url;
    tempLink.setAttribute('download', 'Company List.csv');
    tempLink.click();
  };

  const optionChange = (e) => {
    let val = e.target.value;
    setSearchValue(val);
    if (val == '') {
      setRows(companyList.content);
    } else {
      let filterResult =
        rows && rows.length &&
        rows.filter(
          (item) =>
            (item.companyName && item.companyName.toLowerCase().includes(val.toLowerCase())) ||
            (item.companyPreferredName && item.companyPreferredName.toLowerCase().includes(val.toLowerCase())) ||
            (item.companyTag && item.companyTag.toLowerCase().includes(val.toLowerCase())) ||
            (item.globalKey && item.globalKey.toLowerCase().includes(val.toLowerCase())) ||
            (item.companyUsername && item.companyUsername.toLowerCase().includes(val.toLowerCase()))
        );
      if (filterResult && filterResult.length == 0) {
        setRows([]);
      } else {
        setRows(filterResult);
      }
    }
  };

  return (
    <>
      <Card>
        <Grid container spacing={5} sx={{ alignItems: 'center' }}>
          <Grid item xs={2}>
            <CardHeader title="Company List" sx={{ mb: 3 }} />
          </Grid>
          <Grid item xs={7}>
            <Stack direction="row">
              <SearchIcon sx={{ marginTop: '23px', marginRight: '20px' }} />
              <TextField
                id="standard-basic"
                name="search"
                label="Search"
                variant="standard"
                fullWidth
                value={searchValue}
                onInput={(e) => optionChange(e)}
              />
            </Stack>
          </Grid>
          <Grid item xs={3}>
            <Stack direction="row" alignItems="center">
              <Link to="/dashboard/company-management/add" style={{ textDecoration: 'none' }}>
                <Button variant="contained">Add New Company</Button>
              </Link>
              <Button variant="contained" sx={{ mx: '20px' }} onClick={() => downloadData()}>
                Export
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 720 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column, i) => (
                    <TableCell key={i}>{column}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows && rows.length ?
                  rows.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell sx={{ color: '#699ad4' }}>{row.companyName}</TableCell>
                      <TableCell>{formatBytes(row.archivedVolume)}</TableCell>
                      <TableCell>{row.companyPreferredName}</TableCell>
                      <TableCell>{row.companyTag}</TableCell>
                      <TableCell>
                        {row.globalKey && row.globalKey.slice(0, 19)}...
                        <Button
                          sx={{ minWidth: 'fit-content', p: 0 }}
                          onClick={() => copiedFunction(row.globalKey, dispatch)}
                        >
                          <ContentCopyIcon />
                        </Button>
                      </TableCell>
                      <TableCell>{row.companyUsername}</TableCell>
                      <TableCell>
                        <Button variant="contained" onClick={() => loginAsCompany(row)}>
                          Login
                        </Button>
                        <Link to={`/dashboard/company-management/edit/${row.id}`}>
                          <Button sx={{ minWidth: 'fit-content', p: 0 }}>
                            <ModeEditIcon />
                          </Button>
                        </Link>
                      </TableCell>
                    </TableRow>
                  )): 
                  <TableRow>
                    <TableCell colSpan={7}>No Data Found</TableCell>
                  </TableRow>
                  }
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20, 100]}
              component="div"
              count={companyList.totalElements}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Scrollbar>
      </Card>
    </>
  );
}
