import React, { useEffect, useMemo, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Slider,
  Snackbar,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import jwt from 'jwt-decode';
import Page from 'src/components/Page';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  SEARCH_MQ_ALERT_CLOSE,
  backenExportFiles,
  downloadFile,
  downloadResultCSV,
  downloadZipFile,
  exportAllFiles,
  getSimilarDoc,
  getUserHistory,
  getUserInfo,
  searchData,
  searchMq,
} from '../ediscoveryActions';
import PathTable from './PathTable';
import ExportTable from './ExportTable';
import FileTable from './FileTable';
import PropertiesTable from './PropertiesTable';
import ExportModal from './ExportModal';
import SimilarDocTable from './SimilarDocTable';
import ExportFilesModal from './ExportFilesModal';
import { get, isEmpty, set } from 'lodash';
import RestoreModal from './RestoreModal';
import SuccessModal from './SuccessModal';
import CompressModal from './CompressModal';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import 'src/utils/style.css';
import UserSearchHistory from './UserSearchHistory';
import QueueModal from './QueueModal';
import AlertDialog from 'src/components/AlertDialog';

const dateRangeArray = [
  { days: 10, name: 'Custom date' },
  { days: 1, name: 'Today' },
  { days: 7, name: 'Last week' },
  { days: 30, name: 'Last month ' },
  { days: 90, name: 'Last quarter' },
  { days: 180, name: 'Last 6 months' },
  { days: 365, name: 'Last year' },
];

const getBackedDateStart = (days) => {
  var today = new Date();
  var priorDate = 0;
  if (days == 0 || days == 7) {
    priorDate = today.setDate(today.getDate() - days);
  } else if (days == 30) {
    let months = 1;
    priorDate = today.setMonth(today.getMonth() - months);
  } else if (days == 90) {
    let months = 3;
    priorDate = today.setMonth(today.getMonth() - months);
  } else if (days == 180) {
    let months = 6;
    priorDate = today.setMonth(today.getMonth() - months);
  } else if (days == 365) {
    let year = 1;
    priorDate = today.setFullYear(today.getFullYear() - year);
  }

  var startedDate = new Date(priorDate).setHours(0);
  startedDate = new Date(startedDate).setMinutes(0);
  startedDate = new Date(startedDate).setSeconds(0);
  return new Date(startedDate);
};

const getTodayDateEnd = () => {
  var todayEnd = new Date();
  var endedDateLast = todayEnd.setHours(23);
  endedDateLast = new Date(endedDateLast).setMinutes(59);
  endedDateLast = new Date(endedDateLast).setSeconds(59);
  return new Date(endedDateLast);
};

const repoColumns = ['Copy Path', 'Name', 'Last Modified', 'Last Accessed', 'File Size', 'Download'];
const fileColumns = ['Copy Path', 'Name', 'Last Modified', 'Last Accessed', 'File Size'];
const similarColumns = ['Copy Path', 'Name', 'Last Modified', 'Last Accessed', 'File Size', 'Download'];
const exportColumns = ['Download', 'No of files', 'Start Time', 'End Time', 'Time Taken', 'Status Message', 'Status'];

const SearchCriteria = ({ roleAccess }) => {
  const dispatch = useDispatch();
  const searchFilterData = useSelector((state) => state.ediscoveryReducer.searchFilterData);
  const csvExportLoading = useSelector((state) => state.ediscoveryReducer.csvExportLoading);
  const userInfo = useSelector((state) => state.ediscoveryReducer.userInfo);
  const similarDoc = useSelector((state) => state.ediscoveryReducer.similarDoc);
  const userHistory = useSelector((state) => state.ediscoveryReducer.userHistory);
  const searchLoading = useSelector((state) => state.ediscoveryReducer.searchLoading);
  const searchMqLoading = useSelector((state) => state.ediscoveryReducer.searchMqLoading);
  const searchMqError = useSelector((state) => state.ediscoveryReducer.searchMqError);
  const searchMqErrorMessage = useSelector((state) => state.ediscoveryReducer.searchMqErrorMessage);
  const searchSucess = useSelector((state) => state.ediscoveryReducer.searchSucess);
  const globalConfiguration = useSelector((state) => state.globalReducer.globalConfiguration);
  const maxResultsToExport = get(globalConfiguration, 'maxResultsToExport', 0);
  const [refresh, setRefresh] = useState(false);
  const stea = useSelector(state => state.licenseReducer)
  const [startSize, setStartSize] = useState(0);
  const [endSize, setEndSize] = useState(1024);
  const [dataSize, setDataSize] = useState('KB');
  const [tab, setTab] = useState(0);
  const [open, setOpen] = useState(true);

  const [exportVal, setExportVal] = useState('all');
  const [openExportModal, setOpenExportModal] = useState(false);
  const [openExportFilesModal, setOpenExportFilesModal] = useState(false);
  const [openRestoreModal, setOpenRestoreModal] = useState(false);

  const [fileProperties, setFileProperties] = useState();
  const [fileServerUuid, setFileServerUuid] = useState(false);
  const [selectedPropertyType, setSelectedPropertyType] = useState(0);

  const [addFilter, setAddFilter] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const [isAccessed, setIsAccessed] = useState(false);
  const [isArchived, setIsArchived] = useState(false);
  const [isMetaData, setIsMetaData] = useState(false);
  const [isPath, setIsPath] = useState(false);
  const [resUid, setResUid] = useState();
  const [isFileSize, setIsFileSize] = useState(false);
  const [guids, setGuids] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [queue, setQueue] = useState('');
  const [openSuccess, setOpenSuccess] = useState(false);
  const [compressModal, setCompressModal] = useState(false);

  const [modifiedDateRangeSelect, setModifiedDateRangeSelect] = useState(30);
  const [createdDateRangeSelect, setCreatedDateRangeSelect] = useState(30);
  const [accessedDateRangeSelect, setAccessedDateRangeSelect] = useState(30);
  const [archivedDateRangeSelect, setArchivedDateRangeSelect] = useState(30);

  const [responseTime, setresponseTime] = useState('');

  const [modifiedStart, setModifiedStart] = useState(getBackedDateStart(30));
  const [modifiedEnd, setModifiedEnd] = useState(getTodayDateEnd());
  const [createdStart, setCreatedStart] = useState(getBackedDateStart(30));
  const [createdEnd, setCreatedEnd] = useState(getTodayDateEnd());
  const [accessedStart, setAccessedStart] = useState(getBackedDateStart(30));
  const [accessedEnd, setAccessedEnd] = useState(getTodayDateEnd());
  const [archivedStart, setArchivedStart] = useState(getBackedDateStart(30));
  const [archivedEnd, setArchivedEnd] = useState(getTodayDateEnd());
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page, setPage] = useState(0);
  const [similarDocPage, setSimilarDocPage] = useState(0);
  const [similarDocPerPage, setSimilarDocPerPage] = useState(100);
  const [historyPage, setHistoryPage] = useState(0);
  const [historyPerPage, setHistoryPerPage] = useState(10);
  const [userHistoryData, setUserHistoryData] = useState({});
  const [nextDetails, setNextDetails] = useState({});
  const [similarDocId, setSimilarDocId] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElFile, setAnchorElFile] = useState(null);
  const [keywordArray, setKeywordArray] = useState([]);
  const [fileKeywordArray, setFileKeywordArray] = useState([]);
  const [keywordToken, setKeywordToken] = useState(false);
  const [fileToken, setFileToken] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [excludeKeywords, setExcludeKeywords] = useState([]);
  const [excludeFileKeywords, setExcludeFileKeywords] = useState([]);
  const openMenu = Boolean(anchorEl);
  const openFileMenu = Boolean(anchorElFile);
  const access_token = localStorage.getItem('accessToken');
  const [rolename, setRolename] = useState();
  const [keywordResult, setKeywordsResult] = useState('');
  const [fileNameResult, setFileNameResult] = useState([]);
  const [isQueueModalOpen, setQueueModal] = useState(false);
  const [searchDetails, setSearchDetails] = useState({
    archivedEndDate: '',
    archivedStartDate: '',
    metaDataText: '',
    fileServer: false,
    dateCreatedEnd: '',
    dateCreatedStart: '',
    documentTag: '',
    fileNames: [''],
    keyWords: '',
    modifiedEndDate: '',
    modifiedStartDate: '',
    searchPaths: [],
    currentPage: 1,
    itemsPerPage: 100,
    repository: true,
    sort: 'lastModifiedDate',
    order: 'desc',
    sid: '',
    lastRepoAccessedDate: '',
  });

  useEffect(() => {
    if (access_token) {
      const decodedToken = jwt(access_token);
      setRolename(decodedToken.authorities[0]);
    }
  }, [access_token]);

  useEffect(() => {
    let data = userHistory || {};
    if (data?.content?.length) {
      data.content.map((item) => {
        let req = typeof item.request == 'string' ? JSON.parse(item.request) : item.request;
        item.request = req;
      });
    }
    setUserHistoryData({ ...data });
  }, [userHistory]);

  useEffect(() => {
    if (tab === 3) {
      let params = {
        pageNo: historyPage + 1,
        size: historyPerPage,
      };
      dispatch(getUserHistory(params));
    }
  }, [tab, historyPage, historyPerPage]);

  const openQueueModal = () => {
    setQueueModal(true);
  };
  const closeQueueModal = () => {
    setQueueModal(false);
    setQueue('')
  };
  const handleRerun = (row) => {
    setKeywordArray([]);
    if (row.request.fileSizeStart || row.request.fileSizeEnd) {
      setIsFileSize(true);
    } else {
      setIsFileSize(false);
    }
    if (row.request.fileSizeEnd) {
      if (row.request.fileSizeEnd / 1000 <= 1024) {
        setEndSize(row.request.fileSizeEnd / 1000);
        setStartSize(row.request.fileSizeStart / 1000);
        setDataSize('KB');
      } else if (row.request.fileSizeEnd / 1000000 <= 1024) {
        setEndSize(row.request.fileSizeEnd / 1000000);
        setStartSize(row.request.fileSizeStart / 1000000);
        setDataSize('MB');
      } else if (row.request.fileSizeEnd / 1000000000 <= 1024) {
        setEndSize(row.request.fileSizeEnd / 1000000000);
        setStartSize(row.request.fileSizeStart / 1000000000);
        setDataSize('GB');
      }
    } else {
      setEndSize(1024);
      setStartSize(0);
      setDataSize('KB');
    }
    if (
      row.request.modifiedEndDate ||
      row.request.modifiedStartDate ||
      row.request.archivedEndDate ||
      row.request.archivedStartDate ||
      row.request.dateCreatedStart ||
      row.request.dateCreatedEnd ||
      row.request.lastRepoAccessedDate ||
      row.request.lastRepoAccessedEndDate ||
      row.request.metaDataText

    ) {
      setAddFilter(true);
    } else {
      setAddFilter(false);
    }
    if (row.request.searchPaths && row.request.searchPaths.length) {
      if (row.request.searchPaths[0].length) {
        setIsPath(true);
        setAddFilter(true);
      }
    } else {
      setIsPath(false);
    }
    if (row.request.modifiedEndDate && row.request.modifiedStartDate) {
      setIsModified(true);
      setModifiedDateRangeSelect(10);
      setModifiedStart(row.request.modifiedStartDate);
      setModifiedEnd(row.request.modifiedEndDate);
    } else {
      setIsModified(false);
    }
    if (row.request.dateCreatedEnd && row.request.dateCreatedStart) {
      setIsCreated(true);
      setCreatedDateRangeSelect(10);
      setCreatedStart(row.request.dateCreatedStart);
      setCreatedEnd(row.request.dateCreatedEnd);
    } else {
      setIsCreated(false);
    }
    if (row.request.archivedEndDate && row.request.archivedStartDate) {
      setIsArchived(true);
      setArchivedDateRangeSelect(10);
      setArchivedStart(row.request.archivedStartDate);
      setArchivedEnd(row.request.archivedEndDate);
    } else {
      setIsArchived(false);
    }
    if (row.request?.metaDataText) {
      setIsMetaData(true);
      setSearchDetails({ ...searchDetails, metaDataText: row.request?.metaDataText });
    } else {
      setIsMetaData(false);
    }
    if (row.request.lastRepoAccessedEndDate && row.request.lastRepoAccessedDate) {
      setIsAccessed(true);
      setAccessedDateRangeSelect(10);
      setAccessedStart(row.request.lastRepoAccessedDate);
      setAccessedEnd(row.request.lastRepoAccessedEndDate);
    } else {
      setIsAccessed(false);
    }
    setSearchDetails({
      ...searchDetails,
      keyWords: row.request.keyWords,
      fileNames: row.request.fileNames,
      repository: row.repoResultCount ? true : false,
      fileServer: row.fsResultCount ? true : false,
      documentTag: row.request.documentTag,
      searchPaths: row.request.searchPaths,
      sort: row.request.sort,
    });
    handleSearch(true, row);
  };

  const handleClose = (i) => {
    setAnchorEl(null);
  };
  const handleClick = (event, i) => {
    setAnchorEl(event.currentTarget);
    setCurrentIndex(i);
  };
  const handleFileClick = (event, i) => {
    setAnchorElFile(event.currentTarget);
    setCurrentFileIndex(i);
  };
  const handleExclude = () => {
    setExcludeKeywords([...excludeKeywords, keywordArray[currentIndex]]);
    setAnchorEl(null);
  };
  const handleFileExclude = () => {
    setExcludeFileKeywords([...excludeFileKeywords, fileKeywordArray[currentFileIndex]]);
    setAnchorElFile(null);
  };
  const handleInclude = () => {
    let data = excludeKeywords.filter((keyword) => keywordArray[currentIndex] != keyword);
    setExcludeKeywords([...data]);
    setAnchorEl(null);
  };
  const handleFileInclude = () => {
    let data = excludeFileKeywords.filter((keyword) => fileKeywordArray[currentFileIndex] != keyword);
    setExcludeFileKeywords([...data]);
    setAnchorElFile(null);
  };
  const handleAndType = () => {
    let data = keywordArray;
    data.splice(currentIndex, 1, 'AND');
    setAnchorEl(null);
  };
  const handleFileAndType = () => {
    let data = fileKeywordArray;
    data.splice(currentFileIndex, 1, 'AND');
    setAnchorElFile(null);
  };
  const handleOrType = () => {
    let data = keywordArray;
    data.splice(currentIndex, 1, 'OR');
    setAnchorEl(null);
  };
  const handleFileOrType = () => {
    let data = fileKeywordArray;
    data.splice(currentFileIndex, 1, 'OR');
    setAnchorElFile(null);
  };

  const handleEditKeyword = () => {
    setSearchDetails({ ...searchDetails, keyWords: keywordArray[currentIndex] });
    let data = keywordArray;
    if (data.length == currentIndex + 1) {
      data.splice(currentIndex - 1, 2);
    } else {
      data.splice(currentIndex, 2);
    }
    setKeywordArray([...data]);
    setAnchorEl(null);
  };
  const handleFileEditKeyword = () => {
    setSearchDetails({ ...searchDetails, fileNames: [fileKeywordArray[currentFileIndex]] });
    let data = fileKeywordArray;
    if (data.length == currentFileIndex + 1) {
      data.splice(currentFileIndex - 1, 2);
    } else {
      data.splice(currentFileIndex, 2);
    }
    setFileKeywordArray([...data]);
    setAnchorElFile(null);
  };
  const handleDeleteKeyword = (i) => {
    let data = keywordArray;
    if (data.length == i + 1) {
      data.splice(i - 1, 2);
    } else {
      data.splice(i, 2);
    }
    setKeywordArray([...data]);
  };
  const handleDeleteFileKeyword = (i) => {
    let data = fileKeywordArray;
    if (data.length == i + 1) {
      data.splice(i - 1, 2);
    } else {
      data.splice(i, 2);
    }
    setFileKeywordArray([...data]);
  };

  useEffect(() => {
    if (tab === 4 || tab === 5) {
      dispatch(getUserInfo());
    }
  }, [refresh, tab]);
  const modifiedRange = (e) => {
    setModifiedDateRangeSelect(e.target.value);
    let selectedRange = e.target.value;
    if (selectedRange == 10) {
      setModifiedStart(null);
      setModifiedEnd(null);
      //   fileData['modifiedStartDate'] = '';
      //   fileData['modifiedEndDate'] = '';
    }

    if (selectedRange == 1) {
      setModifiedStart(getBackedDateStart(0));
      setModifiedEnd(getTodayDateEnd());
    } else if (selectedRange == 7) {
      setModifiedStart(getBackedDateStart(7));
      setModifiedEnd(getTodayDateEnd());
    } else if (selectedRange == 30) {
      setModifiedStart(getBackedDateStart(30));
      setModifiedEnd(getTodayDateEnd());
    } else if (selectedRange == 90) {
      setModifiedStart(getBackedDateStart(90));
      setModifiedEnd(getTodayDateEnd());
    } else if (selectedRange == 180) {
      setModifiedStart(getBackedDateStart(180));
      setModifiedEnd(getTodayDateEnd());
    } else if (selectedRange == 365) {
      setModifiedStart(getBackedDateStart(365));
      setModifiedEnd(getTodayDateEnd());
    }
  };

  const createdRange = (e) => {
    setCreatedDateRangeSelect(e.target.value);
    let selectedRange = e.target.value;
    if (selectedRange == 10) {
      createdStart = null;
      createdEnd = null;
      //   fileData["dateCreatedStart"] = "";
      //   fileData["dateCreatedEnd"] = "";
    }

    if (selectedRange == 1) {
      setCreatedStart(getBackedDateStart(0));
      setCreatedEnd(getTodayDateEnd());
    } else if (selectedRange == 7) {
      setCreatedStart(getBackedDateStart(7));
      setCreatedEnd(getTodayDateEnd());
    } else if (selectedRange == 30) {
      setCreatedStart(getBackedDateStart(30));
      setCreatedEnd(getTodayDateEnd());
    } else if (selectedRange == 90) {
      setCreatedStart(getBackedDateStart(90));
      setCreatedEnd(getTodayDateEnd());
    } else if (selectedRange == 180) {
      setCreatedStart(getBackedDateStart(180));
      setCreatedEnd(getTodayDateEnd());
    } else if (selectedRange == 365) {
      setCreatedStart(getBackedDateStart(365));
      setCreatedEnd(getTodayDateEnd());
    }
  };

  const accessedRange = (e) => {
    setAccessedDateRangeSelect(e.target.value);
    let selectedRange = e.target.value;
    if (selectedRange == 10) {
      accessedStart = null;
      accessedEnd = null;
      // fileData["lastRepoAccessedDate"] = "";
      // fileData["lastRepoAccessedEndDate"] = "";
    }

    if (selectedRange == 1) {
      setAccessedStart(getBackedDateStart(0));
      setAccessedEnd(getTodayDateEnd());
    } else if (selectedRange == 7) {
      setAccessedStart(getBackedDateStart(7));
      setAccessedEnd(getTodayDateEnd());
    } else if (selectedRange == 30) {
      setAccessedStart(getBackedDateStart(30));
      setAccessedEnd(getTodayDateEnd());
    } else if (selectedRange == 90) {
      setAccessedStart(getBackedDateStart(90));
      setAccessedEnd(getTodayDateEnd());
    } else if (selectedRange == 180) {
      setAccessedStart(getBackedDateStart(180));
      setAccessedEnd(getTodayDateEnd());
    } else if (selectedRange == 365) {
      setAccessedStart(getBackedDateStart(365));
      setAccessedEnd(getTodayDateEnd());
    }
  };
  const archivedRange = (e) => {
    setArchivedDateRangeSelect(e.target.value);
    let selectedRange = e.target.value;
    if (selectedRange == 10) {
      setArchivedStart(null);
      setArchivedEnd(null);
      // fileData["archivedStartDate"] = "";
      // fileData["archivedEndDate"] = "";
    }
    if (selectedRange == 1) {
      setArchivedStart(getBackedDateStart(0));
      setArchivedEnd(getTodayDateEnd());
    } else if (selectedRange == 7) {
      setArchivedStart(getBackedDateStart(7));
      setArchivedEnd(getTodayDateEnd());
    } else if (selectedRange == 30) {
      setArchivedStart(getBackedDateStart(30));
      setArchivedEnd(getTodayDateEnd());
    } else if (selectedRange == 90) {
      setArchivedStart(getBackedDateStart(90));
      setArchivedEnd(getTodayDateEnd());
    } else if (selectedRange == 180) {
      setArchivedStart(getBackedDateStart(180));
      setArchivedEnd(getTodayDateEnd());
    } else if (selectedRange == 365) {
      setArchivedStart(getBackedDateStart(365));
      setArchivedEnd(getTodayDateEnd());
    }
  };

  useEffect(() => {
    if (!isEmpty(nextDetails)) {
      nextDetails.currentPage = page + 1;
      nextDetails.itemsPerPage = rowsPerPage;
      dispatch(searchData(nextDetails, handleSearchTime));
    }
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (tab == 0 || tab == 1) {
      setPage(0);
      setRowsPerPage(100);
    }
  }, [tab]);

  const handleSearchTime = (startTime, endTime) => {
    let ResponseTime = (endTime - startTime) / 1000;
    setresponseTime(ResponseTime);
  };
  const handleSearch = (searchBtn, rerunData) => {
    const { metaDataText, ...rest } = searchDetails;
    let bodyData = { ...rest };
    bodyData.companyTag = localStorage.getItem('companyTagLocal');

    if (isFileSize) {
      if (dataSize == 'KB') {
        bodyData.fileSizeStart = startSize * 1000;
        bodyData.fileSizeEnd = endSize * 1000;
      } else if (dataSize == 'MB') {
        bodyData.fileSizeStart = startSize * 1000000;
        bodyData.fileSizeEnd = endSize * 1000000;
      } else if (dataSize == 'GB') {
        bodyData.fileSizeStart = startSize * 1000000000;
        bodyData.fileSizeEnd = endSize * 1000000000;
      }
    }

    if (!keywordToken) {
      let data = [];
      keywordArray.map((key) => {
        data.push(key);
      });
      if (excludeKeywords.length) {
        excludeKeywords.map((key) => {
          let index = data.indexOf(key);
          let val = data[index];
          let ex = '-';
          data.splice(index, 1, ex.concat(val));
        });
      }
      if (data.length) {
        bodyData.keyWords = data.join(' ');
        setKeywordsResult(data.join(' '));
      }
    }

    if (!fileToken) {
      let data = [];
      fileKeywordArray.map((key) => {
        data.push(key);
      });
      if (excludeFileKeywords.length) {
        excludeFileKeywords.map((key) => {
          let index = data.indexOf(key);
          let val = data[index];
          let ex = '-';
          data.splice(index, 1, ex.concat(val));
        });
      }
      if (data.length) {
        bodyData.fileNames[0] = data.join(' ');
        setFileNameResult([data.join(' ')]);
      }
    }
    if (rerunData) {
      bodyData.keyWords = rerunData.request.keyWords;
      bodyData.fileNames = rerunData.request.fileNames;
      bodyData.repository = rerunData.repoResultCount ? true : false;
      bodyData.fileServer = rerunData.fsResultCount ? true : false;
      bodyData.sort = rerunData.request.sort;
      bodyData.searchPaths = rerunData.request.searchPaths;
      bodyData.documentTag = rerunData.request.documentTag;
      if (rerunData.request.fileSizeStart || rerunData.request.fileSizeStart == 0) {
        bodyData.fileSizeStart = rerunData.request.fileSizeStart;
      }
      if (rerunData.request.fileSizeEnd) {
        bodyData.fileSizeEnd = rerunData.request.fileSizeEnd;
      }
      if (rerunData.request.modifiedEndDate) {
        bodyData.modifiedEndDate = rerunData.request.modifiedEndDate;
      }
      if (rerunData.request.modifiedStartDate) {
        bodyData.modifiedStartDate = rerunData.request.modifiedStartDate;
      }
      if (rerunData.request.archivedEndDate) {
        bodyData.archivedEndDate = rerunData.request.archivedEndDate;
      }
      if (rerunData.request.archivedStartDate) {
        bodyData.archivedStartDate = rerunData.request.archivedStartDate;
      }
      if (rerunData.request.dateCreatedStart) {
        bodyData.dateCreatedStart = rerunData.request.dateCreatedStart;
      }
      if (rerunData.request.dateCreatedEnd) {
        bodyData.dateCreatedEnd = rerunData.request.dateCreatedEnd;
      }
      if (rerunData.request.lastRepoAccessedDate) {
        bodyData.lastRepoAccessedDate = rerunData.request.lastRepoAccessedDate;
      }
      if (rerunData.request.lastRepoAccessedEndDate) {
        bodyData.lastRepoAccessedEndDate = rerunData.request.lastRepoAccessedEndDate;
      }
      if (rerunData.request.metaDataText) {
        bodyData.metaDataText = rerunData.request.metaDataText;
      }
    } else {
      bodyData.archivedStartDate = isArchived ? moment(archivedStart).format('YYYY-MM-DD HH:mm:ss.SSS') : '';
      bodyData.archivedEndDate = isArchived ? moment(archivedEnd).format('YYYY-MM-DD HH:mm:ss.SSS') : '';
      bodyData.lastRepoAccessedDate = isAccessed ? moment(accessedStart).format('YYYY-MM-DD HH:mm:ss.SSS') : '';
      bodyData.modifiedStartDate = isModified ? moment(modifiedStart).format('YYYY-MM-DD HH:mm:ss.SSS') : '';
      bodyData.modifiedEndDate = isModified ? moment(modifiedEnd).format('YYYY-MM-DD HH:mm:ss.SSS') : '';
      bodyData.dateCreatedStart = isCreated ? moment(createdStart).format('YYYY-MM-DD HH:mm:ss.SSS') : '';
      bodyData.dateCreatedEnd = isCreated ? moment(createdEnd).format('YYYY-MM-DD HH:mm:ss.SSS') : '';
      bodyData.lastRepoAccessedEndDate = isAccessed ? moment(accessedEnd).format('YYYY-MM-DD HH:mm:ss.SSS') : '';
      bodyData.metaDataText = isMetaData ? metaDataText : '';
    }
    setNextDetails(bodyData);
    dispatch(searchData(bodyData, handleSearchTime));
    setOpen(false);
    if (searchBtn) {
      setPage(0);
      setTab(0);
    }
    // if (!keywordToken && keywordArray.length) {
    //   searchDetails.keyWords = '';
    // }
    // if (!fileToken && fileKeywordArray.length) {
    //   searchDetails.fileNames[0] = '';
    // }
  };
  const handleSearchMq = (queue) => {
    let bodyData = { ...searchDetails };
    bodyData.companyTag = localStorage.getItem('companyTagLocal');
    bodyData.queueName = queue || '';
    if (isFileSize) {
      if (dataSize == 'KB') {
        bodyData.fileSizeStart = startSize * 1000;
        bodyData.fileSizeEnd = endSize * 1000;
      } else if (dataSize == 'MB') {
        bodyData.fileSizeStart = startSize * 1000000;
        bodyData.fileSizeEnd = endSize * 1000000;
      } else if (dataSize == 'GB') {
        bodyData.fileSizeStart = startSize * 1000000000;
        bodyData.fileSizeEnd = endSize * 1000000000;
      }
    }

    if (!keywordToken) {
      let data = [];
      keywordArray.map((key) => {
        data.push(key);
      });
      if (excludeKeywords.length) {
        excludeKeywords.map((key) => {
          let index = data.indexOf(key);
          let val = data[index];
          let ex = '-';
          data.splice(index, 1, ex.concat(val));
        });
      }
      if (data.length) {
        bodyData.keyWords = data.join(' ');
        setKeywordsResult(data.join(' '));
      }
    }

    if (!fileToken) {
      let data = [];
      fileKeywordArray.map((key) => {
        data.push(key);
      });
      if (excludeFileKeywords.length) {
        excludeFileKeywords.map((key) => {
          let index = data.indexOf(key);
          let val = data[index];
          let ex = '-';
          data.splice(index, 1, ex.concat(val));
        });
      }
      if (data.length) {
        bodyData.fileNames[0] = data.join(' ');
        setFileNameResult([data.join(' ')]);
      }
    }
    bodyData.archivedStartDate = isArchived ? moment(archivedStart).format('YYYY-MM-DD HH:mm:ss.SSS') : '';
    bodyData.archivedEndDate = isArchived ? moment(archivedEnd).format('YYYY-MM-DD HH:mm:ss.SSS') : '';
    bodyData.lastRepoAccessedDate = isAccessed ? moment(accessedStart).format('YYYY-MM-DD HH:mm:ss.SSS') : '';
    bodyData.modifiedStartDate = isModified ? moment(modifiedStart).format('YYYY-MM-DD HH:mm:ss.SSS') : '';
    bodyData.modifiedEndDate = isModified ? moment(modifiedEnd).format('YYYY-MM-DD HH:mm:ss.SSS') : '';
    bodyData.dateCreatedStart = isCreated ? moment(createdStart).format('YYYY-MM-DD HH:mm:ss.SSS') : '';
    bodyData.dateCreatedEnd = isCreated ? moment(createdEnd).format('YYYY-MM-DD HH:mm:ss.SSS') : '';
    bodyData.lastRepoAccessedEndDate = isAccessed ? moment(accessedEnd).format('YYYY-MM-DD HH:mm:ss.SSS') : '';
    // setNextDetails(bodyData);
    if (searchDetails.fileServer) {
      dispatch(searchMq(bodyData, closeQueueModal));
    }
    setOpen(false);
    // if (!keywordToken && keywordArray.length) {
    //   searchDetails.keyWords = '';
    // }
    // if (!fileToken && fileKeywordArray.length) {
    //   searchDetails.fileNames[0] = '';
    // }
  };
  const handleChange = (e, newValue) => {
    setTab(newValue);
  };
  const handleReset = () => {
    setAddFilter(false);
    setIsModified(false);
    setIsCreated(false);
    setIsAccessed(false);
    setIsArchived(false);
    setIsPath(false);
    setStartSize(0);
    setEndSize(1024);
    setDataSize('KB');
    setSearchDetails({
      archivedEndDate: '',
      archivedStartDate: '',
      fileServer: false,
      dateCreatedEnd: '',
      dateCreatedStart: '',
      documentTag: '',
      fileNames: [''],
      keyWords: '',
      modifiedEndDate: '',
      modifiedStartDate: '',
      searchPaths: [],
      currentPage: 1,
      itemsPerPage: 100,
      repository: true,
      sort: 'lastModifiedDate',
      order: 'desc',
      sid: '',
      lastRepoAccessedDate: '',
    });
    setExcludeKeywords([]);
    setExcludeFileKeywords([]);
    setKeywordArray([]);
    setFileKeywordArray([]);
  };

  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  const showProperties = (fileDetails, type) => {
    if (type === 2) {
      setFileServerUuid(false);
      setSelectedPropertyType(2);
    } else if (type === 1) {
      setFileServerUuid(true);
      setSelectedPropertyType(1);
    } else {
      setFileServerUuid(true);
    }
    setFileProperties(fileDetails);
    setTab(2);
  };

  const findSimilarDoc = (resID) => {
    setTab(4);
    var uid = resID.plainUuid;
    setResUid(resID.plainUuid);
    var pageNo = similarDocPage + 1;
    var size = similarDocPerPage;
    setSimilarDocId(uid);
    dispatch(getSimilarDoc({ pageNo, size }, uid, handleSearchTime));
  };
  useEffect(() => {
    if (similarDocId) {
      var pageNo = similarDocPage + 1;
      var size = similarDocPerPage;
      dispatch(getSimilarDoc({ pageNo, size }, similarDocId, handleSearchTime));
    }
  }, [similarDocPage, similarDocPerPage]);

  const checkUuid = (resId) => {
    if (resId && resId.plainUuid) {
      const ALPHA_NUMERIC_REGEX = /^[A-Z0-9_]*$/;
      if (ALPHA_NUMERIC_REGEX.test(resId.plainUuid) && resId.plainUuid.length >= 38) {
        return false;
      }
      return true;
    }
    return true;
  };

  useEffect(() => {
    let files = !isEmpty(searchFilterData) ? searchFilterData.results : [];
    if (exportVal === 'all') {
      setGuids(() => files.map((file) => file.plainUuid));
    } else {
      setGuids(() => selectedFiles.map((file) => file.plainUuid));
    }
  }, [exportVal]);
  const handleExports = () => {
    const companyId = +localStorage.getItem('companyId');
    let selectID;
    if (exportVal === 'all') {
      dispatch(exportAllFiles(nextDetails, companyId, maxResultsToExport));
      setOpenExportModal(false);
      setTimeout(() => {
        setOpenSuccess(true);
      }, 1000);
      return;
    }
    if (exportVal === 'selected') {
      selectID = selectedFiles.map((file) => file.plainUuid);
    }
    dispatch(backenExportFiles({ guids: selectID, companyId, maxResultsToExport }));
    setOpenExportModal(false);
    setTimeout(() => {
      setOpenSuccess(true);
    }, 1000);
  };

  const handleSelectedFiles = (e, row) => {
    if (e.target.checked) {
      setSelectedFiles([...selectedFiles, row]);
    } else {
      setSelectedFiles(selectedFiles.filter((s) => s.plainUuid !== row.plainUuid));
    }
  };
  const selectAllFiles = (e) => {
    if (searchFilterData.results && searchFilterData.results.length) {
      if (e.target.checked) {
        setSelectedFiles([...searchFilterData.results]);
      } else {
        setSelectedFiles([]);
      }
    }
  };

  const downloadSelected = () => {
    selectedFiles.length &&
      selectedFiles.map((file) =>
        dispatch(
          downloadFile(
            {
              version: 0,
              plainUuid: file.plainUuid,
            },
            file.documentName
          )
        )
      );
  };
  const downloadCompressed = () => {
    let data = [];
    selectedFiles.length && selectedFiles.map((file) => data.push(file.plainUuid));
    dispatch(downloadZipFile(data));
    setCompressModal(false);
  };
  const exportResultToCSV = () => {
    let companyTag = localStorage.getItem('companyTagLocal');
    let data = {
      archivedEndDate: '',
      archivedStartDate: '',
      companyTag: companyTag,
      currentPage: 1,
      dateCreatedEnd: '',
      dateCreatedStart: '',
      documentTag: '',
      fileNames: [''],
      fileServer: false,
      itemsPerPage: 100,
      keyWords: 'winsa',
      lastRepoAccessedDate: '',
      modifiedEndDate: '',
      modifiedStartDate: '',
      order: 'desc',
      repository: true,
      searchPaths: [],
      sid: '',
      sort: 'lastModifiedDate',
    };
    dispatch(downloadResultCSV({ ...nextDetails, fileServer: tab == 1 ? true : false, repository: tab == 0 ? true : false }));
  };

  const isCsvButtonDisabled = useMemo(() => {
    if (tab == 0 && !isEmpty(searchFilterData) && get(searchFilterData, 'results') && searchFilterData?.results?.length > 0) {
      return false;
    } else if (tab == 1 && !isEmpty(searchFilterData) && get(searchFilterData, 'fileServerResult') && searchFilterData?.fileServerResult?.length > 0) {
      return false;
    } else {
      return true;
    }
  }, [searchFilterData, tab])
  const isOverLimit = useMemo(() => {
    if(exportVal === 'selected' && selectedFiles?.length > maxResultsToExport){
      return true;
    }else return false;
  }, [exportVal, maxResultsToExport, selectedFiles?.length])
  return (
    <>
      <SuccessModal maxResultsToExport={maxResultsToExport} isOverLimit={isOverLimit} openSuccess={openSuccess} close={() => setOpenSuccess(false)} />
      {/* <AlertDialog openAlert={searchMqError ? true : false} type="error" message={searchMqError} /> */}
      <Snackbar
        open={searchMqError}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={() => dispatch({
          type: SEARCH_MQ_ALERT_CLOSE,
        })}
        sx={{ padding: '30px' }}
        bodyStyle={{ height: 'auto', lineHeight: '28px', padding: 24, whiteSpace: 'pre-line' }}
      >
        <Alert severity="error">{searchMqErrorMessage}</Alert>
      </Snackbar>
      <Snackbar
        open={searchSucess}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={() => dispatch({
          type: SEARCH_MQ_ALERT_CLOSE,
        })}
        sx={{ padding: '30px' }}
        bodyStyle={{ height: 'auto', lineHeight: '28px', padding: 24, whiteSpace: 'pre-line' }}
      >
        <Alert severity='success'>{'Successfully Added Results to the Queue'}</Alert>
      </Snackbar>
      <Page>
        <Card>
          <Accordion sx={{ p: '5px 20px' }} expanded={open} onChange={(ev, expanded) => setOpen(expanded)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography variant="h6">Search Criteria</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item xs={12} md={7}>
                  <Stack direction="row">
                    <FormControlLabel
                      control={<Checkbox checked={searchDetails.repository} defaultChecked />}
                      label="Repository"
                      onChange={(e) => setSearchDetails({ ...searchDetails, repository: e.target.checked })}
                    />
                    <FormControlLabel
                      control={<Checkbox checked={searchDetails.fileServer} />}
                      label="File Servers"
                      onChange={(e) => setSearchDetails({ ...searchDetails, fileServer: e.target.checked })}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  {keywordArray.length && !keywordToken ? (
                    <Stack direction="row" sx={{ flexWrap: 'wrap' }}>
                      {keywordArray.map((keyword, i) => (
                        <Stack
                          direction="row"
                          key={i}
                          className={
                            excludeKeywords.includes(keyword)
                              ? 'red-border'
                              : keyword !== 'AND' && keyword !== 'OR'
                                ? 'green-border'
                                : 'no-border'
                          }
                          sx={{ backgroundColor: '#dddddd', borderRadius: '10px', p: '5px 10px', m: '5px' }}
                        >
                          <Typography>{keyword}</Typography>
                          <ArrowDropDownIcon
                            aria-controls={openMenu ? `menu${i}` : undefined}
                            aria-haspopup="true"
                            aria-expanded={openMenu ? 'true' : undefined}
                            onClick={(e) => handleClick(e, i)}
                            sx={{ cursor: 'pointer' }}
                          />
                          {keyword !== 'AND' && keyword !== 'OR' && (
                            <CloseIcon
                              sx={{ color: '#ef4545', cursor: 'pointer' }}
                              onClick={() => handleDeleteKeyword(i)}
                            />
                          )}

                          <Menu
                            id={`menu${currentIndex}`}
                            anchorEl={anchorEl}
                            open={openMenu}
                            onClose={handleClose}
                            MenuListProps={{
                              'aria-labelledby': 'basic-button',
                            }}
                          >
                            {keywordArray[currentIndex] != 'AND' && keywordArray[currentIndex] != 'OR' && (
                              <div>
                                <MenuItem onClick={() => handleEditKeyword()}>Edit</MenuItem>
                                <MenuItem onClick={() => handleInclude()}>Include</MenuItem>
                                <MenuItem onClick={() => handleExclude()}>Exclude</MenuItem>
                              </div>
                            )}
                            {(keywordArray[currentIndex] == 'AND' || keywordArray[currentIndex] == 'OR') && (
                              <div>
                                <MenuItem onClick={() => handleAndType()}>AND</MenuItem>
                                <MenuItem onClick={() => handleOrType()}>OR</MenuItem>
                              </div>
                            )}
                          </Menu>
                        </Stack>
                      ))}
                    </Stack>
                  ) : null}
                </Grid>
                <Grid item xs={12} md={5}>
                  {fileKeywordArray.length && !fileToken ? (
                    <Stack direction="row" sx={{ flexWrap: 'wrap' }}>
                      {fileKeywordArray.map((keyword, i) => (
                        <Stack
                          key={i}
                          direction="row"
                          className={
                            excludeFileKeywords.includes(keyword)
                              ? 'red-border'
                              : keyword !== 'AND' && keyword !== 'OR'
                                ? 'green-border'
                                : 'no-border'
                          }
                          sx={{ backgroundColor: '#dddddd', borderRadius: '10px', p: '5px 10px', m: '5px' }}
                        >
                          <Typography>{keyword}</Typography>
                          <ArrowDropDownIcon
                            aria-controls={openMenu ? `menu${i}` : undefined}
                            aria-haspopup="true"
                            aria-expanded={openMenu ? 'true' : undefined}
                            onClick={(e) => handleFileClick(e, i)}
                            sx={{ cursor: 'pointer' }}
                          />
                          {keyword !== 'AND' && keyword !== 'OR' && (
                            <CloseIcon
                              sx={{ color: '#ef4545', cursor: 'pointer' }}
                              onClick={() => handleDeleteFileKeyword(i)}
                            />
                          )}

                          <Menu
                            id={`menu${currentIndex}`}
                            anchorEl={anchorElFile}
                            open={openFileMenu}
                            onClose={() => setAnchorElFile(null)}
                            MenuListProps={{
                              'aria-labelledby': 'basic-button',
                            }}
                          >
                            {fileKeywordArray[currentFileIndex] != 'AND' && fileKeywordArray[currentFileIndex] != 'OR' && (
                              <div>
                                <MenuItem onClick={() => handleFileEditKeyword()}>Edit</MenuItem>
                                <MenuItem onClick={() => handleFileInclude()}>Include</MenuItem>
                                <MenuItem onClick={() => handleFileExclude()}>Exclude</MenuItem>
                              </div>
                            )}
                            {(fileKeywordArray[currentFileIndex] == 'AND' ||
                              fileKeywordArray[currentFileIndex] == 'OR') && (
                                <div>
                                  <MenuItem onClick={() => handleFileAndType()}>AND</MenuItem>
                                  <MenuItem onClick={() => handleFileOrType()}>OR</MenuItem>
                                </div>
                              )}
                          </Menu>
                        </Stack>
                      ))}
                    </Stack>
                  ) : null}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack direction="row" gap={1}>
                    <TextField
                      fullWidth
                      name="keyWords"
                      value={searchDetails.keyWords}
                      variant="standard"
                      label="Content keywords"
                      onKeyDown={(e) => {
                        if (!keywordToken && e.target.value) {
                          if (e.key === 'Enter' && !keywordArray.includes(e.target.value)) {
                            if (keywordArray.length) {
                              keywordArray.push('AND');
                              setKeywordArray([...keywordArray, e.target.value]);
                              setSearchDetails({ ...searchDetails, keyWords: '' });
                            } else {
                              setKeywordArray([...keywordArray, e.target.value]);
                              setSearchDetails({ ...searchDetails, keyWords: '' });
                            }
                          }
                        }
                      }}
                      onChange={(e) => setSearchDetails({ ...searchDetails, keyWords: e.target.value })}
                    />
                    <FormControlLabel
                      control={<Checkbox checked={keywordToken} />}
                      onChange={(e) => setKeywordToken(e.target.checked)}
                      label="Disable Tokens"
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack direction="row" gap={1}>
                    <TextField
                      fullWidth
                      variant="standard"
                      value={searchDetails.fileNames[0]}
                      label="File Name"
                      onKeyDown={(e) => {
                        if (!fileToken && e.target.value) {
                          if (e.key === 'Enter' && !fileKeywordArray.includes(e.target.value)) {
                            if (fileKeywordArray.length) {
                              fileKeywordArray.push('AND');
                              setFileKeywordArray([...fileKeywordArray, e.target.value]);
                              setSearchDetails({ ...searchDetails, fileNames: [''] });
                            } else {
                              setFileKeywordArray([...fileKeywordArray, e.target.value]);
                              setSearchDetails({ ...searchDetails, fileNames: [''] });
                            }
                          }
                        }
                      }}
                      onChange={(e) => setSearchDetails({ ...searchDetails, fileNames: [e.target.value] })}
                    />
                    <FormControlLabel
                      control={<Checkbox checked={fileToken} />}
                      onChange={(e) => setFileToken(e.target.checked)}
                      label="Disable Tokens"
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Button variant="contained" onClick={() => setAddFilter(!addFilter)}>
                    Add Filter
                  </Button>
                </Grid>
                <Grid item xs={12} md={12}>
                  {addFilter ? (
                    <Box sx={{ border: '1px solid #f2f3f5', p: '20px', borderRadius: '10px' }}>
                      <Stack direction="row" alignItems="center">
                        <Button onClick={() => setIsModified(!isModified)}>
                          {isModified ? <RemoveIcon /> : <AddIcon />}
                        </Button>
                        <Typography>Modified Date Filter</Typography>
                      </Stack>
                      <Stack direction="row" alignItems="center">
                        <Button onClick={() => setIsAccessed(!isAccessed)}>
                          {isAccessed ? <RemoveIcon /> : <AddIcon />}
                        </Button>
                        <Typography>Last Accessed Date Filter</Typography>
                      </Stack>
                      <Stack direction="row" alignItems="center">
                        <Button onClick={() => setIsCreated(!isCreated)}>
                          {isCreated ? <RemoveIcon /> : <AddIcon />}
                        </Button>
                        <Typography>Create Date Filter</Typography>
                      </Stack>
                      <Stack direction="row" alignItems="center">
                        <Button onClick={() => setIsArchived(!isArchived)}>
                          {isArchived ? <RemoveIcon /> : <AddIcon />}
                        </Button>
                        <Typography>Archived Date Filter</Typography>
                      </Stack>
                      <Stack direction="row" alignItems="center">
                        <Button onClick={() => setIsMetaData(!isMetaData)}>
                          {isMetaData ? <RemoveIcon /> : <AddIcon />}
                        </Button>
                        <Typography>Meta Data</Typography>
                      </Stack>
                      <Stack direction="row" alignItems="center">
                        <Button onClick={() => setIsPath(!isPath)}>{isPath ? <RemoveIcon /> : <AddIcon />}</Button>
                        <Typography>Path Filter</Typography>
                      </Stack>
                    </Box>
                  ) : null}
                </Grid>
                {isPath ? (
                  <Grid item xs={12} md={12}>
                    <TextField
                      fullWidth
                      variant="standard"
                      label="Search Path"
                      value={searchDetails.searchPaths[0]}
                      onChange={(e) => setSearchDetails({ ...searchDetails, searchPaths: [e.target.value] })}
                    />
                  </Grid>
                ) : null}
                <Grid item xs={12} md={12}>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography>File Size : </Typography>
                    <Checkbox checked={isFileSize} onChange={() => setIsFileSize(!isFileSize)} />
                    <Typography>Start Size</Typography>
                    <Slider
                      defaultValue={0}
                      aria-label="Default"
                      // min={10}
                      value={startSize}
                      max={1024}
                      size="small"
                      valueLabelDisplay="auto"
                      sx={{ width: '200px' }}
                      onChange={(e) => {
                        setIsFileSize(true);
                        setStartSize(e.target.value);
                      }}
                    />
                    <Typography>End Size</Typography>
                    <Slider
                      defaultValue={0}
                      aria-label="Default"
                      value={endSize}
                      max={1024}
                      size="small"
                      valueLabelDisplay="auto"
                      sx={{ width: '200px' }}
                      onChange={(e) => {
                        setIsFileSize(true);
                        setEndSize(e.target.value);
                      }}
                    />
                    <Typography>
                      Between {startSize} and {endSize}
                    </Typography>
                    <Select
                      variant="standard"
                      value={dataSize}
                      sx={{ width: '100px' }}
                      onChange={(e) => setDataSize(e.target.value)}
                    >
                      <MenuItem value="KB">KB</MenuItem>
                      <MenuItem value="MB">MB</MenuItem>
                      <MenuItem value="GB">GB</MenuItem>
                    </Select>
                  </Stack>
                </Grid>
                {isModified ? (
                  <>
                    <Grid item xs={12} md={2}>
                      <Typography>Date Modified : </Typography>
                    </Grid>
                    <Grid item xs={12} md={10}>
                      <Stack direction="row" alignItems="center" justifyContent="space-between" gap={4}>
                        <FormControl fullWidth>
                          <InputLabel id="select-label">Select Modified Date Range</InputLabel>
                          <Select
                            labelId="select-label"
                            id="select"
                            variant="standard"
                            value={modifiedDateRangeSelect}
                            defaultValue={30}
                            label="Select Modified Date Range"
                            onChange={modifiedRange}
                          >
                            {dateRangeArray.map((range) => (
                              <MenuItem value={range.days}>{range.name}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <TextField
                          fullWidth
                          variant="standard"
                          value={modifiedStart ? moment(modifiedStart).format('YYYY-MM-DD') : ' '}
                          type="date"
                          label="Choose a Date From"
                          onChange={(e) => {
                            setModifiedStart(e.target.value);
                            setModifiedDateRangeSelect(10);
                          }}
                        />
                        <TextField
                          fullWidth
                          variant="standard"
                          value={modifiedEnd ? moment(modifiedEnd).format('YYYY-MM-DD') : ' '}
                          type="date"
                          label="Choose a Date To"
                          InputProps={{ inputProps: { min: moment(modifiedStart).format('YYYY-MM-DD') } }}
                          onChange={(e) => {
                            setModifiedEnd(e.target.value);
                            setModifiedDateRangeSelect(10);
                          }}
                        />
                      </Stack>
                    </Grid>
                  </>
                ) : null}

                {isCreated ? (
                  <>
                    <Grid item xs={12} md={2}>
                      <Typography>Date Created : </Typography>
                    </Grid>
                    <Grid item xs={12} md={10}>
                      <Stack direction="row" alignItems="center" justifyContent="space-between" gap={4}>
                        <FormControl fullWidth>
                          <InputLabel id="select-label">Select Modified Date Range</InputLabel>
                          <Select
                            labelId="select-label"
                            id="select"
                            variant="standard"
                            value={createdDateRangeSelect}
                            defaultValue={30}
                            label="Select Modified Date Range"
                            onChange={createdRange}
                          >
                            {dateRangeArray.map((range) => (
                              <MenuItem value={range.days}>{range.name}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <TextField
                          fullWidth
                          variant="standard"
                          value={createdStart ? moment(createdStart).format('YYYY-MM-DD') : ' '}
                          type="date"
                          label="Choose a Date From"
                          onChange={(e) => {
                            setCreatedStart(e.target.value);
                            setCreatedDateRangeSelect(10);
                          }}
                        />
                        <TextField
                          fullWidth
                          variant="standard"
                          value={createdEnd ? moment(createdEnd).format('YYYY-MM-DD') : ' '}
                          type="date"
                          label="Choose a Date To"
                          InputProps={{ inputProps: { min: moment(createdStart).format('YYYY-MM-DD') } }}
                          onChange={(e) => {
                            setCreatedEnd(e.target.value);
                            setCreatedDateRangeSelect(10);
                          }}
                        />
                      </Stack>
                    </Grid>
                  </>
                ) : null}
                {isAccessed ? (
                  <>
                    <Grid item xs={12} md={2}>
                      <Typography>Date Accessed : </Typography>
                    </Grid>
                    <Grid item xs={12} md={10}>
                      <Stack direction="row" alignItems="center" justifyContent="space-between" gap={4}>
                        <FormControl fullWidth>
                          <InputLabel id="select-label">Select Modified Date Range</InputLabel>
                          <Select
                            labelId="select-label"
                            id="select"
                            variant="standard"
                            value={accessedDateRangeSelect}
                            defaultValue={30}
                            label="Select Modified Date Range"
                            onChange={accessedRange}
                          >
                            {dateRangeArray.map((range) => (
                              <MenuItem value={range.days}>{range.name}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <TextField
                          fullWidth
                          variant="standard"
                          value={accessedStart ? moment(accessedStart).format('YYYY-MM-DD') : ' '}
                          type="date"
                          label="Choose a Date From"
                          onChange={(e) => {
                            setAccessedStart(e.target.value);
                            setAccessedDateRangeSelect(10);
                          }}
                        />
                        <TextField
                          fullWidth
                          variant="standard"
                          value={accessedEnd ? moment(accessedEnd).format('YYYY-MM-DD') : ' '}
                          type="date"
                          label="Choose a Date To"
                          InputProps={{ inputProps: { min: moment(accessedStart).format('YYYY-MM-DD') } }}
                          onChange={(e) => {
                            setAccessedEnd(e.target.value);
                            setAccessedDateRangeSelect(10);
                          }}
                        />
                      </Stack>
                    </Grid>
                  </>
                ) : null}
                {isArchived ? (
                  <>
                    <Grid item xs={12} md={2}>
                      <Typography>Date Archived : </Typography>
                    </Grid>
                    <Grid item xs={12} md={10}>
                      <Stack direction="row" alignItems="center" justifyContent="space-between" gap={4}>
                        <FormControl fullWidth>
                          <InputLabel id="select-label">Select Modified Date Range</InputLabel>
                          <Select
                            labelId="select-label"
                            id="select"
                            variant="standard"
                            value={archivedDateRangeSelect}
                            defaultValue={30}
                            label="Select Modified Date Range"
                            onChange={archivedRange}
                          >
                            {dateRangeArray.map((range) => (
                              <MenuItem value={range.days}>{range.name}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <TextField
                          fullWidth
                          variant="standard"
                          value={archivedStart ? moment(archivedStart).format('YYYY-MM-DD') : ' '}
                          type="date"
                          label="Choose a Date From"
                          onChange={(e) => {
                            setArchivedStart(e.target.value);
                            setArchivedDateRangeSelect(10);
                          }}
                        />
                        <TextField
                          fullWidth
                          variant="standard"
                          value={archivedEnd ? moment(archivedEnd).format('YYYY-MM-DD') : ' '}
                          type="date"
                          label="Choose a Date To"
                          InputProps={{ inputProps: { min: moment(archivedStart).format('YYYY-MM-DD') } }}
                          onChange={(e) => {
                            setArchivedEnd(e.target.value);
                            setArchivedDateRangeSelect(10);
                          }}
                        />
                      </Stack>
                    </Grid>
                  </>
                ) : null}
                {isMetaData ? (
                  <Grid item xs={12} md={12}>
                    <TextField
                      fullWidth
                      variant="standard"
                      value={searchDetails?.metaDataText}
                      label="Meta Data"
                      onChange={(e) => setSearchDetails({ ...searchDetails, metaDataText: e.target.value })}
                    />
                  </Grid>
                ) : null}
                <Grid item xs={12} md={12}>
                  <Button
                    variant="contained"
                    onClick={() => handleSearch(true)}
                    disabled={searchDetails.keyWords || searchDetails.fileNames.length ? false : true}
                  >
                    Search
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleReset}
                    sx={{ ml: '20px' }}
                  // disabled={searchDetails.keyWords || searchDetails.fileNames.length ? false : true}
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Card>

        <Card sx={{ p: '40px', my: '40px' }}>
          <ExportModal
            openModal={openExportModal}
            close={() => setOpenExportModal(false)}
            exportValues={[exportVal, setExportVal]}
            handleExports={handleExports}
          />
          <ExportFilesModal
            nextDetails={nextDetails}
            keywordResult={keywordResult}
            fileNameResult={fileNameResult}
            openModal={openExportFilesModal}
            close={() => setOpenExportFilesModal(false)}
          />
          <CompressModal
            openModal={compressModal}
            close={() => setCompressModal(false)}
            downloadCompressed={downloadCompressed}
            selectedFiles={selectedFiles}
          />
          <RestoreModal
            openModal={openRestoreModal}
            close={() => setOpenRestoreModal(false)}
            fileProperties={fileProperties}
          />
          <QueueModal
            openModal={isQueueModalOpen}
            close={closeQueueModal}
            handleSubmit={handleSearchMq}
            isLoading={searchMqLoading}
            queue={queue}
            setQueue={setQueue}
          />
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Repository" sx={{ px: 3 }} />
              <Tab label="File Server" sx={{ px: 3 }} />
              <Tab label="Properties" sx={{ px: 3 }} />
              <Tab label="Search History" sx={{ px: 3 }} />
              <Tab label="Similar Document" sx={{ px: 3 }} />
              <Tab label="Export" sx={{ px: 3 }} />
            </Tabs>
          </Box>
          <Box /* hidden={tab !== 0 && tab !== 1} */ sx={{ my: '20px' }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              {(tab === 0 || tab === 1) && (
                <Stack sx={{ mt: '20px' }}>
                  <Typography>
                    Found {searchFilterData.totalRepoResultCount ? searchFilterData.totalRepoResultCount : 0} results
                    from Archive Repository.
                  </Typography>
                  <Typography>
                    Found{' '}
                    {searchFilterData.totalFileServerResultCount ? searchFilterData.totalFileServerResultCount : 0}{' '}
                    results from FileServers.
                  </Typography>
                  <Typography>Search time {responseTime} Second(s)</Typography>
                </Stack>
              )}
              {tab === 4 && (
                <Stack sx={{ mt: '20px' }}>
                  <Typography>
                    Found {similarDoc.totalDocuments ? similarDoc.totalDocuments : 0} results from Archive Repository.
                  </Typography>
                  <Typography>Search time {responseTime} Second(s)</Typography>
                </Stack>
              )}

              {tab === 5 && <Stack></Stack>}
              <Stack direction="row" gap={2}>
                {roleAccess?.length ? (
                  roleAccess.map((data) => (
                    <>
                      {tab === 0 &&
                        data.name == 'eDiscovery.backEndExport' &&
                        ((rolename == 'LDAP' && data.ldapAllowed) ||
                          (rolename == 'PRIVILEGED' && data.privAllowed) ||
                          (rolename == 'ADMINISTRATOR' && data.adminAllowed)) && (
                          <Button variant="contained" onClick={() => setOpenExportModal(true)}>
                            Backend Export(All)
                          </Button>
                        )}
                      {tab === 0 &&
                        selectedFiles.length !== 0 &&
                        data.name == 'eDiscovery.download' &&
                        ((rolename == 'LDAP' && data.ldapAllowed) ||
                          (rolename == 'PRIVILEGED' && data.privAllowed) ||
                          (rolename == 'ADMINISTRATOR' && data.adminAllowed)) && (
                          <Button variant="contained" onClick={downloadSelected}>
                            Download Selected
                          </Button>
                        )}
                      {tab === 0 &&
                        selectedFiles.length !== 0 &&
                        data.name == 'eDiscovery.download' &&
                        ((rolename == 'LDAP' && data.ldapAllowed) ||
                          (rolename == 'PRIVILEGED' && data.privAllowed) ||
                          (rolename == 'ADMINISTRATOR' && data.adminAllowed)) && (
                          <Button variant="contained" onClick={() => setCompressModal(true)}>
                            Compress & Download
                          </Button>
                        )}
                      {(tab === 0 || tab === 1) &&
                        !isEmpty(searchFilterData) &&
                        data.name == 'eDiscovery.exportToCsv' &&
                        ((rolename == 'LDAP' && data.ldapAllowed) ||
                          (rolename == 'PRIVILEGED' && data.privAllowed) ||
                          (rolename == 'ADMINISTRATOR' && data.adminAllowed)) && (
                          <Button sx={{ minWidth: '181px' }} disabled={isCsvButtonDisabled} onClick={exportResultToCSV} variant="contained" >
                            {csvExportLoading ? <CircularProgress size={30} color='warning' /> : "Export Results to CSV"}
                          </Button>
                        )}
                      {tab === 0 &&
                        data.name == 'eDiscovery.exportToNetwork' &&
                        ((rolename == 'LDAP' && data.ldapAllowed) ||
                          (rolename == 'PRIVILEGED' && data.privAllowed) ||
                          (rolename == 'ADMINISTRATOR' && data.adminAllowed)) && (
                          <Button variant="contained" onClick={() => setOpenExportFilesModal(true)}>
                            Export Results to network
                          </Button>
                        )}
                    </>
                  ))
                ) : (
                  <>
                    {tab === 0 && (
                      <Button variant="contained" onClick={() => setOpenExportModal(true)}>
                        Backend Export(All)
                      </Button>
                    )}
                    {tab === 0 && selectedFiles.length !== 0 && (
                      <Button variant="contained" onClick={downloadSelected}>
                        Download Selected
                      </Button>
                    )}
                    {tab === 0 && selectedFiles.length !== 0 && (
                      <Button variant="contained" onClick={() => setCompressModal(true)}>
                        Compress & Download
                      </Button>
                    )}
                    {(tab === 0 || tab === 1) && !isEmpty(searchFilterData) && (
                      <Button sx={{ minWidth: '181px' }} disabled={isCsvButtonDisabled} variant="contained" onClick={exportResultToCSV}>
                        {csvExportLoading ? <CircularProgress size={30} color='warning' /> : "Export Results to CSV"}
                      </Button>
                    )}
                    {tab === 0 && (
                      <Button variant="contained" onClick={() => setOpenExportFilesModal(true)}>
                        Export Results to network
                      </Button>
                    )}
                  </>
                )}
                {tab === 5 && (
                  <Button variant="contained" onClick={handleRefresh}>
                    Refresh
                  </Button>
                )}
                {tab === 1 && !isEmpty(searchFilterData) && searchDetails.fileServer && (
                  <Button variant="contained" onClick={openQueueModal}>
                    Add Results to Process Queue
                  </Button>
                )}
              </Stack>
            </Stack>
          </Box>
          <Box hidden={tab !== 0}>
            <PathTable
              handleSearchAPI={handleSearch}
              handleSearchData={[searchDetails, setSearchDetails]}
              rows={searchFilterData}
              columns={repoColumns}
              showProperties={showProperties}
              handleSelectedFiles={handleSelectedFiles}
              selectAllFiles={selectAllFiles}
              selectedFiles={selectedFiles}
              pages={[page, setPage]}
              pageRows={[rowsPerPage, setRowsPerPage]}
              searchLoading={searchLoading}
            />
          </Box>
          <Box hidden={tab !== 1}>
            <FileTable
              rows={searchFilterData}
              columns={fileColumns}
              showProperties={showProperties}
              handleSearchAPI={handleSearch}
              handleSearchData={[searchDetails, setSearchDetails]}
              pages={[page, setPage]}
              pageRows={[rowsPerPage, setRowsPerPage]}
            />
          </Box>
          <Box hidden={tab !== 4}>
            <SimilarDocTable
              rows={similarDoc}
              columns={similarColumns}
              showProperties={showProperties}
              pages={[similarDocPage, setSimilarDocPage]}
              pageRows={[similarDocPerPage, setSimilarDocPerPage]}
            />
          </Box>
          <Box hidden={tab !== 3}>
            <UserSearchHistory
              rows={userHistoryData}
              pages={[historyPage, setHistoryPage]}
              pageRows={[historyPerPage, setHistoryPerPage]}
              handleRerun={handleRerun}
            />
          </Box>
          <Box hidden={tab !== 2}>
            <PropertiesTable
              fileProperties={fileProperties}
              selectedPropertyType={selectedPropertyType}
              fileServerUuid={fileServerUuid}
              findSimilarDoc={findSimilarDoc}
              checkUuid={checkUuid}
              setOpenRestoreModal={setOpenRestoreModal}
              roleAccess={roleAccess}
              rolename={rolename}
            />
          </Box>
          <Box hidden={tab !== 5}>
            <ExportTable columns={exportColumns} rows={userInfo} />
          </Box>
        </Card>
      </Page>
    </>
  );
};

export default SearchCriteria;
