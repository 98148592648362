import {  GetDetails, PostDetails, putDetails, postInfo } from 'src/utils/fetchServices';
import { ERROR_ALERT, PROGRESS_DONE, PROGRESS_START, SUCCESS_ALERT } from 'src/redux/reducers/alertReducer';
export const LICENSE_FETCH_SUCCESS = 'LICENSE_FETCH_SUCCESS';
export const LICENSE2_FETCH_SUCCESS = 'LICENSE2_FETCH_SUCCESS';
export const LICENSE_ID_FETCH_SUCCESS = 'LICENSE_ID_FETCH_SUCCESS';
export const GENERATE_LICENSE_SUCCESS = 'GENERATE_LICENSE_SUCCESS';
export const REQUEST_LICENSE_SUCCESS = 'REQUEST_LICENSE_SUCCESS';
export const DATA_LOADER = 'DATA_LOADER';
export const FETCH_FAIL = 'FETCH_FAIL';


export const getLicenseDetails = () => async (dispatch) => {
    dispatch({
      type: DATA_LOADER,
    });
    try {
      const response = await GetDetails({ url: 'license'});
      const data = response.data;
      dispatch({
        type: LICENSE_FETCH_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_FAIL,
        payload: error.message,
      });
    }
  };

export const getLicenseDetails2 = () => async (dispatch) => {
    dispatch({
      type: DATA_LOADER,
    });
    try {
      const response = await GetDetails({ url: 'system-codes/application-license'});
      const data = response.data;
      dispatch({
        type: LICENSE2_FETCH_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_FAIL,
        payload: error.message,
      });
    }
  };


export const sendLicenseDetails2 = (body) => async (dispatch) => {
    dispatch({
      type: DATA_LOADER,
    });
    try {
      const response = await postInfo({ url: 'system-codes/application-license', body}, dispatch);
      if(response && response.status === 200){
        const data = response.data;
        dispatch({
          type: LICENSE2_FETCH_SUCCESS,
          payload: data,
        });
        dispatch({
          type: SUCCESS_ALERT,
          payload:'License Updated Successfully',
        });
      } else{
        dispatch({type: FETCH_FAIL});
      }
    
    } catch (error) {
      dispatch({
        type: FETCH_FAIL,
        payload: error.message,
      });
    }
  };
export const getLicenseId = (body) => async (dispatch) => {
    dispatch({
      type: DATA_LOADER,
    });
    try {
      const response = await postInfo({ url: 'license', body, method: "PUT"}, dispatch);
      if(response && response.status === 200){
        const data = response.data;
        dispatch({
          type: LICENSE_ID_FETCH_SUCCESS,
          payload: data,
        });
        dispatch({
          type: SUCCESS_ALERT,
          payload:'Applied License Successfully',
        });
      } else{
        dispatch({type: FETCH_FAIL});
      }
    
    } catch (error) {
      dispatch({
        type: FETCH_FAIL,
        payload: error.message,
      });
    }
  };
  
export const requestLicense = (body, params) => async (dispatch) => {
    // dispatch({
    //   type: DATA_LOADER,
    // });
    dispatch({
      type: PROGRESS_START,
    });
    try {
      const response = await postInfo({ url: 'license', body, params}, dispatch);
      if(response && response.status === 200){
        const data = response.data;
        dispatch({
          type: REQUEST_LICENSE_SUCCESS,
          payload: data,
        });
        dispatch({
          type: SUCCESS_ALERT,
          payload:'Request for License has been recieved',
        });
      } else{
        dispatch({type: FETCH_FAIL});
      }
     
    } catch (error) {
      dispatch({
        type: FETCH_FAIL,
        payload: error.message,
      });
    }
    dispatch({
      type: PROGRESS_DONE,
    });
  };

export const generateLicense = (body, params) => async (dispatch) => {
  dispatch({
    type: PROGRESS_START,
  });
  try {
    const response = await postInfo({ url: 'license', body, params}, dispatch);
    if(response && response.status === 200){
      const data = response.data;
      dispatch({
        type: GENERATE_LICENSE_SUCCESS,
        payload: data,
      });
      dispatch({
        type: SUCCESS_ALERT,
        payload:'Please contact your reseller/vendor to request a license',
      });
    } else{
      dispatch({type: FETCH_FAIL});
    }
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
  dispatch({
    type: PROGRESS_DONE,
  });
};