// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  top: getIcon('dashboard'),
  config: getIcon('ic_config'),
  license: getIcon('ic_license'),
  systemConfig: getIcon('ic_system-config'),
};

const navConfig = [
  {
    subheader: 'Application',
    items: [
      {
        title: 'System',
        id: 'system',
        isAdmin: 'true',
        isSuperAdmin: 'true',
        icon: ICONS.dashboard,
        children: [
          {
            title: 'System Status',
            path: PATH_DASHBOARD.system.systemStatus,
            id: 'systemstatus',
            isAdmin: 'true',
            isSuperAdmin: 'false',
          },
          // {
          //   title: 'analytics',
          //   path: PATH_DASHBOARD.system.analytics,
          //   id: 'analytics',
          //   isAdmin: 'true',
          //   isSuperAdmin: 'false',
          // },
          {
            title: 'Repository Report',
            path: PATH_DASHBOARD.system.repositoryReport,
            id: 'repository-reports',
            isAdmin: 'true',
            isSuperAdmin: 'false',
          },
          {
            title: 'Job Status',
            path: PATH_DASHBOARD.system.jobStatus,
            id: 'jobstatus',
            isAdmin: 'true',
            isSuperAdmin: 'false',
          },
          {
            title: 'Jobs Status',
            path: PATH_DASHBOARD.system.jobsStatus,
            id: 'jobsstatus',
            isAdmin: 'false',
            isSuperAdmin: 'true',
          },
          {
            title: 'File Explorer',
            path: PATH_DASHBOARD.system.fileExplorer,
            id: 'fileexplorer',
            isAdmin: 'true',
            isSuperAdmin: 'false',
          },
          {
            title: 'e-Discovery',
            path: PATH_DASHBOARD.system.ediscovery,
            id: 'searchfile',
            isAdmin: 'true',
            isSuperAdmin: 'false',
          },
          {
            title: 'Analytics - Restricted by Path',
            path: PATH_DASHBOARD.system.analyticsByPath,
            id: 'chartanalysis',
            isAdmin: 'true',
            isSuperAdmin: 'false',
          },
          {
            title: 'Audit Access Log',
            path: PATH_DASHBOARD.system.auditAccess,
            id: 'auditaccesslog',
            isAdmin: 'true',
            isSuperAdmin: 'false',
          },
          {
            title: 'Audit Login Log',
            path: PATH_DASHBOARD.system.auditLogin,
            id: 'auditloginlog',
            isAdmin: 'true',
            isSuperAdmin: 'false',
          },
          {
            title: 'Deletion Requests',
            path: PATH_DASHBOARD.system.deleteCodes,
            id: 'deletecodes',
            isAdmin: 'true',
            isSuperAdmin: 'false',
          },
          {
            title: 'Application Event Log',
            path: PATH_DASHBOARD.system.applicationEvent,
            id: 'user-activity',
            isAdmin: 'true',
            isSuperAdmin: 'false',
          },
          {
            title: 'Priv User Search History',
            path: PATH_DASHBOARD.system.privUserHistory,
            id: 'auditloginlog',
            isAdmin: 'true',
            isSuperAdmin: 'false',
          },
        ],
      },
      {
        title: 'Analytics',
        id: 'analytics',
        isAdmin: 'true',
        isSuperAdmin: 'false',
        icon: ICONS.analytics,
        children: [
          {
            title: 'Data Patterns & Policy Tool',
            path: PATH_DASHBOARD.analytics.Analytics,
            id: 'analyticsData',
            isAdmin: 'true',
            isSuperAdmin: 'false',
          },
          {
            title: 'Duplicate Data Chart',
            path: PATH_DASHBOARD.analytics.DuplicateDataChart,
            id: 'DuplicateDataChart',
            isAdmin: 'true',
            isSuperAdmin: 'false',
          },
          {
            title: 'Analyzed Share Volume(s)',
            path: PATH_DASHBOARD.analytics.AnalyzedShareVolume,
            id: 'AnalyzedShareVolume',
            isAdmin: 'true',
            isSuperAdmin: 'false',
          },
          {
            title: 'FileTypes by Volume',
            path: PATH_DASHBOARD.analytics.VolumebyFileType,
            id: 'VolumebyFileType',
            isAdmin: 'true',
            isSuperAdmin: 'false',
          },
          {
            title: 'FileShare Charts (Modified)',
            path: PATH_DASHBOARD.analytics.FileShareChartsbyDateModified,
            id: 'FileShareChartsbyDateModified',
            isAdmin: 'false',
            isSuperAdmin: 'true',
          },
          {
            title: 'FileShare Charts (Accessed)',
            path: PATH_DASHBOARD.analytics.FileShareChartsbyDateAccessed,
            id: 'FileShareChartsbyDateAccessed',
            isAdmin: 'true',
            isSuperAdmin: 'false',
          },
          {
            title: 'Largest Folder(s) by Vol',
            path: PATH_DASHBOARD.analytics.LargestfolderbySize,
            id: 'LargestfolderbySize',
            isAdmin: 'true',
            isSuperAdmin: 'false',
          },
        ],
      },
      {
        title: 'Configuration',
        id: 'settings',
        isAdmin: 'true',
        isSuperAdmin: 'true',
        icon: ICONS.config,
        children: [
          {
            title: 'Job Management',
            path: PATH_DASHBOARD.configuration.jobManagement,
            id: 'jobmanagement',
            isAdmin: 'true',
            isSuperAdmin: 'false',
          },
          {
            title: 'Company Management',
            path: PATH_DASHBOARD.configuration.companyList,
            id: 'companymanagement',
            isAdmin: 'false',
            isSuperAdmin: 'true',
          },
          {
            title: 'Policy Management',
            path: PATH_DASHBOARD.configuration.policyManagement,
            id: 'policymanagement',
            isAdmin: 'true',
            isSuperAdmin: 'false',
          },
          {
            title: 'Document Classifier',
            path: PATH_DASHBOARD.configuration.documentClassifier,
            id: 'documentclassifier',
            isAdmin: 'true',
            isSuperAdmin: 'false',
          },
          {
            title: 'Archive Agent',
            path: PATH_DASHBOARD.configuration.archiveAgents,
            id: 'archiveagents',
            isAdmin: 'true',
            isSuperAdmin: 'true',
          },
          {
            title: 'Volume Management',
            path: PATH_DASHBOARD.configuration.volumeManagement,
            id: 'volumemanagement',
            isAdmin: 'true',
            isSuperAdmin: 'false',
          },
          {
            title: 'Stub Agent & Queues',
            path: PATH_DASHBOARD.configuration.stubAgent,
            id: 'stubagents',
            isAdmin: 'true',
            isSuperAdmin: 'false',
          },
        ],
      },
      {
        title: 'Users and Groups',
        id: 'usersandgroups',
        isAdmin: 'true',
        isSuperAdmin: 'false',
        icon: ICONS.user,
        children: [
          {
            title: 'User Management',
            path: PATH_DASHBOARD.userGroup.userManagement,
            id: 'usermanagement',
            isAdmin: 'true',
            isSuperAdmin: 'false',
          },
          {
            title: 'Account Policy',
            path: PATH_DASHBOARD.userGroup.accountPolicy,
            id: 'accountpolicy',
            isAdmin: 'true',
            isSuperAdmin: 'false',
          },
          {
            title: 'Company Management',
            path: PATH_DASHBOARD.userGroup.companyList,
            id: 'companymanagement',
            isAdmin: 'false',
            isSuperAdmin: 'false',
          },
        ],
      },
      {
        title: 'System Configuration',
        id: 'systemconfiguration ',
        isAdmin: 'true',
        isSuperAdmin: 'false',
        icon: ICONS.systemConfig,
        children: [
          {
            title: 'Global Configuration',
            path: PATH_DASHBOARD.systemConfiguration.globalConfiguration,
            id: 'globalconfiguration',
            isAdmin: 'true',
            isSuperAdmin: 'false',
          },
          {
            title: 'Role Permissions',
            path: PATH_DASHBOARD.systemConfiguration.rolePermissions,
            id: 'rolepermissions',
            isAdmin: 'true',
            isSuperAdmin: 'false',
          },
          {
            title: 'Build Info',
            path: PATH_DASHBOARD.systemConfiguration.buildInfo,
            id: 'buildinfo',
            isAdmin: 'true',
            isSuperAdmin: 'false',
          },
          {
            title: 'Custom Branding',
            path: PATH_DASHBOARD.systemConfiguration.customBranding,
            id: 'custombranding',
            isAdmin: 'true',
            isSuperAdmin: 'false',
          },
          {
            title: 'LDAP Configuration',
            path: PATH_DASHBOARD.systemConfiguration.ldspConfiguration,
            id: 'ldapconfiguration',
            isAdmin: 'true',
            isSuperAdmin: 'false',
          },
        ],
      },

      {
        title: 'Global Configuration',
        id: 'globalconfiguration ',
        isAdmin: 'false',
        isSuperAdmin: 'true',
        icon: ICONS.systemConfig,
        children: [
          {
            title: 'License',
            path: PATH_DASHBOARD.licenseConfig.license,
            id: 'license',
            isAdmin: 'false',
            isSuperAdmin: 'true',
          },
          {
            title: 'Build Info',
            path: PATH_DASHBOARD.systemConfiguration.buildInfo,
            id: 'buildinfo',
            isAdmin: 'false',
            isSuperAdmin: 'true',
          },
          {
            title: 'Custom Branding',
            path: PATH_DASHBOARD.systemConfiguration.customBranding,
            id: 'custombranding',
            isAdmin: 'false',
            isSuperAdmin: 'true',
          },
          {
            title: 'User Feature',
            path: PATH_DASHBOARD.systemConfiguration.userFeature,
            id: 'userfeatures',
            isAdmin: 'false',
            isSuperAdmin: 'true',
          },
        ],
      },
      {
        title: 'License Configuration',
        id: 'licenseconfiguration',
        isAdmin: 'true',
        isSuperAdmin: 'false',
        icon: ICONS.license,
        children: [
          {
            title: 'License',
            path: PATH_DASHBOARD.licenseConfig.license,
            id: 'license',
            isAdmin: 'true',
            isSuperAdmin: 'false',
          },
        ],
      },
    ],
  },
];

export default navConfig;
