import {
  Autocomplete,
  Button,
  Card,
  CardHeader,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import { ERROR_ALERT } from 'src/redux/reducers/alertReducer';
import { savePiiConfig, getPiiConfiguration } from '../globalActions';
import PiiFilePathsTable from './PiiFilePathsTable';

const columns = ['Path', 'Actions'];

const PiiConfiguration = ({ piiConfiguration, uncPaths }) => {
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const [piiConfig, setPiiConfig] = useState({});
  const [filePath, setFilePath] = useState('');
  const [filePathsData, setFilePathsData] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [pathIndex, setPathIndex] = useState();
  useEffect(() => {
    setPiiConfig(piiConfiguration);
    if (piiConfiguration.filePaths) {
      let paths = piiConfiguration.filePaths.split(',');
      setFilePathsData([...paths]);
    }
  }, [piiConfiguration]);
  const handleChange = (e) => {
    setPiiConfig({ ...piiConfig, [e.target.name]: e.target.value });
  };
  const handleExecutionChange = (e) => {
    setPiiConfig({ ...piiConfig, [e.target.name]: e.target.checked });
  };
  const handleAddPath = () => {
    if (filePath.indexOf('\\\\') !== -1) {
      if (filePathsData.indexOf(filePath) === -1) {
        setFilePathsData([...filePathsData, filePath]);
        setFilePath('');
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload: 'Path Already Exists',
        });
      }
    } else {
      dispatch({
        type: ERROR_ALERT,
        payload: 'Please enter a valid path',
      });
    }
  };
  const handleUpdatePath = () => {
    let data = filePathsData;
    if (filePath.indexOf('\\\\') !== -1) {
      if (filePathsData.indexOf(filePath) === -1) {
        data[pathIndex] = filePath;
        setFilePathsData([...data]);
        setIsEdit(false);
        setFilePath('');
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload: 'Path Already Exists',
        });
      }
    } else {
      dispatch({
        type: ERROR_ALERT,
        payload: 'Please enter a valid path',
      });
    }
  };

  const editPath = (row, i) => {
    setIsEdit(true);
    setPathIndex(i);
    setFilePath(row);
  };

  const handleDelete = (i) => {
    let list = filePathsData;
    list.splice(i, 1);
    setFilePathsData([...list]);
  };
  const handleSubmit = () => {
    piiConfig.filePaths = filePathsData.toString();
    dispatch(savePiiConfig(piiConfig));
  };

  return (
    <Page>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Card sx={{ pb: '20px' }}>
          <CardHeader title="PII Configuration" />
          <Grid container spacing={2} sx={{ m: '10px' }}>
            <Grid item xs={12} md={5.5}>
              <TextField
                name="awsAccessKey"
                type="password"
                label="AWS Access Key"
                value={piiConfig.awsAccessKey || ''}
                variant="standard"
                sx={{ width: '100%' }}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={5.5}>
              <TextField
                name="awsRegion"
                value={piiConfig.awsRegion || ''}
                label="AWS Region"
                variant="standard"
                sx={{ width: '100%' }}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={5.5}>
              <TextField
                name="awsSecretKey"
                type="password"
                value={piiConfig.awsSecretKey || ''}
                label="AWS Secret Key"
                variant="standard"
                sx={{ width: '100%' }}
                onChange={handleChange}
                required
              />
            </Grid>
            {/* <Grid item xs={12} md={5.5}>
              <TextField
                name="filePaths"
                value={piiConfig.filePaths || ''}
                label="File Paths"
                variant="standard"
                sx={{ width: '100%' }}
                onChange={handleChange}
                required
              />
            </Grid> */}
            <Grid item xs={12} md={5.5}>
              <TextField
                name="fileTypes"
                value={piiConfig.fileTypes || ''}
                label="File Types"
                variant="standard"
                sx={{ width: '100%' }}
                onChange={handleChange}
                // required
              />
            </Grid>
            <Grid item xs={12} md={5.5}>
              <Autocomplete
                freeSolo
                name="remoteUnc"
                value={filePath || ''}
                options={uncPaths && uncPaths.map((option) => '\\\\' + option)}
                onChange={(e) => setFilePath(e.target.textContent)}
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    {...params}
                    label="File Path"
                    onChange={(e) => {
                      setFilePath(e.target.value);
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={2} sx={{mt:1}}>
              {!isEdit ? (
                <Button variant="contained" onClick={handleAddPath}>
                  Add
                </Button>
              ) : (
                <Stack direction="row" gap={2}>
                  <Button variant="contained" onClick={handleUpdatePath}>
                    Update
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setIsEdit(false);
                      setFilePath('');
                    }}
                  >
                    Cancel
                  </Button>
                </Stack>
              )}
            </Grid>
            {filePathsData.length ? (
              <Grid item xs={12} md={11}>
                <PiiFilePathsTable
                  rows={filePathsData}
                  columns={columns}
                  editPath={editPath}
                  handleDelete={handleDelete}
                />
              </Grid>
            ) : null}
            <Grid item xs={12} md={11}>
              <FormControlLabel
                name="executeNow"
                control={<Checkbox />}
                checked={piiConfig.executeNow || false}
                label="Execute Now"
                onChange={handleExecutionChange}
              />
              <FormControlLabel
                name="executeOnAnalysisIndex"
                control={<Checkbox />}
                checked={piiConfig.executeOnAnalysisIndex || false}
                label="Execute on Analysis Index"
                onChange={handleExecutionChange}
              />
              <FormControlLabel
                name="executeOnArchivedIndex"
                control={<Checkbox />}
                checked={piiConfig.executeOnArchivedIndex || false}
                label="Execute on Archived Index"
                onChange={handleExecutionChange}
              />
            </Grid>

            <Grid item xs={11} md={11} sx={{display:'flex',justifyContent:'right'}}>
              <Button
                variant="contained"
                sx={{ color: '#ffff' }}
                onClick={handleSubmit}
                disabled={!(piiConfig.awsRegion && piiConfig.awsAccessKey && piiConfig.awsSecretKey)}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Container>
    </Page>
  );
};

export default PiiConfiguration;
