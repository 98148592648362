import React, { useState, useEffect } from 'react';
import { Alert, AlertTitle, Button, Card, CardHeader, Grid, TextField } from '@mui/material';
import axios from 'axios';
import jwt from 'jwt-decode';
import LoadingScreen from 'src/components/LoadingScreen';
import Page from 'src/components/Page';
import { BASE_URL, LOGO_URL } from 'src/config';
import { ERROR_ALERT, SUCCESS_ALERT } from 'src/redux/reducers/alertReducer';
import { dispatch } from 'src/redux/store';
import { GetFetch, getHeaders, putDetails } from 'src/utils/fetchServices';
import useSettings from 'src/hooks/useSettings';
import getColorPresets from 'src/utils/getColorPresets';
import { appTitleName } from 'src/redux/actions/loginActions';
import { useSelector } from 'react-redux';

const convertHexToRGB = (hexVal) => {
  let r = parseInt(hexVal.substr(1, 2), 16);
  let g = parseInt(hexVal.substr(3, 2), 16);
  let b = parseInt(hexVal.substr(5, 2), 16);
  // return `rgba(${r}, ${g}, ${b}, 1)`
  return 'rgba(' + r + ',' + g + ',' + b + ',1)';
};

let companyId = localStorage.getItem("companyId")

export function rgbToHex(rgb) {
  return (
    '#' +
    rgb
      .match(/[0-9|.]+/g)
      .map((x, i) => (i === 3 ? parseInt(255 * parseFloat(x)).toString(16) : parseInt(x).toString(16)))
      .join('')
      .slice(0, 6)
  );
}

const CustomBranding = () => {
  const { onChangeCustomColor } = useSettings();
  const [count, setCount] = useState(false)
  // const appTitle = useSelector((state) => state.login.appTitle);
  const [labelColor, setLabelColor] = useState(rgbToHex('rgba(255,255,255)'));
  const [buttonColor, setButtonColor] = useState(rgbToHex('rgba(245,166,35)'));
  const [bannerColor, setBannerColor] = useState(rgbToHex('rgba(74,144,226)'));
  const [appName, setAppName] = useState("")
  const [loading, setLoading] = useState(false);
  const [imageFile, setImageFile] = useState({ name: '', img: '', file: null });
  const [logo, setLogo] = useState({
    img: '',
    width: 160,
    height: 85,
  });


  useEffect(() => {
    let companyId = localStorage.getItem("companyId")
    if (parseInt(companyId)) {
      setImageFile({ ...imageFile, img: `${LOGO_URL}${companyId}` })
    } else {
      setImageFile({ ...imageFile, img: `${BASE_URL}global/logo` })
    }
  }, [companyId])

  useEffect(() => {
    let appName = localStorage.getItem('AppNameGlobal')
    setAppName(appName)
  }, [])

  useEffect(() => {
    let appName = localStorage.getItem('AppNameGlobal')
    setAppName(appName)
  }, [count])

  useEffect(() => {
    // getColors('Label', 1);
    // getColors('Button', 1);
    getColors('Banner', 1);
    getColors('AppName', 1);
    const companyId = localStorage.getItem('companyId') || 0;
    setLogo({ ...logo, img: companyId == 0 ? `${BASE_URL}global/logo` : `${BASE_URL}tenant/logo${companyId}` });
  }, []);

  const refresh = () => {
    window.location.reload(true);
  }

  const access_token = localStorage.getItem('accessToken');
  const [adminRole, setAdminRole] = useState();

  useEffect(() => {
    if (access_token) {
      const decodedToken = jwt(access_token);
      setAdminRole(decodedToken.authorities[0])
    }
  }, [access_token])

  const updateLogoTenant = async () => {
    const formData = new FormData();
    formData.append("file", imageFile.file, imageFile.name);
    const companyId = localStorage.getItem('companyId') || 0;

    try {
      await putDetails({ url: `tenant/logo/${companyId}`, body: formData });
      setTimeout(() => {
        refresh();
      }, 1000);
      dispatch({ type: SUCCESS_ALERT, payload: "Logo updated successfully" })
    } catch (error) {
      if (error && error.status != 200) {
        dispatch({ type: ERROR_ALERT, payload: error })
      }
    }
  }

  const updateGlobalLogo = async () => {
    const formData = new FormData();
    formData.append("file", imageFile.file, imageFile.name);

    try {
      await putDetails({ url: `global/logo`, body: formData });
      setTimeout(() => {
        refresh();
      }, 1000);
      dispatch({ type: SUCCESS_ALERT, payload: "Logo updated successfully" })
    } catch (error) {
      if (error && error.status != 200) {
        dispatch({ type: ERROR_ALERT, payload: error })
      }
    }
  }

  const getColors = async (value, get, param) => {
    setLoading(true);
    setCount(true)
    const companyId = localStorage.getItem('companyId') || "";

    let API_URL = BASE_URL + `${companyId == 0 ? 'global' : 'tenant'}/`;
    switch (value) {
      case 'Label':
        API_URL =
          API_URL + `level-colour/${companyId == 0 ? '' : companyId}${!get ? `?levelColour=${convertHexToRGB(labelColor)}` : ''}`;
        break;
      case 'Button':
        API_URL =
          API_URL + `button-colour/${companyId == 0 ? '' : companyId}${!get ? `?buttonColour=${convertHexToRGB(buttonColor)}` : ''}`;
        break;
      case 'Banner':
        API_URL =
          API_URL + `banner-colour/${companyId == 0 ? '' : companyId}${!get ? `?bannerColour=${convertHexToRGB(bannerColor)}` : ''}`;
        break;
      case 'AppName':
        API_URL = BASE_URL + `global/app-name`;
        break;
      default:
        break;
    }
    const headers = await getHeaders();

    let config = {
      method: get ? 'GET' : 'PUT',
      url: API_URL,
      data: {},
      headers,
    };

    if (param) {
      config.params = param
    }
    await axios(config)
      .then(async (res) => {
        if (res && res.status && res.status === 200) {
          if (res.data && get) {
            switch (value) {
              case 'Label':
                setLabelColor(rgbToHex(res.data));
                break;
              case 'Button':
                setButtonColor(rgbToHex(res.data));
                break;
              case 'Banner':
                setBannerColor(rgbToHex(res.data));
                break;
              // case 'appName':
              //   setAppName(res.data);
              //   break;
              default:
                break;
            }
          } else {
            if (adminRole == "SUPERADMIN") {
              dispatch(appTitleName())
            }
          }
        } else {
          dispatch({
            type: ERROR_ALERT,
            payload: 'Cannot updated',
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          const { data, status } = error.response;
          if (status === 500) {
            dispatch({
              type: ERROR_ALERT,
              payload: data.error || 'Something went wrong',
            });
          } else if (data.validationErrors && data.validationErrors.length > 0 && adminRole == "SUPERADMIN") {
            dispatch({
              type: ERROR_ALERT,
              payload: data.validationErrors[0].message || 'Something went wrong',
            });

          }
          else {
            if (adminRole == "SUPERADMIN") {
              dispatch({
                type: ERROR_ALERT,
                payload: data.message || 'Something went wrong',
              });

            }
          }
        }
      });
  };

  const changeImg = (event) => {
    var fileName = event.target.files[0].name;

    var idxDot = fileName.lastIndexOf(".") + 1;
    var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    if (extFile == "jpg" || extFile == "jpeg" || extFile == "png" || extFile == "webp") {
      setImageFile({ name: event.target.files[0].name, img: URL.createObjectURL(event.target.files[0]), file: event.target.files[0] });
      setLogo({ ...logo, img: URL.createObjectURL(event.target.files[0]) });
    } else {
      dispatch({
        type: ERROR_ALERT,
        payload: 'Only jpg/jpeg and png files are allowed!',
      });
    }
  };

  const handleUpload = () => {
    const companyId = localStorage.getItem('companyId');
    companyId == 0 ? updateGlobalLogo() : updateLogoTenant();
  }

  const changeTitle = (e) => {
    setAppName(e.target.value)
  }


  return (
    <>
      <Page>
        {!loading ? (
          <Card sx={{ p: '20px' }}>
            <CardHeader title={'Custom Branding'} />
            {/* <Grid container spacing={3} sx={{ m: '20px' }}>
              <Grid item xs={12} md={6}>
                <TextField
                  type="color"
                  label="Label Colour"
                  value={labelColor}
                  variant="standard"
                  sx={{ width: '100%' }}
                  onChange={(e) => {
                    setLabelColor(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Button variant="contained" onClick={() => getColors('Label')}>
                  Update
                </Button>
              </Grid>
            </Grid> */}

            {/* <Grid container spacing={3} sx={{ m: '20px' }}>
              <Grid item xs={12} md={6}>
                <TextField
                  type="color"
                  label="Button Colour"
                  value={buttonColor}
                  variant="standard"
                  sx={{ width: '100%' }}
                  onChange={(e) => setButtonColor(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Button variant="contained" onClick={() => getColors('Button')}>
                  Update
                </Button>
              </Grid>
            </Grid> */}
            {adminRole == "ADMINISTRATOR" ? null :
              <Grid container spacing={3} sx={{ m: '20px' }}>
                <Grid item xs={12} md={6}>
                  <TextField
                    type="text"
                    label="App Name"
                    defaultValue={appName}
                    variant="standard"
                    sx={{ width: '100%' }}
                    onChange={(e) => changeTitle(e)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button variant="contained"
                    //  onClick={()=>updateName()}
                    onClick={() => getColors('AppName', "", { appName: appName })}
                  >
                    Update
                  </Button>
                </Grid>
              </Grid>
            }

            <Grid container spacing={3} sx={{ m: '20px' }}>
              <Grid item xs={12} md={6}>
                <TextField
                  type="color"
                  label="Banner Colour"
                  value={bannerColor}
                  variant="standard"
                  sx={{ width: '100%' }}
                  onChange={(e) => setBannerColor(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Button variant="contained" onClick={() => {
                  onChangeCustomColor(bannerColor)
                  getColors('Banner');
                }}>
                  Update
                </Button>
              </Grid>
            </Grid>

            <Grid container spacing={3} sx={{ m: '20px' }}>
              <Grid item xs={12} md={4}>
                <label htmlFor="icon-button-file">
                  <input type="file" accept="image/*" onChange={(e) => changeImg(e)} id="icon-button-file" style={{ display: 'none' }} />
                  <Button component="span">
                    <img src={imageFile.img} alt="Logo" />
                  </Button>
                </label>
                {/* Remove button */}
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  type="number"
                  label="Width"
                  value={logo.width}
                  variant="standard"
                  sx={{ width: '100%' }}
                  onChange={(e) => setLogo({ ...logo, width: +e.target.value })}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  type="number"
                  label="Height"
                  value={logo.height}
                  variant="standard"
                  sx={{ width: '100%' }}
                  onChange={(e) => setLogo({ ...logo, height: +e.target.value })}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Button variant="contained" disabled={!imageFile.file} onClick={handleUpload}>Upload</Button>
              </Grid>
            </Grid>
          </Card>
        ) : <LoadingScreen />}
      </Page>
    </>
  );
};

export default CustomBranding;
