import { GetDetails, GetFetch, postInfo, putDetails } from "src/utils/fetchServices";
import { SUCCESS_ALERT, ERROR_ALERT, PROGRESS_DONE, PROGRESS_START } from "src/redux/reducers/alertReducer";

export const ROOT_NODES_FETCH_SUCCESS = 'ROOT_NODES_FETCH_SUCCESS';
export const DATA_LOADER = 'DATA_LOADER';
export const FETCH_FAIL = 'FETCH_FAIL';
export const CHILDREN_NODES_LOADING = 'CHILDREN_NODES_LOADING';
export const CHILDREN_NODES_SUCCESS = 'CHILDREN_NODES_SUCCESS';
export const CHILDREN_NODES_FAIL = 'CHILDREN_NODES_FAIL';
export const CHILDREN_NODES_FOLDER_SUCCESS = 'CHILDREN_NODES_FOLDER_SUCCESS';
export const NODES_FOLDER_BY_PATH_SUCCESS = 'NODES_FOLDER_BY_PATH_SUCCESS';
export const AUDIT_LOG_DATA_SUCCESS = 'AUDIT_LOG_DATA_SUCCESS';
export const AUDIT_LOG_DATA_FAIL = 'AUDIT_LOG_DATA_FAIL';
export const SET_VISIBLITY = 'SET_VISIBLITY'
export const AGENTS_FETCH_SUCCESS = 'AGENTS_FETCH_SUCCESS'
export const STUB_FETCH_SUCCESS = 'STUB_FETCH_SUCCESS'
export const PROXY_PATH = 'PROXY_PATH'
export const SET_SEARCH_PATH = 'SET_SEARCH_PATH'
export const USERS_FETCH_SUCCESS = 'USERS_FETCH_SUCCESS'

export const getRootNodes = (firstTime) => async (dispatch) => {
  dispatch({
    type: DATA_LOADER,
  });
  const companyId = localStorage.getItem('companyId');
  try {
    const response = await GetDetails({ url: `file-explorer/rootNodes/company/${companyId}` });
    const data = response.data;
    // for(let i=0; i<data.length; i++) {
    //   if(data[i].type === 'nt:folder') {
    //     localStorage.setItem('fileExplorerBasePath', data[i].path.slice(0, data[i].path.lastIndexOf(data[i].name)-1));
    //     break;
    //   }
    // }
    dispatch({
      type: ROOT_NODES_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};

export const getNodesChildren = (path, manageHistory, setManageHistory, node) => async (dispatch) => {
  dispatch({ type: CHILDREN_NODES_LOADING });
  const companyId = localStorage.getItem('companyId');
  try {
    const response = await GetFetch({ url: `file-explorer/folders/path/company/${companyId}`, params: { path } });
    const data = response.data;

    if (!manageHistory.includes((d) => d.name === node.name) && data && data.length > 0) {
      let folderInfo = { name: node.name, path: node.path, uuid: node.uuid };
      setManageHistory([...manageHistory, node])
    }
    dispatch({
      type: CHILDREN_NODES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CHILDREN_NODES_FAIL,
      payload: error.message,
    });
  }
}
export const getUsers = () => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  try {
    const response = await GetFetch({ url: `users/company/${companyId}` });
    const data = response.data;
    dispatch({
      type: USERS_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};

export const getPath = (params) => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  try {
    const response = await GetFetch({ url: `file-explorer/folders/path/company/${companyId}`, params });
    const data = response.data;
    dispatch({
      type: SET_SEARCH_PATH,
      payload: data,
    });
    return data
  } catch (error) {
    // dispatch({
    //   type: CHILDREN_NODES_FAIL,
    //   payload: error.message,
    // });
  }
}

export const getFolderDetails = (params) => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  try {
    const response = await GetFetch({ url: `file-explorer/folders/path/company/${companyId}`, params });
    const data = response.data;
    return data
  } catch (error) {
    // dispatch({
    //   type: CHILDREN_NODES_FAIL,
    //   payload: error.message,
    // });
  }
}

export const getNodesFolderView = (params) => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  params.companyId = companyId;
  try {
    const response = await GetFetch({ url: `file-explorer/children/path/company/${companyId}`, params });
    const data = response.data;
    dispatch({
      type: CHILDREN_NODES_FOLDER_SUCCESS,
      payload: data,
    });
    return response.data
  } catch (error) {
    dispatch({
      type: CHILDREN_NODES_FAIL,
      payload: error.message,
    });
  }
}

export const getFoldersByPath = (path) => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  try {
    const response = await GetFetch({ url: `file-explorer/folders/path/company/${companyId}`, params: { path } });
    const data = response.data;
    dispatch({
      type: CHILDREN_NODES_FOLDER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CHILDREN_NODES_FAIL,
      payload: error.message,
    });
  }
}

export const getAuditAccessLog = (body) => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  try {
    const response = await postInfo({ url: `audit-log-index/${companyId}`, body });
    const data = response.data;
    dispatch({
      type: AUDIT_LOG_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: AUDIT_LOG_DATA_FAIL,
      payload: error.message,
    });
  }
}

export const downloadFile = (params, selectedFile) => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  dispatch({
    type: PROGRESS_START,
  });

  try {
    const response = await GetFetch({
      url: `file-explorer/download-file/company/${companyId}`,
      params,
      responseType: "blob"
    });
    const data = response.data;
    var blob = new Blob([data]);
    let url = window.URL.createObjectURL(blob);
    let tempLink = document.createElement('a');
    tempLink.href = url;
    tempLink.setAttribute('download', `${selectedFile.name}`);
    tempLink.click();
    dispatch({
      type: SUCCESS_ALERT,
      payload: 'File Downloaded successfully',
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
  dispatch({
    type: PROGRESS_DONE,
  });
};

export const switchHandle = (id, params, body) => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  try {
    const response = await postInfo({
      url: `file-explorer/company/${companyId}/nodes/id/${id}/classification`, params, body, method: 'PUT', dispatch
    });
    const data = response.data;
    dispatch({
      type: SET_VISIBLITY,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
}

export const stubAwayCAll = (body) => async (dispatch) => {
  dispatch({
    type: PROGRESS_START,
  });
  try {
    const response = await postInfo({
      url: `file-explorer/stub`, body, dispatch
    });
    const data = response.data;
    if (response.status === 200) {
      dispatch({
        type: SUCCESS_ALERT,
        payload: 'Folder stubed successfully',
      });
    } else {
      dispatch({
        type: ERROR_ALERT,
        payload: data.message || 'Something went wrong',
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
  dispatch({
    type: PROGRESS_DONE,
  });
}

export const restoreFile = (body) => async (dispatch) => {
  dispatch({
    type: PROGRESS_START,
  });
  try {
    const response = await postInfo({
      url: `file-explorer/restore-file`, body, dispatch
    });
    const data = response.data;
    if (response.status == 200) {
      dispatch({
        type: SUCCESS_ALERT,
        payload: 'Request for File Restore received successfully',
      });
    }
    if (response.status == 422) {
      dispatch({
        type: ERROR_ALERT,
        payload: 'undefined URL: undefined undefined',
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
  dispatch({
    type: PROGRESS_DONE,
  });
}
export const restoreCAll = (body) => async (dispatch) => {
  dispatch({
    type: PROGRESS_START,
  });
  try {
    const response = await postInfo({
      url: `jobs/restore-folder`, body, dispatch
    });
    const data = response.data;
    if (response.status == 200) {
      dispatch({
        type: SUCCESS_ALERT,
        payload: 'Request for Folder Restore received successfully',
      });
    }
    if (response.status == 422) {
      dispatch({
        type: ERROR_ALERT,
        payload: 'undefined URL: undefined undefined',
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
  dispatch({
    type: PROGRESS_DONE,
  });
}

export const deletionRequest = (body) => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  dispatch({
    type: PROGRESS_START,
  });
  try {
    const response = await postInfo({
      url: `delete-document/${companyId}`, body, dispatch
    });
    const data = response.data;
    dispatch({
      type: SUCCESS_ALERT,
      payload: 'Folder Request To Deleted Successfully',
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
  dispatch({
    type: PROGRESS_DONE,
  });
}

export const getAgents = (params) => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  params.active = 1;
  try {
    const response = await GetFetch({ url: `agents/company/${companyId}`, params });
    const data = response.data;
    dispatch({
      type: AGENTS_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};

export const getStub = () => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  try {
    const response = await GetFetch({ url: `system-codes/global-configuration/company/${companyId}` });
    const data = response.data;
    dispatch({
      type: STUB_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};

export const findFolder = (id, params) => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  try {
    const response = await GetFetch({ url: `/file-explorer/company/${companyId}/folders/folderUUID/${id}/nodes`, params });
    const data = response.data;
    dispatch({
      type: CHILDREN_NODES_FOLDER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};

export const proxySerevr = () => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  try {
    const response = await GetFetch({ url: `company/${companyId}/proxy-servers` });
    const data = response.data;
    dispatch({
      type: PROXY_PATH,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};


