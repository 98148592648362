import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { forwardRef, useEffect, useState } from 'react';
// @mui
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

// ----------------------------------------------------------------------

const Page = forwardRef(({ children, title = '', meta, ...other }, ref) => 
{
  const appTitle = useSelector((state) => state.login.appTitle);
  let appTitleLocal = localStorage.getItem(("AppNameGlobal"))
  const [count,setCount]=useState("")

  useEffect(()=>{
    setCount(appTitle)
  },[appTitle])

return <>
    <Helmet>
      <title>{count || appTitleLocal}</title>
      {meta}
    </Helmet>

    <Box ref={ref} {...other}>
      {children}
    </Box>
  </>
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  meta: PropTypes.node,
};

export default Page;
