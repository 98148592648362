import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  Typography,
  TableContainer,
  TablePagination,
  Stack,
  TextField,
  Grid,
  Button,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import Scrollbar from '../../../../components/Scrollbar';
import { useEffect, useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

export default function SharePointTable({ sites, columns, setSharePointPathInList, checked, setChecked }) {
  const containerRef = useRef(null);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const [searchValue, setSearchValue] = useState();
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [sitesData, setSitesData] = useState([]);
  const [isSite, setIsSite] = useState(false);
  const [order, setOrder] = useState('asc'); // Default sort order
  const [orderBy, setOrderBy] = useState('');
  const [active, setActive] = useState();

  const [isSelecting, setIsSelecting] = useState(false);
  const [selectedCells, setSelectedCells] = useState([]);
  const [selectionBox, setSelectionBox] = useState(null);
  const tableRef = useRef(null);

  const data = [
    ["Row 1, Col 1", "Row 1, Col 2", "Row 1, Col 3"],
    ["Row 2, Col 1", "Row 2, Col 2", "Row 2, Col 3"],
    ["Row 3, Col 1", "Row 3, Col 2", "Row 3, Col 3"]
  ];

  // Function to determine if a cell is within the selection box
  const isCellSelected = (cellRect) => {
    if (!selectionBox) return false;
    const { top, left, width, height } = selectionBox;
    const right = left + width;
    const bottom = top + height;

    return (
      cellRect.left < right &&
      cellRect.right > left &&
      cellRect.top < bottom &&
      cellRect.bottom > top
    );
  };

  const isElementSelected = (labelRect) => {
    if (!selectionBox) return false;

    // Get the coordinates of the selection box
    const selectionLeft = selectionBox.left;
    const selectionRight = selectionBox.left + selectionBox.width;
    const selectionTop = selectionBox.top;
    const selectionBottom = selectionBox.top + selectionBox.height;

    // Get the coordinates of the label's bounding box
    const labelLeft = labelRect.left;
    const labelRight = labelRect.right;
    const labelTop = labelRect.top;
    const labelBottom = labelRect.bottom;

    // Check for overlap
    const isOverlapping = !(
      selectionRight < labelLeft || // Selection is to the left of the label
      selectionLeft > labelRight || // Selection is to the right of the label
      selectionBottom < labelTop || // Selection is above the label
      selectionTop > labelBottom    // Selection is below the label
    );

    return isOverlapping;
  };


  // Mouse down starts selection
  const handleMouseDown = (e) => {
    const { clientX, clientY } = e;

    if (!containerRef.current) return;

    const containerRect = containerRef.current.getBoundingClientRect();

    setSelectionBox({
      left: clientX - containerRect.left,
      top: clientY - containerRect.top,
      width: 0,
      height: 0,
    });

    setIsSelecting(true);
  };
  const handleMouseMove = (e) => {
    if (!isSelecting || !containerRef.current) return;

    const { clientX, clientY } = e;
    const containerRect = containerRef.current.getBoundingClientRect();

    // Adjust mouse coordinates by subtracting container's position
    const adjustedX = clientX - containerRect.left;
    const adjustedY = clientY - containerRect.top;

    setSelectionBox((prevBox) => {
      const newWidth = Math.abs(adjustedX - prevBox.left);
      const newHeight = Math.abs(adjustedY - prevBox.top);

      // Determine new left/top based on the mouse position
      const newLeft = adjustedX < prevBox.left ? adjustedX : prevBox.left;
      const newTop = adjustedY < prevBox.top ? adjustedY : prevBox.top;

      return {
        left: newLeft,
        top: newTop,
        width: newWidth,
        height: newHeight,
      };
    });
  };

  // Mouse up finalizes the selection
  const handleMouseUp = () => {
    setIsSelecting(false);
    const selectedInputs = []; // Changed to track input elements
    const tableCells = tableRef.current.querySelectorAll("td");

    tableCells.forEach((cell) => {
      const cellRect = cell.getBoundingClientRect(); // Get the bounding rect for the cell
      const inputElements = cell.querySelectorAll("input[type='checkbox']"); // Target input checkboxes
      // Iterate over each input and get its index or id
      inputElements.forEach((input, inputIndex) => {
        const inputRect = input.getBoundingClientRect(); // Get the bounding rect for the checkbox

        // Check if the checkbox is inside the cell's rectangle
        if (
          inputRect.left >= cellRect.left &&
          inputRect.right <= cellRect.right &&
          inputRect.top >= cellRect.top &&
          inputRect.bottom <= cellRect.bottom
        ) {
          // Check if the input is inside the selection box
          if (isElementSelected(inputRect)) {
            const inputId = input.getAttribute("id"); // Get the id if available
            selectedInputs.push({
              inputIndex,  // Index of the input inside the cell
              inputId: inputId || `No ID`,  // Get the ID, or indicate 'No ID'
              checked: input.checked, // Include the checked status
            });
          }
        }
      });
    });

    setSelectedCells(selectedInputs); // Update the state with selected inputs
  };


  // Attach mouse move and up listeners to the window
  useEffect(() => {
    if (isSelecting) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    } else {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isSelecting]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  useEffect(() => {
    if (sites && sites?.length >= 0) {
      setSitesData(sites);
    } else {
      setSitesData([]);
    }
  }, [sites]);

  useEffect(() => {
    let state = false;
    for (const key in checked) {
      if (checked[key] === true) {
        state = true;
        break; // Exit loop early if true is found
      }
    }
    setIsSite(state);
  }, [checked]);

  const optionChange = (e) => {
    let val = e.target.value;
    setSearchValue(val);
    if (val == '') {
      if (sites?.length >= 0) {
        setSitesData(sites);
      } else {
        setSitesData([]);
      }
    } else {
      let filterResult =
        sitesData.length ? sitesData.filter((item) => item && item.toLowerCase().includes(val.toLowerCase())) : [];
      if (filterResult.length == 0) {
        // setSitesData([{ name: 'No Data Found' }]);
        setSitesData([]);
      } else {
        setSitesData(filterResult);
      }
    }
  };

  const handleToggleSite = (site) => {
    setChecked((prev) => ({
      ...prev,
      [site]: !prev[site],
    }));
  };

  const handleSelectAll = () => {
    // Create a new object to store the checked states
    const newCheckedState = {};

    // Toggle checkboxes based on the current selectAllChecked state
    paginatedData.forEach((row) => {
      newCheckedState[row] = !selectAllChecked; // Set to true if not checked, false if checked
    });

    // Update the checked state and the selectAllChecked state
    setChecked(newCheckedState);
    setSelectAllChecked(!selectAllChecked); // Toggle selectAllChecked
  };

  const handleInclude = () => {
    const selectedPaths = Object.keys(checked).filter((site) => checked[site] === true);
    setSharePointPathInList([...selectedPaths]);
  };

  const handleSortRequest = (column, i) => {
    setActive(i + 1);
    const isAsc = orderBy === column && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(column);
  };

  // Sort the data based on current order and orderBy
  const sortedData = [...sitesData].sort((a, b) => {
    if (orderBy === '' || !active) return 0;
    const aValue = a.toLowerCase();
    const bValue = b.toLowerCase();
    if (order === 'asc') {
      return aValue > bValue ? 1 : -1;
    }
    return aValue < bValue ? 1 : -1;
  });

  const paginatedData = sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  const half = Math.ceil(paginatedData.length / 2);
  const firstColumnData = paginatedData.slice(0, half);
  const secondColumnData = paginatedData.slice(half);

  return (
    <>
      <Card>
        <Grid container spacing={5} sx={{ alignItems: 'center' }}>
          <Grid item xs={3}>
            <CardHeader title="SharePoint Sites" sx={{ mb: 3 }} />
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row">
              <SearchIcon sx={{ marginTop: '23px', marginRight: '20px' }} />
              <TextField
                id="standard-basic"
                name="search"
                label="Search"
                variant="standard"
                fullWidth
                // sx={{width:"150px"}}
                value={searchValue}
                onInput={(e) => optionChange(e)}
              />
            </Stack>
          </Grid>
          <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '5px' }}>
            <Button variant="contained" sx={{ color: '#ffff', m: '5px' }} disabled={!isSite} onClick={handleInclude}>
              Include
            </Button>
          </Grid>
        </Grid>
        <Scrollbar>
          <div style={{ position: "relative" }}>
            <FormControlLabel sx={{ pl: 3, pb: 2 }} control={<Checkbox defaultChecked />} checked={selectAllChecked} onChange={handleSelectAll} label="Select All" />
            <TableContainer ref={containerRef} sx={{ minWidth: 720 }} onMouseDown={handleMouseDown}>
              <Table ref={tableRef} >
                <TableHead>
                  <TableRow>
                    {/* <TableCell></TableCell> */}
                    {columns.map((column, i) => (
                      <>
                        <TableCell key={i}>
                          <Stack
                            direction="row"
                            onClick={() => handleSortRequest(column, i)}
                            onMouseEnter={() => setOrderBy(column)}
                          >
                            <Typography style={{ marginRight: '8px' }}>{column}</Typography>
                            {orderBy === column && active == i ? (
                              order === 'asc' ? (
                                <ArrowUpwardIcon fontSize="small" />
                              ) : (
                                <ArrowDownwardIcon fontSize="small" />
                              )
                            ) : orderBy === column ? (
                              <ArrowUpwardIcon size="small" sx={{ opacity: '0', '&:hover': { opacity: '0.5' } }} />
                            ) : null}
                            {/* {i < 1 && active == i ? (
                          arrow == true ? (
                            <ArrowDownwardIcon />
                          ) : (
                            <ArrowUpwardIcon />
                          )
                        ) : i < 1 ? (
                          <ArrowUpwardIcon size="small" sx={{ opacity: '0', '&:hover': { opacity: '0.5' } }} />
                        ) : null} */}
                          </Stack>
                        </TableCell>
                        <TableCell></TableCell>
                      </>
                    ))}
                  </TableRow>
                </TableHead>
                {
                  paginatedData?.length > 0 ? (<TableBody>
                    <TableRow>
                      <TableCell>
                        {firstColumnData.map((row) => (
                          <FormControlLabel
                            key={row}
                            control={<Checkbox checked={checked[row] || false} onChange={() => handleToggleSite(row)} />}
                            label={row}
                          />
                        ))}
                      </TableCell>

                      <TableCell>
                        {secondColumnData.map((row) => (
                          <FormControlLabel
                            key={row}
                            control={<Checkbox checked={checked[row] || false} onChange={() => handleToggleSite(row)} />}
                            label={row}
                          />
                        ))}
                      </TableCell>
                    </TableRow>
                  </TableBody>) : (
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={5} align="center">
                          No Data Found ...
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )
                }

              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                component="div"
                count={sites.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </div>
        </Scrollbar>
      </Card>
    </>
  );
}
