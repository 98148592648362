import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Grid, Typography } from '@mui/material';
// import useSettings from '../../../hooks/useSettings';
// import Widgets from '../system-status/components/Widgets';
// import BarChart from '.../charts/BarChart';
import moment from 'moment';
import { getEpochtime } from 'src/utils/helper';
import { MONTH } from 'src/config';
import {
  getAnalysisPaths,
  getAnalyticsBarChartData,
  getAnalyticsWidgetCount,
  getChartsVolume,
  getDuplicateChartData,
  getFolderSize,
  getPathList,
  getTotalFileVolume,
  getVolumeData,
} from '../analyticsActions';
import LoadingScreen from 'src/components/LoadingScreen';
// import AnalysedPath from './components/AnalysedPath';
// import PathOrientedAnalysis from './components/PathOrientedAnalysis';
// import FolderSizeChart from './components/FolderSizeChart';
// import PathListTable from './components/PathListTable';
import chartColors from 'src/utils/chartColors';
import { Link } from 'react-router-dom';
// import StackedChart from './components/StackedChart'
import Page from 'src/components/Page';
import BarChart from '../../charts/BarChart';
import Widgets from '../../system-status/components/Widgets';
import AnalysedPath from './AnalysedPath';
import FolderSizeChart from './FolderSizeChart';
import PathListTable from './PathListTable';
import PathOrientedAnalysis from './PathOrientedAnalysis';
import Searchbar from 'src/layouts/dashboard/header/Searchbar';

export const INITIAL_DATA = 'INITIAL_DATA';
let months;
let monthNo;
let selectWeek = false;

export default function Analytics() {
  const dispatch = useDispatch();
  //   const { themeStretch } = useSettings();
  const count = useSelector((state) => state.analyticsReducers.count);
  const analysisPaths = useSelector((state) => state.analyticsReducers.analysisPaths);
  const dates = useSelector((state) => state.systemReducer.dates);
  const loading = useSelector((state) => state.analyticsReducers.loading);
  const volumeData = useSelector((state) => state.analyticsReducers.volumeData);
  const fileVolume = useSelector((state) => state.analyticsReducers.fileVolume);
  const barChartDataByYear = useSelector((state) => state.analyticsReducers.barChartDataByYear);
  const chartsVolume = useSelector((state) => state.analyticsReducers.chartsVolume);
  const folderSize = useSelector((state) => state.analyticsReducers.folderSize);
  const pathList = useSelector((state) => state.analyticsReducers.pathList);

  const [startDate, setStartDate] = useState(new Date('1995/01/01'));
  const [endDate, setEndDate] = useState(new Date());
  const [year, setYear] = useState();

  const [barChartPerYearData, setBarChartPerYearData] = useState([]);
  const [barChartPerYearLabels, setBarChartPerYearLabels] = useState([]);
  const [barChartPerMonthsLabels, setBarChartPerMonthsLabels] = useState([]);
  const [pieChartForAllLabels, setPieChartForAllLabels] = useState([]);
  const [pieChartForAllData, setPieChartForAllData] = useState([]);
  const [barChartYearPathData, setBarChartYearPathData] = useState([]);
  const [folderSizeBarChartLabels, setFolderSizeBarChartLabels] = useState([]);
  const [folderSizeBarChartData, setFolderSizeBarChartData] = useState([]);

  const [title, setTitle] = useState('year');
  const [showNextChartStart, setShowNextChartStart] = useState(0);
  const [showNextChartEnd, setShowNextChartEnd] = useState(3);
  const [dateAnalysisType, setDateAnalysisType] = useState('ACCESSED');
  const [dateAnalysisPath, setDateAnalysisPath] = useState('ACCESSED');
  const [analysedPath, setAnalysedPath] = useState('');
  const [selectChartYear, setSelectChartYear] = useState(null);
  const [selectChartMonth, setSelectChartMonth] = useState(null);
  const [analysisPath, setAnalysisPath] = useState([]);
  const [stackedData, setStackedData] = useState([]);

  const [stepCount, setStepCount] = useState(1);

  useEffect(() => {
    dispatch(getAnalysisPaths());
    dispatch(getDuplicateChartData());

  }, []);
  useEffect(() => {
    let data = [];
    if (analysisPaths.length) {
      analysisPaths.forEach((path) => {
        data.push(path.replace('\\', '\\\\'));
      });
    }
    setAnalysisPath([...data]);
  }, [analysisPaths]);

  useEffect(() => {
    let unixStartDate = moment(dates.startDate).format('x');
    let unixEndDate = new Date(dates.endDate).setHours(23, 59, 59);

    dispatch(getAnalyticsWidgetCount(unixStartDate, unixEndDate));
    getVolumeFilesChartData();
    dispatch(getVolumeData({ startDate: unixStartDate, endDate: unixEndDate }));
    dispatch(getTotalFileVolume({ startDate: unixStartDate, endDate: unixEndDate }));
  }, [dates]);

  const getVolumeFilesChartData = () => {
    let unixStartDate = moment(dates.startDate).format('x');
    let unixEndDate = new Date(dates.endDate).setHours(23, 59, 59);
    dispatch(getAnalyticsBarChartData({ startDate: unixStartDate, endDate: unixEndDate }));
  };

  useEffect(() => {
    let unixStartDate = moment(dates.startDate).format('x');
    let unixEndDate = new Date(dates.endDate).setHours(23, 59, 59);
    dispatch(getChartsVolume({ startDate: unixStartDate, endDate: unixEndDate, dateAnalysis: dateAnalysisType }));
  }, [dates, dateAnalysisType]);

  const showNext = () => {
    setShowNextChartStart(showNextChartStart + 4);
    setShowNextChartEnd(showNextChartEnd + 4);
  };
  const back = () => {
    setShowNextChartStart(showNextChartStart - 4);
    setShowNextChartEnd(showNextChartEnd - 4);
  };

  useEffect(() => {
    let unixStartDate = moment(dates.startDate).format('x');
    let unixEndDate = new Date(dates.endDate).setHours(23, 59, 59);
    if (analysedPath === null) {
      dispatch({ type: INITIAL_DATA });
    } else {
      dispatch(
        getFolderSize({
          rootPath: analysedPath,
          startDate: unixStartDate,
          endDate: unixEndDate,
          dateAnalysis: dateAnalysisPath,
        })
      );
      dispatch(
        getPathList({
          rootPath: analysedPath,
          startDate: unixStartDate,
          endDate: unixEndDate,
          dateAnalysis: dateAnalysisPath,
        })
      );
    }
  }, [analysedPath, dateAnalysisPath]);

  useEffect(() => {
    let barChartPerMonthLabels = [];
    let barChartPerMonthData = [];
    let i = 0;
    if (folderSize != '') {
      var MonthData = [];
      for (let bucket of Object.keys(folderSize)) {
        MonthData.push(parseInt(folderSize[bucket].size) / (1024 * 1024 * 1024));
        barChartPerMonthLabels.push(
          folderSize[bucket].md5Path.substr(0, 3) +
          folderSize[bucket].md5Path.substr(folderSize[bucket].md5Path.length - 3, 3)
        );
      }
      barChartPerMonthData.push({
        data: MonthData,
        label: '',
        backgroundColor: chartColors[i++],
      });
      setFolderSizeBarChartLabels(barChartPerMonthLabels);
      setFolderSizeBarChartData(barChartPerMonthData);
    } else {
      setFolderSizeBarChartLabels([]);
      setFolderSizeBarChartData([]);
    }
  }, [folderSize]);

  const handlePathFilter = (path, type) => {
    setAnalysedPath(path);
    setDateAnalysisPath(type);
  };
  const handleDateFilter = (type) => {
    setDateAnalysisType(type);
  };

  const getBarChartDataPerYear = () => {
    const labelBucket = [];
    const labels = [];
    const keys = [];
    const bucketData = [];

    if (barChartDataByYear['aggregations'] !== undefined && barChartDataByYear.aggregations[2] !== undefined) {
      for (let bucket of barChartDataByYear['aggregations'][2].buckets) {
        keys.push(bucket.key);
        let year = new Date(bucket.key).getUTCFullYear().toString();
        if (parseInt(year) >= startDate.getUTCFullYear() && parseInt(year) <= endDate.getUTCFullYear()) {
          if (labels.indexOf(year) === -1) {
            labels.push(year);
          }
        }
        labels.sort();
        if (parseInt(year) >= startDate.getUTCFullYear() && parseInt(year) <= endDate.getUTCFullYear()) {
          for (let item of bucket[3].buckets) {
            if (labelBucket.indexOf(item.key) === -1) {
              labelBucket.push(item.key);
            }
          }
        }
      }
      let i = 0;
      for (let bucketKey of labelBucket) {
        let data = [];
        for (let bucket of barChartDataByYear['aggregations'][2].buckets) {
          let year = new Date(bucket.key).getUTCFullYear().toString();
          for (let item of bucket[3].buckets) {
            if (bucketKey === item.key) {
              data[labels.indexOf(year)] = item[1].value / (1024 * 1024 * 1024);
            }
          }
        }
        bucketData.push({ data: data, label: bucketKey, backgroundColor: chartColors[i++] });
      }
    }
    setBarChartPerYearData(bucketData);
    setBarChartPerYearLabels(labels);
  };

  const getBarChartDataOnYearChange = () => {
    const labelBucket = [];
    const chartData = [];
    const chartLabels = [];
    const monthLabels = [];

    if (barChartDataByYear['aggregations'] !== undefined) {
      for (let bucket of barChartDataByYear['aggregations'][3].buckets) {
        if (selectWeek) {
          chartLabels.push(bucket.key_as_string.substring(0, 10));
        } else {
          let month = new Date(bucket.key).getUTCMonth().toString();
          if (monthLabels.findIndex((c) => c === MONTH[`MONTH_${month}`]) === -1) {
            monthLabels.push(MONTH[`MONTH_${month}`]);
          }
        }
        // }
        for (let item of bucket[4].buckets) {
          if (labelBucket.indexOf(item.key) === -1) {
            labelBucket.push(item.key);
          }
        }
      }
      let i = 0;
      for (let bucketKey of labelBucket) {
        let data = [];
        for (let bucket of barChartDataByYear['aggregations'][3].buckets) {
          if (selectWeek) {
            let week = bucket.key_as_string.substring(0, 10);
            for (let item of bucket[4].buckets) {
              if (bucketKey === item.key) {
                data[monthLabels.indexOf(week)] = item[1].value / (1024 * 1024 * 1024);
              }
            }
          } else {
            months = new Date(bucket.key).getUTCMonth().toString();
            getMonthsDataSet(months);
            for (let item of bucket[4].buckets) {
              if (bucketKey === item.key) {
                data[monthLabels.indexOf(months)] = item[1].value / (1024 * 1024 * 1024);
              }
            }
          }
        }
        chartData.push({ data: data, label: bucketKey, backgroundColor: chartColors[i++] });
      }

      setBarChartPerYearData(chartData);
      setBarChartPerYearLabels(monthLabels);
      setBarChartPerMonthsLabels(monthLabels);
    }
  };
  const getBarChartDataOnMonthChange = () => {
    const labelBucket = [];
    const chartData = [];
    const chartLabels = [];
    const monthLabels = [];

    if (barChartDataByYear['aggregations'] != undefined) {
      for (let bucket of barChartDataByYear['aggregations'][3].buckets) {
        if (selectWeek) {
          chartLabels.push(bucket.key_as_string.substring(0, 10));
        } else {
          let month = new Date(bucket.key).getUTCMonth().toString();
          getMonths(month);
        }
        // }
        for (let item of bucket[4].buckets) {
          if (labelBucket.indexOf(item.key) == -1) {
            labelBucket.push(item.key);
          }
        }
      }
      let i = 0;
      for (let bucketKey of labelBucket) {
        let data = [];
        for (let bucket of barChartDataByYear['aggregations'][3].buckets) {
          if (selectWeek) {
            let week = bucket.key_as_string.substring(0, 10);
            for (let item of bucket[4].buckets) {
              if (bucketKey === item.key) {
                data[chartLabels.indexOf(week)] = item[1].value / (1024 * 1024 * 1024);
              }
            }
          } else {
            months = new Date(bucket.key).getUTCMonth().toString();
            getMonthsDataSet(months);
            for (let item of bucket[4].buckets) {
              if (bucketKey === item.key) {
                data[chartLabels.indexOf(months)] = item[1].value / (1024 * 1024 * 1024);
              }
            }
          }
        }
        chartData.push({ data: data, label: bucketKey, backgroundColor: chartColors[i++] });
      }
      setBarChartPerYearData(chartData);
      setBarChartPerYearLabels(chartLabels);
    }
  };

  useEffect(() => {
    if (stepCount === 1) {
      getBarChartDataPerYear();
    } else if (stepCount === 2) {
      getBarChartDataOnYearChange();
      setTitle('month');
    } else if (stepCount === 3) {
      getBarChartDataOnMonthChange();
      setTitle('week');
    }
  }, [barChartDataByYear, dates]);

  const getMonths = (month) => {
    saveData(MONTH[`MONTH_${month}`], +month);
  };

  const saveData = (monthName) => {
    if (barChartPerMonthsLabels.findIndex((c) => c === monthName) === -1) {
      setBarChartPerMonthsLabels((prev) => [...prev, monthName]);
    }

    if (barChartPerYearLabels.findIndex((c) => c === monthName) === -1) {
      setBarChartPerYearLabels((prev) => [...prev, monthName]);
    }
  };

  useEffect(() => {
    setStackedData(barChartYearPathData)

  }, [barChartYearPathData])

  const getMonthsDataSet = (month) => {
    switch (month) {
      case '0':
        months = 'Jan';
        break;
      case '1':
        months = 'Feb';
        break;
      case '2':
        months = 'Mar';
        break;
      case '3':
        months = 'Apr';
        break;
      case '4':
        months = 'May';
        break;
      case '5':
        months = 'Jun';
        break;
      case '6':
        months = 'Jul';
        break;
      case '7':
        months = 'Aug';
        break;
      case '8':
        months = 'Sep';
        break;
      case '9':
        months = 'Oct';
        break;
      case '10':
        months = 'Nov';
        break;
      case '11':
        months = 'Dec';
        break;
      default:
        break;
    }
  };

  const selectDataYearly = (event, value) => {
    let selectedYear = '';
    if (event) {
      selectedYear = event.target.value;
    } else {
      selectedYear = value;
    }
    setSelectChartYear(selectedYear);
    let start = new Date();
    start.setFullYear(selectedYear, 0, 1);
    start.setHours(0, 0);
    start.setMilliseconds(0);
    const startDate = new Date(start);
    let end = new Date();
    end.setFullYear(selectedYear, 11, 31);
    end.setHours(0, 0);
    end.setMilliseconds(0);
    const endDate = new Date(end);

    setYear(selectedYear);
    setStepCount(2);
    selectWeek = false;

    dispatch(
      getAnalyticsBarChartData({ startDate: getEpochtime(startDate), endDate: getEpochtime(endDate), interval: '1m' })
    );
  };

  const getMonthsLabelSet = (month) => {
    switch (month) {
      case 'Jan':
        monthNo = 0;
        break;
      case 'Feb':
        monthNo = 1;
        break;
      case 'Mar':
        monthNo = 2;
        break;
      case 'Apr':
        monthNo = 3;
        break;
      case 'May':
        monthNo = 4;
        break;
      case 'Jun':
        monthNo = 5;
        break;
      case 'Jul':
        monthNo = 6;
        break;
      case 'Aug':
        monthNo = 7;
        break;
      case 'Sep':
        monthNo = 8;
        break;
      case 'Oct':
        monthNo = 9;
        break;
      case 'Nov':
        monthNo = 10;
        break;
      case 'Dec':
        monthNo = 11;
        break;
      default:
        break;
    }
  };

  const selectDataMonthly = (event) => {
    const month = event.target.value;
    setSelectChartMonth(month);
    let start = new Date();
    getMonthsLabelSet(month);
    start.setFullYear(year, monthNo, 1);
    start.setHours(0, 0);
    start.setMilliseconds(0);
    const startDate = new Date(start);
    let end = new Date();
    end.setFullYear(year, monthNo, 30);
    end.setHours(0, 0);
    end.setMilliseconds(0);
    const endDate = new Date(end);

    setStepCount(3);
    selectWeek = true;
    dispatch(
      getAnalyticsBarChartData({ startDate: getEpochtime(startDate), endDate: getEpochtime(endDate), interval: '1w' })
    );
  };

  const totalValue = (total) => {
    const dataVolume = total && total.toString().split(' ');
    return total && parseFloat(dataVolume[0]).toFixed(1) + ' ' + dataVolume[1];
  };

  function numberWithCommas(total) {
    const val = total && total.toString();
    return total && val.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  return (
    <>
      {/* <Searchbar/> */}
      <Page>
        {/* <Container > */}
        {/* {loading && <LoadingScreen />} */}
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} style={{ paddingTop: '1px' }}>
            <Widgets title="Number of files" total={numberWithCommas(count.numberOfFiles || 0)} color="#03a9f4" />
          </Grid>

          <Grid item xs={12} md={6} style={{ paddingTop: '1px' }}>
            <Widgets title="Data Volume" total={totalValue(count.totalVolume || `0 TB`)} color="#f44336" />
          </Grid>

          <Grid item xs={12} md={12}>
            <BarChart
              labels={barChartPerYearLabels}
              data={barChartPerYearData}
              selectDataYearly={selectDataYearly}
              selectDataMonthly={selectDataMonthly}
              steps={[stepCount, setStepCount]}
              showOptions={true}
              chartHeading="Stacked Chart showing Volume of files types per"
              title={title}
              allData={getVolumeFilesChartData}
              year={selectChartYear}
              month={selectChartMonth}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            {analysisPath?.length ? <AnalysedPath analysisPaths={analysisPath} /> : null}
          </Grid>
          {/* <Grid item xs={12} md={12}>
            {volumeData?.aggregations && <RootShareTable volumeTableData={volumeData?.aggregations[3]?.buckets} />}
          </Grid> */}
          {/* <Grid item xs={12} md={12}>
            <PathOrientedAnalysis analysisPaths={analysisPath} handlePathFilter={handlePathFilter} />
          </Grid>
          <Grid item xs={12} md={12}>
            <FolderSizeChart data={folderSizeBarChartData} labels={folderSizeBarChartLabels} />
          </Grid>
          <Grid item xs={12} md={12}>
            <PathListTable pathList={pathList?.length && pathList} />
          </Grid> */}
        </Grid>
        {/* </Container> */}
      </Page>
    </>
  );
}
