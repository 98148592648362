import { SUCCESS_ALERT } from 'src/redux/reducers/alertReducer';
import {  deleteInfo, GetFetch, postInfo } from 'src/utils/fetchServices';

export const POLICIES_FETCH_SUCCESS = 'POLICIES_FETCH_SUCCESS';
export const DATA_LOADER = 'DATA_LOADER';
export const SUCCESS = 'SUCCESS';
export const DELETED_SUCCESSFULLY = 'DELETED_SUCCESSFULLY';
export const FETCH_FAIL = 'FETCH_FAIL';
export const POLICIES_DOCUMENTS_FETCH_SUCCESS = 'POLICIES_DOCUMENTS_FETCH_SUCCESS';
export const GET_POLICY_SUCCESS = 'GET_POLICY_SUCCESS';
export const ANALYTICS_FETCH_SUCCESS = 'ANALYTICS_FETCH_SUCCESS';

export const getPolicies = (params) => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  // dispatch({
  //   type: DATA_LOADER,
  // });
  // params.sort = 'id,desc';
  try {
    const response = await GetFetch({ url: `policies/company/${companyId}`, params });
    const data = response.data;
    dispatch({
      type: POLICIES_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};


export const getAnalytics = (params) => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  // dispatch({
  //   type: DATA_LOADER,
  // });

  try {
    const response = await GetFetch({ 
      url: `analysis/company/${companyId}/query`,
      params,
  });
  const data = response.data;
    dispatch({
      type: ANALYTICS_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};

export const getDocuments = () => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  dispatch({
    type: DATA_LOADER,
  });
  try {
    const response = await GetFetch({ url: `categories/company/${companyId}` });
    const data = response.data;
    dispatch({
      type: POLICIES_DOCUMENTS_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};

export const addPolicyAction = (body, method, navigate) => async (dispatch) => {
  dispatch({ type: DATA_LOADER });
  try {
    const response = await postInfo(
      {
        url:`policies`,
        body,
        method: method || 'POST',
      },
      dispatch
    );
    if (response && response.status === 200) {
      const data = response.data;
      dispatch({
        type: SUCCESS,
        payload: data,
      });
      if (method) {
        dispatch({
          type: SUCCESS_ALERT,
          payload:'Updated Successfully',
        });
      }else{
        dispatch({
          type: SUCCESS_ALERT,
          payload:'Added Successfully ',
        });
      }
      navigate('/dashboard/policy-management');
    } else {
      dispatch({ type: FETCH_FAIL });
    }
  } catch (error) {
    dispatch({ type: FETCH_FAIL });
  }
};

export const getPolicyDetails = (id) => async (dispatch) => {
  dispatch({
    type: DATA_LOADER,
  });
  try {
    const response = await GetFetch({ url: `policies/${id}`});
    const data = response.data;
    dispatch({
      type: GET_POLICY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};

export const deletePolicy = (id) => async (dispatch) => {
  dispatch({
    type: DATA_LOADER,
  });
  try {
    const response = await deleteInfo({ url: `policies/${id}` });
    const data = response.data;
    dispatch({
      type: DELETED_SUCCESSFULLY,
    });
    dispatch({
      type: SUCCESS_ALERT,
      payload: 'Deleted',
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};