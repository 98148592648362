import {
  Button,
  Card,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import { exportFiles, getAgents, getGlobalConfiguration } from '../ediscoveryActions';
import { testAccess } from 'src/pages/configuration/job-management/jobManagementActions';
import { ERROR_ALERT } from 'src/redux/reducers/alertReducer';
import TestAccessModal from 'src/pages/configuration/job-management/components/TestAccessModal';
import { get } from 'lodash';

const ExportFilesModal = ({ nextDetails, fileNameResult, keywordResult, openModal, close, searchDetails }) => {
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const agents = useSelector((state) => state.ediscoveryReducer.agents);
  const globalConfig = useSelector((state) => state.ediscoveryReducer.globalConfig);
  const testData = useSelector((state) => state.jobManagementReducer.testData);
  const [validTest, setValidTest] = useState(false);
  const [testAccessLoading, setTestAccessLoading] = useState(false);
  const [openAccessModal, setOpenAccessModal] = useState(false);
  const [domainDto, setDomainDto] = useState({});
  const [exportDetails, setExportDetails] = useState({
    agentId: '',
    elasticSearchCriteriaDto: {
      fileNames: ['*'],
      fileServer: false,
      order: 'asc',
      repository: true,
      sort: 'VC_FILESIZE',
      fileSizeEnd: 1024,
    },
    jobName: '',
    restore: false,
  });

  useEffect(() => {
    setDomainDto({
      destinationPath: '',
      domain: globalConfig.restoreDomainName || '',
      userName: globalConfig.restoreUser || '',
      password: globalConfig.restorePassword || '',
    });
  }, [globalConfig]);

  useEffect(() => {
    if (openModal) {
      dispatch(getAgents({}));
      dispatch(getGlobalConfiguration());
    }
  }, [openModal]);
  const handleSubmit = () => {
    exportDetails.domainDto = domainDto;
    exportDetails.companyId = +localStorage.getItem('companyId');
    const payload = {};
    if (nextDetails) {
      payload.archivedEndDate = get(nextDetails, 'archivedEndDate');
      payload.archivedStartDate = get(nextDetails, 'archivedStartDate');
      payload.fileServer = get(nextDetails, 'fileServer');
      payload.dateCreatedStart = get(nextDetails, 'dateCreatedStart');
      payload.dateCreatedEnd = get(nextDetails, 'dateCreatedEnd');
      payload.documentTag = get(nextDetails, 'documentTag');
      payload.modifiedStartDate = get(nextDetails, 'modifiedStartDate');
      payload.modifiedEndDate = get(nextDetails, 'modifiedEndDate');
      payload.searchPaths = get(nextDetails, 'searchPaths');
      payload.repository = get(nextDetails, 'repository');
      payload.companyTag = get(nextDetails, 'companyTag');
      payload.lastRepoAccessedEndDate = get(nextDetails, 'lastRepoAccessedEndDate');
      payload.lastRepoAccessedDate = get(nextDetails, 'lastRepoAccessedDate');
      payload.sid = get(nextDetails, 'sid');
      if (get(nextDetails, 'fileSizeEnd') && get(nextDetails, 'fileSizeStart')) {
        payload.fileSizeEnd = get(nextDetails, 'fileSizeEnd');
        payload.fileSizeStart = get(nextDetails, 'fileSizeStart');
      }
    }
    dispatch(
      exportFiles({
        ...exportDetails,
        elasticSearchCriteriaDto: {
          ...exportDetails.elasticSearchCriteriaDto,
          ...payload,
          fileNames: fileNameResult?.length ? fileNameResult : [''],
          keyWords: keywordResult,
        },
      })
    );
    // dispatch(exportFiles(exportDetails));
    close();
  };

  const getAccessData = async () => {
    setTestAccessLoading(true);
    if (domainDto.destinationPath.slice(0, 2) == '\\\\' && domainDto.destinationPath.split('\\').length > 3) {
      let desiredPath;
      if (domainDto.destinationPath.split('\\').length > 4) {
        const segments = domainDto.destinationPath.split('\\');
        if (segments.length > 2) {
          desiredPath = '\\\\' + segments.slice(2, 4).join('\\'); // Append the second segment if it exists
        }
      } else {
        desiredPath = domainDto.destinationPath;
      }
      await dispatch(
        testAccess({
          domain: domainDto.domain,
          userName: domainDto.userName,
          password: domainDto.password,
          agentId: exportDetails.agentId || 0,
          uncPath: desiredPath || domainDto.destinationPath,
          libType: globalConfig?.remoteLibType || 'JNQ',
        })
      );
      setValidTest(true);
    } else {
      dispatch({
        type: ERROR_ALERT,
        payload: 'Please enter a valid path',
      });
    }
    setTestAccessLoading(false);
  };

  useEffect(() => {
    if (validTest && testData) {
      setOpenAccessModal(true);
    }
  }, [testData]);

  return (
    <>
      <Modal
        open={openModal}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Page sx={{ width: '100%' }}>
          <TestAccessModal
            testData={testData}
            openModal={openAccessModal}
            close={() => {
              setOpenAccessModal(false);
            }}
            loader={testAccessLoading}
          />
          <Container maxWidth={themeStretch ? false : 'xl'}>
            <Card sx={{ p: '40px' }}>
              <Typography variant="h4">Export Files</Typography>
              <Stack gap={2}>
                <TextField
                  name="destinationPath"
                  variant="standard"
                  label="Destination Path"
                  onChange={(e) => setDomainDto({ ...domainDto, destinationPath: e.target.value })}
                  required
                />
                <FormControl fullWidth>
                  <InputLabel id="agent">Agent</InputLabel>
                  <Select
                    name="agentId"
                    labelId="agent"
                    variant="standard"
                    label="Agent"
                    onChange={(e) => setExportDetails({ ...exportDetails, agentId: e.target.value })}
                    required
                  >
                    {agents.content &&
                      agents.content.map((agent) => {
                        return (
                          <MenuItem key={agent.id} value={agent.id}>
                            {agent.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
                <TextField
                  variant="standard"
                  label="Job name"
                  onChange={(e) => setExportDetails({ ...exportDetails, jobName: e.target.value })}
                  required
                />
                <TextField
                  variant="standard"
                  value={domainDto.domain || ''}
                  label="Domain name"
                  onChange={(e) => setDomainDto({ ...domainDto, domain: e.target.value })}
                  required
                />
                <TextField
                  variant="standard"
                  value={domainDto.userName || ''}
                  label="User name"
                  onChange={(e) => setDomainDto({ ...domainDto, userName: e.target.value })}
                  required
                />
                <TextField
                  variant="standard"
                  value={domainDto.password || ''}
                  label="Password"
                  type="password"
                  onChange={(e) => setDomainDto({ ...domainDto, password: e.target.value })}
                  required
                />
              </Stack>
              <Stack direction="row" gap={2} sx={{ my: '20px' }}>
                <Button variant="contained" onClick={close}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  disabled={exportDetails.jobName === '' || domainDto.destinationPath === ''}
                  onClick={handleSubmit}
                >
                  Export
                </Button>
                <Button
                  variant="contained"
                  disabled={domainDto.destinationPath == '' ? true : false}
                  onClick={getAccessData}
                >
                  {' '}
                  Test CIFS Access{' '}
                </Button>
              </Stack>
            </Card>
          </Container>
        </Page>
      </Modal>
    </>
  );
};

export default ExportFilesModal;
