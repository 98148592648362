import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Grid } from '@mui/material';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import LoadingScreen from 'src/components/LoadingScreen';
import VolumeTable from './components/VolumeTable';
import { getFileStoreList } from './volumeActions';

export default function VolumeManagement() {
  const dispatch = useDispatch();
  const { themeStretch } = useSettings();
  const fileStoreList = useSelector((state) => state.volumeReducer.fileStoreList);
  const refresh = useSelector((state) => state.volumeReducer.refresh);
  const loading = useSelector((state) => state.volumeReducer.loading);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  useEffect(() => {
    dispatch(getFileStoreList({ page, size: rowsPerPage }));
  }, [page, rowsPerPage]);
  useEffect(() => {
    if(refresh){
      dispatch(getFileStoreList({ page, size: rowsPerPage }));
    }
  }, [refresh]);

  const columns = ['Tag', 'Status', 'Encryption', 'Type', , 'Action'];

  return (
    <Page>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} style={{ paddingTop: '1px' }}>
            { !loading ? (
              fileStoreList.content &&
              <VolumeTable
                pages={[page, setPage]}
                size={[rowsPerPage, setRowsPerPage]}
                fileStoreList={fileStoreList}
                columns={columns}
              />
            ): <LoadingScreen/>}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
