import {  GetDetails } from 'src/utils/fetchServices';

export const BUILD_INFO_FETCH_SUCCESS = 'BUILD_INFO_FETCH_SUCCESS';
export const DATA_LOADER = 'DATA_LOADER';
export const FETCH_FAIL = 'FETCH_FAIL';


export const getBuildInfo = () => async (dispatch) => {
    dispatch({
      type: DATA_LOADER,
    });
    try {
      const response = await GetDetails({ url: 'actuator/info' });
      const data = response.data;
      dispatch({
        type: BUILD_INFO_FETCH_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_FAIL,
        payload: error.message,
      });
    }
  };