import { Button, Card, Container, Modal, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import { formatSize } from 'src/utils/formatSize';

const CompressModal = ({ openModal, close, downloadCompressed, selectedFiles }) => {
  const { themeStretch } = useSettings();
  const [fileSize, setFileSize] = useState(0);

  useEffect(() => {
    let size;
    if (selectedFiles.length) {
      size = selectedFiles.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.documentSize) , 0);
    }
    setFileSize(size)
  }, [selectedFiles]);
  return (
    <>
      <Modal
        open={openModal}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Page sx={{ width: '60%' }}>
          <Container maxWidth={themeStretch ? false : 'xl'}>
            <Card sx={{ p: '40px' }}>
              <Stack direction="column">
                <Typography sx={{ pt: 1, pb: 1 }} variant="p">
                  Downloading {selectedFiles.length} documents, total uncompressed Size {formatSize(fileSize)}, click "Download" to
                  confirm.
                </Typography>
                <Stack direction="row">
                  <Button variant="contained" sx={{ mr: 2 }} onClick={() => close()}>
                    Close
                  </Button>
                  <Button variant="contained" onClick={() => downloadCompressed()}>
                    Download
                  </Button>
                </Stack>
              </Stack>
            </Card>
          </Container>
        </Page>
      </Modal>
    </>
  );
};

export default CompressModal;
