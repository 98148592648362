import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
// hooks
import useSettings from '../../hooks/useSettings';
import useResponsive from '../../hooks/useResponsive';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// config
import { BASE_URL, HEADER, NAVBAR } from '../../config';
//
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';
import NavbarHorizontal from './navbar/NavbarHorizontal';
import AlertDialog from 'src/components/AlertDialog';
import { useSelector ,useDispatch} from 'react-redux';
import { getHeaders } from 'src/utils/fetchServices';
import axios from 'axios';
import { rgbToHex } from 'src/pages/system-configuration/custom-branding/CustomBranding';
import Progress from 'src/theme/overrides/Progress';
import NProgress from 'nprogress';
import { useStyles } from 'src/utils/view.css';

// ----------------------------------------------------------------------

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const dispatch = useDispatch();
  const { collapseClick, isCollapse } = useCollapseDrawer();

  const { themeLayout, onChangeCustomColor } = useSettings();

  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const verticalLayout = themeLayout === 'vertical';

  
  const alert = useSelector((state) => state.alertReducer);
  const {type, message, progress} = alert;

  useEffect(() => {
    if(type) setShowAlert(true);
    setTimeout(() => {
      dispatch({
        type :'SET_INITIALSTATE'
      })
    }, 6000);
  }, [alert]);

  useEffect(()=>{
    if(progress){
      NProgress.start()
    }
    else{
      NProgress.done()
    }
  },[progress])

  useEffect(() => {
      const getColors = async () => {
        const companyId = localStorage.getItem('companyId') || 0;
  
      let API_URL = BASE_URL + `${companyId == 0 ? 'global': 'tenant'}/banner-colour/${companyId == 0 ? '': companyId}`;
      
      const headers = getHeaders();

      let config = {
        method: 'GET',
        url: API_URL,
        data: {},
        headers,
      };
      await axios(config)
        .then(async (res) => {
          if (res && res.status && res.status === 200) {
            if (res.data) {
              
              onChangeCustomColor(rgbToHex(res.data));
               
            } else {
              // default color
            }
          } else {
            // default color
          }
        })
        .catch((error) => {
          // default color
        });
      }

      getColors();
  }, []);


  if (verticalLayout) {
    return (
      <>
        <DashboardHeader onOpenSidebar={() => setOpen(true)} verticalLayout={verticalLayout} />

        {isDesktop ? (
          <NavbarHorizontal />
        ) : (
          <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
        )}

        <Box
          component="main"
          sx={{
            px: { lg: 2 },
            pt: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
            },
            pb: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
            },
          }}
        >
          <Outlet />
        </Box>
      </>
    );
  }



  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
      }}
    >
      {type && <AlertDialog type={type} message={message} openAlert={[showAlert, setShowAlert]} />}

      <DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} />

      <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

      <MainStyle collapseClick={collapseClick}>
        <Outlet />
      </MainStyle>
    </Box>
  );
}
