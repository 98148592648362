import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { List, Box, ListSubheader } from '@mui/material';
//
import { NavListRoot } from './NavList';
import { useState } from 'react';

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shorter,
    }),
  })
);

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  isCollapse: PropTypes.bool,
  navConfig: PropTypes.array,
};

export default function NavSectionVertical({ navConfig, isCollapse = false,adminRole,userRole,  ...other }) {
  const [isActive, setIsActive] = useState(0);
  const [selectedNav, setSelectedNav] = useState()
  const companyId = parseInt(localStorage.getItem('companyId'));
  const isSingleCompanyModeEnabled = localStorage.getItem('enableSingleCompanyMode');

  return (
    <Box {...other}>
      {/* {companyId > 0? */}
      <>
        {navConfig.map((group) => (
          <List key={group.subheader} disablePadding sx={{ px: 2 }}>
            <ListSubheaderStyle
              sx={{
                ...(isCollapse && {
                  opacity: 0,
                }),
              }}
            >
              {group.subheader}
            </ListSubheaderStyle>

            {group.items.map((list, i) =>
              ((!companyId && list.isSuperAdmin == 'true') ||
                (companyId > 0 &&
                  list.isAdmin == 'true' &&
                  (isSingleCompanyModeEnabled === 'true' ||
                    (isSingleCompanyModeEnabled === 'false' && list.id !== 'licenseconfiguration')))) &&
              ((adminRole != 'PRIVILEGED' && adminRole != 'LDAP' && adminRole != 'DATAGUARDIAN') ||
                ((adminRole == 'PRIVILEGED' || adminRole == 'LDAP' || adminRole == 'DATAGUARDIAN') &&
                  list.id == 'system')) ? (
                <NavListRoot key={list.title} list={list} isCollapse={isCollapse} adminRole={adminRole} userRole={userRole} navSelection={[selectedNav, setSelectedNav]} />
              ) : null
            )}
          </List>
        ))}
      </>
      {/* : null
    } */}
    </Box>
  );
}
