import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  tooltipClasses,
  Typography,
} from '@mui/material';
import React, { useDebugValue, useEffect, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Page from 'src/components/Page';
import Scrollbar from 'src/components/Scrollbar';
import useSettings from 'src/hooks/useSettings';
import FolderRoundedIcon from '@mui/icons-material/FolderRounded';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import DescriptionIcon from '@mui/icons-material/Description';
import { useSelector, useDispatch } from 'react-redux';
import { getPath, switchHandle } from '../fileActions';
import { SUCCESS_ALERT, ERROR_ALERT } from 'src/redux/reducers/alertReducer';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { getNodesFolderView } from '../fileActions';
import { styled } from '@mui/styles';
import moment from 'moment';

const columns = ['Private', 'File Name', 'Modified', 'Last Accessed', 'Size/Items'];
const versionColumns = ['File Name', 'Modified', 'Last Accessed', 'Size/Items'];

const FolderView = ({
  fetchChildrenNodes,
  fetchChildrenNodesFolder,
  parentNodeState,
  manageHistory,
  // onBack,
  getClassName,
  counts,
  headpath,
  headid,
  headFolder,
  rootNodes,
  selectCheck,
  pages,
  pageRows,
  detailDataFolder,
  backIdDetail,
  showFolderView,
  searchEmpty,
  backBtnState,
  childParams,
  loadingState,
  fetchData,
  versionInfo
}) => {
  const dispatch = useDispatch();
  const { themeStretch } = useSettings();
  // const theme = useTheme();
  const { nodes: nodesData, maxResultCount: totalCount } = useSelector((state) => state.fileReducer.folderViewNodes);
  const searchPathData = useSelector((state) => state.fileReducer.searchPath);
  const [parentNode, setParentNode] = parentNodeState;
  const [page, setPage] = pages;
  const [pageSize, setPageSize] = pageRows;
  const [typeData, setTypeData] = useState('');
  const [nodeData, setNodeData] = useState([]);
  const [versionData, setVersionData] = versionInfo;
  const [count, setCount] = counts;
  const [idData, setIdData] = backIdDetail;
  const [idArray, setIdArray] = headid;
  const [pathArray, setPathArray] = headpath;
  const [folderNames, setFolderNames] = headFolder;
  const [backDisabled, setBackDisabled] = backBtnState;
  const [selectPathArray, setSelectPathArray] = selectCheck;
  const [detailsArray, setDetailsArray] = detailDataFolder;
  const [searchFolder, setSearchFolder] = searchEmpty;
  const [childrenParams, setChildrenParams] = childParams;
  const [loader, setLoader] = loadingState;
  const [selectAll, setSelectAll] = useState({
    files: false,
    folders: false
  });

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} enterDelay={600} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      fontSize: 13,
      fontWeight: 400,
      maxWidth: '800px',
    },
  }));

  useEffect(() => {
    setNodeData(nodesData);
  }, [nodesData]);

  useEffect(() => {
    if (searchPathData.length) {
      setParentNode(searchPathData[0]);
    }
  }, [searchPathData]);

  const onNodeChange = (node) => {
    setParentNode(node);
    setCount(count + 1);
    if (node.type == 'nt:folder') {
      fetchChildrenNodesFolder(node);
      idData.push(node.parentId);
      setDetailsArray([...detailsArray, node]);
      setVersionData([]);
    }
    setSelectPathArray([]);
    setBackDisabled(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value));
    setPage(0);
  };

  const switchChange = (e, node) => {
    let checked = e.target.checked;
    let id = node.uuid;
    if (checked) {
      dispatch({
        type: SUCCESS_ALERT,
        payload: 'Visibility set to PRIVATE',
      });
      dispatch(switchHandle(id, { classification: 'PRIVATE' }, {}));
      let data = nodeData;
      data.forEach((n) => {
        if (n.uuid === node.uuid) {
          node.classification = 'PRIVATE';
        }
      });
      setNodeData([...data]);
    } else {
      dispatch({
        type: SUCCESS_ALERT,
        payload: 'Visibility set to PUBLIC',
      });
      dispatch(switchHandle(id, { classification: 'PUBLIC' }, {}));
      let data = nodeData;
      data.forEach((n) => {
        if (n.uuid === node.uuid) {
          node.classification = 'PUBLIC';
        }
      });
      setNodeData([...data]);
    }
  };

  const handleVersionData = (versions) => {
    setVersionData(versions);
  };

  const onBack = async (id) => {
    setLoader(true)
    let nodeBackLength = detailsArray.length;
    let nodeBackArray = detailsArray[nodeBackLength - 2]
    setPage(0)
    if (nodeBackArray) {
      await fetchChildrenNodes(nodeBackArray);
    }
    setSearchFolder('');
    let backLength = id.length - 2;
    let backId;
    if (backLength < 0) {
      backId = nodeBackArray?.parentId;
      // setBackDisabled(true);
      let updatePath = pathArray.slice(0, -1);
      let rootPath = updatePath.slice(0, -1);
      setPathArray(updatePath);
      await dispatch(getPath({ path: `/${rootPath[0]}` }));
    }
    if (backLength >= 0) {
      backId = id[backLength];
    }
    let params = { path: backId, pageNo: 1, filesPerPage: 10 };
    const res = await dispatch(getNodesFolderView(params));
    fetchData(res).then(() => { setLoader(false) });
    setChildrenParams(params);
    if (pathArray.length > 3) {
      idArray.pop();
      let updatePath = pathArray.slice(0, -1);
      setPathArray(updatePath);
    }
    if (detailsArray.length) {
      let updateDetails = detailsArray.slice(-2)[0];
      setParentNode(updateDetails);
    }
    let newIdArray = idData;
    newIdArray.pop()
    setIdData([...newIdArray])
    let newArray = detailsArray;
    newArray.pop()
    setDetailsArray(newArray);
    setVersionData([]);
  };

  const checkColoumn = (e, node) => {
    setParentNode(node);
    setCount(count + 1);
    let val = e;
    if (val) {
      setSelectPathArray(prev => [...prev, node]);
    } else {
      let data = selectPathArray;
      let dataHere = data.filter((n) => n.name !== node.name);
      setSelectPathArray([...dataHere]);
    }
  };

  const handleIsSelected = (row) => {
    let val = false;
    if (selectPathArray?.length) {
      selectPathArray?.map((file) => {
        if (file.uuid == row.uuid) {
          val = true;
          return val;
        }
      });
    }
    return val;
  };

  const handleSelectAllFiles = () => {
    setSelectPathArray([]);
    setSelectAll(prev => ({
      ...prev,
      files: !prev.files,
    }))
  }

  const handleSelectAllFolders = () => {
    setSelectPathArray([]);
    setSelectAll(prev => ({
      ...prev,
      folders: !prev.folders
    }))
  }

  useEffect(() => {
    if (selectAll.files && selectAll.folders) {
      nodeData?.map((item) => {
        checkColoumn(true, item);
      })
    } else if (selectAll.folders && !selectAll.files) {
      nodeData?.map((item) => {
        if (item?.type?.toLowerCase().includes('folder')) {
          checkColoumn(true, item);
        }
      })
    } else if (selectAll.files && !selectAll.folders) {
      nodeData?.map((item) => {
        if (item?.type?.toLowerCase().includes('file')) {
          checkColoumn(true, item);
        }
      })
    } else if (!selectAll.files && !selectAll.folders) {
      setSelectPathArray([])
    }
  }, [selectAll])

  return (
    <Page>
      {/* {nodesData && nodesData.length > 0 ? ( */}
      {showFolderView ? (
        <Container maxWidth={themeStretch ? false : 'xl'}>
          <Card>
            <CardContent>
              <TableContainer sx={{ position: 'relative' }}>
                <Scrollbar sx={{ maxHeight: '500px' }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          {manageHistory && manageHistory.length && manageHistory.length > 0 ? (
                            <Button onClick={() => onBack(idData)} disabled={backDisabled}>
                              <ArrowBackIcon />
                            </Button>
                          ) : null}
                        </TableCell>
                        {columns.map((column, i) => (
                          <TableCell sx={{ paddingLeft: '0px' }} key={i}>
                            {column}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    {nodesData && nodesData.length > 0 ? (
                      <TableBody>
                        {nodeData.length &&
                          nodeData.map((node, i) => (
                            <TableRow key={i}>
                              <TableCell sx={{ padding: 0, display: 'flex', alignItems: 'center' }}>
                                <Checkbox checked={handleIsSelected(node)} onChange={(e) => checkColoumn(e.target.checked, node)} />
                                {node['type'] == 'nt:folder' ? (
                                  <FolderRoundedIcon sx={{ color: '#fda92d' }} />
                                ) : node.name.slice(-3).toString() == 'zip' ? (
                                  <FolderZipIcon sx={{ color: '#fda92d' }} />
                                ) : node.name.slice(-3).toString() == 'pdf' ? (
                                  <PictureAsPdfIcon sx={{ color: '#fda92d' }} />
                                ) : node.name.slice(-3).toString() == 'txt' ? (
                                  <DescriptionIcon sx={{ color: '#fda92d' }} />
                                ) : (
                                  //  (node.name.slice(-3)).toString() == "xls"?  <InsertDriveFileIcon sx={{ color: '#fda92d' }} />:
                                  //  (node.name.slice(-3)).toString() == "wav"?  <InsertDriveFileIcon sx={{ color: '#fda92d' }} />:
                                  <InsertDriveFileIcon sx={{ color: '#fda92d' }} />
                                )}
                                {/* <i className={getClassName(node.iconUrl, node.name)}></i> */}
                              </TableCell>
                              <TableCell sx={{ p: 0 }}>
                                {node['type'] != 'nt:folder' && (
                                  <Switch
                                    onChange={(e) => switchChange(e, node)}
                                    checked={node.classification == 'PRIVATE' ? true : false}
                                  />
                                )}
                              </TableCell>
                              <TableCell sx={{ padding: 0, cursor: 'pointer' }} onClick={() => onNodeChange(node)}>
                                {node['type'] != 'nt:folder' ? (
                                  <LightTooltip title={node.name}>
                                    <Typography
                                      variant="p"
                                      sx={{ color: 'black' }
                                        // (node['type'] != 'nt:folder' && node['partiallyArchived'] == 1) ||
                                        //   node['isDeleted']
                                        //   ? { color: '#039be5' }
                                        //   : { color: 'black' }
                                      }
                                    >
                                      {node.name.slice(0, 30)}
                                    </Typography>
                                  </LightTooltip>
                                ) : (
                                  node.name.slice(0, 30)
                                )}
                                {node.nextVersions && (
                                  <Button
                                    onClick={() => handleVersionData(node.nextVersions)}
                                    sx={{ minWidth: 'fit-content', p: 0 }}
                                  >
                                    {' '}
                                    <FileCopyIcon />{' '}
                                  </Button>
                                )}
                              </TableCell>
                              <Tooltip title={node.lastModified && moment(new Date(node.lastModified)).format('DD-MM-YYYY-hh:mm')}><TableCell sx={{ padding: 0 }}>{node.lastModified && moment(new Date(node.lastModified)).format('YYYY-MM-DD')}</TableCell></Tooltip>
                              <Tooltip title={node.lastAccessed && moment(new Date(node.lastAccessed)).format('DD-MM-YYYY-hh:mm')}><TableCell sx={{ padding: 0 }}>{node.lastAccessed && moment(new Date(node.lastAccessed)).format('YYYY-MM-DD')}</TableCell></Tooltip>
                              <TableCell sx={{ padding: 0 }}>{node.fileSize}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    ) : null}
                  </Table>
                </Scrollbar>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 20, 100]}
                  component="div"
                  count={totalCount}
                  rowsPerPage={pageSize}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <Box className="table-check-wrapper">
                  <FormControlLabel control={<Checkbox checked={selectAll.files} onChange={handleSelectAllFiles} name="files" />} label="Select All Files" />
                  <FormControlLabel control={<Checkbox checked={selectAll.folders} onChange={handleSelectAllFolders} name="folders" />} label="Select All Folders" />
                </Box>
              </TableContainer>
              {versionData.length ? (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          {manageHistory && manageHistory.length && manageHistory.length > 0 ? (
                            <Button onClick={() => setVersionData([])}>
                              <ArrowBackIcon />
                            </Button>
                          ) : null}
                        </TableCell>
                        {versionColumns.map((column, i) => (
                          <TableCell sx={{ paddingLeft: '0px' }} key={i}>
                            {column}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {versionData.length &&
                        versionData.map((node, i) => (
                          <TableRow key={i}>
                            <TableCell sx={{ padding: 0, display: 'flex', alignItems: 'center' }}>
                              <Checkbox checked={handleIsSelected(node)} onChange={(e) => checkColoumn(e.target.checked, node)} />
                              {node['type'] == 'nt:folder' ? (
                                <FolderRoundedIcon sx={{ color: '#fda92d' }} />
                              ) : node.name.slice(-3).toString() == 'zip' ? (
                                <FolderZipIcon sx={{ color: '#fda92d' }} />
                              ) : node.name.slice(-3).toString() == 'pdf' ? (
                                <PictureAsPdfIcon sx={{ color: '#fda92d' }} />
                              ) : node.name.slice(-3).toString() == 'txt' ? (
                                <DescriptionIcon sx={{ color: '#fda92d' }} />
                              ) : (
                                //  (node.name.slice(-3)).toString() == "xls"?  <InsertDriveFileIcon sx={{ color: '#fda92d' }} />:
                                //  (node.name.slice(-3)).toString() == "wav"?  <InsertDriveFileIcon sx={{ color: '#fda92d' }} />:
                                <InsertDriveFileIcon sx={{ color: '#fda92d' }} />
                              )}
                              {/* <i className={getClassName(node.iconUrl, node.name)}></i> */}
                            </TableCell>
                            <TableCell sx={{ padding: 0, cursor: 'pointer' }} onClick={() => onNodeChange(node)}>
                              {node.name}
                              {node.nextVersions && (
                                <Button
                                  /* onClick={() => setSearchFolder('')} */ sx={{ minWidth: 'fit-content', p: 0 }}
                                >
                                  {' '}
                                  <FileCopyIcon />{' '}
                                </Button>
                              )}
                            </TableCell>
                            <Tooltip title={node.lastModified && moment(new Date(node.lastModified)).format('DD-MM-YYYY-hh:mm')}><TableCell sx={{ padding: 0 }}>{node.lastModified && moment(node.lastModified).format('YYYY-MM-DD')}</TableCell></Tooltip>
                            <Tooltip title={node.lastAccessed && moment(new Date(node.lastAccessed)).format('DD-MM-YYYY-hh:mm')}><TableCell sx={{ padding: 0 }}>{node.lastAccessed && moment(node.lastAccessed).format('YYYY-MM-DD')}</TableCell></Tooltip>
                            <TableCell sx={{ padding: 0 }}>{node.fileSize}</TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : null}
            </CardContent>
          </Card>
        </Container>
      ) : null}
    </Page>
  );
};

export default FolderView;
