import { GetDetails, postInfo } from "src/utils/fetchServices";
import { SUCCESS_ALERT } from 'src/redux/reducers/alertReducer';
export const ACCOUNT_POLICY_FETCH_SUCCESS = 'ACCOUNT_POLICY_FETCH_SUCCESS';
export const POST_SUCCESS = 'POST_SUCCESS';
export const DATA_LOADER = 'DATA_LOADER';
export const FETCH_FAIL = 'FETCH_FAIL';

export const getAccountPolicy = () => async (dispatch) => {
  // dispatch({
  //   type: DATA_LOADER,
  // });
  const companyId = localStorage.getItem('companyId');
  try {
    const response = await GetDetails({ url: `system-codes/account-policy/company/${companyId}` });
    const data = response.data;
    dispatch({
      type: ACCOUNT_POLICY_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};
export const postAccountPolicy = (body) => async (dispatch) => {
  dispatch({type: DATA_LOADER});
  try {
    const companyId = localStorage.getItem('companyId');
    const response = await postInfo({ url: `system-codes/account-policy/company/${companyId}`, body }, dispatch);
    if(response && response.status === 200){
      dispatch({type: POST_SUCCESS});
      dispatch({
        type: SUCCESS_ALERT,
        payload: 'Account Policy Created Successfully',
      });
    }else {
      dispatch({type: FETCH_FAIL});
    }
    
  } catch (error) {
    dispatch({type: FETCH_FAIL});
    console.error("error postAccountPolicy", error.message)
  }
};