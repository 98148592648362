import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Grid } from '@mui/material';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import LoadingScreen from 'src/components/LoadingScreen';
import { getStubAgents, refreshStub } from './stubActions';
import StubTable from './components/StubTable';

export default function StubAgent() {
  const dispatch = useDispatch();
  const { themeStretch } = useSettings();
  const stubAgents = useSelector((state) => state.stubReducer.stubAgents);
  const loading = useSelector((state) => state.stubReducer.loading);

  useEffect(() => {
    dispatch(getStubAgents());
  }, []);

  const refreshStubAgent = (host) => {
    dispatch(refreshStub({hostName:host}));
  }

  const columns = ['Host Name', 'Agent Status', 'Files In Staging', 'Stub Queue', 'Stubs Error', 'Retry Stub Error', 'Show Errors', 'Refresh'];

  return (
    <Page>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} style={{ paddingTop: '1px' }}>
            { !loading ? (
              stubAgents &&
              <StubTable
                stubAgents={stubAgents}
                columns={columns}
                refreshStubAgent={refreshStubAgent}
              />
            ): <LoadingScreen/>}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
