import { ERROR_ALERT, SUCCESS_ALERT } from 'src/redux/reducers/alertReducer';
import { deleteInfo, GetDetails, GetFetch, postInfo, putDetails } from 'src/utils/fetchServices';

export const DOCUMENTS_FETCH_SUCCESS = 'DOCUMENTS_FETCH_SUCCESS';
export const DOCUMENT_INFO_FETCH_SUCCESS = 'DOCUMENT_INFO_FETCH_SUCCESS';
export const UPDATED_SUCCESSFULLY = 'UPDATED_SUCCESSFULLY';
export const DELETED_SUCCESSFULLY = 'DELETED_SUCCESSFULLY';
export const ADD_SUCCESSFULLY = 'ADD_SUCCESSFULLY';
export const DATA_LOADER = 'DATA_LOADER';
export const FETCH_FAIL = 'FETCH_FAIL';

export const getDocumentList = (params) => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  // dispatch({
  //   type: DATA_LOADER,
  // });
  // params.sort = 'name,desc';
  try {
    const response = await GetFetch({ url: `categories/company/${companyId}`, params });
    const data = response.data;
    dispatch({
      type: DOCUMENTS_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};

export const getDocumentInfo = (id) => async (dispatch) => {
  dispatch({
    type: DATA_LOADER,
  });
  try {
    const response = await GetDetails({ url: `categories/${id}` });
    const data = response.data;
    dispatch({
      type: DOCUMENT_INFO_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};
export const deleteDocument = (id) => async (dispatch) => {
  try {
    const response = await deleteInfo({ url: `categories/${id}` });
    const data = response.data;
    dispatch({
      type: DELETED_SUCCESSFULLY,
      payload: data,
    });
    dispatch({
      type: SUCCESS_ALERT,
      payload: 'Document deleted successfully.',
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
    dispatch({
      type: ERROR_ALERT,
      payload:'Delete Failed.',
    });
  }
};
export const updateDocument = (body, navigate) => async (dispatch) => {
  dispatch({
    type: DATA_LOADER,
  });
  try {
    const response = await putDetails({ url: `categories`, body });
    const data = response.data;
    dispatch({
      type: UPDATED_SUCCESSFULLY,
      payload: data,
    });
    dispatch({
      type: SUCCESS_ALERT,
      payload:'Document updated successfully.',
    });
    navigate('/dashboard/document-classifier');
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
    dispatch({
      type: ERROR_ALERT,
      payload: 'Update Failed.',
    });
  }
};
export const updateIndexContent = (body) => async (dispatch) => {
  try {
    const response = await putDetails({ url: `categories`, body });
    const data = response.data;
    dispatch({
      type: UPDATED_SUCCESSFULLY,
      payload: data,
    });
    dispatch({
      type: SUCCESS_ALERT,
      payload:'Document Classification Updated Successfully.',
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
    dispatch({
      type: ERROR_ALERT,
      payload: 'Document Classification Update Failed.',
    });
  }
};

export const addDocument = (body, navigate) => async (dispatch) => {
  dispatch({
    type: DATA_LOADER,
  });

  try {
    const response = await postInfo({ url: `categories`, body }, dispatch);
    if(response && response.status === 200){
      const data = response.data;
      dispatch({
        type: ADD_SUCCESSFULLY,
        payload: data,
      });
      dispatch({
        type: SUCCESS_ALERT,
        payload: 'Document added successfully.',
      });
      navigate('/dashboard/document-classifier');
    }else{
      dispatch({type: FETCH_FAIL});
    }
  } catch (error) {
    dispatch({type: FETCH_FAIL});
  }
};
