import { Stack, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BASE_URL } from 'src/config';
import { dispatch } from 'src/redux/store';
import { getHeaders } from 'src/utils/fetchServices';
export const EXPIRE_DETAILS = 'EXPIRE_DETAILS'

const LicenseExpire = () => {
  const headers = getHeaders();

  const [licenseMessage, setLicenseMessage] = useState('');
  const [isLicenseKeyExpiredResult, setIsLicenseKeyExpiredResult] = useState(true);
  const [isTrialLicense, setIsTrialLicense] = useState(false);

  useEffect(() => {
    axios.get(`${BASE_URL}license`, { headers }).then((response) => {
      const data = response.data;
      if (data.email) {
        const expiryDate = new Date(data.expiryDate);
        let Difference_In_Time = expiryDate.getTime() - new Date().getTime();
        let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

        if (data.mode.toLowerCase() === 'trial') {
          setIsTrialLicense(true);
          if (Difference_In_Days > 0) {
            setLicenseMessage(`ShareArchiver  Trial Expires in ${Difference_In_Days.toFixed(0)} days.`);
          } else {
            setLicenseMessage(`ShareArchiver Trial Expired - Please contact your reseller/vendor to request a license`);
          }
          setIsLicenseKeyExpiredResult(true);
        } else if (Difference_In_Days < 1) {
          setIsLicenseKeyExpiredResult(true);
          dispatch({
            type :EXPIRE_DETAILS,
            payload : true
          })
          setLicenseMessage(`ShareArchiver Expired - Please contact your reseller/vendor to request a license`);
        } else if (Difference_In_Days < 60) {
          setIsLicenseKeyExpiredResult(true);

          setLicenseMessage(`ShareArchiver Expires in ${Difference_In_Days.toFixed(0)} days.`);
        }
      } else {
      }
    });
  },[]);
  return (
    <>
      {isLicenseKeyExpiredResult || isTrialLicense ? (
        <Stack alignItems="center" sx={{ width: '100%', backgroundColor: 'red' }}>
          <Typography>{licenseMessage}</Typography>
        </Stack>
      ) : null}
    </>
  );
};

export default LicenseExpire;
