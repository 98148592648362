import { Button, Card, Container, Grid, Modal, TextField, Typography, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import { ERROR_ALERT, SUCCESS_ALERT } from 'src/redux/reducers/alertReducer';
import copiedFunction from 'src/utils/copyMethod';
import { putDetails } from 'src/utils/fetchServices';
// import { getAccountPolicy } from '../../account-policy/accountPolicyActions';
// import { resetPasswordData } from '../usersActions';

const ResetPassword = ({ openModal, close, id }) => {
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  // const [passwordDialog, setPasswordDialog] = openDialog;
  const [enableBtn, setEnableBtn] = useState(true);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  //   const accountPolicy = useSelector((state) => state.accountPolicyReducer.accountPolicy);
  const [passLength, setPassLength] = useState(6);

  useEffect(() => {
    //   dispatch(getAccountPolicy());
  }, []);
  //   useEffect(() => {
  //     setPassLength(accountPolicy.passwordMinimumLength || 6);
  //   }, [accountPolicy]);
  const generatePassword = () => {
    dispatch({
      type: SUCCESS_ALERT,
      payload: 'New Password Generated',
    });
  };

  useEffect(() => {
    if (newPassword == confirmPassword) {
      setEnableBtn(false);
    }
    if (newPassword != confirmPassword) {
      setEnableBtn(true);
    }
    if (newPassword == '' || confirmPassword == '') {
      setEnableBtn(true);
    }
  }, [newPassword, confirmPassword]);

  const resetPasswordData = (body) => async (dispatch) => {
    try {
      const response = await putDetails({ url: `users/reset-password`, body });
      const data = response.data;
      dispatch({
        type: SUCCESS_ALERT,
        payload: 'Reset Password Successfully',
      });
    } catch (error) {
      dispatch({
        type: ERROR_ALERT,
        payload: 'Password does not meet pattern',
      });
    }
  };

  const resetPassword = () => {
    dispatch(
      resetPasswordData({
        id:'3',
        newPassword,
        confirmPassword,
      })
    );
  };

  return (
    <>
      <Modal
        open={openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Page sx={{ width: '100%' }}>
          <Container maxWidth={themeStretch ? false : 'xl'}>
            <Card sx={{ backgroundColor: '#ffff', p: '40px' }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    name="newPassword"
                    label="New Password"
                    type="password"
                    fullWidth
                    variant="standard"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    error={newPassword === ''}
                    helperText={newPassword === '' ? 'Password Required' : ' '}
                  />
                  <TextField
                    margin="dense"
                    id="name"
                    name="confirmPassword"
                    label="Confirm Password"
                    type="password"
                    fullWidth
                    variant="standard"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    error={confirmPassword === '' || confirmPassword != newPassword}
                    helperText={
                      confirmPassword === ''
                        ? 'Password Required'
                        : confirmPassword != newPassword
                        ? 'Password Not Match'
                        : ''
                    }
                  />
                </Grid>
                <Grid item xs={12} md={12} spacing={1}>
                  <Button variant="contained" sx={{ color: '#fff' }} onClick={() => generatePassword()}>
                    Generate Password
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ color: '#fff', m: '10px' }}
                    disabled={enableBtn}
                    onClick={() => copiedFunction(confirmPassword, dispatch)}
                  >
                    Copy Password
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ color: '#fff', m: '10px' }}
                    disabled={enableBtn} onClick={()=>resetPassword()}
                  >
                    Reset Password
                  </Button>
                  <Button variant="contained" sx={{ color: '#fff', m: '10px' }} onClick={close}>
                    Close
                  </Button>
                </Grid>

                {/* <Grid item xs={12} md={12}>
                  <Stack gap={2}>
                    <Typography variant="h6">Password Policy:</Typography>
                    <Typography>
                      Required min length : {accountPolicy && accountPolicy.passwordMinimumLength}
                    </Typography>
                    <Typography>
                      Capital Letter Required :{accountPolicy && accountPolicy.enforceCapsInPasswords ? ' Yes' : ' No'}
                    </Typography>
                    <Typography>
                      Numbers Required :{accountPolicy && accountPolicy.enforceNumbersInPasswords ? ' Yes' : ' No'}
                    </Typography>
                    <Typography>
                      Special Characters Required:{' '}
                      {accountPolicy && accountPolicy.enforceSpecialCharactersInPasswords ? ' Yes' : ' No'}
                    </Typography>
                  </Stack>
                </Grid> */}
              </Grid>
            </Card>
          </Container>
        </Page>
      </Modal>
    </>
  );
};

export default ResetPassword;
