import { ERROR_ALERT, SUCCESS_ALERT,PROGRESS_DONE,PROGRESS_START } from "src/redux/reducers/alertReducer";
import { GetDetails, GetFetch, postInfo } from "src/utils/fetchServices";

export const COMPANY_FETCH_SUCCESS = 'COMPANY_FETCH_SUCCESS';
export const COMPANY_INFO_SUCCESS = 'COMPANY_INFO_SUCCESS';
export const DATA_LOADER = 'DATA_LOADER';
export const SUCCESS = 'SUCCESS';
export const FETCH_FAIL = 'FETCH_FAIL';

export const getCompanyList = (params) => async (dispatch) => {
  dispatch({
    type: DATA_LOADER,
  });
  params.sort = 'id,desc';
  try {
    const response = await GetFetch({ url: `companies`, params });
    const data = response.data;
    dispatch({
      type: COMPANY_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};
export const getCompanyInfo = (id) => async (dispatch) => {
  dispatch({
    type: DATA_LOADER,
  });
  try {
    const response = await GetDetails({ url: `companies/${id}` });
    const data = response.data;
    dispatch({
      type: COMPANY_INFO_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};

export const addNewCompany = (body, navigate) => async (dispatch) => {
  // dispatch({
  //   type: DATA_LOADER,
  // });
  dispatch({
    type: PROGRESS_START,
  });
  try {
    const response = await postInfo({ url: `companies`, body });
    if(response.status === 200){
      dispatch({
        type: SUCCESS,
      });
      dispatch({
        type: SUCCESS_ALERT,
        payload: 'Company Added Successfully'
      });
      navigate('/dashboard/company-list')
    }else{
      dispatch({
        type: FETCH_FAIL
      });
      dispatch({
        type: ERROR_ALERT,
        payload: response.data.message || 'Somthing Went Wrong.'
      });
    }
    
  } catch (error) {
    
  }
  dispatch({
    type: PROGRESS_DONE,
  });
};
export const updateCompany = (body, navigate) => async (dispatch) => {
  dispatch({
    type: DATA_LOADER,
  });
  try {
    const response = await postInfo({ url: `companies`, body, method:'put' });
    if(response.status === 200){
     
      dispatch({
        type: SUCCESS,
      });
      dispatch({
        type: SUCCESS_ALERT,
        payload: 'Company Updated Successfully'
      });
      navigate('/dashboard/company-list')
    }else{
      dispatch({
        type: FETCH_FAIL
      });
      dispatch({
        type: ERROR_ALERT,
        payload: response.data.message || 'Somthing Went Wrong.'
      });
    }
    
  } catch (error) {
    
  }
};