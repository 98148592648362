import { deleteInfo, GetDetails, GetFetch, postInfo, putDetails } from 'src/utils/fetchServices';
import { SUCCESS_ALERT,ERROR_ALERT } from 'src/redux/reducers/alertReducer';
export const FILE_STORE_FETCH_SUCCESS = 'FILE_STORE_FETCH_SUCCESS';
export const ADD_CLOUD_PROFILE_SUCCESS = 'ADD_CLOUD_PROFILE_SUCCESS';
export const FILESTORE_INFO_FETCH_SUCCESS = 'FILESTORE_INFO_FETCH_SUCCESS';
export const PROFILE_INFO_FETCH_SUCCESS = 'PROFILE_INFO_FETCH_SUCCESS';
export const REGION_FETCH_SUCCESS = 'REGION_FETCH_SUCCESS';
export const USER_PROFILE_FETCH_SUCCESS = 'USER_PROFILE_FETCH_SUCCESS';
export const ADD_FILESTORE_SUCCESS = 'ADD_FILESTORE_SUCCESS';
export const CLOUD_PROFILE_FETCH_SUCCESS = 'CLOUD_PROFILE_FETCH_SUCCESS';
export const DELETED_SUCCESSFULLY = 'DELETED_SUCCESSFULLY';
export const SUCCESS = 'SUCCESS';
export const DATA_LOADER = 'DATA_LOADER';
export const FETCH_FAIL = 'FETCH_FAIL';
export const FILE_TYPES_DEFAULT_SUCCESS = 'FILE_TYPES_DEFAULT_SUCCESS'

export const getFileStoreList = (params) => async (dispatch) => {
  dispatch({
    type: DATA_LOADER,
  });
  params.sort = 'id,asc';
  const companyId = localStorage.getItem('companyId');
  try {
    const response = await GetFetch({ url: `file-stores/company/${companyId}`, params });
    const data = response.data;
    dispatch({
      type: FILE_STORE_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};
export const getUserProfile = (type) => async (dispatch) => {
  dispatch({
    type: DATA_LOADER,
  });
  const companyId = localStorage.getItem('companyId');
  try {
    const response = await GetDetails({ url: `cloud-profile/${companyId}/${type}` });
    const data = response.data;
    dispatch({
      type: USER_PROFILE_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};
export const getProfileInfo = (id) => async (dispatch) => {
  dispatch({
    type: DATA_LOADER,
  });
  const companyId = localStorage.getItem('companyId');
  try {
    const response = await GetDetails({ url: `cloud-profile/${id}` });
    const data = response.data;
    dispatch({
      type: PROFILE_INFO_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};
export const getCloudProfile = (type, params) => async (dispatch) => {
  dispatch({
    type: DATA_LOADER,
  });
  params.sort = 'id,asc';
  const companyId = localStorage.getItem('companyId');
  try {
    const response = await GetFetch({ url: `cloud-profile/${companyId}/${type}`, params });
    const data = response.data;
    dispatch({
      type: CLOUD_PROFILE_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};

export const getAwsRegion = () => async (dispatch) => {
  // dispatch({
  //   type: DATA_LOADER,
  // });
  try {
    const response = await GetDetails({ url: `file-stores/aws-regions` });
    const data = response.data;
    dispatch({
      type: REGION_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};

export const addFileStore = (body) => async (dispatch) => {
  dispatch({
    type: DATA_LOADER,
  });
  try {
    const response = await putDetails({ url: 'file-stores', body });
    const data = response.data;
    dispatch({
      type: ADD_FILESTORE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};
export const getFileStoreInfo = (id) => async (dispatch) => {
  dispatch({
    type: DATA_LOADER,
  });
  try {
    const response = await GetDetails({ url: `file-stores/${id}` });
    const data = response.data;
    dispatch({
      type: FILESTORE_INFO_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};
export const deleteFileStore = (id) => async (dispatch) => {
  dispatch({
    type: DATA_LOADER,
  });
  try {
    const response = await deleteInfo({ url: `file-stores/${id}` });
    const data = response.data;
    dispatch({
      type: DELETED_SUCCESSFULLY,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};
export const updateProfile = (body, method,url,navigate) => async (dispatch) => {
  try {
    const response = await postInfo(
      {
        url:url || `cloud-profile`,
        body,
        method: method|| 'POST',
      },
      dispatch
    );
    if (response && response.status === 200) {
      const data = response.data;
      dispatch({
        type: SUCCESS,
        payload: data,
      });
      if (method == 'PUT') {
        dispatch({
          type: SUCCESS_ALERT,
          payload:'Updated Successfully',
        });
      }else{
        dispatch({
          type: SUCCESS_ALERT,
          payload:'Stored Successfully ',
        });
      }
      body?.paramsId &&  await dispatch(getFileStoreInfo(body?.paramsId))
      if(body.fileStoreBase){
        dispatch(getUserProfile(body.fileStoreBase));
      }
      // navigate('/dashboard/volume-management/profile') 
    } else {
      dispatch({ 
        type: ERROR_ALERT, 
        payload: response.data.message, 
      });
    }
  } catch (error) {
    console.log("error",error);
    dispatch({ type: FETCH_FAIL });
  }
};

export const updateCloudProfile = (body) => async (dispatch) => {
  dispatch({
    type: DATA_LOADER,
  });
  try {
    const response = await putDetails({ url: 'cloud-profile', body });
    const data = response.data;
    dispatch({
      type: ADD_CLOUD_PROFILE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};

export const deleteProfile = (id) => async (dispatch) => {
  dispatch({
    type: DATA_LOADER,
  });
  try {
    const response = await deleteInfo({ url: `cloud-profile/${id}` });
    const data = response.data;
    dispatch({
      type: DELETED_SUCCESSFULLY,
    });
    dispatch({
      type: SUCCESS_ALERT,
      payload:'Deleted'
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};

export const getDefaultValue = (type) => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  try {
    const response = await GetDetails({ url: `system-codes/global-configuration/company/${companyId}` });
    const data = response.data;
    dispatch({
      type: FILE_TYPES_DEFAULT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};
