import { GetFetch, postInfo} from 'src/utils/fetchServices';
import { SUCCESS_ALERT } from 'src/redux/reducers/alertReducer';

export const AUDITACCESS_FETCH_SUCCESS = 'AUDITACCESS_FETCH_SUCCESS';
export const USERS_ACCESS_FETCH_SUCCESS = 'USERS_ACCESS_FETCH_SUCCESS';
export const DATA_LOADER = 'DATA_LOADER';
export const FETCH_FAIL = 'FETCH_FAIL';
export const DOWNLOAD_SUCCESS = 'DOWNLOAD_SUCCESS';


export const getAuditUsers = (body, handleSearchTime) => async (dispatch) => {
  let startTime = new Date().getTime();
    const companyId = localStorage.getItem('companyId');
    dispatch({
      type: DATA_LOADER,
    });
    try {
      const response = await postInfo({ 
        url: `audit-log-index/${companyId}`,
        body,
    });
    const data = response.data;
      dispatch({
        type: AUDITACCESS_FETCH_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_FAIL,
        payload: error.message,
      });
    }
  let endTime = new Date().getTime();
  handleSearchTime(startTime, endTime)
};


export const getUsersAccess = (params, handleSearchTime) => async (dispatch) => {
  let startTime = new Date().getTime();
    const companyId = localStorage.getItem('companyId');
    dispatch({
      type: DATA_LOADER,
    });
    try {
      const response = await GetFetch({ 
        url: `analysis/company/${companyId}/user-data-access`,
        params
    });
    const data = response.data;
      dispatch({
        type: USERS_ACCESS_FETCH_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_FAIL,
        payload: error.message || 'Something went wrong',
      });
    }
  let endTime = new Date().getTime();
  handleSearchTime(startTime, endTime)
};



export  const downloadFile = (body) => async (dispatch) => {
    const companyId = localStorage.getItem('companyId');
    try {
      const response = await postInfo({ 
        url: `audit-log-index/export-to-csv/${companyId}`,
        body,
    });
      const data = response.data;
      var blob = new Blob([data]);
      let url = window.URL.createObjectURL(blob);
      let tempLink = document.createElement('a');
      tempLink.href = url;
      tempLink.setAttribute('download', 'Audit Access Logs.csv');
      tempLink.click();
      dispatch({
        type: DOWNLOAD_SUCCESS,
        payload: data,
      });
      dispatch({
        type: SUCCESS_ALERT,
        payload: 'Files exported successfully',
      });
    } catch (error) {
      dispatch({
        type: FETCH_FAIL,
        payload: error.message,
      });
    }
};