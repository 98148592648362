
import { PATH_DASHBOARD } from './routes/paths';
export const HOST_API = process.env.REACT_APP_HOST_API_KEY || '';
export const MAPBOX_API = process.env.REACT_APP_MAPBOX;
export const BASE_URL = '/archiver/api/'
export const LOGIN_BASE_URL = '/archiver/'
export const LOGO_URL = '/archiver/api/tenant/logo/'

export const PATH_AFTER_LOGIN = PATH_DASHBOARD.system.systemStatus;

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 290,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

export const defaultSettings = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeColorPresets: 'default',
  themeLayout: 'horizontal',
  themeStretch: false,
};

export const MONTH = {
  MONTH_0: "Jan",
  MONTH_1: "Feb",
  MONTH_2: "Mar",
  MONTH_3: "Apr",
  MONTH_4: "May",
  MONTH_5: "Jun",
  MONTH_6: "Jul",
  MONTH_7: "Aug",
  MONTH_8: "Sep",
  MONTH_9: "Oct",
  MONTH_10: "Nov",
  MONTH_11: "Dec",
}
