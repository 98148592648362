import {
  Button,
  Card,
  CardHeader,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React, { useEffect, useState, useMemo } from 'react';
import { isEmpty } from 'lodash';
import LoadingScreen from 'src/components/LoadingScreen';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountPolicy, postAccountPolicy } from './accountPolicyActions';

const schema = yup.object().shape({
  passwordMinimumLength: yup.string().required('required'),
  passwordExpiryTime: yup.string().required('required'),
  noOfTriesBeforeAccountLock: yup.string().required('required'),
  accountLockTimeOutPeriod: yup.string().required('required'),
});

const AccountPolicy = () => {
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const accountPolicy = useSelector((state) => state.accountPolicyReducer.accountPolicy);
  const loading = useSelector((state) => state.accountPolicyReducer.loading);
  const [policies, setPolicies] = useState({});
  const [checkboxes, setCheckboxes] = useState({
    enforceCapsInPasswords: false,
    enforceNumbersInPasswords: false,
    enforceSpecialCharactersInPasswords: false,
  });

  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setPolicies(accountPolicy);
    if (!isEmpty(accountPolicy)) {
      reset(accountPolicy);
      setCheckboxes({
        enforceCapsInPasswords: accountPolicy.enforceCapsInPasswords,
        enforceNumbersInPasswords: accountPolicy.enforceNumbersInPasswords,
        enforceSpecialCharactersInPasswords: accountPolicy.enforceSpecialCharactersInPasswords,
      });
    }
  }, [accountPolicy]);

  useEffect(() => {
    dispatch(getAccountPolicy());
  }, []);

  const onSubmit = (data) => {
    dispatch(
      postAccountPolicy({
        passwordExpiryTime: data.passwordExpiryTime,
        noOfTriesBeforeAccountLock: data.noOfTriesBeforeAccountLock,
        accountLockTimeOutPeriod: data.accountLockTimeOutPeriod,
        passwordMinimumLength: data.passwordMinimumLength,
        enforceCapsInPasswords: checkboxes.enforceCapsInPasswords,
        enforceNumbersInPasswords: checkboxes.enforceNumbersInPasswords,
        enforceSpecialCharactersInPasswords: checkboxes.enforceSpecialCharactersInPasswords,
      })
    );
  };
  const handleChange = (e) => {
    clearErrors(e.target.name);
    setPolicies({ ...policies, [e.target.name]: e.target.value });
  };

  return (
    <Page>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        {!loading ? (
          <Card sx={{ p: '20px' }}>
            <CardHeader title="Account Policy" />
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2} sx={{ m: '10px' }}>
                <Grid item xs={12} md={5.5}>
                  <TextField
                    name="passwordMinimumLength"
                    // value={policies.passwordMinimumLength}
                    label="Min. Password Length"
                    helperText={errors.passwordMinimumLength && errors.passwordMinimumLength.message}
                    error={errors.passwordMinimumLength && errors.passwordMinimumLength.message}
                    {...register('passwordMinimumLength')}
                    variant="standard"
                    sx={{ width: '100%' }}
                    // onChange={handleChange}
                    required
                    InputLabelProps={{
                      shrink: true,
                  }}
                  />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <TextField
                    name="passwordExpiryTime"
                    label="Password Expiry (days)"
                    // value={policies.passwordExpiryTime}
                    helperText={errors.passwordExpiryTime && errors.passwordExpiryTime.message}
                    error={errors.passwordExpiryTime && errors.passwordExpiryTime.message}
                    {...register('passwordExpiryTime')}
                    variant="standard"
                    sx={{ width: '100%' }}
                    // onChange={handleChange}
                    required
                    InputLabelProps={{
                      shrink: true,
                  }}
                  />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <TextField
                    name="noOfTriesBeforeAccountLock"
                    label="Allow Failed Login Attempts"
                    // value={policies.noOfTriesBeforeAccountLock}
                    helperText={errors.noOfTriesBeforeAccountLock && errors.noOfTriesBeforeAccountLock.message}
                    error={errors.noOfTriesBeforeAccountLock && errors.noOfTriesBeforeAccountLock.message}
                    {...register('noOfTriesBeforeAccountLock')}
                    variant="standard"
                    sx={{ width: '100%' }}
                    // onChange={handleChange}
                    required
                    InputLabelProps={{
                      shrink: true,
                  }}
                  />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <TextField
                    name="accountLockTimeOutPeriod"
                    label="Lock Timeout(min(s))"
                    // value={policies.accountLockTimeOutPeriod}
                    helperText={errors.accountLockTimeOutPeriod && errors.accountLockTimeOutPeriod.message}
                    error={errors.accountLockTimeOutPeriod && errors.accountLockTimeOutPeriod.message}
                    {...register('accountLockTimeOutPeriod')}
                    variant="standard"
                    sx={{ width: '100%' }}
                    // onChange={handleChange}
                    required
                    InputLabelProps={{
                      shrink: true,
                  }}
                  />
                </Grid>
                <Grid item xs={12} md={3.5}>
                  <FormControlLabel
                    name="enforceCapsInPasswords"
                    checked={checkboxes.enforceCapsInPasswords}
                    // checked={getValues("enforceCapsInPasswords")}
                    control={<Checkbox />}
                    label=" Capital Letters"
                    {...register('enforceCapsInPasswords')}
                    onChange={(e) =>
                      setCheckboxes({ ...checkboxes, [e.target.name]: !checkboxes.enforceCapsInPasswords })
                    }
                    // onChange={(e) => setValue("enforceCapsInPasswords", e.target.checked)}
                  />
                </Grid>
                <Grid item xs={12} md={3.5}>
                  <FormControlLabel
                    name="enforceNumbersInPasswords"
                    checked={checkboxes.enforceNumbersInPasswords}
                    // checked={getValues("enforceNumbersInPasswords")}
                    control={<Checkbox />}
                    label="Numbers"
                    {...register('enforceNumbersInPasswords')}
                    onChange={(e) =>
                      setCheckboxes({ ...checkboxes, [e.target.name]: !checkboxes.enforceNumbersInPasswords })
                    }
                  />
                </Grid>
                <Grid item xs={12} md={3.5}>
                  <FormControlLabel
                    name="enforceSpecialCharactersInPasswords"
                    checked={checkboxes.enforceSpecialCharactersInPasswords}
                    control={<Checkbox />}
                    label="Special Characters"
                    {...register('enforceSpecialCharactersInPasswords')}
                    onChange={(e) =>
                      setCheckboxes({ ...checkboxes, [e.target.name]: !checkboxes.enforceSpecialCharactersInPasswords })
                    }
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <Button type="submit" variant="contained" sx={{ color: '#ffff' }}>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Card>
        ) : (
          <LoadingScreen />
        )}
      </Container>
    </Page>
  );
};

export default AccountPolicy;
