import {
  Button,
  Card,
  CardHeader,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import { testEmail } from '../globalActions';

const EmailConfiguration = ({ register, errors, watch, setValue }) => {
  const dispatch = useDispatch();
  const { themeStretch } = useSettings();
  const [smtpAuth, setSmtpAuth] = useState(false);
  const [emailTest, setEmailTest] = useState(true);
  const [testBtn,setTestBtn]= useState("")
  const emailProtocols = [
    { name: 'Basic(unencrypted)', value: 'basic' },
    { name: 'TLS', value: 'TLS' },
    { name: 'SSL', value: 'SSL' },
  ];
  const emailTesting = () => {
    dispatch(
      testEmail({
        host: watch('smptHost'),
        protocol: watch('emailSecProtocol'),
        port: watch('smptPort'),
        userName: watch('emailUserName'),
        password: watch('emailPassword'),
        sendFrom: watch('emailFrom'),
        sendTo: watch('emailTo'),
      })
    );
  };

  const emailbtnDisale =()=>{
    setSmtpAuth(!smtpAuth)
    if(smtpAuth){
        setEmailTest(true)  
    }
    else{
      setEmailTest(false)
    }
  }

  useEffect(()=>{
    if(smtpAuth){
      if(watch('emailUserName') != "" && watch('emailPassword') != ''){
        setTestBtn(false)
      }
      else if(watch('emailUserName') == "" || watch('emailPassword') == ''){
        setTestBtn(true)
      }
    }
    else{
      setTestBtn(false)
    }
  })

  return (
    <Page>
      {/* <Container maxWidth={themeStretch ? false : 'xl'}> */}
      <Typography sx={{fontSize:'1.125 rem !important',fontWeight:'700'}} >Email Configuration</Typography>
        {/* <Card sx={{ pb: '20px' }}>
          <CardHeader title="Email Configuration" /> */}
          <Grid container spacing={2} sx={{ m: '10px' }}>
            <Grid item xs={12} md={11}>
              <FormControlLabel
                control={<Checkbox />}
                checked={smtpAuth}
                label="SMTP Authentication"
                // onChange={() => setSmtpAuth(!smtpAuth)}
                onChange={()=>emailbtnDisale()}
              />
            </Grid>
            <Grid item xs={12} md={5.5}>
              <TextField
                name="smptHost"
                label="SMTP Host"
                // value={global.smptHost || ''}
                helperText={errors?.smptHost && errors?.smptHost?.message}
                error={errors?.smptHost && errors?.smptHost?.message}
                {...register('smptHost')}
                variant="standard"
                sx={{ width: '100%' }}
                InputLabelProps={{
                  shrink: true,
              }}
                // onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={5.5}>
              <FormControl fullWidth>
                <InputLabel id="protocol">Protocols</InputLabel>
                <Select
                  name="emailSecProtocol"
                  labelId="protocol"
                  value={watch('emailSecProtocol')}
                  variant="standard"
                  label="Protocols"
                  InputLabelProps={{
                    shrink: true,
                }}
                  helperText={errors.emailSecProtocol && errors.emailSecProtocol.message}
                  error={errors.emailSecProtocol && errors.emailSecProtocol.message}
                  {...register('emailSecProtocol')}
                  // onChange={handleChange}
                >
                  {emailProtocols.map((protocol, i) => {
                    return (
                      <MenuItem key={i} value={protocol.value}>
                        {protocol.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={11}>
              <TextField
                name="smptPort"
                label="SMTP Port"
                // value={global.smptPort || ''}
                helperText={errors.smptPort && errors.smptPort.message}
                error={errors.smptPort && errors.smptPort.message}
                {...register('smptPort')}
                variant="standard"
                sx={{ width: '100%' }}
                InputLabelProps={{
                  shrink: true,
              }}
                // onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={5.5}>
              <TextField
                name="emailUserName"
                label="Email Username"
                // value={global.emailUserName || ''}
                helperText={errors.emailUserName && errors.emailUserName.message}
                error={errors.emailUserName && errors.emailUserName.message}
                {...register('emailUserName')}
                variant="standard"
                sx={{ width: '100%' }}
                InputLabelProps={{
                  shrink: true,
              }}
                // onChange={handleChange}
                disabled={emailTest}
                required
              />
            </Grid>
            <Grid item xs={12} md={5.5}>
              <TextField
                name="emailPassword"
                label="Email Password"
                type='password'
                // value={global.emailPassword || ''}
                helperText={errors.emailPassword && errors.emailPassword.message}
                error={errors.emailPassword && errors.emailPassword.message}
                {...register('emailPassword')}
                variant="standard"
                sx={{ width: '100%' }}
                InputLabelProps={{
                  shrink: true,
              }}
                // onChange={handleChange}
                disabled={emailTest}
                required
              />
            </Grid>
            <Grid item xs={12} md={5.5}>
              <TextField
                name="emailFrom"
                label="Admin from email address"
                // value={global.emailFrom || ''}
                helperText={errors.emailFrom && errors.emailFrom.message}
                error={errors.emailFrom && errors.emailFrom.message}
                {...register('emailFrom')}
                variant="standard"
                sx={{ width: '100%' }}
                     InputLabelProps={{
                    shrink: true,
                }}
                // onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={5.5}>
              <TextField
                name="emailTo"
                label="Admin email recipient(s)"
                // value={global.emailTo || ''}
                helperText={errors.emailTo && errors.emailTo.message}
                error={errors.emailTo && errors.emailTo.message}
                {...register('emailTo')}
                variant="standard"
                sx={{ width: '100%' }}
                InputLabelProps={{
                  shrink: true,
              }}
                // onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={5.5}>
              <FormControlLabel
                name='enableStatusMail'
                control={<Checkbox/>}
                checked={!!watch('enableStatusMail') || false}
                label="Enable Job Status Email"
                onChange={(e) => setValue('enableStatusMail', e.target.checked)}
              />
            </Grid>
            <Grid item xs={12} md={5.5}>
              <Button
                variant="contained"
                sx={{ color: '#ffff' }}
                disabled={ testBtn }
                onClick={emailTesting}
              >
                Email Test
              </Button>
            </Grid>
          </Grid>
        {/* </Card> */}
      {/* </Container> */}
    </Page>
  );
};

export default EmailConfiguration;
