import { Button, Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import copiedFunction from 'src/utils/copyMethod';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { downloadFile } from '../ediscoveryActions';

const PropertiesTable = ({
  fileProperties,
  selectedPropertyType,
  fileServerUuid,
  findSimilarDoc,
  checkUuid,
  setOpenRestoreModal,
  roleAccess,
  rolename,
}) => {
  const dispatch = useDispatch();

  const isPropertyEmpty = (obj) => {
    if (!isEmpty(obj)) {
      return Object.keys(obj).length === 0;
    }
    return false;
  };
  const downloadData = () => {
    dispatch(
      downloadFile(
        {
          version: 0,
          plainUuid: fileProperties.plainUuid,
        },
        fileProperties.documentName
      )
    );
  };
  // console.log('roleAccess', roleAccess);
  // console.log('roleName', rolename);

  return (
    <>
      <Card sx={{ p: '10px' }}>
        <TableContainer>
          <Table /* sx={{minWidth:'910px'}}  */>
            <colgroup>
              <col style={{ width: '30%' }} />
              <col style={{ width: '40%' }} />
              <col style={{ width: '20%' }} />
              <col style={{ width: '5%' }} />
              <col style={{ width: '5%' }} />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>Key</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>
                  {roleAccess?.length ? (
                    roleAccess.map((data) => {
                      if (
                        data.name == 'eDiscovery.findSimilar.Docs' &&
                        ((rolename == 'LDAP' && data.ldapAllowed) ||
                          (rolename == 'PRIVILEGED' && data.privAllowed) ||
                          (rolename == 'ADMINISTRATOR' && data.adminAllowed))
                      ) {
                        return (
                          <Button
                            variant="contained"
                            onClick={() => findSimilarDoc(fileProperties)}
                            disabled={checkUuid(fileProperties)}
                          >
                            Find Similar Documents
                          </Button>
                        );
                      }
                    })
                  ) : (
                    <Button
                      variant="contained"
                      onClick={() => findSimilarDoc(fileProperties)}
                      disabled={checkUuid(fileProperties)}
                    >
                      Find Similar Documents
                    </Button>
                  )}
                </TableCell>
                <TableCell>
                  {roleAccess?.length ? (
                    roleAccess.map((data) => {
                      if (
                        data.name == 'eDiscovery.download' &&
                        ((rolename == 'LDAP' && data.ldapAllowed) ||
                          (rolename == 'PRIVILEGED' && data.privAllowed) ||
                          (rolename == 'ADMINISTRATOR' && data.adminAllowed))
                      ) {
                        return (
                          <Button
                            variant="contained"
                            disabled={isPropertyEmpty(fileProperties)}
                            onClick={() => downloadData()}
                          >
                            Download
                          </Button>
                        );
                      }
                    })
                  ) : (
                    <Button
                      variant="contained"
                      disabled={isPropertyEmpty(fileProperties)}
                      onClick={() => downloadData()}
                    >
                      Download
                    </Button>
                  )}
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    onClick={() => setOpenRestoreModal(true)}
                    disabled={selectedPropertyType !== 1}
                  >
                    {' '}
                    Restore
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            {fileProperties && (
              <TableBody>
                <TableRow>
                  <TableCell sx={{ p: '5px 0' }}>Document Name</TableCell>
                  <TableCell sx={{ py: '5px' }} colSpan={4}>
                    {fileProperties.documentName}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ p: '5px 0' }}>Document Path</TableCell>
                  <TableCell sx={{ py: '5px', wordBreak: 'break-all' }} colSpan={4}>
                    <Button
                      onClick={() => copiedFunction(fileProperties.documentPath, dispatch)}
                      sx={{ minWidth: 'fit-content' }}
                    >
                      <ContentCopyIcon />
                    </Button>
                    {fileProperties.documentPath.slice(0, 120)}
                  </TableCell>
                </TableRow>
                {fileServerUuid && (
                  <TableRow>
                    <TableCell sx={{ p: '5px 0' }}>UUID</TableCell>
                    <TableCell sx={{ py: '5px' }} colSpan={4}>
                      {fileProperties.plainUuid}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell sx={{ p: '5px 0' }}>Last Modified</TableCell>
                  <TableCell sx={{ py: '5px' }} colSpan={4}>
                    {moment(fileProperties.lastModificationDate).format('YYYY-MM-DD HH:mm:ss')}
                  </TableCell>
                </TableRow>
                {fileServerUuid && (
                  <TableRow>
                    <TableCell sx={{ p: '5px 0' }}>Last Accessed</TableCell>
                    <TableCell sx={{ py: '5px' }} colSpan={4}>
                      {moment(fileProperties.accessedOn).format('YYYY-MM-DD HH:mm:ss')}
                    </TableCell>
                  </TableRow>
                )}
                {fileServerUuid && (
                  <TableRow>
                    <TableCell sx={{ p: '5px 0' }}>Archived Date</TableCell>
                    <TableCell sx={{ py: '5px' }} colSpan={4}>
                      {moment(fileProperties.archivedDate).format('YYYY-MM-DD HH:mm:ss')}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell sx={{ p: '5px 0' }}>Document Size</TableCell>
                  <TableCell sx={{ py: '5px' }} colSpan={4}>
                    {fileProperties.documentSizeToDisplay}
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Card>
    </>
  );
};

export default PropertiesTable;
