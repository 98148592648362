import { DOWNLOAD_SUCCESS, APPLICATIONEVENT_FETCH_SUCCESS, DATA_LOADER, FETCH_FAIL } from './applicationEventActions';

const initialState = {
  loading: false,
  users: {},
  roles: [],
  error: null,
};

const applicationEventReducer = (state = initialState, action) => {
  switch (action.type) {
    case DATA_LOADER: {
      return {
        ...state,
        loading: true,
      };
    }
    case APPLICATIONEVENT_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        users: action.payload,
      };
    }
    case DOWNLOAD_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case FETCH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default: {
      return state;
    }
  }
};
export default applicationEventReducer;
