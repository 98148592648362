import { CHARTS_VOLUME_FETCH_SUCCESS, FETCH_FAIL, GRAPH_DATA_LOADER } from "./AnalyticsPathAction";


const initialState = {
  loading: false,
  chartsVolume: {},
  error: null,
};

const analyticPathReducer = (state = initialState, action) => {
  switch (action.type) {
    case GRAPH_DATA_LOADER: {
      return {
        ...state,
        loading: true,
      };
    }
    case CHARTS_VOLUME_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        chartsVolume: action.payload,
      };
    }
    case FETCH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default: {
      return state;
    }
  }
};

export default analyticPathReducer;
