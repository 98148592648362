import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPrivUserHistory } from './searchHistoryActions';
import UserHistoryTable from './UserHistoryTable';

const PrivUserSearchHistory = () => {
  const dispatch = useDispatch();
  const userSearchHistory = useSelector((state) => state.searchHistoryReducer.userSearchHistory);
  const [historyPage, setHistoryPage] = useState(0);
  const [historyPerPage, setHistoryPerPage] = useState(100);
  const [userHistoryData, setUserHistoryData] = useState({});

  useEffect(() => {
    let data = userSearchHistory || {};
    if (data?.content?.length) {
      data.content.map((item) => {
        let req = typeof item.request == 'string' ? JSON.parse(item.request) : item.request ;
        item.request = req;
      });
    }
    setUserHistoryData({ ...data });
  }, [userSearchHistory]);

  useEffect(() => {
    let params = {
      pageNo: historyPage + 1,
      size: historyPerPage,
    };
    dispatch(getPrivUserHistory(params));
  }, [historyPerPage, historyPage]);

  return (
    <>
      <UserHistoryTable
        rows={userHistoryData}
        pages={[historyPage, setHistoryPage]}
        pageRows={[historyPerPage, setHistoryPerPage]}
      />
    </>
  );
};

export default PrivUserSearchHistory;
