import {
  Button,
  Card,
  CardHeader,
  Checkbox,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Stack,
  TextField,
  Typography,
  Select,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import { SUCCESS_ALERT, ERROR_ALERT } from 'src/redux/reducers/alertReducer';
import { stubAwayCAll } from '../fileActions';

const StubModal = ({ openModal, close, node, agent, stubData, selectStub }) => {
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const [title, setTitle] = useState();
  const [proxy, setProxy] = useState(false);
  const [stubPath, setStubPath] = useState('');
  const [stubPathName, setStubPathName] = useState('');
  const proxyPath = useSelector((state) => state.fileReducer.proxy);
  const [agentId, setAgentId] = useState(null);
  const [domainName, setDomainName] = useState('');
  const [userName, setUserName] = useState('');
  const [userPassword, setuserPassword] = useState('');
  const [apiPath, setApiPath] = useState('');
  const [selectPathArray, setSelectPathArray] = selectStub;
  const [proxyIds, setProxyIds] = useState([]);

  useEffect(() => {
    setDomainName(stubData.restoreDomainName);
    setUserName(stubData.restoreUser);
    setuserPassword(stubData.restorePassword);
  }, []);

  useEffect(() => {
    if (node['type'] == 'nt:folder') {
      setTitle(true);
    } else {
      setTitle(false);
    }
  }, [node]);

  useEffect(() => {
    if (agent && agent.content && agent.content.length == 1) {
      setAgentId(agent.content[0].id);
    }
  }, [agent]);

  useEffect(() => {
    if (node.path) {
      let path = node.uncpath.split('\\');
      path.pop();
      let pp = path.join('\\');
      setStubPath(pp);
    }
  }, [node.path]);

  const stubAway = () => {
    const companyId = localStorage.getItem('companyId');
    if (proxy == true) {
      // if (proxyCheck == false) {
      //   dispatch({
      //     type: ERROR_ALERT,
      //     payload: 'Please enter valid UNC path',
      //   });
      //   return;
      // } else
      if (agentId == null) {
        dispatch({
          type: ERROR_ALERT,
          payload: 'An Agent must be selected',
        });
        return;
      }
    }
    selectPathArray.map((stubAPi) => {
      if (stubAPi.type == 'nt:folder') {
        dispatch(
          stubAwayCAll({
            agentId: agentId || 0,
            companyId: companyId,
            domain: domainName,
            password: userPassword,
            proxyStubServer: '',
            proxyStubServerIds: proxyIds,
            folderUUID: stubAPi.uuid,
            stubSubfolders: true,
            truetargetUncPath: stubPathName || stubPath.replace(/,/g, '\\'),
            userName: userName,
          })
        );
        close();
      } else {
        dispatch(
          stubAwayCAll({
            agentId: agentId || 0,
            companyId: companyId,
            domain: domainName,
            password: userPassword,
            proxyStubServer: '',
            fileUUIDs: [stubAPi.uuid],
            stubSubfolders: false,
            userName: userName,
          })
        );
        close();
      }
    });
    setProxy(false);
  };
  const sharePathClick = (e, p) => {
    let val = e.target.checked;
    if (val) {
      setProxyIds([...proxyIds, p.id]);
    } else {
      let ids = proxyIds.filter((id) => id != p.id);
      setProxyIds([...ids]);
    }
  };
  const checkProxyPath = (p) => {
    let val = false;
    proxyIds.map((id) => {
      if (id == p.id) {
        val = true;
        return val;
      }
    });
    return val;
  };


  return (
    <>
      <Modal
        open={openModal}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Page sx={{ width: '100%' }}>
          <Container maxWidth={themeStretch ? false : 'xl'}>
            <Card sx={{ backgroundColor: '#ffff', pb: '40px', px: '40px' }}>
              <CardHeader
                title={title ? 'Stub Folder Request' : 'Stub File Request'}
                sx={{ mb: '10px', ml: '-25px' }}
              />

              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <Stack flexDirection="column">
                    <Typography>{title ? 'Folder Target UNC Path' : 'File Target UNC Path'}</Typography>
                    <TextField
                      variant="standard"
                      defaultValue={stubPath}
                      onChange={(e) => setStubPathName(e.target.value)}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Stack flexDirection="row" alignItems="center">
                    <Checkbox onChange={(e) => setProxy(!proxy)} checked={proxy} />{' '}
                    <Typography>Enable Proxy Server</Typography>
                  </Stack>
                </Grid>
                {proxy ? (
                  <>
                    <Grid item xs={12} md={12}>
                      <Card sx={{ p: 1 }}>
                        <Stack flexDirection="column">
                          <Typography variant="h5" sx={{ marginLeft: '10px' }}>
                            Proxy Server Share Path
                          </Typography>
                          <Stack direction="row" alignItems="center">
                            {proxyPath && proxyPath.length
                              ? proxyPath.map((proxy) => (
                                <Stack direction="row" alignItems="center">
                                  <Checkbox checked={checkProxyPath(proxy)} onChange={(e) => sharePathClick(e, proxy)} />{' '}
                                  <Typography variant="p">{proxy.path}</Typography>
                                </Stack>
                              ))
                              : null}
                          </Stack>
                        </Stack>
                      </Card>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <FormControl fullWidth variant="standard">
                        <InputLabel id="agent">Agent</InputLabel>
                        <Select
                          value={agentId || ''}
                          onChange={(e) => setAgentId(e.target.value)}
                        >
                          {agent &&
                            agent?.content?.map((agent) => {
                              return (
                                <MenuItem
                                  key={agent.id}
                                  value={agent.id}
                                  sx={agent.online ? { color: '#45b55f' } : { color: '#f53b3b' }}
                                >
                                  {agent.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        defaultValue={domainName || ''}
                        label="Domain *"
                        variant="standard"
                        sx={{ width: '100%' }}
                        onChange={(e) => setDomainName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <TextField
                        defaultValue={userName || ''}
                        label="Username*"
                        variant="standard"
                        sx={{ width: '100%' }}
                        onChange={(e) => setUserName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <TextField
                        defaultValue={userPassword || ''}
                        type="password"
                        label="Password*"
                        variant="standard"
                        sx={{ width: '100%' }}
                        onChange={(e) => setuserPassword(e.target.value)}
                      />
                    </Grid>
                  </>
                ) : null}
                <Grid item xs={3} md={3}>
                  <Stack flexDirection="row">
                    <Button
                      variant="contained"
                      onClick={() => {
                        setProxy(false);
                        close();
                      }}
                      sx={{ color: '#fff', ml: '10px' }}
                    >
                      Cancel
                    </Button>
                    <Button variant="contained" sx={{ color: '#fff', ml: '10px' }} onClick={() => stubAway()}>
                      Stub Away
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Card>
          </Container>
        </Page>
      </Modal>
    </>
  );
};

export default StubModal;
