import { DOCUMENTS_FETCH_SUCCESS, DATA_LOADER, FETCH_FAIL, DOCUMENT_INFO_FETCH_SUCCESS, UPDATED_SUCCESSFULLY, DELETED_SUCCESSFULLY, ADD_SUCCESSFULLY } from './documentActions';

const initialState = {
  loading: false,
  documents: {},
  documentInfo: {},
  refresh:false,
  error: null,
};

const documentReducer = (state = initialState, action) => {
  switch (action.type) {
    case DATA_LOADER: {
      return {
        ...state,
        loading: true,
        refresh:false
      };
    }
    case DOCUMENTS_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        documents: action.payload,
      };
    }
    case DOCUMENT_INFO_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        documentInfo: action.payload,
      };
    }
    case UPDATED_SUCCESSFULLY: {
      return {
        ...state,
        loading: false,
      };
    }
    case DELETED_SUCCESSFULLY: {
      return {
        ...state,
        refresh: true,
        loading: false,
      };
    }
    case ADD_SUCCESSFULLY: {
      return {
        ...state,
        refresh: true,
        loading: false,
      };
    }
    case FETCH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default: {
      return state;
    }
  }
};

export default documentReducer;
