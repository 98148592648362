import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardHeader, Checkbox, Container, Grid, TextField, Typography, Stack, IconButton } from '@mui/material';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import {
  getNodesChildren,
  getNodesFolderView,
  getRootNodes,
  getAgents,
  getStub,
  findFolder,
  getPath,
  getFolderDetails,
} from './fileActions';
import RootNodes from './components/RootNodes';
import LoadingScreen from 'src/components/LoadingScreen';
import FolderView from './components/FolderView';
import DetailsView from './components/DetailsView';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import NavigateBeforeRoundedIcon from '@mui/icons-material/NavigateBeforeRounded';
import { SUCCESS_ALERT, ERROR_ALERT, PROGRESS_START, PROGRESS_DONE } from 'src/redux/reducers/alertReducer';
import { isEmpty } from 'lodash';
import { getRoleAccess } from 'src/redux/actions/loginActions';

let activeNode = null;

const getClassName = (iconUrl, filename) => {
  if (iconUrl == 'folder.gif') return 'folderIcon';

  let dotPosition = filename.indexOf('.');
  let extension = filename.substr(dotPosition + 1, 3);
  switch (extension) {
    case 'doc':
      return 'docIcon';
    case 'js':
      return 'jsIcon';
    case 'gif':
      return 'gifIcon';
    case 'htm':
      return 'htmlIcon';
    case 'php':
      return 'phpIcon';
    case 'xls':
      return 'xlsIcon';
    case 'txt':
      return 'txtIcon';
    default:
      return 'unknownIcon';
  }
};

export default function FileExplorer() {
  const dispatch = useDispatch();
  const { themeStretch } = useSettings();
  const { roleAccess } = useSelector((state) => state.login);
  const rootNodes = useSelector((state) => state.fileReducer.rootNodes);
  const loading = useSelector((state) => state.fileReducer.loading);
  const childrenNodes = useSelector((state) => state.fileReducer.childrenNodes);
  const agent = useSelector((state) => state.fileReducer.agents);
  const stub = useSelector((state) => state.fileReducer.stubDetails);
  const { nodes: folderViewNodesData, maxResultCount: totalCount } = useSelector((state) => state.fileReducer.folderViewNodes);

  const searchPathData = useSelector((state) => state.fileReducer.searchPath);
  const [versionData, setVersionData] = useState([]);
  const [nodesData, setNodesData] = useState([]);
  const [parentNode, setParentNode] = useState({});
  const [folderLevel, setFolderLevel] = useState(0);
  const [headerPath, setHeaderPath] = useState([{ path: 'archive / FS', pathUrl: '/archive/FS' }]);
  const [fileRestoreData, setFileRestoreData] = useState({});
  const [filesData, setFilesData] = useState([]);
  const [count, setCount] = useState(0);
  const [manageHistory, setManageHistory] = useState([{ path: 'archive / FS', pathUrl: '/archive/FS' }]);
  const [checkBtn, setCheckBtn] = useState(false);
  const [folderNames, setFolderNames] = useState(' archive / FS');
  const [searchFolder, setSearchFolder] = useState('');
  const [pathArray, setPathArray] = useState([]);
  const [idArray, setIdArray] = useState([]);
  const [PathID, setPathID] = useState('');
  const [selectPathArray, setSelectPathArray] = useState([]);
  const [childrenParams, setChildrenParams] = useState({});
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [detailsArray, setDetailsArray] = useState([]);
  const [idData, setIdData] = useState([]);
  const [showFolderView, setShowFolderView] = useState(false);
  const [openFolderView, setOpenFolderView] = useState(true);
  const [backDisabled, setBackDisabled] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    dispatch(getRootNodes(true));
    dispatch(getAgents({}));
    dispatch(getStub());
    dispatch(getRoleAccess());
  }, []);

  useEffect(() => {
    setNodesData(rootNodes);
  }, [rootNodes]);

  useEffect(() => {
    if (activeNode) {
      activeNode.loading = false;
      activeNode.childNodes = childrenNodes;
      setNodesData([...nodesData]);
    }
  }, [childrenNodes]);

  const fetchData = (data) => new Promise(async (resolve, reject) => {
    if (data && data.length > 0) {
      let defaultpath = '/archive/FS';
      let path = '';
      let filePath = data[0]?.path;
      filePath = await filePath?.replace(/\\/g, "/");
      if (!filePath.includes('/archive/FS')) {
        filePath = defaultpath + filePath
      }
      let idData = [];
      let Fpath = await filePath?.slice(1).split('/');
      Fpath.pop()
      setPathArray(Fpath);
      const pathData = await Promise.all(Fpath.map(async (item, i) => {
        path = `${path}/${item}`;
        const res = await dispatch(getFolderDetails({ path }));
        return res[0];
      }));

      // const newArray = pathData.filter((element) => element !== undefined);
      idData = await pathData.map((item) => (item?.parentId));
      setIdData(idData);
      setDetailsArray(pathData);
      resolve();
    } else {
      reject('No node Found')
    }
  });
  // useEffect(() => {

  //   fetchData();
  // }, [folderViewNodesData]);


  const fetchChildrenNodes = async (node) => {
    // setLoader(true);
    activeNode = node;
    setNodesData([...nodesData]);
    activeNode.expand = !activeNode.expand;

    if (!activeNode.uuid || !activeNode.expand || activeNode.childNodes?.length) {
      activeNode.loading = false;
      setNodesData([...nodesData]);
    }

    if (activeNode.expand && !activeNode.childNodes?.length) {
      await dispatch(getNodesChildren(activeNode.uuid, manageHistory, setManageHistory, node));
    }
    // setLoader(false);
  };

  useEffect(() => {
    const fetchNodesFolder = async () => {
      setLoader(true);
      if (!isEmpty(childrenParams)) {
        childrenParams.pageNo = page + 1;
        childrenParams.filesPerPage = pageSize;
        const res = await dispatch(getNodesFolderView(childrenParams));
        await fetchData(res.nodes);
      }
      setLoader(false)
    }
    fetchNodesFolder();
  }, [page, pageSize, childrenParams]);

  const fetchChildrenNodesFolder = async (node, fetchParent, key) => {
    setLoader(true);
    if (key) {
      setDetailsArray([]);
      setIdArray([]);
      setIdData([]);
      setDetailsArray([...detailsArray, node]);
      setShowFolderView(true);
      setPage(0);
      setPageSize(pageSize || 100);
    }
    if (node && node.type == 'nt:folder') {
      const id = fetchParent ? node.parentId : node.uuid;
      setParentNode(node);
      if (id) {
        const params = {
          path: id,
          pageNo: 1,
          filesPerPage: pageSize,
        };
        setChildrenParams(params);
        const res = await dispatch(getNodesFolderView(params));
        if (res.nodes && !!res?.nodes?.length) {
          fetchData(res.nodes).then(() => { setLoader(false) }).catch(() => setLoader(false)).finally(() => setLoader(false));
        } else {
          setLoader(false)
        }
      }
      if (key) {
        setPage(0);
        setPageSize(pageSize || 100);
      } else {
        setPage(0);
        setPageSize(pageSize);
      }
    }
  };

  useEffect(() => {
    // if (parentNode['path']) {
    // if (parentNode['type'] == 'nt:folder') {
    //   setFolderNames(parentNode['path']);
    // }
    // }
    let Fpath = folderNames.slice(1).split('/');
    setPathArray(Fpath);
  }, [folderNames]);

  useEffect(() => {
    // idArray.push(parentNode.parentId)
    if (parentNode.type == 'nt:folder') {
      idArray.push(parentNode.uuid);
      let setarray = new Set(idArray);
      setIdArray([...setarray]);
    }
  }, [parentNode]);

  useEffect(() => {
    searchPathData.map((item, i) => {
      if (item.name == PathID) {
        dispatch(
          getNodesFolderView({
            path: item.uuid,
            pageNo: page + 1,
            filesPerPage: pageSize,
          })
        );
      }
    });
  }, [searchPathData]);

  useEffect(() => {
    let backLength = idData?.length;
    if (backLength <= 2) {
      setBackDisabled(true);
    } else {
      setBackDisabled(false);
    }
  }, [idData])

  const headPath = async (item, i) => {
    let id = i < 2 ? idData[1] : idData[i - 1];
    setChildrenParams({ ...childrenParams, path: id });
    // let updatePath = pathArray.slice(0, i + 1);
    // setPathArray(updatePath);
    let backLength = id?.length - 1;
    if (backLength <= 0) {
      setBackDisabled(true);
    }
    idData?.map((item, i) => {
      if (item == id) {
        idData.length = i;
      }
    });
    let pathLength = pathArray.length;
    if (pathArray[pathLength - 1] != item) {
      setPage(0);
      setPageSize(pageSize || 100);

      if (detailsArray.length) {
        detailsArray?.map((item, i) => {
          if (item?.uuid == id) {
            detailsArray.length = i + 1;
            setParentNode(item);
          }
        });
        if (idData && idData.length > 1) {
          idData.length = i - 2;
        }
        let newIdArray = idData;
        newIdArray.pop()
        setIdData([...newIdArray])

        let newArray = detailsArray;
        newArray.pop()
        setDetailsArray([...newArray]);
        setSearchFolder('');
        setVersionData([]);
      }
    } else {
      setPage(0);
      setPageSize(pageSize || 100);
    }
  };


  const emptyPath = async () => {
    setLoader(true)
    await dispatch(getRootNodes(true));
    setLoader(false)
  };

  const inputFolder = (id) => {
    if (searchFolder == '') {
      dispatch({
        type: ERROR_ALERT,
        payload: 'Please Enter Text',
      });
    } else {
      dispatch(
        findFolder(id, {
          nodeName: searchFolder,
          pageNo: 1,
          filesPerPage: 100,
        })
      );
    }
  };

  const deleteSearch = (id) => {
    const params = {
      path: id,
      pageNo: page + 1,
      filesPerPage: pageSize,
    };
    if (searchFolder != '') {
      setSearchFolder('');
      dispatch(getNodesFolderView(params));
    }
  };

  const searchPath = async () => {
    setLoader(true)
    setShowFolderView(true);
    setBackDisabled(false);
    let sPath, rootPath, pathValue;
    let detailPath = searchFolder.slice(0, 2);
    if (detailPath == '\\\\') {
      sPath = searchFolder.split('\\');
      if (sPath.includes(pathArray[0])) {
        rootPath = `//${pathArray[0]}`;
      }
      sPath.shift();
      sPath.unshift('/archive/FS');
    } else {
      sPath = searchFolder.split('/');
    }
    let sPath2 = sPath.slice(-1);
    const filteredPath = sPath.filter((item) => item !== "");
    filteredPath.pop()
    // const pathData = await filteredPath.map(async (item) => {
    //   path = `${path}/${item}`
    //   const res = await dispatch(getFolderDetails({ path }));
    //   // setTimeout(() => {
    //   // pathData = [...pathData, res[0]]
    //   // idData = [...idData, res[0].parentId]
    //   return res[0]
    //   // }, 2000);
    // })
    // let path = '';
    // // let pathData = [];
    // let idData = [];
    // const pathData = await Promise.all(filteredPath.map(async (item) => {
    //   path = `${path}/${item}`;
    //   const res = await dispatch(getFolderDetails({ path }));
    //   return res[0];
    // }));
    // idData = await pathData?.map((item) => (item?.parentId))
    // setIdData(idData)
    // setDetailsArray(pathData);
    setPathID(sPath2);
    sPath.pop();
    if (rootPath) {
      pathValue = rootPath;
    } else {
      pathValue = sPath.join('/');
    }
    const res = await dispatch(getPath({ path: pathValue }));
    setVersionData([])
    fetchData(res).then(() => setLoader(false))
  };

  return (
    <Page>
      {loader && <LoadingScreen />}
      {!loading ? (
        <Container sx={{ ml: 0 }} maxWidth={themeStretch ? false : 'xl'}>
          <Card>
            <Stack direction="row">
              <CardHeader title="File Explorer" sx={{ mb: 4 }} />
              <Stack direction="row" alignItems="center" sx={{ flexWrap: 'wrap' }}>
                {pathArray.map((item, i) => (
                  <Typography
                    sx={{
                      width: 'fit-content',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '6px',
                      '&:hover': {
                        color: 'lightskyblue',
                        cursor: 'pointer',
                      },
                    }}
                    onClick={() => headPath(item, i)}
                  >
                    {item} <NavigateNextRoundedIcon />
                  </Typography>
                ))}
              </Stack>
            </Stack>
            <Grid container spacing={2} sx={{ ml: '10px' }}>
              <Grid item xs={9}>
                <TextField
                  id="standard-basic"
                  name="search"
                  label="Search"
                  fullWidth
                  value={searchFolder}
                  variant="standard"
                  onChange={(e) => setSearchFolder(e.target.value)}
                />
              </Grid>
              <Grid item xs={3}>
                <Stack direction="row" sx={{ marginTop: '20px' }}>
                  <Button onClick={() => inputFolder(parentNode.uuid)} sx={{ minWidth: 'fit-content', p: 0 }}>
                    <SearchIcon />
                  </Button>
                  <Button onClick={() => deleteSearch(parentNode.uuid)} sx={{ minWidth: 'fit-content', p: 0 }}>
                    <DeleteIcon />
                  </Button>
                  <Button
                    disabled={searchFolder ? false : true}
                    variant="contained"
                    sx={{ color: '#fff', ml: '10px' }}
                    onClick={() => searchPath()}
                  >
                    Search Path
                  </Button>
                </Stack>
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ my: '20px', justifyContent: 'space-between' }}>
              <Grid item xs={12} md={4} className={`folder-view ${openFolderView && 'open'}`}>
                <IconButton aria-label="delete" size="large" className='toggle-btn' onClick={() => setOpenFolderView(!openFolderView)}>
                  {openFolderView ?
                    <NavigateBeforeRoundedIcon />
                    :
                    <NavigateNextRoundedIcon />
                  }
                </IconButton>
                <RootNodes
                  getClassName={getClassName}
                  rootNodes={nodesData}
                  fetchChildrenNodes={fetchChildrenNodes}
                  getFolderView={fetchChildrenNodesFolder}
                  loadingState={[loader, setLoader]}
                  versionInfo={[versionData, setVersionData]}
                />
              </Grid>
              <Grid item xs={12} md={openFolderView ? 8 : 11} sx={{ transition: '0.4s ease-in-out' }}>
                <FolderView
                  getClassName={getClassName}
                  manageHistory={manageHistory}
                  backBtnState={[backDisabled, setBackDisabled]}
                  searchEmpty={[searchFolder, setSearchFolder]}
                  showFolderView={showFolderView}
                  fetchChildrenNodes={fetchChildrenNodes}
                  fetchChildrenNodesFolder={fetchChildrenNodesFolder}
                  parentNodeState={[parentNode, setParentNode]}
                  counts={[count, setCount]}
                  headpath={[pathArray, setPathArray]}
                  headFolder={[folderNames, setFolderNames]}
                  headid={[idArray, setIdArray]}
                  rootNodes={nodesData}
                  selectCheck={[selectPathArray, setSelectPathArray]}
                  pageRows={[pageSize, setPageSize]}
                  pages={[page, setPage]}
                  detailDataFolder={[detailsArray, setDetailsArray]}
                  backIdDetail={[idData, setIdData]}
                  childParams={[childrenParams, setChildrenParams]}
                  loadingState={[loader, setLoader]}
                  fetchData={fetchData}
                  versionInfo={[versionData, setVersionData]}
                />
                <DetailsView
                  node={parentNode}
                  counts={count}
                  agent={agent}
                  stubData={stub}
                  selectData={[selectPathArray, setSelectPathArray]}
                  detailData={[detailsArray, setDetailsArray]}
                  roleAccess={roleAccess}
                />
              </Grid>
            </Grid>
          </Card>
        </Container>
      ) : (
        <LoadingScreen />
      )}
    </Page>
  );
}
