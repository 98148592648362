import {
  FETCH_FAIL,
  BAR_SYSTEM_CHART_YEAR_FETCH_SUCCESS,
  DATA_LOADER,
  BAR_CHART_MONTH_FETCH_SUCCESS,
  PIE_CHART_FETCH_SUCCESS,
  LINE_CHART_FETCH_SUCCESS,
  DISC_STATUS_FETCH_SUCCESS,
  STATUS_FETCH_SUCCESS,
} from './systemStatusActions';

const initialState = {
  loading: false,
  barChartDataByYear: [],
  barChartDataByMonth: null,
  pieChartData: null,
  lineChartData: [],
  discStatus: [],
  count: [],
  error: null,
};

const systemStatusReducers = (state = initialState, action) => {
  switch (action.type) {
    case DATA_LOADER: {
      return {
        ...state,
        loading: true,
      };
    }
    case BAR_SYSTEM_CHART_YEAR_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        barChartDataByYear: action.payload,
      };
    }
    case BAR_CHART_MONTH_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        barChartDataByMonth: action.payload,
      };
    }
    case PIE_CHART_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        pieChartData: action.payload,
      };
    }
    case LINE_CHART_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        lineChartData: action.payload,
      };
    }
    case DISC_STATUS_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        discStatus: action.payload,
      };
    }
    case STATUS_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        count: action.payload,
      };
    }
    case FETCH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case 'CHART_INITIALSTATE':
      return (state = initialState);
    default: {
      return state;
    }
  }
};

export default systemStatusReducers;
