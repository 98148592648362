import { FETCH_FAIL, USERROLE_HISTORY } from './searchHistoryActions';

const initialState = {
  loading: false,
  userSearchHistory: {},
  error: null,
};

const searchHistoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case USERROLE_HISTORY: {
          return {
            ...state,
            loading: false,
            userSearchHistory: action.payload
          };
        }
        case FETCH_FAIL:
            return {
            ...state,
            loading: false,
            error: action.payload,
            };
        default: {
            return state;
        }
    }

}
export default searchHistoryReducer;