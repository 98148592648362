import axios from 'axios';
import { BASE_URL, LOGIN_BASE_URL } from 'src/config';
import axiosInstance from 'src/utils/axios';
import { GetDetails, GetFetch, getHeaders, postInfo } from 'src/utils/fetchServices';
import { dispatch } from '../store';
import jwt from 'jwt-decode';
import { ERROR_ALERT, SUCCESS_ALERT } from '../reducers/alertReducer';
import { useEffect } from 'react';

export const LOGIN = 'LOGIN';
export const COMPANY_MODE_FETCH_SUCCESS = 'COMPANY_MODE_FETCH_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const LOGIN_LOADER = 'LOGIN_LOADER';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const FETCH_FAIL = 'FETCH_FAIL';
export const SUCCESS_CODE = 'SUCCESS_CODE';
export const APP_TITLE = 'APP_TITLE';
export const COMPANY_TAG = 'COMPANY_TAG';
export const COMPANY_NUM = 'COMPANY_NUM';
export const ADMIN_ROLE = 'ADMIN_ROLE';
export const APP_INFO = 'APP_INFO';
export const ROLE_ACCESS_DATA = 'ROLE_ACCESS_DATA';

export const login = async (username, password, code, granttype, navigate, companyTagStorage) => {
  let adminRole = '';
  const newHeaders = {
    'Content-Type': 'application/x-www-form-urlencoded',
    Authorization: 'Basic dGVzdGp3dGNsaWVudGlkOlhZN2ttem9OemwxMDA=',
  };

  const bodyFormData = new FormData();
  bodyFormData.append('username', username);
  bodyFormData.append('password', password);
  if (code) {
    bodyFormData.append('code', code);
  }
  bodyFormData.append('grant_type', granttype);

  if (localStorage.getItem('companyTagLocal')) {
    bodyFormData.companyTag = localStorage.getItem('companyTagLocal');
  }

  try {
    const response = await axios.post(`${LOGIN_BASE_URL}oauth2/token`, bodyFormData, {
      headers: newHeaders,
    });
    const data = response.data;
    if (data) {
      if (companyTagStorage != '') {
        localStorage.setItem('companyTagLocal', companyTagStorage);
        localStorage.setItem('companyTag', companyTagStorage);
      }
      const decodedToken = jwt(data.access_token);
      adminRole = decodedToken.authorities[0];
      localStorage.setItem('accessToken', data.access_token);
      // localStorage.setItem('username', decodedToken.user_name);
      // localStorage.setItem('rolename', decodedToken.authorities[0]);
      // localStorage.setItem('adminRole', decodedToken.authorities[0]);
      localStorage.setItem('companyId', decodedToken.companyId);
      localStorage.setItem('CompanyIdSU', decodedToken.companyId);
      localStorage.setItem('userId', decodedToken.userId);
      localStorage.setItem('accessRights', JSON.stringify(decodedToken.scope));
      dispatch({
        type: COMPANY_NUM,
        payload: decodedToken.companyId,
      });
      dispatch({
        type: ADMIN_ROLE,
        payload: decodedToken.authorities[0],
      });

      if (
        decodedToken.companyId > 0 &&
        adminRole != 'PRIVILEGED' &&
        adminRole != 'LDAP' &&
        adminRole != 'DATAGUARDIAN'
      ) {
        const response = await GetDetails({ url: `companies/${decodedToken.companyId}` });
        const globalData = response.data;
        localStorage.setItem('globalKey', globalData.globalKey);
      }
    }
    dispatch({
      type: LOGIN,
      payload: {
        data,
        error: '',
      },
    });
    let roleData;
    if (localStorage.getItem('companyId')) {
      // dispatch(getRoleAccess());
      const companyId = localStorage.getItem('companyId');
      const res = await GetFetch({ url: `company/${companyId}/role-feature-access` });
      roleData = res.data;
    }
    if (adminRole == 'PRIVILEGED') {
      roleData?.length &&
        roleData.map((item, i) => {
          if (item.name == 'eDiscovery') {
            if (item.privAllowed) {
              navigate('/dashboard/e-discovery');
            } else {
              navigate('/dashboard/file-explorer');
            }
          }
        });
    }
    if (adminRole == 'SUPERADMIN') {
      navigate('/dashboard/company-list');
    }
    if (adminRole == 'DATAGUARDIAN') {
      navigate('/dashboard/audit-access-log');
    }
    if (adminRole == 'ADMINISTRATOR') {
      navigate('/dashboard/system-status');
    }
    if (adminRole == 'LDAP') {
      roleData?.length &&
        roleData.map((item, i) => {
          if (item.name == 'eDiscovery') {
            if (item.ldapAllowed) {
              navigate('/dashboard/e-discovery');
            } else {
              navigate('/dashboard/file-explorer');
            }
          }
        });
    }
  } catch (error) {
    if (error?.response?.data?.error == "PROVIDE_2FA_CODE") {
      //   dispatch({
      //     type: ERROR_ALERT,
      //     payload: 'Please enable 2FA---',
      // })
      dispatch({
        type: SUCCESS_CODE,
        payload: true,
      });
    } else {
      dispatch({
        type: ERROR_ALERT,
        payload: error?.response?.data?.error || error?.response?.data?.message || 'Something went wrong!!' ,
      });
    }
  }
};
export const logout = () => {
  dispatch({
    type: LOGOUT,
  });
  dispatch({
    type: SUCCESS_CODE,
    payload: false,
  });
  dispatch({
    type: 'SET_ALL_INITIAL',
  });
};

export const getRoleAccess = () => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  try {
    const response = await GetFetch({ url: `company/${companyId}/role-feature-access` });
    const data = response.data;
    dispatch({
      type: ROLE_ACCESS_DATA,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
    });
  }
};
export const updateRoleAccess = (body) => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  try {
    const response = await postInfo({ url: `company/${companyId}/role-feature-access`, body, method: 'PUT' });
    const data = response.data;
    if (response.status == 200) {
      dispatch({
        type: ROLE_ACCESS_DATA,
        payload: data,
      });
      dispatch({
        type: SUCCESS_ALERT,
        payload: 'Role Permissions Updated Successfully',
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
    });
  }
};

export const getCompanyMode = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get(`${BASE_URL}global/single-company-mode`);
    const data = response.data;
    if (data) {
      // localStorage.setItem('companyTagLocal', 'DataRepository');
      localStorage.setItem('enableSingleCompanyMode', data.enableSingleCompanyMode.toString());
    }
    if (data.enableSingleCompanyMode) {
      localStorage.setItem('companyTagLocal', 'DataRepository');
    }

    dispatch({
      type: COMPANY_MODE_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
    });
  }
};

export const appTitleName = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get(`${BASE_URL}global/app-name`);
    const data = response.data;
    localStorage.setItem('AppNameGlobal', data);
    // window.location.reload()
    dispatch({
      type: APP_TITLE,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
    });
  }
};
export const appInfo = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get(`${BASE_URL}actuator/info`);
    const data = response.data;
    // window.location.reload()
    if (localStorage.getItem('companyTagLocal') && localStorage.getItem('companyTagLocal') != null) {
      companyName(localStorage.getItem('companyTagLocal'));
    }
    dispatch({
      type: APP_INFO,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
    });
  }
};
export const ColorInfo = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get(`${BASE_URL}tenant/banner-colour/1`);
    const data = response.data;
    // window.location.reload()
    localStorage.setItem("colorHex", data)
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
    });
  }
};

export const companyName = (companyTag) => async (dispatch) => {
  try {
    const response = await axiosInstance.get(`${BASE_URL}global/company/${companyTag}`);
    const data = response.data;
    if (data.companyId) {
      // localStorage.setItem("companyTagLocal","DataRepository")
      localStorage.setItem('companyId', data.companyId);
    }
    dispatch({
      type: COMPANY_NUM,
      payload: data.companyId,
    });
    dispatch({
      type: COMPANY_TAG,
      payload: companyTag,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
    });
  }
};

export const getStatus2FA = () => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  try {
    const config = {
      headers: getHeaders(),
    };
    const response = await axios.get(`${BASE_URL}users/company/${companyId}/2fa/status`, config);
    const data = response.data;
    dispatch({
      type: SUCCESS_CODE,
      payload: data.status,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
    });
  }
};
