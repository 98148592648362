import {
  Box,
  Card,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  TableContainer,
  TablePagination,
  Typography,
  Stack,
} from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Scrollbar from '../../../../components/Scrollbar';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { deleteFileStore } from '../volumeActions';
import DeleteAlert from 'src/pages/DeleteAlert';

export default function VolumeTable({ pages, size, fileStoreList, columns }) {
  const dispatch = useDispatch()
  const [rowsPerPage, setRowsPerPage] = size;
  const [page, setPage] = pages;
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState();

  const fileType = (row) => {
    if (row.fileStoreBase === 1) {
      return 'File System';
    }
    if (row.fileStoreBase === 2) {
      return 's3 Cloud';
    }
    if (row.fileStoreBase === 3) {
      return 'Azure Blob Store';
    }
    if (row.fileStoreBase === 4) {
      return 'Windows SMB Store';
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleDelete = (id) => {
    setDeleteDialog(true);
    setDeleteId(id);
  };

  const removeFileStore = () => {
    dispatch(deleteFileStore(deleteId));
  };

  return (
    <>
      <Card>
      <DeleteAlert remove={removeFileStore} openDialog={[deleteDialog, setDeleteDialog]} />
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <CardHeader title="File Store List" sx={{ mb: 3 }} />
          <Stack direction="row" alignItems="center">
            <Link to="profile" style={{ textDecoration: 'none' }}>
              <Button variant="contained" sx={{ color: '#ffff', height: '40px', m: '20px' }}>
                Manage Profile
              </Button>
            </Link>
            <Link to="add" style={{ textDecoration: 'none' }}>
              <Button variant="contained" sx={{ color: '#ffff', height: '40px', m: '20px' }}>
                Add File Store
              </Button>
            </Link>
          </Stack>
        </Box>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 720 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column, i) => (
                    <TableCell key={i}>{column}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {fileStoreList &&
                  fileStoreList.content.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>
                        <Typography sx={{ color: '#699ad4' }}>{row.fileStoreType}</Typography>
                      </TableCell>
                      <TableCell>{row.writable === true ? 'Read/Write' : 'Read Only'}</TableCell>
                      <TableCell>{row.encryption.toString()}</TableCell>
                      <TableCell>{fileType(row)}</TableCell>
                      <TableCell sx={{ maxWidth: '100px' }}>
                        <Link to={`edit/${row.id}`}>
                          <Button sx={{minWidth:'fit-content', p:0}}>
                            <ModeEditIcon />
                          </Button>
                        </Link>

                        <Button sx={{minWidth:'fit-content', p:0}} onClick={()=> handleDelete(row.id)}>
                          <DeleteForeverIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20, 100]}
              component="div"
              count={fileStoreList.totalElements}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Scrollbar>
      </Card>
    </>
  );
}
