import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Grid } from '@mui/material';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import LoadingScreen from 'src/components/LoadingScreen';
import UserFeatureTable from './components/UserFeatureTable';
import { getUserFeature } from './featureAction';

export default function UserFeature() {
  const dispatch = useDispatch();
  const { themeStretch } = useSettings();
  const loading = useSelector((state) => state.featureReducer.loading);
  const userFeature = useSelector((state) => state.featureReducer.userFeature);

  useEffect(() => {
    dispatch(getUserFeature());
  }, []);

  return (  
    <Page>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} style={{ paddingTop: '1px' }}>
            { !loading ? (
              userFeature &&
              <UserFeatureTable
                userFeature={userFeature}
              />
            ): <LoadingScreen/>}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
