import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardHeader,
  Container,
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getVolumeData } from '../analyticsActions';
import moment from 'moment';

export default function RootShareTable() {
  const dispatch = useDispatch();

  const volumeData = useSelector((state) => state.analyticsReducers.volumeData);
  const dates = useSelector((state) => state.systemReducer.dates);

  const [startDate, setStartDate] = useState(new Date('1995/01/01'));
  const [endDate, setEndDate] = useState(new Date());
  const [year, setYear] = useState();
  const [volumeTableData, setVolumeTableData] = useState({})

  useEffect(() => {
    let unixStartDate = moment(dates.startDate).format('x');
    let unixEndDate = new Date(dates.endDate).setHours(23, 59, 59);
    dispatch(getVolumeData({ startDate: unixStartDate, endDate: unixEndDate }));
  }, [dates]);

  useEffect(() => {
    if (volumeData && volumeData.aggregations) {
      setVolumeTableData(volumeData.aggregations[3].buckets)
    }
  }, [volumeData])

  const columns = ['Path', 'TotalDocs', 'Volume'];

  function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  return (
    <Page>
      <Card sx={{ p: 2 }}>
        {/* <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header"> */}
        <Typography variant='h5' sx={{ p: 2 }}>Total Volume and files per Root Share</Typography>
        {/* </AccordionSummary>
          <AccordionDetails> */}
        <TableContainer sx={{ minWidth: 720 }}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column, i) => (
                  <TableCell key={i}>{column}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {volumeTableData?.length ?
                volumeTableData.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell sx={{ py: '5px' }}>
                      <Typography sx={{ color: '#699ad4' }}>{row.key}</Typography>
                    </TableCell>
                    <TableCell sx={{ py: '5px' }}>{(row.doc_count).toLocaleString()}</TableCell>
                    <TableCell sx={{ py: '5px' }}>{formatBytes(row[1].value)}</TableCell>
                  </TableRow>
                )) :
                <TableRow >
                  <TableCell sx={{ py: '5px' }}>
                    <Typography sx={{ color: '#699ad4' }}>No Data Found</Typography>
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
        {/* </AccordionDetails>
        </Accordion> */}
      </Card>
    </Page>
  );
}
