import { sentenceCase } from 'change-case';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Card,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  TableContainer,
  Typography,
  Stack,
} from '@mui/material';
import Scrollbar from '../../../../components/Scrollbar';
import Label from 'src/components/Label';
import moment from 'moment';
import { useState } from 'react';
import JobLogModal from './JobLogModal';
import { downloadFile, downloadFolder, getJobLogs } from '../jobStatusActions';
import { useDispatch, useSelector } from 'react-redux';

export default function DetailTable({
  executionColumns,
  statisticColumns,
  executionDetails,
  jobDetails,
  jobRerun,
  statisticSummary,
  statisticDetails,
  close
}) {
  const dispatch = useDispatch();
  const [openJobLogModal, setOpenJobLogModal] = useState(false);
  const statusColor = (row) => {
    if (sentenceCase(row.status) === 'Completed') return 'success';
    else return 'warning';
  };
  const dateFormat = (startTime) => {
    return moment(startTime).format('MMMM Do YYYY, h:mm:ss a');
  };

  const openJobLogs = (row) => {
    dispatch(getJobLogs({ jobstatisticsId: row.id }))
    setOpenJobLogModal(true)
  }

  const handleDownload = (jobId, name) => {
    dispatch(downloadFolder(jobId, name))
  }

  return (
    <div style={{ backgroundColor: '#fff', padding: '5px', borderRadius: '10px' }}>
      <JobLogModal modalPopUp={[openJobLogModal, setOpenJobLogModal]} />
      <Card sx={{ m: '20px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <CardHeader title={`Execution Details - Long Path (Start Time:  )`} />
          <Stack direction='row'>
            <Button
              variant="contained"
              sx={{ color: '#ffff', height: '40px', m: '20px' }}
              onClick={() => jobRerun(jobDetails)}
            >
              Rerun Job
            </Button>
            <Button
              variant="contained"
              sx={{ color: '#ffff', height: '40px', m: '20px' }}
              onClick={close}
            >
              Close
            </Button>

          </Stack>
        </Box>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 720 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {executionColumns.map((column, i) => (
                    <TableCell key={i}>{column}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {executionDetails &&
                  executionDetails.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>
                        <Typography sx={{ cursor: 'pointer', color: '#699ad4' }} onClick={() => statisticSummary(row)}>
                          {row.serialNumber}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography sx={{ cursor: 'pointer', color: '#699ad4' }} onClick={() => statisticSummary(row)}>
                          {row.executionId}
                        </Typography>
                      </TableCell>
                      <TableCell>{dateFormat(row.execStartTime)}</TableCell>
                      <TableCell>{row.totalTimeTaken}</TableCell>
                      <TableCell>
                        <Label variant="ghost" color={statusColor(row)}>
                          {sentenceCase(row.status)}
                        </Label>
                      </TableCell>
                      <TableCell>
                        <Button variant="contained" sx={{ color: '#ffff', mr: '10px' }} onClick={() => openJobLogs(row)}>
                          Log Index
                        </Button>
                        <Button variant="contained" sx={{ color: '#ffff' }} onClick={() => handleDownload(row.id, row.serialNumber)}>
                          Download
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Card>

      {/* {statisticDetails.length !== 0 && ( */}
      <Card sx={{ m: '20px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <CardHeader title={`Statistics Summary`} sx={{ mb: '10px' }} />
          <Button
            variant="contained"
            sx={{ color: '#ffff', height: '40px', m: '20px' }}
            onClick={() => statisticSummary(executionDetails[0])}
          >
            Refresh
          </Button>
        </Box>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 720 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Seq Id</TableCell>
                  <TableCell>Evaluated</TableCell>
                  <TableCell>Skipped</TableCell>
                  <TableCell>Processed</TableCell>
                  <TableCell>Duplicates</TableCell>
                  <TableCell>{jobDetails.actionType === 'WITHOUTSTUB' ? 'Deleted' : 'Stub Queue'}</TableCell>
                  <TableCell>Errored</TableCell>
                  <TableCell>Size</TableCell>
                  <TableCell>Throughput</TableCell>
                </TableRow>
              </TableHead>
              {(statisticDetails) ? (
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography sx={{ color: '#699ad4' }} >
                        {statisticDetails.serialNo}
                      </Typography>
                    </TableCell>
                    <TableCell>{statisticDetails.totalEvaluated}</TableCell>
                    <TableCell>{statisticDetails.totalSkipped}</TableCell>
                    <TableCell>{statisticDetails.totalFreshArchived}</TableCell>
                    <TableCell>{statisticDetails.totalDuplicated + statisticDetails.alreadyArchived}</TableCell>
                    <TableCell>{statisticDetails.totalStubbed}</TableCell>
                    <TableCell>{statisticDetails.totalFailed}</TableCell>
                    <TableCell>
                      {statisticDetails.archivedVolume}
                      {statisticDetails.dataUnit}
                    </TableCell>
                    <TableCell>
                      {statisticDetails.throughput}
                      {statisticDetails.throughputDataUnit}
                      {statisticDetails.throughputDataUnit ? ' /min' : ""}
                      {/* /min */}
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : null}
            </Table>
          </TableContainer>
        </Scrollbar>
      </Card>

    </div>
  );
}
