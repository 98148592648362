import { GetFetch } from "src/utils/fetchServices";
export const CHARTS_VOLUME_FETCH_SUCCESS = 'CHARTS_VOLUME_FETCH_SUCCESS';
export const GRAPH_DATA_LOADER = 'GRAPH_DATA_LOADER';
export const FETCH_FAIL = 'FETCH_FAIL';


export const getChartsVolume = (params) => async (dispatch) => {
    const companyId = localStorage.getItem('companyId');
    dispatch({
      type: GRAPH_DATA_LOADER,
    });
  
    params.chartType = 'barBYYear';
  
    try {
      const response = await GetFetch({ url: `analysis/company/${companyId}/query`, params });
      const data = response.data;
      dispatch({
        type: CHARTS_VOLUME_FETCH_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_FAIL,
        payload: error.message,
      });
    }
  };