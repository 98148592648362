import {
  Box,
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  TableContainer,
  Typography,
  Checkbox,
  Button,
  Stack,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Scrollbar from '../../../../components/Scrollbar';
import { updateUserFeature } from '../featureAction';

export default function UserFeatureTable({ userFeature }) {
  const dispatch = useDispatch();

  const [userFeatureList, setUserFeatureList] = useState([]);
  const [selectAllAdmin, setSelectAllAdmin] = useState(false);
  const [selectAllPriv, setSelectAllPriv] = useState(false);
  const [selectAllLdap, setSelectAllLdap] = useState(false);
  useEffect(() => {
    setUserFeatureList(userFeature);
  }, [userFeature]);

  useEffect(() => {
    userFeatureList.map((list) => {
      if (!list.adminAllowed) {
        setSelectAllAdmin(false);
      }
      if (!list.privAllowed) {
        setSelectAllPriv(false);
      }
      if (!list.ldapAllowed) {
        setSelectAllLdap(false);
      }
    });
  }, [userFeatureList]);

  const adminCheckAll = (e) => {
    let list = userFeatureList;
    list.forEach((val) => (val.adminAllowed = e.target.checked));
    setUserFeatureList([...list]);
    setSelectAllAdmin(e.target.checked);
  };

  const handleAdminSelect = (e, id) => {
    let list = userFeatureList;
    list.forEach((val) => {
      if (val.id === id) {
        return (val.adminAllowed = e.target.checked);
      }
    });
    setUserFeatureList([...list]);
  };

  const privCheckAll = (e) => {
    let list = userFeatureList;
    list.forEach((val) => (val.privAllowed = e.target.checked));
    setUserFeatureList([...list]);
    setSelectAllPriv(e.target.checked);
  };

  const handlePrivSelect = (e, id) => {
    let list = userFeatureList;
    list.forEach((val) => {
      if (val.id === id) {
        return (val.privAllowed = e.target.checked);
      }
    });
    setUserFeatureList([...list]);
  };

  const ldapCheckAll = (e) => {
    let list = userFeatureList;
    list.forEach((val) => (val.ldapAllowed = e.target.checked));
    setUserFeatureList([...list]);
    setSelectAllLdap(e.target.checked);
  };

  const handleLdapSelect = (e, id) => {
    let list = userFeatureList;
    list.forEach((val) => {
      if (val.id === id) {
        return (val.ldapAllowed = e.target.checked);
      }
    });
    setUserFeatureList([...list]);
  };

  const handleSubmit = () => {
    dispatch(updateUserFeature(userFeatureList))
  };

  return (
    <>
      <Card>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mr: '20px' }}>
          <CardHeader title="User Feature" sx={{ mb: 3 }} />
          <Button variant="contained" onClick={handleSubmit}>
            Save
          </Button>
        </Stack>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 720 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Feature</TableCell>
                  <TableCell>
                    Admin <Checkbox checked={selectAllAdmin} onChange={adminCheckAll} />{' '}
                  </TableCell>
                  <TableCell>
                    Priv <Checkbox checked={selectAllPriv} onChange={privCheckAll} />{' '}
                  </TableCell>
                  <TableCell>
                    Company Name <Checkbox checked={selectAllLdap} onChange={ldapCheckAll} />{' '}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userFeatureList.length ?
                  userFeatureList.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>
                        <Typography sx={{ color: '#699ad4' }}>{row.displayName}</Typography>
                      </TableCell>
                      <TableCell>
                        <Checkbox checked={row.adminAllowed} onChange={(e) => handleAdminSelect(e, row.id)} />
                      </TableCell>
                      <TableCell>
                        <Checkbox checked={row?.privAllowed} onChange={(e) => handlePrivSelect(e, row.id)} />
                      </TableCell>
                      <TableCell>
                        <Checkbox checked={row?.ldapAllowed} onChange={(e) => handleLdapSelect(e, row.id)} />
                      </TableCell>
                    </TableRow>
                  )): null}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Card>
    </>
  );
}
