import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// @mui
import { List, Collapse } from '@mui/material';
//
import { NavItemRoot, NavItemSub } from './NavItem';
import { getActive } from '..';
import { useDispatch, useSelector } from 'react-redux';

// ----------------------------------------------------------------------

NavListRoot.propTypes = {
  isCollapse: PropTypes.bool,
  list: PropTypes.object,
};

export function NavListRoot({ list, isCollapse, adminRole, userRole, navSelection }) {
  const { roleAccess } = useSelector((state) => state.login);
  const { pathname } = useLocation();

  const active = getActive(list.path, pathname);
  const [selectedNav, setSelectedNav] = navSelection;
  const [open, setOpen] = useState(false);
  const [privAllow, setPrivAllow] = useState({
    fileExplorer: false,
    searchfile: false,
    chartAnalysis: false,
  });
  const [ldapAllow, setLdapAllow] = useState({
    fileExplorer: false,
    searchfile: false,
    chartAnalysis: false,
  });
  
  useEffect(() => {
    roleAccess?.length &&
      roleAccess.map((item, i) => {
        if (item.name == 'fileExplorer') {
          privAllow.fileExplorer = item.privAllowed;
          ldapAllow.fileExplorer = item.ldapAllowed;
        }
        if (item.name == 'eDiscovery') {
          privAllow.searchfile = item.privAllowed;
          ldapAllow.searchfile = item.ldapAllowed;
        }
        if (item.name == 'chartAnalysis') {
          privAllow.chartAnalysis = item.privAllowed;
          ldapAllow.chartAnalysis = item.ldapAllowed;
        }
      });
  }, [roleAccess]);

  const hasChildren = list.children;

  const handleOpen = (node) => {
    setSelectedNav(node.title);
    if (selectedNav == node.title) {
      setSelectedNav('');
    }
    setOpen(!open);
  };

  const handleCheck = (list) => {
    let val = false;
    if (selectedNav == list.title) {
      val = true;
      return val;
    }
    return val;
  };

  if (hasChildren) {
    return (
      <>
        <NavItemRoot
          item={list}
          isCollapse={isCollapse}
          active={active}
          open={open}
          onOpen={handleOpen}
          handleCheck={handleCheck}
        />

        {!isCollapse && (
          <Collapse in={handleCheck(list)} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {(list.children || []).map((item) =>
                ((item.id == 'fileexplorer' || item.id == 'custombranding') && userRole == 'superadmin') ||
                (item.id == 'searchfile' && adminRole != 'PRIVILEGED' && adminRole != 'LDAP') ||
                (item.id == 'chartanalysis' && adminRole != 'PRIVILEGED' && adminRole != 'LDAP') ||
                ((item.id != 'searchfile' || !privAllow.searchfile) &&
                  (item.id != 'fileexplorer' || !privAllow.fileExplorer) &&
                  (item.id != 'chartanalysis' || !privAllow.chartAnalysis) &&
                  adminRole == 'PRIVILEGED') ||
                ((item.id != 'searchfile' || !ldapAllow.searchfile) &&
                  (item.id != 'fileexplorer' || !ldapAllow.fileExplorer) &&
                  (item.id != 'chartanalysis' || !ldapAllow.chartAnalysis) &&
                  adminRole == 'LDAP') ||

                (item.id != 'archiveagents' &&
                  item.id != 'jobsstatus' &&
                  item.id != 'jobstatus' &&
                  item.id != 'license' &&
                  item.id != 'buildinfo' &&
                  item.id != 'custombranding' &&
                  item.id != 'userfeatures' &&
                  item.id != 'companymanagement' &&
                  item.id != 'jobmanagement' &&
                  item.id != 'systemstatus' &&
                  item.id != 'analytics' &&
                  item.id != 'analyticsData' &&
                  item.id != 'DuplicateDataChart' &&
                  item.id != 'AnalyzedShareVolume' &&
                  item.id != 'VolumebyFileType' &&
                  item.id != 'FileShareChartsbyDateModified' &&
                  item.id != 'FileShareChartsbyDateAccessed' &&
                  item.id != 'LargestfolderbySize' &&
                  item.id != 'repository-reports' &&
                  item.id != 'user-activity' &&
                  item.id != 'policymanagement' &&
                  item.id != 'documentclassifier' &&
                  item.id != 'volumemanagement' &&
                  item.id != 'stubagents' &&
                  item.id != 'usermanagement' &&
                  item.id != 'accountpolicy' &&
                  item.id != 'globalconfiguration' &&
                  item.id != 'rolepermissions' &&
                  item.id != 'ldapconfiguration' &&
                  adminRole == 'SUPERADMIN') ||
                (item.id == 'jobsstatus' && userRole == 'superadmin') ||
                (item.id == 'companymanagement' && userRole == 'superadmin') ||
                (item.id == 'companymanagement' && adminRole == 'ADMINISTRATOR') ||
                (item.id == 'jobsstatus' && adminRole == 'ADMINISTRATOR') ||
                (item.id == 'jobmanagement' && adminRole == 'SUPERADMIN' && userRole != 'superadmin') ||
                ((item.id == 'systemstatus' ||
                  item.id == 'analytics' ||
                  item.id == 'repository-reports' ||
                  item.id == 'jobmanagement' ||
                  item.id == 'jobstatus' ||
                  item.id == 'user-activity' ||
                  item.id == 'documentclassifier' ||
                  item.id == 'volumemanagement' ||
                  item.id == 'stubagents' ||
                  item.id == 'usermanagement' ||
                  item.id == 'accountpolicy' ||
                  item.id == 'globalconfiguration' ||
                  item.id == 'ldapconfiguration' ||
                  item.id == 'policymanagement') &&
                  userRole != 'superadmin' &&
                  adminRole != 'ADMINISTRATOR' &&
                  adminRole != 'DATAGUARDIAN') ||
                (item.id != 'deletecodes' &&
                  item.id != 'auditaccesslog' &&
                  item.id != 'auditloginlog' &&
                  item.id != 'user-activity' &&
                  adminRole == 'DATAGUARDIAN') ||
                ((item.id == 'deletecodes' || item.id == 'auditaccesslog' || item.id == 'auditloginlog') &&
                  // item.id == 'jobmanagement') &&
                  // item.id == 'user-activity'
                  adminRole != 'DATAGUARDIAN') ? null : (
                  <NavListSub key={item.title} list={item} />
                )
              )}
            </List>
          </Collapse>
        )}
      </>
    );
  }

  return <NavItemRoot item={list} active={active} isCollapse={isCollapse} />;
}

// ----------------------------------------------------------------------

NavListSub.propTypes = {
  list: PropTypes.object,
};

function NavListSub({ list }) {
  const { pathname } = useLocation();

  const active = getActive(list.path, pathname);

  const [open, setOpen] = useState(active);

  const hasChildren = list.children;

  if (hasChildren) {
    return (
      <>
        <NavItemSub item={list} onOpen={() => setOpen(!open)} open={open} active={active} />

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ pl: 3 }}>
            {(list.children || []).map((item) => (
              <NavItemSub key={item.title} item={item} active={getActive(item.path, pathname)} />
            ))}
          </List>
        </Collapse>
      </>
    );
  }

  return <NavItemSub item={list} active={active} />;
}
