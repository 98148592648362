import { BAR_CODE } from './UserSetting';

const initialState = {
  barCode: '',
};

const userSettingReducer = (state = initialState, action) => {
  switch (action.type) {
    case BAR_CODE: {
      return {
        ...state,
        barCode: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default userSettingReducer;
