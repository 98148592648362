import {
  Button,
  Card,
  Container,
  FormControlLabel,
  Grid,
  Modal,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Page from 'src/components/Page';
import { BASE_URL } from 'src/config';
import useSettings from 'src/hooks/useSettings';
import { ERROR_ALERT, SUCCESS_ALERT } from 'src/redux/reducers/alertReducer';
import { GetFetch, getHeaders } from 'src/utils/fetchServices';
export const BAR_CODE = 'BAR_CODE';
// export const SUCCESS_CODE = 'SUCCESS_CODE';

const UserSetting = ({ openModal, close }) => {
  const dispatch = useDispatch();
  const barCode = useSelector((state) => state.userSettingReducer.barCode);
  const codeVerifyData = useSelector((state) => state.login.twoFactorAuth);
  const { themeStretch } = useSettings();
  const [checked, setChecked] = useState(codeVerifyData);
  const [firtRun, setFirstRun] = useState(false);
  const [code, setCode] = useState();

  useEffect(()=>{
    setChecked(codeVerifyData)
  },[codeVerifyData])
  const getBarCode = (check, code) => async (dispatch) => {
    const companyId = localStorage.getItem('companyId');
    const config = { 
      headers: getHeaders()
    }
    if(!code){
      config.responseType = 'blob'
    }
    try {
      const response = await axios.get(
        `${BASE_URL}users/company/${companyId}/2fa/${!check ? 'disable' : code ? 'verify/' : 'enable'}${
          code ? code : ''
        }`,config
        
      );
      const data = response.data;
      if (!code) {
        var blob = new Blob([data]);
        let url = URL.createObjectURL(blob);
        if (response && response.status === 200) {
          dispatch({
            type: BAR_CODE,
            payload: url,
          });
        }

        dispatch({
          type: SUCCESS_ALERT,
          payload: !check
            ? '2FA Disabled Successfully'
            : !code
            ? '2FA Enabled Successfully'
            : data.success
            ? 'Verified'
            : '',
        });
      }
      if(data.success){
        dispatch({
          type: SUCCESS_ALERT,
          payload: 'Enabled Successfully.'
        })
      }
      if (data.success === false) {
        dispatch({
          type: ERROR_ALERT,
          payload: 'Please Check the Code',
        });
      }
    } catch (error) {
      console.log('errors', error);
    }
  };

  useEffect(() => {
    if (firtRun) {
      dispatch(getBarCode(checked));
    }
    setFirstRun(true);
  }, [checked]);

  const handleSubmit = () => {
    dispatch(getBarCode(checked, code));
  };


  return (
    <>
      <Modal
        open={openModal}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Page sx={{ width: '100%' }}>
          <Container maxWidth={themeStretch ? false : 'xl'}>
            <Card sx={{ backgroundColor: '#ffff', p: '40px' }}>
              <Typography variant="h5">User Setting</Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <Card sx={{ p: '20px' }}>
                    <Typography variant="h6">2 Factor Authentication</Typography>
                    <FormControlLabel
                      control={<Switch />}
                      label="2FA"
                      checked={checked}
                      onChange={() => setChecked(!checked)}
                    />
                    {checked &&(
                      <>
                        {barCode  &&  !codeVerifyData &&<img src={barCode} />}
                        { !codeVerifyData && 
                        <>
                        <TextField
                          fullWidth
                          type="number"
                          variant="standard"
                          label="Scan And Enter QR Code"
                          sx={{ my: '20px' }}
                          onChange={(e) => setCode(e.target.value)}
                          />
                        <Button
                          variant="contained"
                          sx={{ color: '#fff', mt: '10px' }}
                          disabled={code ? false : true}
                          onClick={handleSubmit}
                        >
                          Submit
                        </Button>
                          </>
                        }
                      </>
                    )}
                  </Card>
                </Grid>
              </Grid>
            </Card>
          </Container>
        </Page>
      </Modal>
    </>
  );
};

export default UserSetting;
