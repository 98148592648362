import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, CardHeader, Grid, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useDispatch } from 'react-redux';
import jwt from 'jwt-decode';
import { isEmpty } from 'lodash';
import { generateLicense, requestLicense } from '../licenseActions';
import { ERROR_ALERT } from 'src/redux/reducers/alertReducer';
import copiedFunction from 'src/utils/copyMethod';

const LicenseSchema = yup.object().shape({
  companyName: yup.string()
    .max(50, 'Company Name is too long')
    .required('Company Name is required')
    .matches(/^[a-zA-Z0-9\s\-!@#$%^&*,.]+$/, 'Invalid characters in Company Name'),
  contactName: yup.string()
    .max(50, 'Contact Name is too long')
    .required('Contact Name is required')
    .matches(/^[a-zA-Z\s-]+$/, 'Invalid characters in Contact Name'),
  contactEmail: yup.string().email('Invalid email address').required('Email is required'),
  contactPhone: yup
    .string()
    .matches(
      '^[0-9+-]*$',
      'Company Telephone Accepts Only Numbers'
    )
    .required('Contact Phone is required'),
  address: yup.string().required('Address is required').matches(/^[a-zA-Z0-9\s.,;:'"!?()-]+$/, 'Invalid characters in address'),
});

const GeneratLicence = ({ license, requestId }) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(LicenseSchema),
  });

  useEffect(() => {
    if (!isEmpty(license)) {
      reset({
        companyName: license.companyName,
        contactName: license.contactName,
        contactEmail: license.email,
        contactPhone: license.phoneNo,
        address: license.address,
      });
    }
  }, [license]);

  const generateLicenseId = () => {
    let generateLicenseData = getValues();
    if (isEmpty(errors)) {
      dispatch(generateLicense(generateLicenseData, { productionRequest: true }));
    } else {
      dispatch({
        type: ERROR_ALERT,
        payload: 'Please fill all the fields',
      });
    }
  };
  const handleRefresh = () => {
    window.location.reload();
  };
  const access_token = localStorage.getItem('accessToken');
  const [admin, setAdminRole] = useState();

  useEffect(() => {
    if (access_token) {
      const decodedToken = jwt(access_token);
      setAdminRole(decodedToken.authorities[0])
    }
  }, [access_token])

  const onSubmit = (data) => {
    dispatch(requestLicense(data, { productionRequest: false }));
  };

  return (
    <Card>
      <CardHeader title="Generate License" />
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} sx={{ m: '40px', mt: 1 }}>
          <Grid item xs={12} md={5}>
            <TextField
              name="companyName"
              // value={generateDetails.companyName}
              label="Company Name"
              variant="standard"
              helperText={errors.companyName && errors.companyName.message}
              error={errors.companyName && errors.companyName.message}
              {...register('companyName')}
              sx={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
              }}
            // onChange={(e) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              name="contactName"
              // value={generateDetails.contactName}
              label="Contact Name"
              variant="standard"
              helperText={errors.contactName && errors.contactName.message}
              error={errors.contactName && errors.contactName.message}
              {...register('contactName')}
              sx={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
              }}
            // onChange={(e) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              name="contactEmail"
              // value={generateDetails.contactEmail}
              label="Contact Email"
              variant="standard"
              helperText={errors.contactEmail && errors.contactEmail.message}
              error={errors.contactEmail && errors.contactEmail.message}
              {...register('contactEmail')}
              sx={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
              }}
            // onChange={(e) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              name="contactPhone"
              // value={generateDetails.contactPhone}
              label="Contact Phone"
              type="text"
              variant="standard"
              helperText={errors.contactPhone && errors.contactPhone.message}
              error={errors.contactPhone && errors.contactPhone.message}
              {...register('contactPhone')}
              sx={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
              }}
            // onChange={(e) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={12} md={10}>
            <TextField
              name="address"
              // value={generateDetails.address}
              label="Address"
              variant="standard"
              helperText={errors.address && errors.address.message}
              error={errors.address && errors.address.message}
              {...register('address')}
              sx={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
              }}
            // onChange={(e) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={12} md={10} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <TextField
              value={requestId}
              label="Generate License Request Id"
              variant="standard"
              sx={{ width: '93%' }}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
            />
            <Button onClick={() => copiedFunction(requestId, dispatch)}>
              <ContentCopyIcon />
            </Button>
          </Grid>
          <Grid item xs={12} md={5}>
            <Button variant="contained" type="submit" sx={{ color: '#ffff' }}>
              Request Trial License
            </Button>
            <Button variant="contained" sx={{ color: '#ffff', m: '10px' }} onClick={() => generateLicenseId()}>
              Generate License ID(PROD)
            </Button>
            {admin == 'SUPERADMIN' ? (
              <Button variant="contained" sx={{ color: '#ffff', m: '10px' }} onClick={handleRefresh}>
                Refresh
              </Button>
            ) : null}
          </Grid>
        </Grid>
      </form>
    </Card>
  );
};

export default GeneratLicence;
