import { useTheme } from '@mui/material/styles';
import {
  Box,
  Card,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  Typography,
  TableContainer,
  TablePagination,
  Checkbox,
  Stack,
} from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Scrollbar from '../../../../components/Scrollbar';
import { Link } from 'react-router-dom';
import { CheckBox } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import DeleteAlert from 'src/pages/DeleteAlert';
import { deleteDocument, updateIndexContent } from '../documentActions';
import { useDispatch } from 'react-redux';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

export default function DocumentTable({ pages, size, documents, columns, sortData }) {
  const dispatch = useDispatch();
  const [rowsPerPage, setRowsPerPage] = size;
  const [page, setPage] = pages;
  const [deleteId, setDeleteId] = useState();
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [documentsData, setDocumentsData] = useState([]);
  const [sort, setSort] = sortData;
  const [arrow, setArrow] = useState(false);
  const [active, setActive] = useState();

  useEffect(() => {
    setDocumentsData(documents.content);
  }, [documents]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleDelete = (id) => {
    setDeleteId(id);
    setDeleteDialog(true);
  };
  const removeInfo = () => {
    dispatch(deleteDocument(deleteId));
    setDeleteDialog(false);
  };

  const onCheckboxChange = (event, row) => {
    row[event.target.name] = event.target.checked;
    setDocumentsData([...documentsData]);
    dispatch(
      updateIndexContent({
        companyId: row.companyId,
        description: row.description,
        documentTypes: row.children,
        id: row.id,
        indexContentEnabled: row.indexContentEnabled,
        name: row.name,
      })
    );
  };

  const TableSort = (i) => {
    setActive(i);
    setArrow(!arrow);
    if (i == 0) {
      if (!arrow) {
        setSort('name,desc');
      } else {
        setSort('name,asc');
      }
    }
    if (i == 2) {
      if (!arrow) {
        setSort('description,desc');
      } else {
        setSort('description,asc');
      }
    }
  };

  return (
    <>
      <Card>
        <DeleteAlert openDialog={[deleteDialog, setDeleteDialog]} remove={removeInfo} />
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <CardHeader title="Document Category List" sx={{ mb: 3 }} />
          <Link to="add" style={{ textDecoration: 'none' }}>
            <Button variant="contained" sx={{ color: '#ffff', height: '40px', m: '20px' }}>
              Add
            </Button>
          </Link>
        </Box>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 720 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column, i) => (
                    <TableCell key={i}>
                      <Stack direction="row" onClick={() => TableSort(i)}>
                        <Typography style={{ marginRight: '8px' }}> {column}</Typography>
                        {i == 0 && active == i ? (
                          arrow == true ? (
                            <ArrowDownwardIcon />
                          ) : (
                            <ArrowUpwardIcon />
                          )
                        ) : i == 2 && active == i ? (
                          arrow == true ? (
                            <ArrowDownwardIcon />
                          ) : (
                            <ArrowUpwardIcon />
                          )
                        ) : i == 0 || i == 2 ? (
                          <ArrowUpwardIcon size="small" sx={{ opacity: '0', '&:hover': { opacity: '0.5' } }} />
                        ) : null}
                      </Stack>
                      {/* {column} */}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {documents.content &&
                  documentsData.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>
                        <Typography sx={{ color: '#699ad4' }}>{row.name}</Typography>
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          name="indexContentEnabled"
                          checked={row.indexContentEnabled || ''}
                          onChange={(e) => onCheckboxChange(e, row)}
                        />
                      </TableCell>
                      <TableCell>{row.description === '' ? 'NA' : row.description}</TableCell>
                      <TableCell sx={{ maxWidth: '100px' }}>
                        <Link to={`edit/${row.id}`} style={{ textDecoration: 'none' }}>
                          <Button sx={{ minWidth: 'fit-content', p: 0 }}>
                            <ModeEditIcon />
                          </Button>
                        </Link>
                        <Button sx={{ minWidth: 'fit-content', p: 0 }} onClick={() => handleDelete(row.id)}>
                          <DeleteForeverIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20, 100]}
              component="div"
              count={documents.totalElements}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Scrollbar>
      </Card>
    </>
  );
}
