import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Grid } from '@mui/material';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import LoadingScreen from 'src/components/LoadingScreen';
import CompanyTable from './components/CompanyTable';
import { getCompanyList } from './companyActions';

export default function CompanyList() {
  const dispatch = useDispatch();
  const companyList = useSelector(state => state.companyReducer.companyList);
  const loading = useSelector(state => state.companyReducer.loading);
  const { themeStretch } = useSettings();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  useEffect(() => {
    dispatch(getCompanyList({ page, size: rowsPerPage }));
  }, [page, rowsPerPage]);


  const columns = ['Company Name', 'Data Volume', 'Preferred name', 'Company Tag', 'Global Key', 'Admin Name', 'Company Login'];

  return (
    <Page>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} style={{ paddingTop: '1px' }}>
            { !loading ? (
              companyList.content &&
              <CompanyTable
                pages={[page, setPage]}
                size={[rowsPerPage, setRowsPerPage]}
                columns={columns}
                companyList={companyList}
              />
             ): <LoadingScreen/>} 
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
