import { Refresh } from '@mui/icons-material';
import { SUCCESS_ALERT } from 'src/redux/reducers/alertReducer';
import {  GetDetails, GetFetch, postInfo } from 'src/utils/fetchServices';

export const STUB_AGENTS_FETCH_SUCCESS = 'STUB_AGENTS_FETCH_SUCCESS';
export const REFRESHED_SUCCESS = 'REFRESHED_SUCCESS';
export const DATA_LOADER = 'DATA_LOADER';
export const FETCH_FAIL = 'FETCH_FAIL';

export const getStubAgents = () => async (dispatch) => {
  let companyTag = localStorage.getItem("companyTag")
  dispatch({
    type: DATA_LOADER,
  });
  try {
    const response = await GetDetails({ url: `system-status/stub-status/${companyTag || 'DataRepository'}` });
    const data = response.data;
    dispatch({
      type: STUB_AGENTS_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};

export const refreshStub = (params) => async (dispatch) => {
  let companyTag = localStorage.getItem("companyTag")
  dispatch({type: DATA_LOADER});
  try {
    const response = await postInfo({ 
      url:`file-stubber/refresh-stub/${companyTag || 'DataRepository'}`,
      params,
      method:'PUT',
    }, 
    dispatch);
    if(response && response.status === 200){
      const data = response.data;
      dispatch({
        type: REFRESHED_SUCCESS,
        payload: data,
      });
      dispatch({
        type: SUCCESS_ALERT,
        payload:'Refreshed Successfully',
      });
    } else{
      dispatch({type: FETCH_FAIL});
    }

  } catch (error) {
    dispatch({type: FETCH_FAIL});
  };
};