import { GLOBAL_FETCH_SUCCESS, DATA_LOADER, FETCH_FAIL, POST_SUCCESS, INDEX_FETCH_SUCCESS, LANGUAGES_FETCH_SUCCESS, TOKENIZERS_FETCH_SUCCESS, PROXY_PATH_FETCH_SUCCESS, DELETED_SUCCESSFULLY, PII_FETCH_SUCCESS, UNC_PATH_FETCH_SUCCESS, ROLE_LIMITS_FETCH_SUCCESS, ROLE_LIMITS_FETCH_LOADER, ROLE_LIMITS_FETCH_FAIL, ROLE_LIMITS_ADD_LOADER, ROLE_LIMITS_ADD_SUCCESS } from './globalActions';

const initialState = {
  loading: false,
  globalConfiguration: {},
  roleLimitisConfiguration: [],
  piiConfiguration: {},
  indexConfiguration: {},
  languages: {},
  tokenizers: {},
  uncPaths: [],
  proxyPaths: [],
  error: null,
  isRoleLimitLoading : false,
  isRoleLimitAddLoading: false,
  roleLimitError: false,
  roleLimitErrorMessage: ''
};

const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case DATA_LOADER: {
      return {
        ...state,
        loading: true,
      };
    }
    case ROLE_LIMITS_FETCH_LOADER: {
      return {
        ...state,
        isRoleLimitLoading: true,
        globalConfiguration: [],
        roleLimitError: false,
        roleLimitErrorMessage: ''
      };
    }
    case ROLE_LIMITS_ADD_LOADER: {
      return {
        ...state,
        isRoleLimitAddLoading: true,
        roleLimitError: false,
        roleLimitErrorMessage: ''
      };
    }
    case ROLE_LIMITS_ADD_SUCCESS: {
      return {
        ...state,
        isRoleLimitAddLoading: false,
        roleLimitError: false,
        roleLimitErrorMessage: ''
      };
    }
    case GLOBAL_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        globalConfiguration: action.payload,
      };
    }
    case ROLE_LIMITS_FETCH_FAIL: {
      return {
        ...state,
        isRoleLimitLoading: false,
        isRoleLimitAddLoading: false,
        roleLimitError: true,
      };
    }
    case ROLE_LIMITS_FETCH_SUCCESS: {
      return {
        ...state,
        isRoleLimitLoading: false,
        roleLimitisConfiguration: action.payload,
        roleLimitError: false,
        roleLimitErrorMessage: ''
      };
    }
    case PII_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        piiConfiguration: action.payload,
      };
    }
    case INDEX_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        indexConfiguration: action.payload,
      };
    }
    case UNC_PATH_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        uncPaths: action.payload,
      };
    }
    case LANGUAGES_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        languages: action.payload,
      };
    }
    case TOKENIZERS_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        tokenizers: action.payload,
      };
    }
    case PROXY_PATH_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        proxyPaths: action.payload,
      };
    }
    case POST_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case DELETED_SUCCESSFULLY: {
      return {
        ...state,
        loading: false,
      };
    }
    case FETCH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default: {
      return state;
    }
  }
};

export default globalReducer;
