import { STUB_AGENTS_FETCH_SUCCESS, DATA_LOADER, FETCH_FAIL, REFRESHED_SUCCESS } from './stubActions';

const initialState = {
  loading: false,
  stubAgents: [ ],
  error: null,
};

const stubReducer = (state = initialState, action) => {
  switch (action.type) {
    case DATA_LOADER: {
      return {
        ...state,
        loading: true,
      };
    }
    case STUB_AGENTS_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        stubAgents: action.payload,
      };
    }
    case REFRESHED_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case FETCH_FAIL:
      return {
        ...state,
        loading: false,
      };
    default: {
      return state;
    }
  }
};

export default stubReducer;
