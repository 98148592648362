import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import jwt from 'jwt-decode';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Typography, MenuItem } from '@mui/material';
// routes
import { PATH_DASHBOARD, PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import MyAvatar from '../../../components/MyAvatar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import { COMPANY_NUM, logout } from '../../../redux/actions/loginActions';
import LogoutIcon from '@mui/icons-material/Logout';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ChangePassword from './ChangePassword';
import UserSetting from './UserSetting';
import LaunchIcon from '@mui/icons-material/Launch';
import { getStatus2FA } from '../../../redux/actions/loginActions';
import { useDispatch } from 'react-redux';
import ResetPassword from './ResetPassword';

export default function AccountPopover() {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { user } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const userRole = localStorage.getItem('userRole');
  const access_token = localStorage.getItem('accessToken');
  const [adminRole, setAdminRole] = useState();

  const [open, setOpen] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openResetModal, setOpenResetModal] = useState(false);
  const [openSettingModal, setOpenSettingModal] = useState(false);

  useEffect(() => {
    if (access_token) {
      const decodedToken = jwt(access_token);
      setAdminRole(decodedToken.authorities[0])
    }
  }, [access_token])

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/auth/login", { replace: true });
      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };
  const handleModal = () => {
    if (adminRole == 'SUPERADMIN') {
      setOpenResetModal(true)
    } else {
      setOpenModal(true);
    }
    setOpen(false);
  };
  const handleSettingModal = () => {
    dispatch(getStatus2FA())
    setOpenSettingModal(true);
    setOpen(false);
  };

  const handleSuperAdminRole = () => {
    dispatch({ type: 'CHART_INITIALSTATE' })
    setOpen(null);
    localStorage.removeItem("companyTag");
    localStorage.removeItem("companyId");
    localStorage.removeItem("globalKey");
    localStorage.removeItem("companyName");
    localStorage.setItem("userRole", '');
    localStorage.setItem("companyId", localStorage.getItem("CompanyIdSU"));
    navigate('/dashboard/company-list');
    dispatch({
      type: COMPANY_NUM,
      payload: localStorage.getItem("CompanyIdSU"),
    });
  };

  return (
    <>
      <ChangePassword openModal={openModal} close={() => setOpenModal(false)} />
      <ResetPassword openModal={openResetModal} close={() => setOpenResetModal(false)} />
      <UserSetting openModal={openSettingModal} close={() => setOpenSettingModal(false)} />
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <MyAvatar />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        {userRole === 'superadmin' ? (
          <MenuItem onClick={handleSuperAdminRole} sx={{ p: 1 }}>
            <LaunchIcon sx={{ mr: '5px', color: '#888e93' }} /> Switch to SUPERADMIN
          </MenuItem>
        ) : (
          <MenuItem onClick={handleModal} sx={{ p: 1 }}>
            <LockOpenIcon sx={{ mr: '10px', color: '#888e93' }} /> {adminRole == 'SUPERADMIN' ? 'Reset Password' : 'Change Password'}
          </MenuItem>
        )}
        <MenuItem onClick={handleSettingModal} sx={{ p: 1 }}>
          <LockOpenIcon sx={{ mr: '10px', color: '#888e93' }} /> User Setting
        </MenuItem>
        <MenuItem onClick={handleLogout} sx={{ p: 1 }}>
          <LogoutIcon sx={{ mr: '10px', color: '#888e93' }} /> Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
