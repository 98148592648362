const { DATA_LOADER,DOWNLOAD_SUCCESS, POST_SUCCESS, FETCH_FAIL, USER_INFO, SIMILAR_DOC, AGENTS_FETCH_SUCCESS, GLOBAL_FETCH_SUCCESS, EXPORT_FILES_SUCCESS, HISTORY_SEARCH_SUCCESS, SEARCH_LOADER, FILE_VIEW_DATA,FILE_CONVERT_DATA, SEARCH_MQ_LOADER, SEARCH_MQ_FAIL, SEARCH_MQ_ALERT_CLOSE, SEARCH_SUCESS, CSV_EXPORT_START, PROGRESS_STOP } = require('./ediscoveryActions');

const initialState = {
  searchFilterData: {},
  userInfo: [],
  similarDoc: {},
  agents:{},
  globalConfig:{},
  userHistory:{},
  loading: false,
  searchLoading: false,
  searchMqLoading: false,
  searchMqError: false,
  searchMqErrorMessage: '',
  fileView: '',
  searchSucess: false,
  csvExportLoading: false,
};

const ediscoveryReducer = (state = initialState, action) => {
  switch (action.type) {
    case DATA_LOADER: {
      return {
        ...state,
        loading: true,
      };
    }
    case CSV_EXPORT_START: {
      return {
        ...state,
        csvExportLoading: true,
      };
    }
    case PROGRESS_STOP: {
      return {
        ...state,
        csvExportLoading: false,
      };
    }
    case SEARCH_LOADER: {
      return {
        ...state,
        searchLoading: true,
      };
    }
    case SEARCH_MQ_LOADER: {
      return {
        ...state,
        searchMqError: false,
        searchMqLoading: action.payload,
        searchMqErrorMessage: ''
      };
    }
    case SEARCH_MQ_FAIL: {
      return {
        ...state,
        searchMqLoading: false,
        searchMqError: true,
        searchMqErrorMessage: action.payload
      };
    }
    case SEARCH_SUCESS: {
      return {
        ...state,
        searchMqLoading: false,
        searchMqError: false,
        searchMqErrorMessage: '',
        searchSucess: true
      };
    }
    case SEARCH_MQ_ALERT_CLOSE: {
      return {
        ...state,
        searchMqError: false,
        searchSucess: false,
        searchMqErrorMessage: ''
      };
    }
    case POST_SUCCESS: {
      return {
        ...state,
        searchLoading: false,
        searchFilterData: action.payload,
      };
    }
    case USER_INFO: {
      return {
        ...state,
        loading: false,
        userInfo: action.payload,
      };
    }
    case FILE_VIEW_DATA: {
      return {
        ...state,
        loading: false,
        fileView: action.payload
      };
    }
    case HISTORY_SEARCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        userHistory: action.payload,
      };
    }
    case SIMILAR_DOC: {
      return {
        ...state,
        loading: false,
        similarDoc: action.payload,
      };
    }
    case AGENTS_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        agents: action.payload,
      };
    }
    case GLOBAL_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        globalConfig: action.payload,
      };
    }
    case EXPORT_FILES_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case DOWNLOAD_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case FETCH_FAIL:
      return {
        ...state,
        loading: false,
        searchLoading: false,
        error: action.payload,
      };
    case 'SET_ALL_INITIAL':
      return state = initialState;
    default: {
      return state;
    }
  }
};

export default ediscoveryReducer;
