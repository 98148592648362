import {
  Button,
  Card,
  Container,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { get } from 'lodash';
import React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';

const ExportModal = ({ openModal, close, exportValues, handleExports }) => {
  const { themeStretch } = useSettings();
  const [exportVal, setExportVal] = exportValues;
  const globalConfiguration = useSelector((state) => state.globalReducer.globalConfiguration);
  const maxResultsToExport = get(globalConfiguration, 'maxResultsToExport', 0);
  return (
    <>
      <Modal
        open={openModal}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Page>
          <Container maxWidth={themeStretch ? false : 'xl'}>
            <Card sx={{ p: '40px' }}>
              <Stack direction="row" alignItems="center" gap={6}>
                <Typography>Select Type</Typography>
                <RadioGroup value={exportVal} onChange={(e)=> setExportVal(e.target.value)}>
                  <Stack direction="row">
                    <FormControlLabel value="all" control={<Radio />} label={`Export All #(Max${maxResultsToExport})`} />
                    <FormControlLabel value="selected" control={<Radio />} label="Selected" />
                  </Stack>
                </RadioGroup>
              </Stack>
              <Stack direction="row" gap={4} sx={{ mt: '20px' }}>
                <Button variant="contained" onClick={close}>
                  Cancel
                </Button>
                <Button variant="contained" onClick={handleExports}>Export</Button>
              </Stack>
            </Card>
          </Container>
        </Page>
      </Modal>
    </>
  );
};

export default ExportModal;
