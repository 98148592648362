import { Button, Card, CardHeader, Container, Grid, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import { ERROR_ALERT } from 'src/redux/reducers/alertReducer';
import { addProxyPath, deletePath } from '../globalActions';
import ProxyTable from './ProxyTable';

const ProxyServer = ({ proxyPaths, onSubmit }) => {
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const [targetUncPath, setTargetUncPath] = useState();
  const [pathList, setPathList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [pathId, setPathId] = useState('');

  useEffect(() => {
    setPathList(proxyPaths);
  }, [proxyPaths]);

  const columns = ['Path', 'Actions'];

  const saveProxyServer = () => {
    if (targetUncPath == undefined || targetUncPath.trim() == '') {
      dispatch({
        type: ERROR_ALERT,
        payload: 'Please enter a valid path',
      });
      return;
    }
    let serverName = targetUncPath;
    if (serverName.indexOf('\\\\') === -1) {
      dispatch({
        type: ERROR_ALERT,
        payload: 'Path should start with \\\\server\\share',
      });
      return;
    } else {
      serverName = serverName.substring(2);
      if (serverName.indexOf('\\') === -1) {
        dispatch({
          type: ERROR_ALERT,
          payload: 'Path should start with \\\\server\\share ',
        });
        return;
      } else {
        if (isEdit) {
          dispatch(addProxyPath({ path: targetUncPath }, pathId, 'PUT'));
          setPathList(pathList.map(p => {
            if(p.id === pathId) {
              p.path = targetUncPath
            }
            return p;
          }))
          setIsEdit(false)
          setTargetUncPath('')
        } else {
          dispatch(addProxyPath({ path: targetUncPath }));
          setPathList([...pathList, { path: targetUncPath }]);
          setTargetUncPath('');
        }
      }
    }
  };

  const editPath = (row) => {
    setIsEdit(true);
    setPathId(row.id);
    setTargetUncPath(row.path);
  };

  const handleDelete = (id, i) => {
    let list = pathList;
    list.splice(i, 1);
    setPathList([...list]);
    dispatch(deletePath(id));
  };
  return (
    <Page>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Card sx={{ pb: '20px' }}>
          <CardHeader title="Proxy Server Path" />
          <Grid container spacing={2} sx={{ m: '10px' }}>
            <Grid item xs={12} md={5.5}>
              <TextField
                name="targetUncPath"
                label="e.g. \\server\share"
                value={targetUncPath}
                variant="standard"
                sx={{ width: '100%' }}
                onChange={(e) => setTargetUncPath(e.target.value)}
                InputLabelProps={{
                  shrink: true,
              }}
                required
              />
            </Grid>
            <Grid item xs={12} md={5.5}>
              <Button variant="contained" sx={{ color: '#ffff',mt:1 }} onClick={() => saveProxyServer()}>
                {isEdit ? 'Update' : 'Add'}
              </Button>
              {isEdit && (
                <Button
                  variant="contained"
                  sx={{ color: '#ffff', ml: '10px' }}
                  onClick={() => {
                    setIsEdit(false);
                    setTargetUncPath('');
                  }}
                >
                  Cancel
                </Button>
              )}
            </Grid>
            <Grid item xs={12} md={11}>
              <ProxyTable columns={columns} rows={pathList} handleDelete={handleDelete} editPath={editPath} />
            </Grid>
            {/* <Grid item xs={12} md={12}>
              <Button type="submit" variant="contained" sx={{ color: '#ffff' }}>
                Submit
              </Button>
            </Grid> */}
          </Grid>
        </Card>
      </Container>
    </Page>
  );
};

export default ProxyServer;
