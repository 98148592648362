export const getEpochtime = (date) => {
    return date.getTime();
  }

  export const truncateString = (str, maxLength) => {
    return str.length > maxLength ? `${str.substring(0, maxLength)}...` : str;
  };

  export const  toLowerCaseAlphabets = (input) => {
    let result = '';
  
    for (let i = 0; i < input.length; i++) {
      let char = input[i];
      if (char >= 'A' && char <= 'Z') {
        result += String.fromCharCode(char.charCodeAt(0) + 32);
      } else {
        result += char;
      }
    }
  
    return result;
  }