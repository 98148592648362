import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  Box,
  Card,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  TableContainer,
  TablePagination,
  Typography,
  Stack,
  TextField,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import SearchIcon from '@mui/icons-material/Search';
import { getAuditUsers, downloadFile } from './auditActions';


const DAYS = ['No Date', 'Custom', 'Today', 'Yesterday', 'This Week', 'This Month', 'This Year'];
const ROLE = ['DATAGUARDIAN', 'ADMINISTRATOR', 'PRIVILEGED'];

export default function AuditLogTable() {
  const users = useSelector((state) => state.auditReducer.users);
  const dispatch = useDispatch();
  const theme = useTheme();

  let date = new Date();
  let EndDate = date.toISOString();
  let EndDateCustom = moment(new Date(date).setHours(6, 53, 41)).format('YYYY-MM-DDTHH:mm:ss.SSS')
  let showStartDate = moment(date).format('YYYY-MM-DDTHH:mm')
  let showEndDate = date.toISOString().slice(0, 10);
  let setDate = date.setDate(date.getDate() - 1);
  let setDate2 = date.setDate(date.getDate() - 1);
  let Today1 = moment(new Date(setDate).setHours(18, 30, 0)).format('YYYY-MM-DD HH:mm:ss');

  const [range, setRange] = useState('Today');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const [filter, setFilter] = useState({
    loginStartDate: Today1,
    loginEndDate: EndDate,
    currentPage: page,
    itemsPerPage: rowsPerPage,
  });

  const [responseTime, setresponseTime] = useState('');

  const [newFilter, setnewFilter] = useState({
    role: '',
    userId: '',
    sourceIp: '',
  });
  const [showDate, setShowdate] = useState({
    StartDate: '',
    EndDate: '',
  });

  const [exportId, setExportId] = useState()
  const handleRange = (event) => {
    setRange(event.target.value);
  };

  useEffect(() => {
    if (range == 'Today') {
      let Today1 = moment(new Date(setDate).setHours(18, 30, 0)).format('YYYY-MM-DD HH:mm:ss');
      setFilter({ ...filter, loginStartDate: Today1, loginEndDate: EndDate });
      setShowdate({ StartDate: showEndDate, EndDate: showEndDate });
    } else if (range == 'Yesterday') {
      let Yesterday1 = moment(new Date(setDate2).setHours(18, 30, 0)).format('YYYY-MM-DD HH:mm:ss');
      let Yesterday2 = moment(new Date(setDate).setHours(18, 29)).format('YYYY-MM-DDTHH:mm:ss.SSS');
      let showYesterday = Yesterday2.slice(0, 10);
      setFilter({ ...filter, loginStartDate: Yesterday1, loginEndDate: Yesterday2 });
      setShowdate({ StartDate: showYesterday, EndDate: showYesterday });
    } else if (range == 'This Year') {
      let showfyear = date.setMonth(0) && date.setDate(1);
      let fullyear = date.setFullYear(date.getFullYear() - 1) && date.setMonth(11) && date.setDate(31);

      let fullyear1 = moment(new Date(fullyear).setHours(18, 30, 0)).format('YYYY-MM-DD HH:mm:ss');
      setFilter({ ...filter, loginStartDate: fullyear1, loginEndDate: EndDate });

      let showfullyear1 = moment(new Date(showfyear)).format('YYYY-MM-DD HH:mm:ss');
      let showYear = showfullyear1.slice(0, 10);
      setShowdate({ StartDate: showYear, EndDate: showEndDate });
    } else if (range == 'This Month') {
      let showMonth1 = moment(new Date(date.setDate(1))).format('YYYY-MM-DD HH:mm:ss');
      let Getmonth = date.setDate(0) && date.setMonth(date.getMonth());
      let Month1 = moment(new Date(Getmonth)).format('YYYY-MM-DD HH:mm:ss');
      let showMonth = showMonth1.slice(0, 10);
      setFilter({ ...filter, loginStartDate: Month1, loginEndDate: EndDate });
      setShowdate({ StartDate: showMonth, EndDate: showEndDate });
    } else if (range == 'This Week') {
      let weekDate = new Date();
      let weekD = weekDate.getDate() - weekDate.getDay();
      let showweekD = weekDate.getDate() - weekDate.getDay();
      let Week1 = moment(new Date(weekDate.setDate(weekD - 1)).setHours(18, 30, 0)).format('YYYY-MM-DD HH:mm:ss');
      setFilter({ ...filter, loginStartDate: Week1, loginEndDate: EndDate });

      let showWeek1 = moment(new Date(weekDate.setDate(showweekD))).format('YYYY-MM-DD HH:mm:ss');
      let showWeek = showWeek1.slice(0, 10);
      setShowdate({ StartDate: showWeek, EndDate: showEndDate });
    } else if (range == 'No Date') {
      setFilter({ currentPage: filter.currentPage, itemsPerPage: filter.itemsPerPage });
    }
  }, [range]);

  const handleStartDateChange = (event) => {
    setRange('Custom')
    let val = new Date(event.target.value);
    let startD = val.setDate(val.getDate() - 1)
    setFilter({ ...filter, loginStartDate: moment(startD).format('YYYY-MM-DD 18:30:ss'), loginEndDate: EndDateCustom });
  };
  const handleEndDateChange = (event) => {
    let val = new Date(event.target.value);
    setRange('Custom')
    setFilter({ ...filter, loginEndDate: moment(new Date(val).setHours(6, 53, 41)).format('YYYY-MM-DDTHH:mm:ss.SSS') });
  };

  const optionChange = (event) => {
    setnewFilter({ ...newFilter, [event.target.name]: event.target.value });
    setFilter({ ...filter, [event.target.name]: event.target.value });
    setExportId({ [event.target.name]: event.target.value })
  };

  const handleSearchTime = (startTime, endTime) => {
    let ResponseTime = (endTime - startTime) / 1000;
    setresponseTime(ResponseTime);
  };

  const Search = () => {
    filter.currentPage = page + 1;
    if (filter.userId == '') {
      delete filter.userId;
      delete exportId.userId;
    }
    if (filter.role == '') {
      delete filter.role;
      delete exportId.role;
    }
    if (filter.sourceIp == '') {
      delete filter.sourceIp;
      delete exportId.sourceIp;
    }
    dispatch(getAuditUsers(filter, handleSearchTime));
  };

  useEffect(() => {
    Search();
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setFilter({ ...filter, currentPage: newPage });
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setFilter({ ...filter, itemsPerPage: parseInt(event.target.value) });
  };

  const downloadCSV = () => {
    dispatch(
      downloadFile({
        ...exportId,
        loginEndDate: filter.loginEndDate,
        loginStartDate: filter.loginStartDate,
      })
    );
  };

  return (
    <>
      <Card>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <CardHeader title="Audit Login Log" sx={{ mb: 3 }} />
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-around"
          marginBottom="10px"
          backgroundColor="#F4F6F8"
          padding="10px"
        >
          <FormControl variant="standard" sx={{ m: 1, }}>
            <InputLabel id="demo-simple-select-standard-label">Select Range</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={range}
              onChange={handleRange}
              label="Range"
              name="range"
            >
              {DAYS.map((options, i) => (
                <MenuItem key={i} value={options}>
                  {options}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {range == 'No Date' ? null : range == 'Custom' ? (
            <Box>
              <Stack direction='row'>
                <TextField
                  id="startDate"
                  type="date"
                  name="loginStartDate"
                  label=" Start Date"
                  variant="standard"
                  InputProps={{ inputProps: { max: showEndDate } }}
                  onChange={handleStartDateChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  id="endDate"
                  type="date"
                  name="loginEndDate"
                  label="End Date"
                  variant="standard"
                  sx={{ marginLeft: '20px' }}
                  onChange={handleEndDateChange}
                  InputProps={{ inputProps: { max: showEndDate } }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Stack>
            </Box>
          ) : (
            <Box>
              <Stack direction='row'>
                <TextField
                  id="startDate"
                  type="date"
                  name="loginStartDate"
                  label=" Start Date"
                  variant="standard"
                  value={showDate.StartDate}
                  onChange={handleStartDateChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  id="endDate"
                  type="date"
                  name="loginEndDate"
                  label="End Date"
                  variant="standard"
                  sx={{ marginLeft: '20px' }}
                  value={showDate.EndDate}
                  onChange={handleEndDateChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Stack>
            </Box>
          )}
          <Box
            component="form"
            sx={{
              '& > :not(style)': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="standard-basic"
              name="sourceIp"
              label="Sorce IP"
              variant="standard"
              value={newFilter.sourceIp}
              onInput={optionChange}
            />
            <FormControl variant="standard" sx={{ m: 1, }}>
              <InputLabel id="standard-basic-label">Select Role</InputLabel>
              <Select
                labelId="standard-basic-label"
                id="standard-basic"
                value={newFilter.role}
                onChange={optionChange}
                label="Role"
                name="role"
              >
                {ROLE.map((options, i) => (
                  <MenuItem key={i} value={options}>
                    {options}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              id="standard-basic"
              name="userId"
              label="User"
              variant="standard"
              value={newFilter.userId}
              onInput={optionChange}
            />
          </Box>
          <Button variant='contained' onClick={Search}>
            <SearchIcon /> Search
          </Button>
        </Stack>
        {users?.totalCount ? (
          <Stack
            fullWidth
            direction="row"
            justifyContent="space-between"
            sx={{
              p: '20px',
              m: '10px',
              backgroundColor: '#F4F6F8',
              borderRadius: '12px',
            }}
          >
            <Typography>
              Found {users?.totalCount} Record(s) <br />
              Search Time {responseTime} Second(s)
            </Typography>
            <Button variant="contained" sx={{ padding: '10px' }} onClick={downloadCSV}>Export to CSV</Button>
          </Stack>
        ) : null}
        <TableContainer sx={{ minWidth: 720 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Login Time</TableCell>
                <TableCell>User Role</TableCell>
                <TableCell>Source IP</TableCell>
                <TableCell>Accessed By</TableCell>
              </TableRow>
            </TableHead>
            {users?.totalCount == 0 ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    No Data Found ...
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {users?.list &&
                  users?.list.length &&
                  users?.list.map((row) => (
                    <TableRow>
                      <TableCell>
                        <Typography sx={{ color: '#699ad4' }}>
                          {moment(row.loginTime).format('YYYY-MM-DD HH:mm:ss')}
                        </Typography>
                      </TableCell>
                      <TableCell>{row.role}</TableCell>
                      <TableCell>{row.sourceIp}</TableCell>
                      <TableCell>{row.userId}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            )}
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20, 100]}
            component="div"
            count={users?.totalCount && users.totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Card>
    </>
  );
}
