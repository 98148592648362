import {
  Box,
  Card,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  TableContainer,
  Typography,
} from '@mui/material';
import Scrollbar from '../../../../components/Scrollbar';

export default function StubTable({ stubAgents, columns, refreshStubAgent }) {
  
  return (
    <>
      <Card>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <CardHeader title="Process Queues" sx={{ mb: 3 }} />
        </Box>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 720 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column, i) => (
                    <TableCell key={i}>{column}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {stubAgents &&
                  stubAgents.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>
                        <Typography sx={{ color: '#699ad4' }}>{row.hostName}</Typography>
                      </TableCell>
                      <TableCell>{row.agentStatus}</TableCell>
                      <TableCell>{row.partiallyArchived}</TableCell>
                      <TableCell>{row.waitingToBeStubbed}</TableCell>
                      <TableCell>{row.inError}</TableCell>
                      <TableCell>
                        <Button variant="contained" disabled={row.inError == 0 ? true : false} sx={{ color: '#ffff' }}>
                          Retry Stub Error
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button variant="contained" disabled={row.inError == 0 ? true : false} sx={{ color: '#ffff' }}>
                          Show Error
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button variant="contained" sx={{ color: '#ffff' }} onClick={()=>refreshStubAgent(row.hostName)} >
                          Refresh
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Card>
    </>
  );
}
