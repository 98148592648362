import {
  Button,
  Card,
  CardHeader,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import { saveConfig,getIndexConfiguration } from '../globalActions';

const IndexConfiguration = ({ indexConfiguration, languages, tokenizers }) => {
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const [indexConfig, setIndexConfig] = useState({});
  useEffect(() => {
    setIndexConfig(indexConfiguration);
  }, [indexConfiguration]);

  const handleChange = (e) => {
    setIndexConfig({ ...indexConfig, [e.target.name]: e.target.value });
  };

  const handleSubmit = ()=> {
    dispatch(saveConfig(indexConfig));
    dispatch(getIndexConfiguration());
  }

  return (
    <Page>
      <Container maxWidth={themeStretch ? false : 'xl'}>
          <Card sx={{ pb: '20px' }}>
            <CardHeader title="Index Configuration" />
            <Grid container spacing={2} sx={{ m: '10px' }}>
              <Grid item xs={12} md={5.5}>
                <TextField
                  name="noOfReplicas"
                  type="number"
                  label="Index Replicas"
                  value={indexConfig.noOfReplicas || ''}
                  variant="standard"
                  sx={{ width: '100%' }}
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12} md={5.5}>
                <TextField
                  name="noOfShards"
                  type="number"
                  value={indexConfig.noOfShards || ''}
                  label="Index Shards"
                  variant="standard"
                  sx={{ width: '100%' }}
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12} md={5.5}>
                <FormControl fullWidth>
                  <InputLabel id="language" sx={{marginLeft:"-15px"}}>Index Language</InputLabel>
                  <Select
                    name="analyzerLanguage"
                    labelId="language"
                    variant="standard"
                    value={indexConfig.analyzerLanguage || ''}
                    label="Index Language"
                    onChange={handleChange}
                  >
                    {languages.languages &&
                      languages.languages.map((language, i) => {
                        return (
                          <MenuItem key={i} value={language}>
                            {language}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={5.5}>
                <FormControl fullWidth>
                  <InputLabel id="tokenizer" sx={{marginLeft:"-15px"}}>Tokenizer Type</InputLabel>
                  <Select
                    name="tokenizer"
                    labelId="tokenizer"
                    variant="standard"
                    label="Tokenizer Type"
                    value={indexConfig.tokenizer || ''}
                    onChange={handleChange}
                  >
                    {tokenizers.tokenizers &&
                      tokenizers.tokenizers.map((tokenizer, i) => {
                        return (
                          <MenuItem key={i} value={tokenizer}>
                            {tokenizer}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={5.5}>
                <TextField
                  name="tokenizableChars"
                  value={indexConfig.tokenizableChars || ''}
                  label="Tokenizer Characters"
                  variant="standard"
                  sx={{ width: '100%' }}
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={11} md={11} sx={{display:'flex',justifyContent:'right'}}>
                <Button
                  variant="contained"
                  sx={{ color: '#ffff' }}
                  onClick={handleSubmit}
                  disabled={!(indexConfig.noOfShards && indexConfig.noOfReplicas)}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Card>
      </Container>
    </Page>
  );
};

export default IndexConfiguration;
