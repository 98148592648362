import { Button, Card, CardHeader, Grid, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import jwt from 'jwt-decode';
import { getLicenseDetails, getLicenseDetails2, getLicenseId } from '../licenseActions';

const ApplyLicense = () => {
  const dispatch = useDispatch();
  const [license, setLicense] = useState('');
  const access_token = localStorage.getItem('accessToken');
  let admin = ''

  useEffect(() => {
    if (access_token) {
      const decodedToken = jwt(access_token);
      admin = decodedToken.authorities[0];
    }
  }, [access_token])
  const handleSubmit = () => {
    dispatch(getLicenseId({ license: license }))
  }
  const handleRefresh = () => {
    if (admin == 'SUPERADMIN') {
      dispatch(getLicenseDetails2())
    } else {
      dispatch(getLicenseDetails());
    }
  }
  return (
    <Card>
      <CardHeader title="Apply License" />
      <Grid container spacing={2} sx={{ m: '-10px 10px 20px 40px' }}>
        <Grid item xs={12} md={10}>
          <TextField
            label="Enter License"
            variant="standard"
            sx={{ width: '100%' }}
            onChange={(e) => setLicense(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <Button variant="contained" sx={{ color: '#ffff' }} onClick={() => handleSubmit()} disabled={license === '' ? true : false}>
            Submit
          </Button>
          <Button variant="contained" sx={{ color: '#ffff', m: '10px' }} onClick={handleRefresh}>
            Refresh
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ApplyLicense;
