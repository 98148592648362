import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardHeader } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { DUPLICATE_FILES, getDuplicateChartData, getDuplicateFiles, getVolumeData } from '../analyticsActions';
import moment from 'moment';
import chartColors from 'src/utils/chartColors';
import DuplicateFileModal from './DuplicateFileModal';

export default function DuplicateDataChart() {
  const dispatch = useDispatch();

  const duplicateDataChart = useSelector((state) => state.analyticsReducers.duplicateDataChart);
  const duplicateFiles = useSelector((state) => state.analyticsReducers.duplicateFiles);
  const dates = useSelector((state) => state.systemReducer.dates);
  const [startDate, setStartDate] = useState(new Date('1995/01/01'));
  const [endDate, setEndDate] = useState(new Date());
  const [year, setYear] = useState();
  const [duplicateTableData, setDuplicateTableData] = useState([]);
  const columns = ['Key', 'Doc Count', 'Value'];
  const [nextChart, setNextChart] = useState(50);
  const [prevChart, setPrevChart] = useState(0);
  const [nextBtn, setNextBtn] = useState(false);

  const [folderSizeBarChartLabels, setFolderSizeBarChartLabels] = useState([]);
  const [folderSizeBarChartData, setFolderSizeBarChartData] = useState([]);
  const [fileLabel, setFileLabel] = useState('');
  const [fileHashId, setFileHashId] = useState('');
  const [fileHashModal, setFileHashModal] = useState(false);
  const [filePage, setFilePage] = useState(0);
  const [filePageSize, setFilePageSize] = useState(10);

  useEffect(() => {
    dispatch(getDuplicateChartData());
  }, []);

  useEffect(() => {
    duplicateTableData.length &&
      duplicateTableData.map((data) => {
        if (data.key.substr(0, 3) + data.key.substr(-3, 3) == fileLabel) {
          setFileHashId(data.key);
        }
      });
  }, [fileLabel]);

  const getDuplicateFileTableData = (fileLabel) => {
    let hashId = '';
    duplicateTableData.length &&
      duplicateTableData.map((data) => {
        if (data.key.substr(0, 3) + data.key.substr(-3, 3) == fileLabel) {
          hashId = data.key;
        }
      });
    if (hashId) {
      const params = {
        fileHashIds: hashId,
        pageNo: 1,
        size: 10,
      };
      dispatch(getDuplicateFiles(params));
      setFileHashModal(true);
    }
  };

  useEffect(() => {
    if (fileHashId) {
      const params = {
        fileHashIds: fileHashId,
        pageNo: filePage + 1,
        size: filePageSize,
      };
      dispatch(getDuplicateFiles(params));
      // setFileHashModal(true);
    }
  }, [filePage, filePageSize]);

  useEffect(() => {
    if (duplicateDataChart && duplicateDataChart.aggregations) {
      setDuplicateTableData(duplicateDataChart.aggregations[2].buckets);
    }
  }, [duplicateDataChart]);

  useEffect(() => {
    let barChartPerMonthLabels = [];
    let barChartPerMonthData = [];
    let i = 0;
    let data = duplicateTableData && duplicateTableData.length && duplicateTableData.slice(prevChart, nextChart);
    let lastData =
      duplicateTableData && duplicateTableData.length && duplicateTableData[duplicateTableData.length - 1].key;
    if (duplicateTableData != '') {
      var MonthData = [];
      duplicateTableData &&
        duplicateTableData.length &&
        data.map((item, i) => {
          MonthData.push(parseInt(item[1].value) / (1024 * 1024 * 1024));
          barChartPerMonthLabels.push(item.key.substr(0, 3) + item.key.substr(-3, 3));
          if (item.key == lastData) {
            setNextBtn(true);
          }
        });
      barChartPerMonthData.push({
        data: MonthData,
        label: '',
        backgroundColor: chartColors[i++],
      });
      setFolderSizeBarChartLabels(barChartPerMonthLabels);
      setFolderSizeBarChartData(barChartPerMonthData);
    } else {
      setFolderSizeBarChartLabels([]);
      setFolderSizeBarChartData([]);
    }
  }, [duplicateTableData, nextChart, prevChart]);

  function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  const options = {
    onClick: function (evt) {
      getDuplicateFileTableData(evt.chart.tooltip.dataPoints[0].label);
      setFileLabel(evt.chart.tooltip.dataPoints[0].label);
    },
    plugins: {
      title: {
        display: true,
      },
      tooltip: {
        displayColors: false,
        titleFont: "'Open Sans', Helvetica, Arial, sans-serif",
        titleFontStyle: 'normal',
        titleColor: '#66afc9',
        bodyFont: "'Open Sans', Helvetica, Arial, sans-serif",
        bodyFontStyle: 'normal',
        bodyColor: '#66afc9',
        footerFont: "'Open Sans', Helvetica, Arial, sans-serif",
        footerFontStyle: 'normal',
        footerColor: '#66afc9',
        afterFooterFont: "'Open Sans', Helvetica, Arial, sans-serif",
        afterFooterFontStyle: 'normal',
        afterFooterColor: '#66afc9',
        footerSpacing: 7,
        callbacks: {
          title: function (tooltipItem) {
            return 'Size Volume:  ' + formatBytes(tooltipItem[0].parsed.y * 1024 * 1024 * 1024);
          },
          label: function (tooltipItem) {
            if (tooltipItem && tooltipItem.dataset.label == '') {
              return 'UUID: ' + tooltipItem.label;
            } else {
              return 'Date Modified:  ' + tooltipItem.label;
            }
          },
          // footer: function (tooltipItem) {
          //   // setLabel(tooltipItem[0].dataset.label);
          //   if (tooltipItem && tooltipItem[0].dataset.label != '') {
          //     return 'File Extensions:  ' + tooltipItem[0].dataset.label;
          //   }
          // },
          afterFooter: function (tooltipItem) {
            return 'Click to see files';
          },
        },
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        ticks: {
          autoSkip: false,
        },
        title: {
          display: true,
          text: 'Content Id',
        },
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: 'Size Volume (GB)',
        },
      },
    },
  };

  const data2 = {
    labels: folderSizeBarChartLabels,
    datasets: folderSizeBarChartData,
  };

  const prevDuplicate = () => {
    setNextChart(nextChart - 50);
    setPrevChart(prevChart - 50);
    setNextBtn(false);
  };
  const nextDuplicate = () => {
    setNextChart(nextChart + 50);
    setPrevChart(prevChart + 50);
  };

  const handleChangePage = (event, newPage) => {
    setFilePage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setFilePageSize(parseInt(event.target.value));
    setFilePage(0);
  };

  const handleFileClose = () => {
    dispatch({
      type: DUPLICATE_FILES,
      payload: {},
    });
    setFilePage(0);
    setFilePageSize(10);
    setFileHashId('');
    setFileHashModal(false);
  };
  return (
    <>
      <DuplicateFileModal
        openModal={fileHashModal}
        close={handleFileClose}
        data={duplicateFiles.result && duplicateFiles.result[0]}
        totalCount={duplicateFiles?.totalCount}
        rowsPerPage={filePageSize}
        page={filePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
      />
      <Card sx={{ p: '20px' }}>
        <CardHeader
          sx={{ mb: '20px', p: 0 }}
          title="Volume Chart by Duplicate Files"
          action={
            <>
              <Button
                variant="contained"
                sx={{ maxHeight: '48px', mr: '10px' }}
                disabled={prevChart == 0 ? true : false}
                onClick={() => prevDuplicate()}
              >
                prev
              </Button>
              <Button
                variant="contained"
                sx={{ maxHeight: '48px', mr: '10px' }}
                disabled={nextBtn ? true : false}
                onClick={() => nextDuplicate()}
              >
                next
              </Button>
            </>
          }
        />
        {folderSizeBarChartData && folderSizeBarChartData.length ? (
          <>
            <Bar options={options} data={data2} />
          </>
        ) : null}
      </Card>
    </>
  );
}
