import {
  Button,
  Card,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  tooltipClasses,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import copiedFunction from 'src/utils/copyMethod';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { styled } from '@mui/styles';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} enterDelay={600} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: 13,
    fontWeight: 400,
    maxWidth: 'none',
  },
}));

const FileTable = ({ columns, rows, showProperties, pages, pageRows, handleSearchAPI, handleSearchData }) => {
  const dispatch = useDispatch();
  const [rowsPerPage, setRowsPerPage] = pageRows;
  const [page, setPage] = pages;
  const [searchDetails, setSearchDetails] = handleSearchData;
  const [arrow, setArrow] = useState(false);
  const [active, setActive] = useState();

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const TableSort = (i) => {
    let data = searchDetails
    setActive(i);
    setArrow(!arrow);
    if (i == 2) {
      data.sort = 'lastModifiedDate'
      if (!arrow) {
        data.order = 'desc'
      } else {
        data.order = 'asc'
      }
    }
    if (i == 3) {
      data.sort = 'lastAccessDate'
      if (!arrow) {        
        data.order = 'desc'
      } else {
        data.order = 'asc'
      }
    }
    if (i == 4) {
      data.sort = 'size'
      if (!arrow) {
        data.order = 'desc'
      } else {
        data.order = 'asc'
      }
    }
    if (rows.fileServerResult) {
      handleSearchAPI();
    }
  };

  return (
    <>
      <Card sx={{ p: '20px' }}>
        <TableContainer>
          <Table>
            <colgroup>
              {/* <col style={{ width: '5%' }} /> */}
              <col style={{ width: '10%' }} />
              <col style={{ width: '40%' }} />
              <col style={{ width: '15%' }} />
              <col style={{ width: '15%' }} />
              <col style={{ width: '10%' }} />
              {/* <col style={{ width: '10%' }} /> */}
            </colgroup>
            <TableHead>
              <TableRow>
                {columns.map((column, i) => (
                  <TableCell key={i} sx={{pl:0}}>
                  <Stack direction="row" onClick={column == 'Name'? ()=> {} : () => TableSort(i)}>
                    <Typography > {column}</Typography>
                    {i > 1 && i < 5 && active == i ? (
                      arrow == true ? (
                        <ArrowDownwardIcon />
                      ) : (
                        <ArrowUpwardIcon />
                      )
                    ) : i > 1 && i < 5 ? (
                      <ArrowUpwardIcon size="small" sx={{ opacity: '0', '&:hover': { opacity: '0.5' } }} />
                    ) : null}
                  </Stack>

                </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.fileServerResult && rows.fileServerResult.length
                ? rows.fileServerResult.map((row, i) => (
                    <TableRow key={i}>
                      <TableCell sx={{ p: 0 }}>
                        <Button
                          onClick={() => copiedFunction(row.documentPath, dispatch)}
                          sx={{ minWidth: 'fit-content' }}
                        >
                          <ContentCopyIcon />
                        </Button>
                      </TableCell>
                      <TableCell sx={{ p: 0 }}>
                        <LightTooltip title={row.documentPath}>
                          <Typography
                            onClick={() => showProperties(row, 2)}
                            sx={{ cursor: 'pointer', color: '#039be5', fontSize: '14px', pr: '5px', width:'fit-content' }}
                          >
                            {row.documentName.slice(0, 120)}
                          </Typography>
                        </LightTooltip>
                      </TableCell>
                      <TableCell sx={{ p: 0 }}>
                        {moment(row.lastModificationDate).format('YYYY-MM-DD HH:mm:ss')}
                      </TableCell>
                      <TableCell sx={{ p: 0 }}>{moment(row.accessedOn).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                      <TableCell sx={{ p: 0 }}>{row.documentSizeToDisplay}</TableCell>
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50, 100]}
            component="div"
            count={rows.totalFileServerResultCount ? rows.totalFileServerResultCount : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Card>
    </>
  );
};

export default FileTable;
