import {
  JOB_STATUS_FETCH_SUCCESS,
  DATA_LOADER,
  FETCH_FAIL,
  JOB_RERUN_SUCCESS,
  EXECUTION_DETAILS_FETCH_SUCCESS,
  STATISTIC_FETCH_SUCCESS,
  JOB_LOG_FETCH_SUCCESS,
  DOWNLOAD_SUCCESS,
} from './jobStatusActions';

const initialState = {
  loading: false,
  jobStatus: {},
  executionDetails: [],
  statisticDetails: [],
  jobLogs: [],
  error: null,
};

const jobStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case DATA_LOADER: {
      return {
        ...state,
        loading: true,
      };
    }
    case JOB_STATUS_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        jobStatus: action.payload,
      };
    }
    case EXECUTION_DETAILS_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        executionDetails: action.payload,
      };
    }
    case STATISTIC_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        statisticDetails: action.payload,
      };
    }
    case JOB_LOG_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        jobLogs: action.payload,
      };
    }
    case JOB_RERUN_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case DOWNLOAD_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case FETCH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default: {
      return state;
    }
  }
};

export default jobStatusReducer;
