import {
  Autocomplete,
  Button,
  Card,
  CardHeader,
  Checkbox,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import { addJob, getAgents, getCopyAgents, getPolicies, getUncPath } from '../jobManagementActions';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { PROGRESS_DONE, PROGRESS_START } from 'src/redux/reducers/alertReducer';

const columns = ['Job Name', 'Policy', 'Target Path', 'Destination', 'Action'];

const CopyModal = ({ openModal, close, jobId }) => {
  const dispatch = useDispatch();
  const agents = useSelector((state) => state.jobManagementReducer.agents);
  const copyAgents = useSelector((state) => state.jobManagementReducer.copyAgents);
  const policies = useSelector((state) => state.jobManagementReducer.policies);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [isEdit, setIsEdit] = useState(-1);
  const { themeStretch } = useSettings();
  const [editFields, setEditFields] = useState({ path: '', name: '', policyName: '' });
  const [disableBtn, setDisableBtn] = useState(true);
  const uncPath = useSelector((state) => state.jobManagementReducer.uncPath);

  useEffect(() => {
    dispatch(getAgents({}));
    dispatch(getPolicies(1));
    dispatch(getUncPath());
  }, []);

  useEffect(() => {
    if (copyAgents) {
      setSelectedAgents([...selectedAgents, copyAgents]);
    }
  }, [copyAgents]);

  useEffect(() => {
    if (selectedAgents.length > 0) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
  }, [selectedAgents.length]);

  useEffect(() => {
    if (isEdit !== -1) {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  }, [isEdit]);

  const selectAgent = (e, agentId) => {
    if (e.target.checked) {
      dispatch(getCopyAgents({ jobId: jobId, agentId: agentId }));
    } else {
      setSelectedAgents(selectedAgents.filter((s) => s.agentId !== agentId));
    }
  };
  const handleClose = () => {
    setSelectedAgents([]);
    close();
  };

  const saveAgents = () => {
    dispatch(addJob(selectedAgents, 'jobs/bulk'));
    close();
  };

  const handleChange = (e, row, index) => {
    setIsEdit(isEdit === index ? -1 : index);
    setEditFields({ ...editFields, name: e.target.value });
    // row.name = e.target.value;
    // setSelectedAgents((c) => [...c]);
  };
  const handlePolicyChange = (e, row, index) => {
    // row.policyName = e.target.value;
    setIsEdit(isEdit === index ? -1 : index);
    setEditFields({ ...editFields, policy: e.target.value });
    // setSelectedAgents((c) => [...c]);
  };

  const handleRowChange = (row, index) => {
    setIsEdit(isEdit === index ? -1 : index);
    setEditFields({ path: row.remotePathsList.join(','), name: row.name, policyName: row.policyName });
  };

  const handleRowSave = (row, index) => {
    setIsEdit(-1);
    row.remotePathsList = editFields.path.split(',');
    row.name = editFields.name;
    row.policyName = editFields.policyName;
    setSelectedAgents((c) => [...c]);
  };

  const showTarget = (row) => {
    if (row.stagingJob === 0) return 'Repository';
    else if (row.stagingJob === 4) return 'SMB/CIFS';
    else if (row.stagingJob === 2) return 'S3 Cloud';
    else if (row.stagingJob === 3) return 'Azure Cloud Blob';
    else return 'NA';
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Page sx={{ width: '100%' }}>
          <Container maxWidth={themeStretch ? false : 'xl'}>
            <Card sx={{ backgroundColor: '#ffff', py: '40px' }}>
              <Grid container spacing={3} sx={{ padding: '0 24px' }}>
                <Grid item xs={12} md={12}>
                  <Card sx={{ p: '20px' }}>
                    <CardHeader title="Agents" sx={{ mb: '20px' }} />
                    <Stack direction="row" gap="25px" flexWrap="wrap" sx={{ ml: '20px' }}>
                      {agents &&
                        agents.content.map((agent, i) => {
                          return (
                            <Stack key={i} direction="row">
                              <Checkbox sx={{ padding: 0 }} onChange={(e) => selectAgent(e, agent.id)} />
                              <Typography sx={agent.online ? { color: '#45b55f' } : { color: '#f53b3b' }}>
                                {agent.name}
                              </Typography>
                            </Stack>
                          );
                        })}
                    </Stack>
                  </Card>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Card sx={{ p: '20px 14px' }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {columns.map((column, i) => (
                            <TableCell
                              sx={
                                column === 'Job Name'
                                  ? { width: '280px' }
                                  : column === 'Action'
                                  ? { width: '150px' }
                                  : column === 'Destination'
                                  ? { width: '280px' }
                                  : column === 'Policy'
                                  ? { width: '220px' }
                                  : ''
                              }
                              key={i}
                            >
                              {column}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedAgents.length
                          ? selectedAgents.map((row, i) => (
                              <TableRow key={i}>
                                <TableCell sx={{ p: 0, pl: '12px', width: '280px' }}>
                                  {isEdit === i ? (
                                    <TextField
                                      variant="standard"
                                      value={editFields.name}
                                      onChange={(e) => setEditFields({ ...editFields, name: e.target.value })}
                                    />
                                  ) : (
                                    row.name
                                  )}
                                </TableCell>
                                <TableCell sx={{ p: 0, pl: '12px', width: '220px' }}>
                                  {isEdit === i ? (
                                    <FormControl fullWidth>
                                      <Select
                                        variant="standard"
                                        value={editFields.policyName || ''}
                                        onChange={(e) => setEditFields({ ...editFields, policyName: e.target.value })}
                                      >
                                        {policies.length &&
                                          policies.map((policy) => {
                                            return (
                                              <MenuItem key={policy.id} value={policy.name}>
                                                {policy.name}
                                              </MenuItem>
                                            );
                                          })}
                                      </Select>
                                    </FormControl>
                                  ) : (
                                    row.policyName
                                  )}
                                </TableCell>
                                <TableCell sx={{ p: 0, pl: '12px' }}>
                                  {isEdit === i ? (
                                    <>
                                      <Autocomplete
                                        freeSolo
                                        name="remoteUnc"
                                        value={editFields.path || ""}
                                        options={uncPath && uncPath.map((option) => '\\\\' + option)}
                                        onChange={(e) => setEditFields({ ...editFields, path: e.target.textContent })}
                                        renderInput={(params) => (
                                          <TextField
                                            variant="standard"
                                            {...params}
                                            // label="UNC (e.g \\server\folder)"
                                            onChange={(e) => {
                                              setEditFields({ ...editFields, path: e.target.value })
                                            }}
                                          />
                                        )}
                                      />
                                      {/* <TextField
                                        variant="standard"
                                        value={editFields.path}
                                        onChange={(e) => setEditFields({ ...editFields, path: e.target.value })}
                                      /> */}
                                    </>
                                  ) : (
                                    row.remotePathsList.map((path, i) => (
                                      <Typography key={i} variant="body2">
                                        {' '}
                                        {path}{' '}
                                      </Typography>
                                    ))
                                  )}
                                </TableCell>
                                <TableCell sx={{ p: 0, pl: '12px', width: '280px' }}>{showTarget(row)}</TableCell>
                                {/* <TableCell sx={{ p: 0, pl: '16px' }}>
                                {row.scheduled ? <QueryBuilderIcon sx={{ color: '#f79816' }} /> : 'No'}
                              </TableCell> */}
                                <TableCell sx={{ width: '150px' }}>
                                  {isEdit === i ? (
                                    <>
                                      <Button
                                        sx={{ minWidth: 'fit-content', p: 0 }}
                                        onClick={() => handleRowSave(row, i)}
                                      >
                                        Confirm
                                      </Button>
                                      {/* <Button
                                      sx={{ minWidth: 'fit-content', p: 0 }}
                                      onClick={() => setIsEdit(-1)}
                                    >
                                      <CloseIcon />
                                    </Button> */}
                                    </>
                                  ) : (
                                    <Button
                                      sx={{ minWidth: 'fit-content', p: 0 }}
                                      onClick={() => handleRowChange(row, i)}
                                    >
                                      {<ModeEditIcon />}
                                    </Button>
                                  )}
                                </TableCell>
                              </TableRow>
                            ))
                          : null}
                      </TableBody>
                    </Table>
                  </Card>
                </Grid>
                <Grid item xs={12} md={7}>
                  {console.log(disableBtn)}
                  <Button
                    variant="contained"
                    sx={{ color: '#ffff', ml: '10px' }}
                    disabled={disableBtn}
                    onClick={saveAgents}
                  >
                    Save
                  </Button>
                  <Button variant="contained" sx={{ color: '#ffff', ml: '10px' }} onClick={handleClose}>
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Container>
        </Page>
      </Modal>
    </>
  );
};

export default CopyModal;
