import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import * as React from 'react';

export default function DeleteAlert({ remove, openDialog }) {
  const [deleteDialog, setDeleteDialog] = openDialog;

  const handleClose = () => {
    setDeleteDialog(false);
  };

  return (
    <>
      <Dialog
        open={deleteDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Are you Sure, You want to Delete ?'}</DialogTitle>
        <DialogActions>
          <Button variant='contained' sx={{color:'#fff'}} onClick={remove}>Yes</Button>
          <Button variant='contained' sx={{color:'#fff'}} onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
