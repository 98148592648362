import { DATES } from "src/layouts/dashboard/header/Searchbar";

const initialState = {
    dates: {
        startDate: '1995-01-01',
        endDate: new Date()
    }
}

const systemReducer = (state = initialState, action) => {
    switch (action.type) {

        case DATES: {
            return {
                ...state,
                loading: false,
                dates: action.payload
            }
        }
        default: {
            return state
        }
    }
}

export default systemReducer