import { Button, Card, Container, Modal, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import { updateProfile } from '../volumeActions';

const ConfirmModal = ({ openModal, close, updateDetails }) => {
  const { themeStretch } = useSettings();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const updateData = () => {
    dispatch(updateProfile(updateDetails, 'PUT', 'file-stores', navigate));
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Page sx={{ width: '60%' }}>
          <Container maxWidth={themeStretch ? false : 'xl'}>
            <Card sx={{ p: '40px' }}>
              <Stack direction="column">
                <Typography sx={{ pt: 1, pb: 1 }} variant="p">
                  Any files that are moved to the archive tier based on the set criteria may not be accessible for a few
                  hours once requested! click on OK to accept!
                </Typography>
                <Stack direction="row">
                  <Button variant="contained" onClick={() => updateData()}>
                    Ok
                  </Button>
                  <Button variant="contained" sx={{ ml: 2 }} onClick={() => close()}>
                    Cancel
                  </Button>
                </Stack>
              </Stack>
            </Card>
          </Container>
        </Page>
      </Modal>
    </>
  );
};

export default ConfirmModal;
