import {GetFetch} from 'src/utils/fetchServices';
import { SUCCESS_ALERT } from 'src/redux/reducers/alertReducer';
export const APPLICATIONEVENT_FETCH_SUCCESS = 'APPLICATIONEVENT_FETCH_SUCCESS';
export const DATA_LOADER = 'DATA_LOADER';
export const FETCH_FAIL = 'FETCH_FAIL';
export const DOWNLOAD_SUCCESS = 'DOWNLOAD_SUCCESS';



export const getApplicationEventUsers = (params, handleSearchTime) => async (dispatch) => {
  let startTime = new Date().getTime();
    const companyId = localStorage.getItem('companyId');
    dispatch({
      type: DATA_LOADER,
    });

    try {
      const response = await GetFetch({ 
        url: `user-activity/company/${companyId}`,
        params,
    });
    const data = response.data;
      dispatch({
        type: APPLICATIONEVENT_FETCH_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_FAIL,
        payload: error.message,
      });
    }
    let endTime = new Date().getTime();
    handleSearchTime(startTime, endTime)

  };

export  const downloadFile = (params) => async (dispatch) => {
    const companyId = localStorage.getItem('companyId');
    try {
      const response = await GetFetch({ 
        url: `user-activity/company/${companyId}/download-events`,
        params,
    });
      const data = response.data;
      var blob = new Blob([data]);
      let url = window.URL.createObjectURL(blob);
      let tempLink = document.createElement('a');
      tempLink.href = url;
      tempLink.setAttribute('download', 'Application Event Log.csv');
      tempLink.click();
      dispatch({
        type: DOWNLOAD_SUCCESS,
        payload: data,
      });
      dispatch({
        type: SUCCESS_ALERT,
        payload: 'Files exported successfully',
      });
    } catch (error) {
      dispatch({
        type: FETCH_FAIL,
        payload: error.message,
      });
    }
  };
