import {
    Button,
    Card,
    CardHeader,
    Container,
    Modal,
    Stack,
    TextField,
  } from '@mui/material';
import { useState } from 'react';
  import Page from 'src/components/Page';
  import useSettings from 'src/hooks/useSettings';
  
  const ApproveModal = ({  openModal, close, handleApprove }) => {
    const { themeStretch } = useSettings();
    const [job, setJob] = useState('')
   
  
    return (
      <>
        <Modal
          open={openModal}
          onClose={close}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <Page sx={{ width: '40%' }}>
            <Container maxWidth={themeStretch ? false : 'xl'}>
              <Card sx={{ backgroundColor: '#ffff', pb: '20px' }}>
                <Stack justifyContent='center' alignItems='center' sx={{m:'20px', p:'20px'}} >

                <CardHeader title="Approve To Delete File Request?" sx={{ mb: '20px' }} />
                <TextField fullWidth variant='standard' label='Job Name' onChange={(e)=> setJob(e.target.value)} />
                <Stack direction='row' gap ={4} sx={{mt:'20px'}}>
                    <Button variant='contained' onClick={close}>
                        Cancel
                    </Button>
                    <Button variant='contained' disabled= {job ===''} onClick={handleApprove} >
                        Confirm
                    </Button>
                </Stack>
                </Stack>
                
              </Card>
            </Container>
          </Page>
        </Modal>
      </>
    );
  };
  
  export default ApproveModal;
  