import { Button, Card, CardContent, Container, Grid, Typography } from '@mui/material';
import Page from '../../../components/Page';
import { Link } from 'react-router-dom';

export const INITIAL_DATA = 'INITIAL_DATA';

export default function Analytics() {
  // const [dateAnalysisType, setDateAnalysisType] = useState('ACCESSED');
  // const handleDateFilter = (type) => {
  //   setDateAnalysisType(type);
  // };

  const TilesData = [ 
    {link:"/dashboard/analytics/storagegraph",name:"Analytics"},
    {link:"/dashboard/analytics/duplicatedatachart",name:"Duplicate Data Chart"},
    {link:"/dashboard/analytics/rootsharetable",name:"Analyzed Share Volume(s)"},
    {link:"/dashboard/analytics/totalfilevolume",name:"Volume by File Type"},
    {link:"/dashboard/analytics/stackedchartmodified",name:"FileShare Charts by Date Modified"},
    {link:"/dashboard/analytics/stackedchart",name:"FileShare Charts by Date Accessed"},
    {link:"/dashboard/analytics/largestfolders",name:"Largest folder(s) by Size"},
  ]
  return (
    <>
    <Page>
        {/* <Grid container spacing={3}> */}
        <Card sx={{display:"flex",flexWrap:"wrap",p:5}} >
          {TilesData.map((item,i)=>{
            return(
            // <Grid item xs={12} md={12}>
            //   <Link  to={item.link} style={{ textDecoration: 'none' }}>
            //   <Button  variant="contained"
            //     sx={{ color: '#ffff', height: '40px', m: '20px' ,textDecoration:"none"}} >{item.name}</Button>
            //   </Link>
            // </Grid>
            <Card sx={{ margin: "10px", minWidth: "350px", maxWidth: "360px" ,border:"3px solid #464647",boxShadow:"5px 5px #464647"}}>
              <Link  to={item.link} style={{ textDecoration: 'none' }}>
              <CardContent>
               <Typography variant='h6' color="black">
                {item.name}
               </Typography>
              </CardContent>
                </Link>
            </Card>
               )
            })}
            </Card>
    </Page>
    </>
  );
}
