// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
}; 

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  system: {
    root: path(ROOTS_DASHBOARD, '/system'),
    systemStatus: path(ROOTS_DASHBOARD, '/system-status'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'), 
    repositoryReport: path(ROOTS_DASHBOARD, '/repository-report'),
    jobStatus: path(ROOTS_DASHBOARD, '/job-status'),
    jobsStatus: path(ROOTS_DASHBOARD, '/jobs-status'),
    fileExplorer: path(ROOTS_DASHBOARD, '/file-explorer'),
    ediscovery: path(ROOTS_DASHBOARD, '/e-discovery'),
    auditLogin: path(ROOTS_DASHBOARD, '/audit-login-log'),
    privUserHistory: path(ROOTS_DASHBOARD, '/user-search-history'),
    auditAccess: path(ROOTS_DASHBOARD, '/audit-access-log'),
    deleteCodes: path(ROOTS_DASHBOARD, '/delete-codes'),
    applicationEvent: path(ROOTS_DASHBOARD, '/user-activity'),
    analyticsByPath: path(ROOTS_DASHBOARD, '/analytics-restricted-by-path'),
  },
  analytics: {
    root: path(ROOTS_DASHBOARD, '/analytics'),
    Analytics: path(ROOTS_DASHBOARD, '/analytics/storagegraph'),
    DuplicateDataChart: path(ROOTS_DASHBOARD, '/analytics/duplicatedatachart'), 
    AnalyzedShareVolume: path(ROOTS_DASHBOARD, '/analytics/rootsharetable'),
    VolumebyFileType: path(ROOTS_DASHBOARD, '/analytics/totalfilevolume'),
    FileShareChartsbyDateModified: path(ROOTS_DASHBOARD, '/analytics/stackedchartmodified'),
    FileShareChartsbyDateAccessed: path(ROOTS_DASHBOARD, '/analytics/stackedchart'),
    LargestfolderbySize: path(ROOTS_DASHBOARD, '/analytics/largestfolders'),
  },
  configuration: {
    root: path(ROOTS_DASHBOARD, '/configuration'),
    jobManagement: path(ROOTS_DASHBOARD, '/job-list'),
    policyManagement: path(ROOTS_DASHBOARD, '/policy-management'), 
    documentClassifier: path(ROOTS_DASHBOARD, '/document-classifier'),
    archiveAgents: path(ROOTS_DASHBOARD, '/agent-registration'),
    stubAgent: path(ROOTS_DASHBOARD, '/stub-agents'),
    volumeManagement: path(ROOTS_DASHBOARD, '/volume-management'),
    companyList: path(ROOTS_DASHBOARD, '/company-list'),
  },
  userGroup: {
    root: path(ROOTS_DASHBOARD, '/user-group'),
    userManagement: path(ROOTS_DASHBOARD, '/user-management'),
    accountPolicy: path(ROOTS_DASHBOARD, '/account-policy'),
    companyList: path(ROOTS_DASHBOARD, '/company-list'),
  },
  systemConfiguration: {
    root: path(ROOTS_DASHBOARD, '/system-configuration'),
    globalConfiguration: path(ROOTS_DASHBOARD, '/global-configuration'),
    rolePermissions: path(ROOTS_DASHBOARD, '/role-permissions'),
    buildInfo: path(ROOTS_DASHBOARD, '/build-info'), 
    customBranding: path(ROOTS_DASHBOARD, '/custom-branding'),
    ldspConfiguration: path(ROOTS_DASHBOARD, '/ldap-configuration'),
    stubAgent: path(ROOTS_DASHBOARD, '/stub-agents'),
    volumeManagement: path(ROOTS_DASHBOARD, '/volume-management'),
    userFeature: path(ROOTS_DASHBOARD, '/user-feature'),
  },
  licenseConfig :{
    root: path(ROOTS_DASHBOARD, '/license-config'),
    license: path(ROOTS_DASHBOARD, '/license-details'),
  },


  
  // general: {
  //   app: path(ROOTS_DASHBOARD, '/app'),
  //   ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
  //   analytics: path(ROOTS_DASHBOARD, '/analytics'),
  //   banking: path(ROOTS_DASHBOARD, '/banking'),
  //   booking: path(ROOTS_DASHBOARD, '/booking')
  // },
  // mail: {
  //   root: path(ROOTS_DASHBOARD, '/mail'),
  //   all: path(ROOTS_DASHBOARD, '/mail/all')
  // },
  // chat: {
  //   root: path(ROOTS_DASHBOARD, '/chat'),
  //   new: path(ROOTS_DASHBOARD, '/chat/new'),
  //   conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey')
  // },
  // calendar: path(ROOTS_DASHBOARD, '/calendar'),
  // kanban: path(ROOTS_DASHBOARD, '/kanban'),
  // user: {
  //   root: path(ROOTS_DASHBOARD, '/user'),
  //   profile: path(ROOTS_DASHBOARD, '/user/profile'),
  //   cards: path(ROOTS_DASHBOARD, '/user/cards'),
  //   list: path(ROOTS_DASHBOARD, '/user/list'),
  //   newUser: path(ROOTS_DASHBOARD, '/user/new'),
  //   editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  //   account: path(ROOTS_DASHBOARD, '/user/account')
  // },
  // eCommerce: {
  //   root: path(ROOTS_DASHBOARD, '/e-commerce'),
  //   shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
  //   product: path(ROOTS_DASHBOARD, '/e-commerce/product/:name'),
  //   productById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  //   list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
  //   newProduct: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
  //   editById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
  //   checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),

  // },
  // blog: {
  //   root: path(ROOTS_DASHBOARD, '/blog'),
  //   posts: path(ROOTS_DASHBOARD, '/blog/posts'),
  //   post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
  //   postById: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  //   newPost: path(ROOTS_DASHBOARD, '/blog/new-post')
  // }
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
