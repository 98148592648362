import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Grid } from '@mui/material';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import LoadingScreen from 'src/components/LoadingScreen';
import { getFTPUsers, getUsers } from './usersActions';
import UsersTable from './components/UsersTable';

export default function UserManagement() {
  const dispatch = useDispatch();
  const { themeStretch } = useSettings();
  const users = useSelector((state) => state.usersReducer.users);
  const ftpUsers = useSelector((state) => state.usersReducer.ftpUsers);
  const loading = useSelector((state) => state.usersReducer.loading);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  useEffect(() => {
    dispatch(getUsers({ page, size: rowsPerPage }));
    dispatch(getFTPUsers());
  }, [page, rowsPerPage]);

  const userColumns = ['User Name', 'Role', 'First Name', 'Last Name', 'Lock Status' , 'Action'];
  const ftpUserColumns = ['User Name', 'Company', 'Action'];

  return (
    <Page>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} style={{ paddingTop: '1px' }}>
            { !loading ? (
              users.content &&
              <UsersTable
                pages={[page, setPage]}
                size={[rowsPerPage, setRowsPerPage]}
                users={users}
                ftpUsers={ftpUsers}
                userColumns={userColumns}
                ftpUserColumns={ftpUserColumns}
              />
            ): <LoadingScreen/>}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
