import { get } from "lodash";
import { useEffect, useState } from "react";
import { Outlet } from "react-router";
import { BASE_URL } from "src/config";
import axiosInstance from "src/utils/axios";
import { getHeaders } from "src/utils/fetchServices";
import LicsenceExpired from "./LicsenceExpired";
import LoadingScreen from "./LoadingScreen";

const CheckLicense = () => {

    const [isLicenseLoading,setIsLicenseLoading] = useState(false)
    const [isExpired,setIsExpired] = useState(false)

    const checkLicense = async () => {
        setIsLicenseLoading(true);
        const headers = getHeaders();
        await axiosInstance
          .get(`${BASE_URL}license`, { headers })
          .then((res) => {
            if (get(res, 'status') === 200) {
              if (
                get(res, 'data.expiryDate') &&
                get(res, 'data.mode') &&
                get(res, 'data.mode')?.toLowerCase() === 'trial'
              ) {
                const expiryDate = new Date(get(res, 'data.expiryDate', ''));
                const currentDate = new Date();
                if (currentDate > expiryDate) {
                  // expired
                  setIsExpired(true);
                }
              }
            }
          })
          .finally(() => setIsLicenseLoading(false));
      };

    useEffect(() => {
        checkLicense();
      }, []);
    
    // if (!Role?.role) {
    //   return <Navigate to={`${APP_ROUTER_BASE_URL}login`} state={{ from: location }} replace />;
    // }
    // if (allowedRoles?.includes(role)) {
    if(isLicenseLoading){
        return <LoadingScreen />
    }
    if(isExpired){
        return <LicsenceExpired />
    }
      return <Outlet />;
    // }
    // return <Navigate to={`${APP_ROUTER_BASE_URL}unAuthorized`} state={{ from: location }} replace />;
  };
  
  
  export default CheckLicense;