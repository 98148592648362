import {DOWNLOAD_SUCCESS, AUDITACCESS_FETCH_SUCCESS, DATA_LOADER, FETCH_FAIL, USERS_ACCESS_FETCH_SUCCESS, } from './auditAccessActions';

const initialState = {
  loading: false,
  users: {},
  roles: [],
  usersAccess: [],
  error: null,
};

const auditAccessReducer = (state = initialState, action) => {
    switch (action.type) {
        case DATA_LOADER: {
          return {
            ...state,
            loading: true,
          };
        }
        case AUDITACCESS_FETCH_SUCCESS: {
          return {
            ...state,
            loading: false,
            users: action.payload
          };
        }
        case USERS_ACCESS_FETCH_SUCCESS: {
          return {
            ...state,
            loading: false,
            usersAccess: action.payload
          };
        }
        case DOWNLOAD_SUCCESS: {
          return {
            ...state,
            loading: false,
          };
        }
        case FETCH_FAIL:
            return {
            ...state,
            loading: false,
            error: action.payload,
            };
        default: {
            return state;
        }
    }

}
export default auditAccessReducer;