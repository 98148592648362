import React, { useEffect, useState } from 'react';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import {
  Accordion,
  AccordionDetails,
  Typography,
  AccordionSummary,
  Box,
  Button,
  Tabs,
  Tab,
  Card,
  CardContent,
  Checkbox,
  Container,
  TableHead,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import jwt from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import { getAuditAccessLog, proxySerevr, getUsers } from '../fileActions';
import { PostDetails } from 'src/utils/fetchServices';
import { ERROR_ALERT, SUCCESS_ALERT } from 'src/redux/reducers/alertReducer';
import DeleteModal from './DeleteModals';
import StubModal from './StubModal';
import { downloadFile } from '../fileActions';
import RestoreFolder from './RestoreFolder';
import { getLicenseDetails } from 'src/pages/license/licenseActions';

const DetailsView = ({ node, counts, agent, stubData, selectData, detailData, roleAccess }) => {
  const [tab, setTab] = useState(0);
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const auditLog = useSelector((state) => state.fileReducer.auditLog);
  const users = useSelector((state) => state.fileReducer.users);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [open, setOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openStubModal, setOpenStubModal] = useState(false);
  const [openRestore, setOpenRestore] = useState(false);
  const [detailsArray, setDetailsArray] = detailData;
  const [userInfo, setUserInfo] = useState([]);
  const access_token = localStorage.getItem('accessToken');
  const [rolename, setRolename] = useState();
  const license = useSelector((state) => state.licenseReducer.license);
  useEffect(() => {
    if (!license) {
      dispatch(getLicenseDetails());
    }
  }, []);
  useEffect(() => {
    if (access_token) {
      const decodedToken = jwt(access_token);
      setRolename(decodedToken.authorities[0]);
    }
  }, [access_token]);

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  useEffect(() => {
    let data = [];
    if (users.content && users.content.length) {
      data = users.content.filter((item) => item.roleName == 'DATAGUARDIAN');
    }
    setUserInfo([...data]);
  }, [users]);

  useEffect(() => {
    if (tab === 3) getAccessLogData();
  }, [page, pageSize, tab]);

  useEffect(() => {
    if (Object.keys(node).length) setOpen(true);
    let setarray = new Set(detailsArray);
    setDetailsArray([...setarray]);
    setTab(0);
  }, [node]);

  const handleChange = (e, newValue) => {
    setTab(newValue);
  };

  const getAccessLogData = () => {
    if (Object.keys(node).length) {
      let startDate = new Date(new Date().setFullYear(new Date().getFullYear() - 5)).toISOString().split('T');
      let requestDate = startDate[0] + ' ' + startDate[1].split('.')[0];
      let params = {
        currentPage: page + 1,
        itemsPerPage: pageSize,
        lastRepoAccessedEndDate: new Date(),
        lastRepoAccessedStartDate: requestDate,
        guid: node.uuid,
      };
      dispatch(getAuditAccessLog(params));
    }
  };

  const formatPathString = (filePath) => {
    if (filePath[filePath.length - 1] == '\\') return filePath.substr(0, filePath.length - 1);
    else return filePath;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value));
    setPage(0);
  };

  const deleteFile = () => {
    setOpenDeleteModal(true);
  };

  const restoreFile = () => {
    setOpenRestore(true);
  };

  const downloadData = (selectedFile) => {
    dispatch(
      downloadFile(
        {
          version: '0',
          plainUuid: selectedFile.uuid,
        },
        selectedFile
      )
    );
  };

  const stub = (childNode) => {
    setOpenStubModal(true);
    dispatch(proxySerevr());
  };

  const reIndex = async (childNode) => {
    let stub_file = {
      companyId: localStorage.getItem('companyId'),
      uuid: childNode.uuid,
    };
    let message = childNode.type === 'nt:folder' ? 'Folder reindexed successfully' : 'File reindexed successfully';
    try {
      const response = await PostDetails({ url: 'file-explorer/index', body: stub_file });
      if (response.status === 200) dispatch({ type: SUCCESS_ALERT, payload: message });
      else dispatch({ type: ERROR_ALERT, payload: 'Something went wrong' });
    } catch (e) {
      dispatch({ type: ERROR_ALERT, payload: 'Something went wrong' });
    }
  };

  return (
    <Page>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <DeleteModal
          openModal={openDeleteModal}
          close={() => setOpenDeleteModal(false)}
          node={node}
          userInfo={userInfo}
        />
        <StubModal
          agent={agent}
          stubData={stubData}
          openModal={openStubModal}
          close={() => setOpenStubModal(false)}
          node={node}
          selectStub={selectData}
        />
        <RestoreFolder
          agent={agent}
          stubData={stubData}
          openModal={openRestore}
          close={() => setOpenRestore(false)}
          node={node}
          selectRestore={selectData}
        />
        <Card sx={{ my: '20px' }}>
          <Accordion expanded={open} onChange={(ev, expanded) => setOpen(expanded)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography variant="h6">{node && node['name'] ? node['name'] : ''} properties</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box textAlign="right">
                {roleAccess?.length ? (
                  roleAccess.map((data) => {
                    if (
                      data.name == 'fileExplorer.stub' &&
                      ((rolename == 'LDAP' && data.ldapAllowed) ||
                        (rolename == 'PRIVILEGED' && data.privAllowed) ||
                        (rolename == 'ADMINISTRATOR' && data.adminAllowed))
                    ) {
                      return (
                        <Button variant="contained" onClick={() => stub(node)} sx={{ color: '#fff', ml: '10px' }}>
                          Stub
                        </Button>
                      );
                    }
                  })
                ) : (
                  <Button variant="contained" onClick={() => stub(node)} sx={{ color: '#fff', ml: '10px' }}>
                    Stub
                  </Button>
                )}
                {roleAccess?.length ? (
                  roleAccess.map((data) => {
                    if (
                      data.name == 'fileExplorer.reindex' &&
                      ((rolename == 'LDAP' && data.ldapAllowed) ||
                        (rolename == 'PRIVILEGED' && data.privAllowed) ||
                        (rolename == 'ADMINISTRATOR' && data.adminAllowed))
                    ) {
                      return (
                        <Button variant="contained" onClick={() => reIndex(node)} sx={{ color: '#fff', ml: '10px' }}>
                          Reindex
                        </Button>
                      );
                    }
                  })
                ) : (
                  <Button variant="contained" onClick={() => reIndex(node)} sx={{ color: '#fff', ml: '10px' }}>
                    Reindex
                  </Button>
                )}

                {node['type'] != 'nt:folder' &&
                  (roleAccess?.length ? (
                    roleAccess.map((data) => {
                      if (
                        data.name == 'fileExplorer.download' &&
                        ((rolename == 'LDAP' && data.ldapAllowed) ||
                          (rolename == 'PRIVILEGED' && data.privAllowed) ||
                          (rolename == 'ADMINISTRATOR' && data.adminAllowed))
                      ) {
                        return (
                          <Button
                            variant="contained"
                            onClick={() => downloadData(node)}
                            sx={{ color: '#fff', ml: '10px' }}
                          >
                            Download
                          </Button>
                        );
                      }
                    })
                  ) : (
                    <Button variant="contained" onClick={() => downloadData(node)} sx={{ color: '#fff', ml: '10px' }}>
                      Download
                    </Button>
                  ))}

                {roleAccess?.length ? (
                  roleAccess.map((data) => {
                    if (
                      data.name == 'fileExplorer.delete' &&
                      ((rolename == 'LDAP' && data.ldapAllowed) ||
                        (rolename == 'PRIVILEGED' && data.privAllowed) ||
                        (rolename == 'ADMINISTRATOR' && data.adminAllowed))
                    ) {
                      return (
                        <Button
                          variant="contained"
                          onClick={() => deleteFile()}
                          sx={{ color: '#fff', ml: '10px' }}
                          disabled={node && node.isDeleted}
                        >
                          Delete
                        </Button>
                      );
                    }
                  })
                ) : (
                  <Button
                    variant="contained"
                    onClick={() => deleteFile()}
                    sx={{ color: '#fff', ml: '10px' }}
                    disabled={node && node.isDeleted}
                  >
                    Delete
                  </Button>
                )}
                {(counts && counts > 1) || license?.restoreLimitPerJob === -1 ? (
                  roleAccess?.length ? (
                    node['type'] == 'nt:folder' ? (
                      roleAccess.map((data) => {
                        if (
                          data.name == 'fileExplorer.folderRestore' &&
                          ((rolename == 'LDAP' && data.ldapAllowed) ||
                            (rolename == 'PRIVILEGED' && data.privAllowed) ||
                            (rolename == 'ADMINISTRATOR' && data.adminAllowed))
                        ) {
                          return (
                            <Button
                              variant="contained"
                              onClick={() => restoreFile()}
                              sx={{ color: '#fff', ml: '10px' }}
                            >
                              Restore Folder
                            </Button>
                          );
                        }
                      })
                    ) : (
                      roleAccess.map((data) => {
                        if (
                          data.name == 'fileExplorer.fileRestore' &&
                          ((rolename == 'LDAP' && data.ldapAllowed) ||
                            (rolename == 'PRIVILEGED' && data.privAllowed) ||
                            (rolename == 'ADMINISTRATOR' && data.adminAllowed))
                        ) {
                          return (
                            <Button
                              variant="contained"
                              onClick={() => restoreFile()}
                              sx={{ color: '#fff', ml: '10px' }}
                            >
                              Restore File
                            </Button>
                          );
                        }
                      })
                    )
                  ) : (
                    <Button variant="contained" onClick={() => restoreFile()} sx={{ color: '#fff', ml: '10px' }}>
                      Restore {node['type'] == 'nt:folder' ? 'Folder' : 'File'}
                    </Button>
                  )
                ) : (
                  ''
                )}
                {/* {node['type']!='nt:folder' && fileRestoreData['restoreFile'].length && <Button onClick={() => restoreFile()}>Restore</Button>} */}
                {/* {node['type']=='nt:folder' && folderLevel > 4 && <Button onClick={() => restoreFolder()} >Restore Folder</Button>}  */}
              </Box>

              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="Summary" sx={{ px: 3 }} />
                  <Tab label="Security" sx={{ px: 3 }} />
                  <Tab label="Details" sx={{ px: 3 }} />
                  <Tab label="Access Log" sx={{ px: 3 }} />
                </Tabs>
              </Box>

              <Box hidden={tab !== 0}>
                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ p: 0, pt: 1 }}>Name</TableCell>
                        <TableCell sx={{ p: 0, pt: 1 }}>{node && node['name'] ? node['name'] : ''}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ p: 0, pt: 1 }}>Path</TableCell>
                        <TableCell sx={{ p: 0, pt: 1 }}>
                          {node && node['path'] ? formatPathString(node['path']) : ''}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ p: 0, pt: 1 }}>Type</TableCell>
                        <TableCell sx={{ p: 0, pt: 1 }}>
                          {node && node['fileTypeDesc'] ? node['fileTypeDesc'] : ''}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ p: 0, pt: 1 }}>Size</TableCell>
                        <TableCell sx={{ p: 0, pt: 1 }}>{node && node['fileSize'] ? node['fileSize'] : ''}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ p: 0, pt: 1 }}>Category</TableCell>
                        <TableCell sx={{ p: 0, pt: 1 }}>{node && node['tags'] ? node['tags'] : ''}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ p: 0, pt: 1 }}>FileSystem Last Accessed</TableCell>
                        <TableCell sx={{ p: 0, pt: 1 }}>
                          {node && node['legacyLastAccessed'] ? node['legacyLastAccessed'] : ''}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ p: 0, pt: 1 }}>Created Date</TableCell>
                        <TableCell sx={{ p: 0, pt: 1 }}>
                          {node && node['createdDate'] ? node['createdDate'] : ''}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ p: 0, pt: 1 }}>Archive Date</TableCell>
                        <TableCell sx={{ p: 0, pt: 1 }}>
                          {node && node['archivedDate'] ? node['archivedDate'] : ''}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ p: 0, pt: 1 }}>Last Modified</TableCell>
                        <TableCell sx={{ p: 0, pt: 1 }}>
                          {node && node['lastModified'] ? node['lastModified'] : ''}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ p: 0, pt: 1 }}>Last Accessed</TableCell>
                        <TableCell sx={{ p: 0, pt: 1 }}>
                          {node && node['lastAccessed'] ? node['lastAccessed'] : ''}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ p: 0, pt: 1 }}>Duplicate file</TableCell>
                        {node && node['type'] && node['type'] == 'nt:linkedFile' && (
                          <TableCell sx={{ p: 0, pt: 1 }}>
                            {node && node['type'] && node['type'] == 'nt:linkedFile' ? node['linkedToNode'] : ''}
                            &nbsp;&nbsp;
                          </TableCell>
                        )}
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box hidden={tab !== 1}>
                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ p: 0, pt: 1 }}>Security Allow</TableCell>
                        <TableCell sx={{ p: 0, pt: 1 }}>
                          {node && node['securityAllowGroupNames'] ? node['securityAllowGroupNames'] : ''}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ p: 0, pt: 1 }}>Security Deny</TableCell>
                        <TableCell sx={{ p: 0, pt: 1 }}>
                          {node && node['securityDenyGroupNames'] ? node['securityDenyGroupNames'] : ''}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ p: 0, pt: 1 }}>Share Allow</TableCell>
                        <TableCell sx={{ p: 0, pt: 1 }}>
                          {node && node['shareAllowGroupNames'] ? node['shareAllowGroupNames'] : ''}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ p: 0, pt: 1 }}>Share Deny</TableCell>
                        <TableCell sx={{ p: 0, pt: 1 }}>
                          {node && node['shareDenyGroupNames'] ? node['shareDenyGroupNames'] : ''}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ p: 0, pt: 1 }}>Shared With</TableCell>
                        <TableCell sx={{ p: 0, pt: 1 }}>
                          {node && node['sharedWithGroupNames'] ? node['sharedWithGroupNames'] : ''}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ p: 0, pt: 1 }}>Read Only</TableCell>
                        <TableCell sx={{ p: 0, pt: 1 }}>
                          {node && node['readOnlyUsernames'] ? node['readOnlyUsernames'] : ''}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ p: 0, pt: 1 }}>Read/Write</TableCell>
                        <TableCell sx={{ p: 0, pt: 1 }}>
                          {node && node['readWriteUsernames'] ? node['readWriteUsernames'] : ''}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box hidden={tab !== 2}>
                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ p: 0, pt: 1 }}>UUID</TableCell>
                        <TableCell sx={{ p: 0, pt: 1 }}>{node && node['uuid'] ? node['uuid'] : ''}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ p: 0, pt: 1 }}>UNC Path</TableCell>
                        <TableCell sx={{ p: 0, pt: 1 }}>{node && node['uncpath'] ? node['uncpath'] : ''}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ p: 0, pt: 1 }}>Filestore Tag</TableCell>
                        <TableCell sx={{ p: 0, pt: 1 }}>
                          {node && node['filestoreTag'] ? node['filestoreTag'] : ''}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box hidden={tab !== 3}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ p: 0, pt: 1 }}>Accessed Date/Time</TableCell>
                        <TableCell sx={{ p: 0, pt: 1 }}>Access Type</TableCell>
                        <TableCell sx={{ p: 0, pt: 1 }}>Accessed By</TableCell>
                        <TableCell sx={{ p: 0, pt: 1 }}>User Role</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {auditLog?.list?.length ? (
                        auditLog?.list?.map((data) => (
                          <TableRow>
                            <TableCell sx={{ p: 0, pt: 1 }}>{data['repoAccessedOn']}</TableCell>
                            <TableCell sx={{ p: 0, pt: 1 }}>{data['accessType']}</TableCell>
                            <TableCell sx={{ p: 0, pt: 1 }}>{data['userId']}</TableCell>
                            <TableCell sx={{ p: 0, pt: 1 }}>{data['userRole']}</TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableCell colSpan={4}>
                          <Typography textAlign="center">0 access log entries found</Typography>
                        </TableCell>
                      )}
                    </TableBody>
                  </Table>

                  <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 100]}
                    component="div"
                    count={auditLog?.totalCount}
                    rowsPerPage={pageSize}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableContainer>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Card>
      </Container>
    </Page>
  );
};

export default DetailsView;
