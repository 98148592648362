import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';

const AddDocumentCategoryTable = ({ columns, rows, handleDelete }) => {

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column, i) => (
                <TableCell key={i} align={column === 'Action' ? 'right' : 'left'}>
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows &&
              rows.map((row, i) => (
                <TableRow key={i}>
                  <TableCell sx={{p:0,pt:1}}>{row.name}</TableCell>
                  <TableCell sx={{p:0,pt:1}}>{row.value}</TableCell>
                  <TableCell align="right" sx={{p:0,pt:1}}>
                    <Button onClick={() => handleDelete(i)} >Delete</Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default AddDocumentCategoryTable;
