import { Container, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingScreen from 'src/components/LoadingScreen';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import DataRetensionTable from './components/DataRetensionTable';
import { getRetensionData } from './dataRetensionActions';

const DataRetension = () => {
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const documents = useSelector(state => state.dataRetensionReducer.documents)
  const loading = useSelector(state => state.dataRetensionReducer.loading)
  const refresh = useSelector(state => state.dataRetensionReducer.refresh)

  useEffect(()=> {
    dispatch(getRetensionData());
  },[refresh])


  return (
    <Page>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} style={{ paddingTop: '1px' }}>
            { !loading ? (
            documents &&
            <DataRetensionTable
              documents={documents}
            />
          ): <LoadingScreen />}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default DataRetension;
