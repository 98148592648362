import {
  Box,
  Card,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  TableContainer,
  Typography,
} from '@mui/material';
import Scrollbar from '../../../../components/Scrollbar';
import moment from 'moment';

export default function JobLogTable({ jobLogColumns, jobLogs, handleDownload, close }) {
  const dateFormat = (startTime) => {
    return moment(startTime).format('MMMM Do YYYY, h:mm:ss a');
  };
  return (
    <div style={{ backgroundColor: '#fff', padding: '5px', borderRadius: '10px' }}>
      <Card sx={{ m: '20px', maxHeight: '500px', overflow: 'auto' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <CardHeader title={'Job Logs'} />
          <Button variant="contained" sx={{ color: '#ffff', height: '40px', m: '20px' }} onClick={close}>
            Close
          </Button>
        </Box>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 720 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {jobLogColumns.map((column, i) => (
                    <TableCell key={i}>{column}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {jobLogs &&
                  jobLogs.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.id}</TableCell>
                      <TableCell>{row.fileName}</TableCell>

                      <TableCell>{dateFormat(row.lastModifiedDate)}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          sx={{ color: '#ffff', mr: '10px' }}
                          onClick={() => handleDownload(row.id, row.fileName)}
                        >
                          Download
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Card>
    </div>
  );
}
