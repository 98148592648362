import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { downloadExportPageFile } from '../ediscoveryActions';
import { get } from 'lodash';

const ExportTable = ({ columns, rows }) => {
  const dispatch = useDispatch();
  const [rowsData, setRowsData] = useState([]);

  useEffect(() => {
    let data = rows.reverse();
    setRowsData(data);
  }, [rows]);
  const downloadData = (id, filePath) => {
    const parts = filePath && filePath.split('.');
    // Return the last part which is the file extension
    const status = parts && parts[parts.length - 1];
    dispatch(downloadExportPageFile(id, status));
  };
  function calculateTimeDifference(startTime, endTime) {
    // Calculate the difference in milliseconds
    const difference = endTime - startTime;

    // Convert the difference into hours, minutes, seconds, and milliseconds
    const hours = Math.floor(difference / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    // Construct the result string based on the calculated values
    let result = '';

    if (hours > 0) {
      result += `${hours} hours`;
    }
    if (minutes > 0) {
      if (result) result += ', ';
      result += `${minutes} minutes`;
    }
    if (hours === 0 && seconds > 0) {
      if (result) result += ', ';
      result += `${seconds} seconds`;
    }

    return result;
  }
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column, i) => (
                <TableCell sx={{ pl: 0 }} key={i}>
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsData.length
              ? rowsData.map((row, i) => (
                <TableRow key={i}>
                  <TableCell sx={{ p: 0 }}>
                    <Button>
                      <FileDownloadIcon onClick={() => downloadData(get(row, 'id'), get(row, 'filePath'))} />
                    </Button>
                  </TableCell>
                  <TableCell sx={{ p: 0 }}>{row.noOfFiles}</TableCell>
                  <TableCell sx={{ p: 0 }}>{moment(row.startTime).format('YYYY-MM-DD HH:mm:ss.SSS')}</TableCell>
                  <TableCell sx={{ p: 0 }}>{moment(row.endTime).format('YYYY-MM-DD HH:mm:ss.SSS')}</TableCell>
                  {/* <TableCell sx={{ p: 0 }}>0.{Math.abs(row.startTime - row.endTime)} second(s)</TableCell> */}
                  <TableCell sx={{ p: 0 }}>{calculateTimeDifference(row?.startTime, row?.endTime)}</TableCell>
                  <TableCell sx={{ p: 0 }}>{row.statusMessage}</TableCell>
                  <TableCell sx={{ p: 0 }}>{row.status}</TableCell>
                </TableRow>
              ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ExportTable;
