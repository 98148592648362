// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import ThemeColorPresets from './components/ThemeColorPresets';
import ThemeLocalization from './components/ThemeLocalization';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import './utils/style.css';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { GetDetails, getHeaders } from './utils/fetchServices';
import axios from 'axios';
import { BASE_URL } from './config';
import axiosInstance from './utils/axios';

// ----------------------------------------------------------------------
const token = localStorage.getItem('accessToken');
export default function App() {
  const companyId = useSelector((state) => state.login.companyId);
  // const token = useSelector((state) => state.login.token);
  
  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    if (companyId != 0) {
      link.href = `${BASE_URL}tenant/logo/${companyId}`;
    }else{
      link.href = `${BASE_URL}global/logo`;
    }
  }, [companyId]);

  const getLicense = () => {
    const headers = getHeaders()
    axiosInstance.get(`${BASE_URL}license`,{headers} ).then(res=> {})
  }

  useEffect(()=> {
    if(token){
      setInterval(getLicense, 15000)
    }
  }, [])

  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <ThemeLocalization>
          <RtlLayout>
            <NotistackProvider>
              <MotionLazyContainer>
                <ProgressBarStyle />
                <ChartStyle />
                {/* <Settings /> */}
                <ScrollToTop />
                <Router />
              </MotionLazyContainer>
            </NotistackProvider>
          </RtlLayout>
        </ThemeLocalization>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}
