import {
  FILE_STORE_FETCH_SUCCESS,
  DATA_LOADER,
  FETCH_FAIL,
  USER_PROFILE_FETCH_SUCCESS,
  REGION_FETCH_SUCCESS,
  ADD_FILESTORE_SUCCESS,
  FILESTORE_INFO_FETCH_SUCCESS,
  CLOUD_PROFILE_FETCH_SUCCESS,
  ADD_CLOUD_PROFILE_SUCCESS,
  PROFILE_INFO_FETCH_SUCCESS,
  DELETED_SUCCESSFULLY,
  SUCCESS,
  FILE_TYPES_DEFAULT_SUCCESS
} from './volumeActions';

const initialState = {
  loading: false,
  fileStoreList: {},
  fileStoreInfo: {},
  profileInfo: {},
  userProfile: [],
  cloudProfile: [],
  region: [],
  defaultDetails:{},
  refresh:false,
  error: null,
};

const volumeReducer = (state = initialState, action) => {
  switch (action.type) {
    case DATA_LOADER: {
      return {
        ...state,
        loading: true,
        refresh:false
      };
    }
    case FILE_STORE_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        fileStoreList: action.payload,
      };
    }
    case USER_PROFILE_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        userProfile: action.payload,
      };
    }
    case FILESTORE_INFO_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        fileStoreInfo: action.payload,
      };
    }
    case REGION_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        region: action.payload,
      };
    }
    case PROFILE_INFO_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        profileInfo: action.payload,
      };
    }
    case CLOUD_PROFILE_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        cloudProfile: action.payload,
      };
    }
    case ADD_FILESTORE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case ADD_CLOUD_PROFILE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case DELETED_SUCCESSFULLY: {
      return {
        ...state,
        loading: false,
        refresh:true
      };
    }
    case SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case FETCH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      case FILE_TYPES_DEFAULT_SUCCESS: {
        return {
          ...state,
          loading: false,
          defaultDetails: action.payload,
        };
      }
    default: {
      return state;
    }
  }
};

export default volumeReducer;
