import { ANALYTICS_FETCH_SUCCESS,POLICIES_FETCH_SUCCESS, DATA_LOADER, FETCH_FAIL, POLICIES_DOCUMENTS_FETCH_SUCCESS, GET_POLICY_SUCCESS, SUCCESS, DELETED_SUCCESSFULLY } from './policyManagementActions';

const initialState = {
  loading: false,
  policies: {},
  analytics: {},
  error: null,
  documents: [],
  policy: null,
  refresh:false
};

const policyManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case DATA_LOADER: {
      return {
        ...state,
        loading: true,
        refresh:false
      };
    }
    case POLICIES_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        policies: action.payload,
      };
    }
    case ANALYTICS_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        analytics: action.payload,
      };
    }
    case SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case POLICIES_DOCUMENTS_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        documents: action.payload,
      };
    }
    case GET_POLICY_SUCCESS: {
      return {
        ...state,
        loading: false,
        policy: action.payload,
      };
    }
    case DELETED_SUCCESSFULLY: {
      return {
        ...state,
        loading: false,
        refresh: true,
      };
    }
    case FETCH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default: {
      return state;
    }
  }
};

export default policyManagementReducer;
