import {
  Card,
  CardHeader,
  Container,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Slider,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import { getAccessedData, getModifiedData, getTotalData } from '../analyticsActions';

const AnalysedPath = ({ analysisPaths }) => {
  const dispatch = useDispatch();
  const { themeStretch } = useSettings();
  const totalData = useSelector((state) => state.analyticsReducers.totalData);
  const modifiedData = useSelector((state) => state.analyticsReducers.modifiedData);
  const accessedData = useSelector((state) => state.analyticsReducers.accessedData);
  const [years, setYears] = useState(0);
  const [path, setPath] = useState();

  useEffect(() => {
    if (path) {
      dispatch(getTotalData({ year: 0, dateAnalysis: 'MODIFIED', path: path }));
      dispatch(getAccessedData({ year: years, dateAnalysis: 'ACCESSED', path: path }));
      dispatch(getModifiedData({ year: years, dateAnalysis: 'MODIFIED', path: path }));
    } else {
      dispatch(getTotalData({ year: 0, dateAnalysis: 'MODIFIED' }));
      dispatch(getAccessedData({ year: years, dateAnalysis: 'ACCESSED' }));
      dispatch(getModifiedData({ year: years, dateAnalysis: 'MODIFIED' }));
    }
  }, [years, path]);

  function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  return (
    <Page>
      {/* <Container maxWidth={themeStretch ? false : 'xl'}> */}
      <Card>
        <Grid container spacing={3} sx={{ m: '20px' }}>
          <Grid item xs={12} md={11} style={{ paddingTop: '1px' }}>
            <FormControl fullWidth>
              <InputLabel id="path">Select Analysed Path</InputLabel>
              <Select
                labelId="path"
                value={path}
                variant="standard"
                label="Select Analysed Path"
                onChange={(e) => setPath(e.target.value)}
              >
                <MenuItem value={null}>Select Path</MenuItem>
                {analysisPaths.map((path, i) => {
                  return (
                    <MenuItem key={i} value={path}>
                      {path}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography>Years : {years}</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Slider
              defaultValue={years}
              steps={20}
              max={20}
              aria-label="Default"
              valueLabelDisplay="auto"
              onChange={(e) => setYears(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={11}>
            <Stack spacing={1}>
              <Typography variant="h5">Size by accessed date</Typography>
              <Stack direction="row" alignItems="center">
                <Typography variant="subtitle2">Data outside archive policy :</Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary', flexGrow: 1, m: '10px' }}>
                  {formatBytes(totalData?.saveVolume - accessedData?.saveVolume)}
                </Typography>
                <Typography variant="subtitle2">Data ready for archiving : </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary', m: '10px' }}>
                  {formatBytes(accessedData.saveVolume)}
                </Typography>
              </Stack>

              <LinearProgress
                variant="determinate"
                value={((totalData?.saveVolume - accessedData?.saveVolume) * 100) / totalData?.saveVolume}
                sx={{ height: '20px' }}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} md={11}>
            <Stack spacing={1}>
              <Typography variant="h5">Size by modified date</Typography>
              <Stack direction="row" alignItems="center">
                <Typography variant="subtitle2">Data outside archive policy :</Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary', flexGrow: 1, m: '10px' }}>
                  {formatBytes(totalData?.saveVolume - modifiedData?.saveVolume)}
                </Typography>
                <Typography variant="subtitle2">Data ready for archiving : </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary', m: '10px' }}>
                  {formatBytes(modifiedData.saveVolume)}
                </Typography>
              </Stack>

              <LinearProgress
                variant="determinate"
                value={((totalData?.saveVolume - modifiedData?.saveVolume) * 100) / totalData?.saveVolume}
                sx={{ height: '20px' }}
              />
            </Stack>
          </Grid>
        </Grid>
      </Card>
      {/* </Container> */}
    </Page>
  );
};

export default AnalysedPath;
