import { Card, CardHeader, Button, Grid, TextField, Checkbox } from '@mui/material';
import React, { useEffect, useState } from 'react';
import jwt from 'jwt-decode';
import { Typography } from '@mui/material';
import { dispatch } from 'src/redux/store';
import { sendLicenseDetails2 } from '../licenseActions';

const LicenseDetails = ({ license, license2 }) => {

  const [licenseData, setLicenseData] = useState(license2)
  const [enableField, setEnableField] = useState("")
  const access_token = localStorage.getItem('accessToken');
  const [admin, setAdminRole] = useState();

  useEffect(() => {
    if (access_token) {
      const decodedToken = jwt(access_token);
      setAdminRole(decodedToken.authorities[0])
    }
  }, [access_token])

  useEffect(() => {
    setLicenseData(license2)
  }, [license2])
  const expireDate = (param) => {
    var dd = param.getDate();
    var mm = param.getMonth() + 1;
    var yyyy = param.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    return yyyy + '/' + mm + '/' + dd;
  };

  useEffect(() => {
    if (admin == 'SUPERADMIN') {
      setEnableField(license2 && license2.extractContent)
    }
  }, [])

  const enableData1 = (e) => {
    setLicenseData({ ...license2, enableSingleCompanyMode: e.target.checked })
  }

  const enableData2 = (e) => {
    setLicenseData({ ...license2, extractContent: e.target.checked })
    setEnableField(e.target.checked)
  }
  const updateValue = (e) => {
    let name = e.target.name;
    let val = e.target.value;
    setLicenseData({ ...licenseData, [name]: val })
  }
  const licenseSubmit = () => {
    dispatch(sendLicenseDetails2(licenseData))
  }

  return (
    <Card>
      <CardHeader title="License Details" />
      {admin == 'SUPERADMIN' ?

        <Grid container spacing={2} sx={{ m: '40px', mt: 1 }}>
          <Grid item xs={12} md={10}>
            <TextField
              defaultValue={licenseData && licenseData.licenseStr || ""}
              name="licenseStr"
              label="License String *"
              variant="standard"
              sx={{ width: '100%' }}
              onChange={(e) => updateValue(e)}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField defaultValue={licenseData && licenseData.indexesURL || ""} label="Indexes URL *"
              name='indexesURL' variant="standard" sx={{ width: '100%' }} onChange={(e) => updateValue(e)}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField defaultValue={licenseData && licenseData.agentName || ""} label="Agent Name" name='agentName' onChange={(e) => updateValue(e)} variant="standard" sx={{ width: '100%' }} />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField defaultValue={licenseData && licenseData.jobLoggingPath || ""} label="Agent Base Logging Path *" name='jobLoggingPath' onChange={(e) => updateValue(e)} variant="standard" sx={{ width: '100%' }} />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              defaultValue={licenseData && licenseData.maxNoOfTries || ""} label="Max Tries To write *"
              name='maxNoOfTries'
              onChange={(e) => updateValue(e)}
              variant="standard" sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              defaultValue={licenseData && licenseData.indexesBasePath || ""}
              label="Indexes Base Path *"
              name='indexesBasePath'
              variant="standard"
              sx={{ width: '100%' }}
              onChange={(e) => updateValue(e)}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              defaultValue={licenseData && licenseData.globalFileStorePath || ""}
              label="Default File Store Path *"
              name='globalFileStorePath'
              variant="standard"
              sx={{ width: '100%' }}
              onChange={(e) => updateValue(e)}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              defaultValue={licenseData && licenseData.awsAccessKeyId || ""}
              label="AWS Access Key ID"
              name='awsAccessKeyId'
              variant="standard"
              type='password'
              sx={{ width: '100%' }}
              onChange={(e) => updateValue(e)}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              defaultValue={licenseData && licenseData.awsSecretAccessKey || ""}
              label="AWS Secret Access Key"
              name='awsSecretAccessKey'
              variant="standard"
              type='password'
              sx={{ width: '100%' }}
              onChange={(e) => updateValue(e)}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              defaultValue={licenseData && licenseData.awsAccessUrl || ""}
              label="AWS Access Url"
              name='awsAccessUrl'
              variant="standard"
              sx={{ width: '100%' }}
              onChange={(e) => updateValue(e)}
            />
          </Grid>

          <Grid item xs={12} md={3} sx={{ display: "flex" }}>
            <Checkbox defaultChecked={licenseData && licenseData.enableSingleCompanyMode || ""}
              name='enableSingleCompanyMode'
              onChange={enableData1}
            />
            <Typography sx={{ marginTop: "12px" }}>
              Enable single company mode
            </Typography>
          </Grid>

          <Grid item xs={12} md={3} sx={{ display: "flex" }} >
            <Checkbox defaultChecked={licenseData && licenseData.extractContent || ""} name='extractContent' onChange={enableData2} />
            <Typography sx={{ marginTop: "12px" }}>
              Enable Index Authentication
            </Typography>
          </Grid>

          <Grid item xs={12} md={5}>
            <TextField
              defaultValue={licenseData && licenseData.esUserName || ""}
              label="Index UserName"
              name='esUserName'
              variant="standard"
              disabled={!enableField ? true : false}
              sx={{ width: '100%' }}
              onChange={(e) => updateValue(e)}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              defaultValue={licenseData && licenseData.esPassword || ""}
              type="password"
              label="Index Password"
              name='esPassword'
              variant="standard"
              disabled={!enableField ? true : false}
              sx={{ width: '100%' }}
              onChange={(e) => updateValue(e)}
            />
          </Grid>
          <Grid item xs={12} md={10}>
            <TextField
              defaultValue={licenseData && licenseData.awsRegion || ""}
              label="Region"
              name='awsRegion'
              variant="standard"
              sx={{ width: '100%' }}
              onChange={(e) => updateValue(e)}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <Button variant="contained" sx={{ color: '#ffff' }} onClick={() => licenseSubmit()} >
              Submit
            </Button>
          </Grid>
        </Grid>
        :
        <Grid container spacing={2} sx={{ m: '40px', mt: 1 }}>
          <Grid item xs={12} md={5}>
            <TextField
              value={license && license.companyName || ""}
              label="Company Name"
              variant="standard"
              sx={{ width: '100%' }}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              value={license && license.contactName || ""}
              label="Contact Name"
              variant="standard"
              sx={{ width: '100%' }}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField value={license && license.email || ""} label="Email" variant="standard" sx={{ width: '100%' }} disabled />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField value={license && license.seialNo || ""} label="Serial No." variant="standard" sx={{ width: '100%' }} disabled />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField value={license && license.mode || ""} label="Mode" variant="standard" sx={{ width: '100%' }} disabled />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              value={expireDate(new Date(license && license.expiryDate || ""))}
              label="Expire Date (YYYY/MM/DD)"
              variant="standard"
              sx={{ width: '100%' }}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              value={`${license && (license.archiveVolLimit / (1024 * 1024 * 1024)).toFixed(3) || ""} GB`}
              label="Archive Volume Limit"
              variant="standard"
              sx={{ width: '100%' }}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              value={license && license.activeArchiveEnabled}
              label="Active Archive Enabled"
              variant="standard"
              sx={{ width: '100%' }}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              value={license && license.contentIndexingEnabled ? 'Enabled' : "Disabled"}
              label="Content Indexing Enabled"
              variant="standard"
              sx={{ width: '100%' }}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              value={license && license.replicationEnabled}
              label="Replication Enabled"
              variant="standard"
              sx={{ width: '100%' }}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              value={license && license.analysisLimit || ""}
              label="Analysis Limit"
              variant="standard"
              sx={{ width: '100%' }}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              value={license && license.archiveLimit || ""}
              label="Archive Limit"
              variant="standard"
              sx={{ width: '100%' }}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              value={license && license.noOfTenantsLimit || ""}
              label="No of Tenants Limit"
              variant="standard"
              sx={{ width: '100%' }}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              value={license && license.remoteAgentLimit || ""}
              label="Remote Agent Limit"
              variant="standard"
              sx={{ width: '100%' }}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              value={license && license.stubAgentLimit || ""}
              label="Stub Agent limit"
              variant="standard"
              sx={{ width: '100%' }}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              value={license && license.restoreLimitPerJob || ""}
              label="Restore Limit Per Job"
              variant="standard"
              sx={{ width: '100%' }}
              disabled
            />
          </Grid>
        </Grid>
      }

    </Card>
  );
};

export default LicenseDetails;
