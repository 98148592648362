import { GetDetails, GetFetch } from 'src/utils/fetchServices';

export const ANALYTICS_FETCH_SUCCESS = 'ANALYTICS_FETCH_SUCCESS';
export const ANALYSIS_PATHS_FETCH_SUCCESS = 'ANALYSIS_PATHS_FETCH_SUCCESS';
export const TOTAL_DATA_FETCH_SUCCESS = 'TOTAL_DATA_FETCH_SUCCESS';
export const ACCESSED_DATA_FETCH_SUCCESS = 'ACCESSED_DATA_FETCH_SUCCESS';
export const MODIFIED_DATA_FETCH_SUCCESS = 'MODIFIED_DATA_FETCH_SUCCESS';
export const BAR_CHART_YEAR_FETCH_SUCCESS = 'BAR_CHART_YEAR_FETCH_SUCCESS';
export const BAR_CHART_MONTH_FETCH_SUCCESS = 'BAR_CHART_MONTH_FETCH_SUCCESS';
export const VOLUME_DATA_FETCH_SUCCESS = 'VOLUME_DATA_FETCH_SUCCESS';
export const FILE_VOLUME_CHART_FETCH_SUCCESS = 'FILE_VOLUME_CHART_FETCH_SUCCESS';
export const CHARTS_VOLUME_FETCH_SUCCESS = 'CHARTS_VOLUME_FETCH_SUCCESS';
export const PATH_LIST_FETCH_SUCCESS = 'PATH_LIST_FETCH_SUCCESS';
export const FOLDER_SIZE_FETCH_SUCCESS = 'FOLDER_SIZE_FETCH_SUCCESS';
export const GRAPH_DATA_LOADER = 'GRAPH_DATA_LOADER';
export const DUPLICATE_FILES = 'DUPLICATE_FILES';
export const FETCH_FAIL = 'FETCH_FAIL';
export const DUPLICATE_DATA_CHART = 'DUPLICATE_DATA_CHART';



export const getAnalyticsBarChartData = (params) => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  dispatch({
    type: GRAPH_DATA_LOADER,
  });

  params.chartType = 'bar';

  try {
    const response = await GetFetch({ url: `analysis/company/${companyId}/query`, params });
    const data = response.data;
    dispatch({
      type: BAR_CHART_YEAR_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
    dispatch({
      type: BAR_CHART_YEAR_FETCH_SUCCESS,
      payload: {},
    });
  }
};

export const getFileChartDownload = (params) => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  dispatch({
    type: GRAPH_DATA_LOADER,
  });

  // params.chartType = 'bar';

  try {
    const response = await GetFetch({ url: `analysis/company/${companyId}/data.csv`, params });
    const data = response.data;
    // dispatch({
    //   type: BAR_CHART_YEAR_FETCH_SUCCESS,
    //   payload: data,
    // });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};
export const getChartsVolume = (params) => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  dispatch({
    type: GRAPH_DATA_LOADER,
  });

  params.chartType = 'barBYYear';

  try {
    const response = await GetFetch({ url: `analysis/company/${companyId}/query`, params });
    const data = response.data;
    dispatch({
      type: CHARTS_VOLUME_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};
export const getPathList = (params) => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  dispatch({
    type: GRAPH_DATA_LOADER,
  });

  try {
    const response = await GetFetch({ url: `analysis/company/${companyId}/path-list`, params });
    const data = response.data;
    dispatch({
      type: PATH_LIST_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};
export const getFolderSize = (params) => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  dispatch({
    type: GRAPH_DATA_LOADER,
  });
  try {
    const response = await GetFetch({ url: `analysis/company/${companyId}/sub-folder-size`, params });
    const data = response.data;
    dispatch({
      type: FOLDER_SIZE_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};
export const getTotalFileVolume = (params) => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  dispatch({
    type: GRAPH_DATA_LOADER,
  });

  params.chartType = 'pie';

  try {
    const response = await GetFetch({ url: `analysis/company/${companyId}/query`, params });
    const data = response.data;
    dispatch({
      type: FILE_VOLUME_CHART_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};

export const getVolumeData = (params) => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  dispatch({
    type: GRAPH_DATA_LOADER,
  });

  try {
    const response = await GetFetch({ url: `charts/company/${companyId}/volume-table-by-path`, params });
    const data = response.data;
    dispatch({
      type: VOLUME_DATA_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};

export const getDuplicateChartData = () => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  dispatch({
    type: GRAPH_DATA_LOADER,
  });
  try {
    const response = await GetFetch({ url: `analysis/company/${companyId}/duplicate-volumes` });
    const data = response.data;
    dispatch({
      type: DUPLICATE_DATA_CHART,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};
export const getDuplicateFiles = (params) => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  dispatch({
    type: GRAPH_DATA_LOADER,
  });
  
  try {
    const response = await GetFetch({ url: `analysis/company/${companyId}/duplicate-files`, params });
    const data = response.data;
    dispatch({
      type: DUPLICATE_FILES,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};

export const getAnalyticsWidgetCount = (startDate, endDate) => async (dispatch) => {
  const body = {
    startDate: startDate,
    endDate: endDate,
  };
  const companyId = localStorage.getItem('companyId')
  try {
    const response = await GetFetch({ url: `analysis/company/${companyId}/widget`, params: body });
    const data = response.data;
    dispatch({
      type: ANALYTICS_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};

export const getAnalysisPaths = () => async (dispatch) => {
  const companyId = localStorage.getItem('companyId')
  try {
    const response = await GetDetails({ url: `jobs/company/${companyId}/analysis-paths`});
    const data = response.data;
    dispatch({
      type: ANALYSIS_PATHS_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};

export const getTotalData = (params) => async (dispatch) => {
  const companyId = localStorage.getItem('companyId')
  try {
    const response = await GetFetch({ url: `analysis/company/${companyId}/before-year`, params});
    const data = response.data;
    dispatch({
      type: TOTAL_DATA_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};
export const getAccessedData = (params) => async (dispatch) => {
  const companyId = localStorage.getItem('companyId')
  try {
    const response = await GetFetch({ url: `analysis/company/${companyId}/before-year`, params});
    const data = response.data;
    dispatch({
      type: ACCESSED_DATA_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};
export const getModifiedData = (params) => async (dispatch) => {
  const companyId = localStorage.getItem('companyId')
  try {
    const response = await GetFetch({ url: `analysis/company/${companyId}/before-year`, params});
    const data = response.data;
    dispatch({
      type: MODIFIED_DATA_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};
