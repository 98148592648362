import axios from 'axios';

// config
import { HOST_API } from '../config';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {


    if (error.response?.data?.error === "invalid_token" || error.response?.status === 401) {

      window.location.href = '/auth/login';
      localStorage.clear();

    }
    // Promise.reject((error.response && error.response.data) || 'Something went wrong')
  }
);

export default axiosInstance;
