import { COMPANY_FETCH_SUCCESS, COMPANY_INFO_SUCCESS, DATA_LOADER, FETCH_FAIL, SUCCESS } from "./companyActions";

const initialState = {
  loading: false,
  companyList: {},
  companyInfo: {},
  error: null,
};

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case DATA_LOADER: {
      return {
        ...state,
        loading: true,
      };
    }
    case COMPANY_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        companyList: action.payload,
      };
    }
    case COMPANY_INFO_SUCCESS: {
      return {
        ...state,
        loading: false,
        companyInfo: action.payload,
      };
    }
    case SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case FETCH_FAIL:
      return {
        ...state,
        loading: false,
      };
    default: {
      return state;
    }
  }
};

export default companyReducer;
