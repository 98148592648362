import {
  Button,
  Card,
  CardHeader,
  Checkbox,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Stack,
  TextField,
  Typography,
  Select,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import BarChart from '../../charts/BarChart';

const ChartDataModal = ({ openModal, close, data }) => {
  const { themeStretch } = useSettings();
  return (
    <>
      <Modal
        open={openModal}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Page sx={{ width: '100%' }}>
          <Container maxWidth={themeStretch ? false : 'xl'}>
            <BarChart
              title={data.pathKey}
              labels={data.label}
              data={data.data}
              showOptions={false}
              pathKey={data.pathKey}
              stackedChart={true}
              onClose={close}
            />
          </Container>
        </Page>
      </Modal>
    </>
  );
};

export default ChartDataModal;
