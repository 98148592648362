import { useTheme } from '@mui/material/styles';
import {
  Box,
  Card,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  Typography,
  TableContainer,
  TablePagination,
  Stack,
} from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Scrollbar from '../../../../components/Scrollbar';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import DeleteAlert from 'src/pages/DeleteAlert';
import { deletePolicy } from '../policyManagementActions';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

export default function PolicyManagementTable({ pages, size, policies, columns,sortData }) {
  const dispatch = useDispatch();
  const [rowsPerPage, setRowsPerPage] = size;
  const [page, setPage] = pages;
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [sort,setSort] = sortData
  const [arrow, setArrow] = useState(false);
  const [active, setActive] = useState();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleDelete = (id) => {
    setDeleteDialog(true);
    setDeleteId(id);
  };

  const removeFileStore = () => {
    dispatch(deletePolicy(deleteId));
  };

  const TableSort =(i)=>{
    setActive(i)
    setArrow(!arrow);
    if(i == 0){
      if(!arrow){
        setSort("name,desc")
      }else{
        setSort("name,asc")
      }
    }
    if(i == 2){
      if(!arrow){
        setSort("description,desc")
      }else{
        setSort("description,asc")
      }
    }
   
  } 

  return (
    <>
      <Card>
      <DeleteAlert remove={removeFileStore} openDialog={[deleteDialog, setDeleteDialog]} />
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <CardHeader title="Policy Management" sx={{ mb: 3 }} />
          <Link to='add' style={{ textDecoration:'none' }}>
            <Button variant="contained" sx={{ color: '#ffff', height: '40px', m: '20px' }}>
              Add New Policy
            </Button>
          </Link>
        </Box>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 720 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column, i) => (
                    <TableCell key={i}>
                        <Stack direction="row" onClick={()=>TableSort(i)} >
                          <Typography style={{ marginRight: '8px' }}>{column}</Typography>
                          {i==0  && active == i ? (
                            arrow == true ? (
                              <ArrowDownwardIcon />
                            ) : (
                              <ArrowUpwardIcon />
                            )
                          ):i==2  && active == i ?(
                            arrow == true ? (
                              <ArrowDownwardIcon />
                            ) : (
                              <ArrowUpwardIcon />
                            )
                          ) : ( i==0 || i ==2  ?
                            <ArrowUpwardIcon size="small" sx={{ opacity: '0', '&:hover': { opacity: '0.5' } }} />:null
                          )}
                        </Stack>
                      </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {policies &&
                  policies.content.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>
                        <Typography sx={{color: '#699ad4' }} >
                          {row.name}
                        </Typography>
                      </TableCell>
                      <TableCell>{row.type ===1 ? 'Inclusion':'Exclusion'}</TableCell>
                      <TableCell>{row.description ==='' ? 'NA':row.description}</TableCell>
                      <TableCell sx={{maxWidth:'100px'}}>
                        <Link to={`edit/${row.id}/edit`}>
                          <Button sx={{minWidth:'fit-content', p:0}}>
                            <ModeEditIcon  />
                          </Button>
                        </Link>
                        <Button sx={{minWidth:'fit-content', p:0}} onClick={()=> handleDelete(row.id)}>
                          <DeleteForeverIcon />
                        </Button>
                        <Link to={`add/${row.id}/add`}>
                        <Button sx={{minWidth:'fit-content', p:0}}>
                          <ContentCopyIcon />
                        </Button>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20, 100]}
              component="div"
              count={policies.totalElements}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Scrollbar>
      </Card>
    </>
  );
}
