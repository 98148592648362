import { INITIAL_DATA } from './Analytics';
import {
  ACCESSED_DATA_FETCH_SUCCESS,
  ANALYSIS_PATHS_FETCH_SUCCESS,
  ANALYTICS_FETCH_SUCCESS,
  BAR_CHART_MONTH_FETCH_SUCCESS,
  BAR_CHART_YEAR_FETCH_SUCCESS,
  CHARTS_VOLUME_FETCH_SUCCESS,
  FETCH_FAIL,
  FILE_VOLUME_CHART_FETCH_SUCCESS,
  FOLDER_SIZE_FETCH_SUCCESS,
  GRAPH_DATA_LOADER,
  MODIFIED_DATA_FETCH_SUCCESS,
  PATH_LIST_FETCH_SUCCESS,
  TOTAL_DATA_FETCH_SUCCESS,
  VOLUME_DATA_FETCH_SUCCESS,
  DUPLICATE_DATA_CHART,
  DUPLICATE_FILES
} from './analyticsActions';

const initialState = {
  loading: false,
  barChartDataByYear: [],
  barChartDataByMonth: null,
  pieChartData: null,
  lineChartData: [],
  discStatus: [],
  count: [],
  analysisPaths: [],
  folderSize: [],
  pathList: [],
  accessedData: {},
  totalData: {},
  modifiedData: {},
  volumeData: {},
  fileVolume: {},
  chartsVolume: {},
  error: null,
  duplicateDataChart:{},
  duplicateFiles:{}
};

const analyticsReducers = (state = initialState, action) => {
  switch (action.type) {
    case GRAPH_DATA_LOADER: {
      return {
        ...state,
        loading: true,
      };
    }
    case BAR_CHART_YEAR_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        barChartDataByYear: action.payload,
      };
    }
    case BAR_CHART_MONTH_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        barChartDataByMonth: action.payload,
      };
    }
    case DUPLICATE_DATA_CHART: {
      return {
        ...state,
        loading: false,
        duplicateDataChart: action.payload,
      };
    }
    case ANALYSIS_PATHS_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        analysisPaths: action.payload,
      };
    }
    case CHARTS_VOLUME_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        chartsVolume: action.payload,
      };
    }
    case ACCESSED_DATA_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        accessedData: action.payload,
      };
    }
    case TOTAL_DATA_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        totalData: action.payload,
      };
    }
    case MODIFIED_DATA_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        modifiedData: action.payload,
      };
    }
    case INITIAL_DATA: {
      return {
        ...state,
        loading: false,
        folderSize: [],
        pathList: [],
      };
    }
    case ANALYTICS_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        count: action.payload,
      };
    }
    case VOLUME_DATA_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        volumeData: action.payload,
      };
    }
    case FILE_VOLUME_CHART_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        fileVolume: action.payload,
      };
    }
    case FOLDER_SIZE_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        folderSize: action.payload,
      };
    }
    case PATH_LIST_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        pathList: action.payload,
      };
    }
    case DUPLICATE_FILES: {
      return {
        ...state,
        loading: false,
        duplicateFiles: action.payload,
      };
    }
    case FETCH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case 'CHART_INITIALSTATE':
      return (state = initialState);
    default: {
      return state;
    }
  }
};

export default analyticsReducers;
