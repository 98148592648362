import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Grid, Card, CardHeader, Stack } from '@mui/material';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import BarChart from '../charts/BarChart';
import PieChart from '../charts/PieChart';
import LineChart from '../charts/LineChart';
import Widgets from './components/Widgets';
import DiscStatus from './components/DiscStatus';
import moment from 'moment';
import {
  getBarChartDataByMonth,
  getBarChartDataByYear,
  getDiscStatus,
  getLineChartData,
  getPieChartData,
  statusWidgetCount,
} from './systemStatusActions';
import LoadingScreen from 'src/components/LoadingScreen';
import chartColors from 'src/utils/chartColors';

export default function SystemStatus() {
  const dispatch = useDispatch();
  const { themeStretch } = useSettings();
  const count = useSelector((state) => state.systemStatusReducers.count);
  const loading = useSelector((state) => state.systemStatusReducers.loading);
  const dates = useSelector((state) => state.systemReducer.dates);
  const { pieChartData, lineChartData, discStatus, barChartDataByMonth } = useSelector((state) => state.systemStatusReducers);
  const barChartDataByYear = useSelector((state) => state.systemStatusReducers.barChartDataByYear);

  const [startDate, setStartDate] = useState(new Date(dates.startDate));
  const [endDate, setEndDate] = useState(new Date());

  const [barChartPerYearData, setBarChartPerYearData] = useState([]);
  const [barChartPerYearLabels, setBarChartPerYearLabels] = useState([]);

  const [barChartPerMonthData, setBarChartPerMonthData] = useState([]);
  const [barChartPerMonthLabels, setBarChartPerMonthLabels] = useState([]);

  const [pieChartForAllData, setPieChartForAllData] = useState([]);
  const [pieChartForAllLabels, setPieChartForAllLabels] = useState([]);

  const [lineChartPerYearData, setLineChartPerYearData] = useState([]);
  const [lineChartPerYearLabels, setLineChartPerYearLabels] = useState([]);

  const [totalSpace, setTotalSpace] = useState();
  const [usedSpace, setUsedSpace] = useState();
  const [filteredDiscStatus, setFilteredDiscStatus] = useState();
  const [stepCount, setStepCount] = useState(1);

  const totalValue = (total) => {
    const dataVolume = total && total.toString().split(' ');
    return total && parseFloat(dataVolume[0]).toFixed(1) + ' ' + dataVolume[1];
  };

  useEffect(() => {
    let unixStartDate = moment(dates.startDate).format('x');
    let unixEndDate = new Date(dates.endDate).setHours(23, 59, 59);
    const companyId = localStorage.getItem('companyId')
    if (companyId != 0) {
      dispatch(statusWidgetCount(unixStartDate, unixEndDate));
      dispatch(getBarChartDataByYear(unixStartDate, unixEndDate));
      dispatch(getBarChartDataByMonth(unixStartDate, unixEndDate));
      dispatch(getPieChartData(unixStartDate, unixEndDate));
      dispatch(getLineChartData(unixStartDate, unixEndDate));
      dispatch(getDiscStatus(unixStartDate, unixEndDate));
    }
  }, [dates]);

  useEffect(() => {
    const labelBucket = [];
    const labels = [];
    const bucketData = [];
    if (barChartDataByYear.aggregations !== undefined && barChartDataByYear.aggregations[2] !== undefined) {
      for (const bucket of barChartDataByYear.aggregations[2].buckets) {
        const year = new Date(bucket.key).getFullYear().toString();
        if (parseInt(year) >= startDate.getFullYear() && parseInt(year) <= endDate.getFullYear()) {
          if (labels.indexOf(year) == -1) {
            labels.push(year);
          }
        }
        setBarChartPerYearLabels(labels.sort());
        if (parseInt(year) >= startDate.getFullYear() && parseInt(year) <= endDate.getFullYear()) {
          for (const item of bucket[3].buckets) {
            if (labelBucket.indexOf(item.key) === -1) {
              labelBucket.push(item.key);
            }
          }
        }
      }

      let i = 0;
      for (const bucketKey of labelBucket) {
        const data = [];
        for (const bucket of barChartDataByYear.aggregations[2].buckets) {
          const year = new Date(bucket.key).getFullYear().toString();
          for (const item of bucket[3].buckets) {
            if (bucketKey == item.key) {
              data[labels.indexOf(year)] = item[1].value / (1024 * 1024 * 1024);
            }
          }
        }
        bucketData.push({ data, label: bucketKey, backgroundColor: chartColors[i++] });
      }

      setBarChartPerYearData(bucketData);
    }
  }, [barChartDataByYear, dates]);

  useEffect(() => {
    const tooltipPercentageData = [];
    const labels = [];
    const bucketData = [];

    if (pieChartData) {
      let total = pieChartData['aggregations'][2].buckets.reduce(function (a, b) {
        return a + b[1].value;
      }, 0);
      for (let bucket of pieChartData['aggregations'][2].buckets) {
        if (labels.indexOf(bucket.key) == -1) labels.push(bucket.key);
        tooltipPercentageData.push((bucket[1].value / total) * 100);
        bucketData.push(bucket[1].value / (1024 * 1024 * 1024));
      }
      setPieChartForAllLabels(labels);
      setPieChartForAllData(bucketData);
    }
  }, [pieChartData, dates]);

  useEffect(() => {
    const labels = [];
    const bucketData = [];
    let data = [];
    let flagedData = [];

    for (let item in lineChartData) {
      if (lineChartData[item].isExtrapolation) {
        flagedData.push(lineChartData[item].volume / (1024 * 1024 * 1024));
      } else {
        data.push(lineChartData[item].volume / (1024 * 1024 * 1024));
      }
      labels.push(new Date(lineChartData[item].year).getFullYear().toString());
    }
    bucketData.push({
      data: data,
      label: 'Size Volume (GB)',
      xlabel: 'Date Modified',
      name: 'Before',
      fill: false,
      showLine: true,
    });
    let otherData = [];
    for (let item of flagedData) {
      let index = data.length + flagedData.indexOf(item);
      otherData[index] = item;
    }
    bucketData.push({
      data: otherData,
      name: 'After',
      fill: false,
      showLine: true,
    });

    setLineChartPerYearData(bucketData);
    setLineChartPerYearLabels(labels);
  }, [lineChartData, dates]);

  useEffect(() => {
    let diskData;
    const filteredData = discStatus?.filter((item) => (
      !item.name.includes('default')
    ));
    const dataWithSamePath = filteredData?.filter((item, index, self) => {
      const matchingItems = self.filter(
        (i) => i.path === item.path && i.totalSpace === item.totalSpace
      );

      return matchingItems.length > 1;
    });
    let remainingData
    if (dataWithSamePath && dataWithSamePath.length > 0) {
      remainingData = filteredData.filter((item) => !dataWithSamePath.includes(item));
    } else {
      remainingData = filteredData;
    }
    diskData = [
      [
        ...remainingData
      ],
      [
        ...dataWithSamePath
      ]
    ]
    setFilteredDiscStatus(diskData);

  }, [discStatus]);

  useEffect(() => {
    const labels = [];
    const bucketData = [];
    let data = [[], []];
    let keys = [];
    let i = 0;

    if (barChartDataByMonth) {
      for (let bucket of barChartDataByMonth['aggregations'][2].buckets) {
        let key = bucket.key_as_string.substring(0, bucket.key_as_string.indexOf('T00'));
        for (let item of bucket[3].buckets) {
          if (item.key == 'nt:node') {
            continue;
          }
          if (labels.indexOf(key) == -1) {
            labels.push(key);
            if (keys.indexOf(item.key) == -1) {
              keys.push(item.key);
              data[keys.indexOf(item.key)][labels.indexOf(key)] = item[1].value / (1024 * 1024 * 1024);
            }
          }
        }
      }
      bucketData.push({ data: data[0], label: keys[0], backgroundColor: chartColors[i++] });
      bucketData.push({ data: data[1], label: keys[1], backgroundColor: chartColors[i++] });

      setBarChartPerMonthData(bucketData);
      setBarChartPerMonthLabels(labels);
    }
  }, [barChartDataByMonth, dates]);

  function numberWithCommas(total) {
    const val = total && total.toString();
    return total && val.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  return (
    <Page>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        {!loading ? (
          <Grid container spacing={3}>
            <Grid item xs={12} md={3} style={{ paddingTop: '1px' }}>
              <Widgets title="Number of files" total={numberWithCommas(count.numberOfFiles || 0)} color="#03a9f4" />
            </Grid>

            <Grid item xs={12} md={3} style={{ paddingTop: '1px' }}>
              <Widgets title="Data Volume" total={totalValue(count.totalVolume || `0 TB`)} color="#f44336" />
            </Grid>

            <Grid item xs={12} md={3} style={{ paddingTop: '1px' }}>
              <Widgets title="Unique files" total={totalValue(count.uniqueVolume || `0 GB`)} color="#ff9800" />
            </Grid>
            <Grid item xs={12} md={3} style={{ paddingTop: '1px' }}>
              <Widgets title="Duplicate files" total={totalValue(count.duplicateVolume || `0 GB`)} color="#607d8b" />
            </Grid>
            <Grid item xs={12} md={12}>
              <BarChart
                labels={barChartPerYearLabels}
                data={barChartPerYearData}
                showOptions={false}
                chartHeading="Stacked Chart showing Volume of files types per"
                title="year"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <PieChart labels={pieChartForAllLabels} data={pieChartForAllData} />
            </Grid>
            <Grid item xs={12} md={8}>
              <LineChart
                labels={lineChartPerYearLabels}
                data={lineChartPerYearData}
                chartHeading="A line graph showing extrapolated data volume"
              />
            </Grid>
            <Grid item display='flex' gap={2} flexDirection='column' xs={12} md={12}>
              <Card>
                <CardHeader title="Disc Status" />
                <Stack spacing={4} sx={{ p: 3 }}>
                  {
                    filteredDiscStatus && filteredDiscStatus[0]?.length > 0 && (
                      <>
                        {
                          filteredDiscStatus[0]?.map((item) => (
                            <DiscStatus totalSpace={item?.totalSpace} usedSpace={item?.usedSpace} label={item?.name} />
                          ))
                        }
                      </>
                    )
                  }
                  {
                    filteredDiscStatus && filteredDiscStatus[1].length > 0 && (
                      <>
                        {
                          <DiscStatus totalSpace={filteredDiscStatus[1][0]?.totalSpace} usedSpace={filteredDiscStatus[1][0]?.usedSpace} label={filteredDiscStatus[1]} />
                        }
                      </>
                    )
                  }
                </Stack>
              </Card>
            </Grid>
            {/* <Grid item xs={12} md={12}>
              <BarChart
                labels={barChartPerMonthLabels}
                data={barChartPerMonthData}
                showOptions={false}
                chartHeading="Stacked chart showing the per month volume of data VS the duplicate data for the same"
                title="month"
              />
            </Grid> */}
          </Grid>
        ) : (
          <LoadingScreen />
        )}
      </Container>
    </Page>
  );
}
