import { deleteInfo, GetDetails, GetFetch, postInfo, putDetails } from 'src/utils/fetchServices';
import { SUCCESS_ALERT,ERROR_ALERT } from 'src/redux/reducers/alertReducer';
import { dispatch } from 'src/redux/store';
export const USERS_FETCH_SUCCESS = 'USERS_FETCH_SUCCESS';
export const ROLES_FETCH_SUCCESS = 'ROLES_FETCH_SUCCESS';
export const FTP_USER_INFO_FETCH_SUCCESS = 'FTP_USER_INFO_FETCH_SUCCESS';
export const USER_ADDED_SUCCESSFULLY = 'USER_ADDED_SUCCESSFULLY';
export const FTP_USERS_FETCH_SUCCESS = 'FTP_USERS_FETCH_SUCCESS';
export const USER_INFO_FETCH_SUCCESS = 'USER_INFO_FETCH_SUCCESS';
export const DATA_LOADER = 'DATA_LOADER';
export const FETCH_FAIL = 'FETCH_FAIL';

export const resetPasswordData=(body)=> async (dispatch)=>{
  try {
    const response = await putDetails({ url: `users/reset-password`, body });
    const data = response.data;
    dispatch({
      type: SUCCESS_ALERT,
      payload: "Reset Password Successfully",
    });
  } catch (error) {
    dispatch({
      type: ERROR_ALERT,
      payload: "Password does not meet pattern",
    });
  }
}

export const getUsers = (params) => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  dispatch({
    type: DATA_LOADER,
  });
  params.order = 'asc';
  params.sort = 'id';
  try {
    const response = await GetFetch({ url: `users/company/${companyId}`, params });
    const data = response.data;
    dispatch({
      type: USERS_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};
export const getFTPUsers = () => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  dispatch({
    type: DATA_LOADER,
  });
  try {
    const response = await GetDetails({ url: `ftpprofile/${companyId}` });
    const data = response.data;
    dispatch({
      type: FTP_USERS_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};
export const getRoles = () => async (dispatch) => {
  dispatch({
    type: DATA_LOADER,
  });
  try {
    const response = await GetDetails({ url: `roles` });
    const data = response.data;
    dispatch({
      type: ROLES_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};
export const getUserInfo = (id) => async (dispatch) => {
  dispatch({
    type: DATA_LOADER,
  });
  try {
    const response = await GetDetails({ url: `users/${id}` });
    const data = response.data;
    dispatch({
      type: USER_INFO_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};
export const getFtpUserInfo = (id) => async (dispatch) => {
  dispatch({
    type: DATA_LOADER,
  });
  try {
    const response = await GetDetails({ url: `ftpprofile/${id}` });
    const data = response.data;
    dispatch({
      type: FTP_USER_INFO_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};

export const addUser = (body, url, method, navigate) => async (dispatch) => {
  dispatch({ type: DATA_LOADER });
  try {
    const response = await postInfo(
      {
        url: url || `users`,
        body,
        method: method || 'POST',
      },
      dispatch
    );
    if (response && response.status === 200) {
      const data = response.data;
      dispatch({
        type: USER_ADDED_SUCCESSFULLY,
        payload: data,
      });
      if (method) {
        dispatch({
          type: SUCCESS_ALERT,
          payload:'User Updated Successfully',
        });
      }else{
        dispatch({
          type: SUCCESS_ALERT,
          payload: url ? 'FTP User Added Successfully' : 'User Added Successfully',
        });
      }
      navigate('/dashboard/user-management');
    } else {
      dispatch({ type: FETCH_FAIL });
    }
  } catch (error) {
    dispatch({ type: FETCH_FAIL });
  }
};

// export const addUser = (body) => async (dispatch) => {
//   dispatch({
//     type: DATA_LOADER,
//   });
//   try {
//     const response = await postInfo({ url: `users`, body });
//     const data = response.data;
//     dispatch({
//       type: USER_ADDED_SUCCESSFULLY,
//       payload: data,
//     });
//   } catch (error) {
//     dispatch({
//       type: FETCH_FAIL,
//       payload: error.message,
//     });
//   }
// };
// export const addFtpProfile = (body) => async (dispatch) => {
//   dispatch({
//     type: DATA_LOADER,
//   });
//   try {
//     const response = await postInfo({ url: `ftpprofile`, body });
//     const data = response.data;
//     dispatch({
//       type: USER_ADDED_SUCCESSFULLY,
//       payload: data,
//     });
//   } catch (error) {
//     dispatch({
//       type: FETCH_FAIL,
//       payload: error.message,
//     });
//   }
// };
// export const updateUser = (body) => async (dispatch) => {
//   dispatch({
//     type: DATA_LOADER,
//   });
//   try {
//     const response = await putDetails({ url: `users`, body });
//     const data = response.data;
//     dispatch({
//       type: USER_ADDED_SUCCESSFULLY,
//       payload: data,
//     });
//   } catch (error) {
//     dispatch({
//       type: FETCH_FAIL,
//       payload: error.message,
//     });
//   }
// };
export const deleteUser = (id) => async (dispatch) => {
  dispatch({
    type: DATA_LOADER,
  });
  try {
    const response = await deleteInfo({ url: `users/${id}` });
    dispatch({type: FETCH_FAIL});
    dispatch({
      type: SUCCESS_ALERT,
      payload: 'User Deleted Successfully',
    });

  } catch (error) {
    dispatch({type: FETCH_FAIL});
  }
};
